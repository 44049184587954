import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {CSSProperties, useCallback, useEffect, useMemo, useState} from "react";
import {cvv_types} from '../../proto/compiled';
import styled from "styled-components";
import {CvvTextInput} from "./text_input";
import {CvvInputField} from "./input";
import {usePlacesWidget} from "react-google-autocomplete";
import {GMAPS_API_KEY} from "../../globals";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface GmapsAddressPickerProps {
    address: cvv_types.IAddress;
    onPlaceSelected: (address: google.maps.places.PlaceResult) => any;
    error?: string
}

export const GmapsAddressPicker = (props: GmapsAddressPickerProps) => {
    const [error, setError] = useState<string | null>(null);
    const [address, setAddress] = useState(props.address.address || '');
    const [place, setPlace] = useState<null | google.maps.places.PlaceResult>(null);

    const {ref} = usePlacesWidget<any>({
        apiKey: GMAPS_API_KEY,
        onPlaceSelected: (place) => {
            if (place.formatted_address) {
                setAddress(place.formatted_address);
            }

            const error = 's spustnega seznama izberite naslov s hišno številko';
            if (!place.address_components) {
                setError(error)
            } else if (place.address_components[0].types[0] !== 'street_number') {
                setError(error)
            } else {
                setError(null);
                props.onPlaceSelected(place);
                setPlace(place);
            }
        },
        options: {
            componentRestrictions: {
                country: ['si'],
            },
            types: ['address'],
        }
    })

    return <Container>
        <CvvInputField label={'NASLOV'} error={error || props.error} input={
            <CvvTextInput
                ref={ref}
                autoComplete={'address-line1'}
                invalid={!!(error || props.error)}
                value={address}
                placeholder={''}
                onChange={(e) => {
                    setAddress(e.target.value);
                    if (place == null && !error) {
                        setError('izberite naslov s spustnega seznama');
                    }
                }}/>
        }/>
        <GMapsShowAddress address={props.address} containerStyle={{
            width: '100%',
            height: '230px'
        }}/>
    </Container>
}

interface GmapsShowAddressProps {
    address?: cvv_types.IAddress;
    zoom?: number;
    containerStyle?: CSSProperties;
}

export const GMapsShowAddress = (props: GmapsShowAddressProps) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);

    const onLoad = useCallback(function callback(map: google.maps.Map) {
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
    }, []);

    const onTilesLoaded = useCallback(() => {
        setTimeout(
            () => {
                [].slice.apply(map?.getDiv().querySelectorAll('div, button, img, a')).forEach((item: any) => {
                    item.setAttribute('tabindex', '-1');
                });
            },
            0
        )
    }, [map]);

    useEffect(() => {
        map?.setCenter(new google.maps.LatLng(
                props.address?.lat || 46.1497487, props.address?.lng || 14.3619146
            )
        );
        map?.setZoom(15);
    }, [map, props.address?.lat, props.address?.lng])

    return <div tabIndex={-1}>
        <GoogleMap
            mapContainerStyle={props.containerStyle}
            center={new google.maps.LatLng(
                props.address?.lat || 46.1497487, props.address?.lng || 14.3619146
            )}
            zoom={props.zoom || 15}
            mapTypeId={'roadmap'}
            onLoad={onLoad}
            onTilesLoaded={onTilesLoaded}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                panControl: false,
                rotateControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                scaleControl: false,
                zoomControl: false,
            }}>
            {props.address?.lat && props.address?.lng &&
                <Marker position={new google.maps.LatLng(props.address.lat, props.address.lng)}/>}
        </GoogleMap>
    </div>;
}