/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const sfiles = $root.sfiles = (() => {

    /**
     * Namespace sfiles.
     * @exports sfiles
     * @namespace
     */
    const sfiles = {};

    sfiles.UploadStartSlot = (function() {

        /**
         * Properties of an UploadStartSlot.
         * @memberof sfiles
         * @interface IUploadStartSlot
         * @property {string|null} [key] UploadStartSlot key
         * @property {string|null} [localKey] UploadStartSlot localKey
         */

        /**
         * Constructs a new UploadStartSlot.
         * @memberof sfiles
         * @classdesc Represents an UploadStartSlot.
         * @implements IUploadStartSlot
         * @constructor
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         */
        function UploadStartSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStartSlot key.
         * @member {string} key
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.key = "";

        /**
         * UploadStartSlot localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStartSlot
         * @instance
         */
        UploadStartSlot.prototype.localKey = "";

        /**
         * Creates a new UploadStartSlot instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot=} [properties] Properties to set
         * @returns {sfiles.UploadStartSlot} UploadStartSlot instance
         */
        UploadStartSlot.create = function create(properties) {
            return new UploadStartSlot(properties);
        };

        /**
         * Encodes the specified UploadStartSlot message. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadStartSlot message, length delimited. Does not implicitly {@link sfiles.UploadStartSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.IUploadStartSlot} message UploadStartSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStartSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStartSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.localKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStartSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStartSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStartSlot message.
         * @function verify
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStartSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadStartSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStartSlot} UploadStartSlot
         */
        UploadStartSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStartSlot)
                return object;
            let message = new $root.sfiles.UploadStartSlot();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadStartSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {sfiles.UploadStartSlot} message UploadStartSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStartSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.localKey = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadStartSlot to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStartSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStartSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadStartSlot
         * @function getTypeUrl
         * @memberof sfiles.UploadStartSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStartSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadStartSlot";
        };

        return UploadStartSlot;
    })();

    sfiles.UploadProgress = (function() {

        /**
         * Properties of an UploadProgress.
         * @memberof sfiles
         * @interface IUploadProgress
         * @property {number|Long|null} [nBytes] UploadProgress nBytes
         * @property {string|null} [key] UploadProgress key
         * @property {string|null} [localKey] UploadProgress localKey
         */

        /**
         * Constructs a new UploadProgress.
         * @memberof sfiles
         * @classdesc Represents an UploadProgress.
         * @implements IUploadProgress
         * @constructor
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         */
        function UploadProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadProgress nBytes.
         * @member {number|Long} nBytes
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.nBytes = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * UploadProgress key.
         * @member {string} key
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.key = "";

        /**
         * UploadProgress localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadProgress
         * @instance
         */
        UploadProgress.prototype.localKey = "";

        /**
         * Creates a new UploadProgress instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress=} [properties] Properties to set
         * @returns {sfiles.UploadProgress} UploadProgress instance
         */
        UploadProgress.create = function create(properties) {
            return new UploadProgress(properties);
        };

        /**
         * Encodes the specified UploadProgress message. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nBytes != null && Object.hasOwnProperty.call(message, "nBytes"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.nBytes);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.localKey);
            return writer;
        };

        /**
         * Encodes the specified UploadProgress message, length delimited. Does not implicitly {@link sfiles.UploadProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.IUploadProgress} message UploadProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nBytes = reader.int64();
                        break;
                    }
                case 2: {
                        message.key = reader.string();
                        break;
                    }
                case 3: {
                        message.localKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadProgress} UploadProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadProgress message.
         * @function verify
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (!$util.isInteger(message.nBytes) && !(message.nBytes && $util.isInteger(message.nBytes.low) && $util.isInteger(message.nBytes.high)))
                    return "nBytes: integer|Long expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            return null;
        };

        /**
         * Creates an UploadProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadProgress} UploadProgress
         */
        UploadProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadProgress)
                return object;
            let message = new $root.sfiles.UploadProgress();
            if (object.nBytes != null)
                if ($util.Long)
                    (message.nBytes = $util.Long.fromValue(object.nBytes)).unsigned = false;
                else if (typeof object.nBytes === "string")
                    message.nBytes = parseInt(object.nBytes, 10);
                else if (typeof object.nBytes === "number")
                    message.nBytes = object.nBytes;
                else if (typeof object.nBytes === "object")
                    message.nBytes = new $util.LongBits(object.nBytes.low >>> 0, object.nBytes.high >>> 0).toNumber();
            if (object.key != null)
                message.key = String(object.key);
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            return message;
        };

        /**
         * Creates a plain object from an UploadProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadProgress
         * @static
         * @param {sfiles.UploadProgress} message UploadProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.nBytes = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.nBytes = options.longs === String ? "0" : 0;
                object.key = "";
                object.localKey = "";
            }
            if (message.nBytes != null && message.hasOwnProperty("nBytes"))
                if (typeof message.nBytes === "number")
                    object.nBytes = options.longs === String ? String(message.nBytes) : message.nBytes;
                else
                    object.nBytes = options.longs === String ? $util.Long.prototype.toString.call(message.nBytes) : options.longs === Number ? new $util.LongBits(message.nBytes.low >>> 0, message.nBytes.high >>> 0).toNumber() : message.nBytes;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            return object;
        };

        /**
         * Converts this UploadProgress to JSON.
         * @function toJSON
         * @memberof sfiles.UploadProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadProgress
         * @function getTypeUrl
         * @memberof sfiles.UploadProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadProgress";
        };

        return UploadProgress;
    })();

    sfiles.UploadDone = (function() {

        /**
         * Properties of an UploadDone.
         * @memberof sfiles
         * @interface IUploadDone
         * @property {string|null} [key] UploadDone key
         * @property {sfiles.IUploadedFile|null} [file] UploadDone file
         */

        /**
         * Constructs a new UploadDone.
         * @memberof sfiles
         * @classdesc Represents an UploadDone.
         * @implements IUploadDone
         * @constructor
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         */
        function UploadDone(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadDone key.
         * @member {string} key
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.key = "";

        /**
         * UploadDone file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.UploadDone
         * @instance
         */
        UploadDone.prototype.file = null;

        /**
         * Creates a new UploadDone instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone=} [properties] Properties to set
         * @returns {sfiles.UploadDone} UploadDone instance
         */
        UploadDone.create = function create(properties) {
            return new UploadDone(properties);
        };

        /**
         * Encodes the specified UploadDone message. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadDone message, length delimited. Does not implicitly {@link sfiles.UploadDone.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.IUploadDone} message UploadDone message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadDone.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadDone();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadDone message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadDone
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadDone} UploadDone
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadDone.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadDone message.
         * @function verify
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadDone.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates an UploadDone message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadDone} UploadDone
         */
        UploadDone.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadDone)
                return object;
            let message = new $root.sfiles.UploadDone();
            if (object.key != null)
                message.key = String(object.key);
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.UploadDone.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadDone message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadDone
         * @static
         * @param {sfiles.UploadDone} message UploadDone
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadDone.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.file = null;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this UploadDone to JSON.
         * @function toJSON
         * @memberof sfiles.UploadDone
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadDone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadDone
         * @function getTypeUrl
         * @memberof sfiles.UploadDone
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadDone.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadDone";
        };

        return UploadDone;
    })();

    sfiles.UploadedFile = (function() {

        /**
         * Properties of an UploadedFile.
         * @memberof sfiles
         * @interface IUploadedFile
         * @property {string|null} [localKey] UploadedFile localKey
         * @property {string|null} [url] UploadedFile url
         * @property {string|null} [id] UploadedFile id
         * @property {string|null} [mime] UploadedFile mime
         * @property {string|null} [thumbUrl] UploadedFile thumbUrl
         * @property {string|null} [name] UploadedFile name
         * @property {boolean|null} [downloadable] UploadedFile downloadable
         */

        /**
         * Constructs a new UploadedFile.
         * @memberof sfiles
         * @classdesc Represents an UploadedFile.
         * @implements IUploadedFile
         * @constructor
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         */
        function UploadedFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadedFile localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.localKey = "";

        /**
         * UploadedFile url.
         * @member {string} url
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.url = "";

        /**
         * UploadedFile id.
         * @member {string} id
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.id = "";

        /**
         * UploadedFile mime.
         * @member {string} mime
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.mime = "";

        /**
         * UploadedFile thumbUrl.
         * @member {string} thumbUrl
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.thumbUrl = "";

        /**
         * UploadedFile name.
         * @member {string} name
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.name = "";

        /**
         * UploadedFile downloadable.
         * @member {boolean} downloadable
         * @memberof sfiles.UploadedFile
         * @instance
         */
        UploadedFile.prototype.downloadable = false;

        /**
         * Creates a new UploadedFile instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile=} [properties] Properties to set
         * @returns {sfiles.UploadedFile} UploadedFile instance
         */
        UploadedFile.create = function create(properties) {
            return new UploadedFile(properties);
        };

        /**
         * Encodes the specified UploadedFile message. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.thumbUrl != null && Object.hasOwnProperty.call(message, "thumbUrl"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.thumbUrl);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.name);
            if (message.downloadable != null && Object.hasOwnProperty.call(message, "downloadable"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.downloadable);
            return writer;
        };

        /**
         * Encodes the specified UploadedFile message, length delimited. Does not implicitly {@link sfiles.UploadedFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.IUploadedFile} message UploadedFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadedFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadedFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localKey = reader.string();
                        break;
                    }
                case 2: {
                        message.url = reader.string();
                        break;
                    }
                case 3: {
                        message.id = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.thumbUrl = reader.string();
                        break;
                    }
                case 6: {
                        message.name = reader.string();
                        break;
                    }
                case 7: {
                        message.downloadable = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadedFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadedFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadedFile} UploadedFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadedFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadedFile message.
         * @function verify
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadedFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                if (!$util.isString(message.thumbUrl))
                    return "thumbUrl: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                if (typeof message.downloadable !== "boolean")
                    return "downloadable: boolean expected";
            return null;
        };

        /**
         * Creates an UploadedFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadedFile} UploadedFile
         */
        UploadedFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadedFile)
                return object;
            let message = new $root.sfiles.UploadedFile();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.url != null)
                message.url = String(object.url);
            if (object.id != null)
                message.id = String(object.id);
            if (object.mime != null)
                message.mime = String(object.mime);
            if (object.thumbUrl != null)
                message.thumbUrl = String(object.thumbUrl);
            if (object.name != null)
                message.name = String(object.name);
            if (object.downloadable != null)
                message.downloadable = Boolean(object.downloadable);
            return message;
        };

        /**
         * Creates a plain object from an UploadedFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadedFile
         * @static
         * @param {sfiles.UploadedFile} message UploadedFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadedFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.url = "";
                object.id = "";
                object.mime = "";
                object.thumbUrl = "";
                object.name = "";
                object.downloadable = false;
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.thumbUrl != null && message.hasOwnProperty("thumbUrl"))
                object.thumbUrl = message.thumbUrl;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.downloadable != null && message.hasOwnProperty("downloadable"))
                object.downloadable = message.downloadable;
            return object;
        };

        /**
         * Converts this UploadedFile to JSON.
         * @function toJSON
         * @memberof sfiles.UploadedFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadedFile
         * @function getTypeUrl
         * @memberof sfiles.UploadedFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadedFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadedFile";
        };

        return UploadedFile;
    })();

    sfiles.UploadStart = (function() {

        /**
         * Properties of an UploadStart.
         * @memberof sfiles
         * @interface IUploadStart
         * @property {string|null} [localKey] UploadStart localKey
         * @property {string|null} [extension] UploadStart extension
         * @property {string|null} [name] UploadStart name
         * @property {string|null} [mime] UploadStart mime
         */

        /**
         * Constructs a new UploadStart.
         * @memberof sfiles
         * @classdesc Represents an UploadStart.
         * @implements IUploadStart
         * @constructor
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         */
        function UploadStart(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadStart localKey.
         * @member {string} localKey
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.localKey = "";

        /**
         * UploadStart extension.
         * @member {string} extension
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.extension = "";

        /**
         * UploadStart name.
         * @member {string} name
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.name = "";

        /**
         * UploadStart mime.
         * @member {string} mime
         * @memberof sfiles.UploadStart
         * @instance
         */
        UploadStart.prototype.mime = "";

        /**
         * Creates a new UploadStart instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart=} [properties] Properties to set
         * @returns {sfiles.UploadStart} UploadStart instance
         */
        UploadStart.create = function create(properties) {
            return new UploadStart(properties);
        };

        /**
         * Encodes the specified UploadStart message. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localKey != null && Object.hasOwnProperty.call(message, "localKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localKey);
            if (message.extension != null && Object.hasOwnProperty.call(message, "extension"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.extension);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            return writer;
        };

        /**
         * Encodes the specified UploadStart message, length delimited. Does not implicitly {@link sfiles.UploadStart.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.IUploadStart} message UploadStart message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadStart.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadStart();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.localKey = reader.string();
                        break;
                    }
                case 2: {
                        message.extension = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadStart message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadStart
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadStart} UploadStart
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadStart.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadStart message.
         * @function verify
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadStart.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                if (!$util.isString(message.localKey))
                    return "localKey: string expected";
            if (message.extension != null && message.hasOwnProperty("extension"))
                if (!$util.isString(message.extension))
                    return "extension: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            return null;
        };

        /**
         * Creates an UploadStart message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadStart} UploadStart
         */
        UploadStart.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadStart)
                return object;
            let message = new $root.sfiles.UploadStart();
            if (object.localKey != null)
                message.localKey = String(object.localKey);
            if (object.extension != null)
                message.extension = String(object.extension);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            return message;
        };

        /**
         * Creates a plain object from an UploadStart message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadStart
         * @static
         * @param {sfiles.UploadStart} message UploadStart
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadStart.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.localKey = "";
                object.extension = "";
                object.name = "";
                object.mime = "";
            }
            if (message.localKey != null && message.hasOwnProperty("localKey"))
                object.localKey = message.localKey;
            if (message.extension != null && message.hasOwnProperty("extension"))
                object.extension = message.extension;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            return object;
        };

        /**
         * Converts this UploadStart to JSON.
         * @function toJSON
         * @memberof sfiles.UploadStart
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadStart.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadStart
         * @function getTypeUrl
         * @memberof sfiles.UploadStart
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadStart.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadStart";
        };

        return UploadStart;
    })();

    sfiles.DeleteFile = (function() {

        /**
         * Properties of a DeleteFile.
         * @memberof sfiles
         * @interface IDeleteFile
         * @property {sfiles.IUploadedFile|null} [file] DeleteFile file
         */

        /**
         * Constructs a new DeleteFile.
         * @memberof sfiles
         * @classdesc Represents a DeleteFile.
         * @implements IDeleteFile
         * @constructor
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         */
        function DeleteFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteFile file.
         * @member {sfiles.IUploadedFile|null|undefined} file
         * @memberof sfiles.DeleteFile
         * @instance
         */
        DeleteFile.prototype.file = null;

        /**
         * Creates a new DeleteFile instance using the specified properties.
         * @function create
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile=} [properties] Properties to set
         * @returns {sfiles.DeleteFile} DeleteFile instance
         */
        DeleteFile.create = function create(properties) {
            return new DeleteFile(properties);
        };

        /**
         * Encodes the specified DeleteFile message. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.sfiles.UploadedFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteFile message, length delimited. Does not implicitly {@link sfiles.DeleteFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.IDeleteFile} message DeleteFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.DeleteFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.file = $root.sfiles.UploadedFile.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.DeleteFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.DeleteFile} DeleteFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteFile message.
         * @function verify
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.sfiles.UploadedFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.DeleteFile} DeleteFile
         */
        DeleteFile.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.DeleteFile)
                return object;
            let message = new $root.sfiles.DeleteFile();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".sfiles.DeleteFile.file: object expected");
                message.file = $root.sfiles.UploadedFile.fromObject(object.file);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.DeleteFile
         * @static
         * @param {sfiles.DeleteFile} message DeleteFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.sfiles.UploadedFile.toObject(message.file, options);
            return object;
        };

        /**
         * Converts this DeleteFile to JSON.
         * @function toJSON
         * @memberof sfiles.DeleteFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteFile
         * @function getTypeUrl
         * @memberof sfiles.DeleteFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.DeleteFile";
        };

        return DeleteFile;
    })();

    sfiles.UploadEnd = (function() {

        /**
         * Properties of an UploadEnd.
         * @memberof sfiles
         * @interface IUploadEnd
         */

        /**
         * Constructs a new UploadEnd.
         * @memberof sfiles
         * @classdesc Represents an UploadEnd.
         * @implements IUploadEnd
         * @constructor
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         */
        function UploadEnd(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UploadEnd instance using the specified properties.
         * @function create
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd=} [properties] Properties to set
         * @returns {sfiles.UploadEnd} UploadEnd instance
         */
        UploadEnd.create = function create(properties) {
            return new UploadEnd(properties);
        };

        /**
         * Encodes the specified UploadEnd message. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UploadEnd message, length delimited. Does not implicitly {@link sfiles.UploadEnd.verify|verify} messages.
         * @function encodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.IUploadEnd} message UploadEnd message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadEnd.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer.
         * @function decode
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.sfiles.UploadEnd();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadEnd message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof sfiles.UploadEnd
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {sfiles.UploadEnd} UploadEnd
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadEnd.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadEnd message.
         * @function verify
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadEnd.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UploadEnd message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {sfiles.UploadEnd} UploadEnd
         */
        UploadEnd.fromObject = function fromObject(object) {
            if (object instanceof $root.sfiles.UploadEnd)
                return object;
            return new $root.sfiles.UploadEnd();
        };

        /**
         * Creates a plain object from an UploadEnd message. Also converts values to other types if specified.
         * @function toObject
         * @memberof sfiles.UploadEnd
         * @static
         * @param {sfiles.UploadEnd} message UploadEnd
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadEnd.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UploadEnd to JSON.
         * @function toJSON
         * @memberof sfiles.UploadEnd
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadEnd.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadEnd
         * @function getTypeUrl
         * @memberof sfiles.UploadEnd
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadEnd.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/sfiles.UploadEnd";
        };

        return UploadEnd;
    })();

    return sfiles;
})();

export const socket_api = $root.socket_api = (() => {

    /**
     * Namespace socket_api.
     * @exports socket_api
     * @namespace
     */
    const socket_api = {};

    socket_api.Ack = (function() {

        /**
         * Properties of an Ack.
         * @memberof socket_api
         * @interface IAck
         * @property {string|null} [uuid] Ack uuid
         * @property {string|null} [errorMessage] Ack errorMessage
         * @property {number|null} [errorCode] Ack errorCode
         */

        /**
         * Constructs a new Ack.
         * @memberof socket_api
         * @classdesc Represents an Ack.
         * @implements IAck
         * @constructor
         * @param {socket_api.IAck=} [properties] Properties to set
         */
        function Ack(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Ack uuid.
         * @member {string} uuid
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.uuid = "";

        /**
         * Ack errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorMessage = "";

        /**
         * Ack errorCode.
         * @member {number} errorCode
         * @memberof socket_api.Ack
         * @instance
         */
        Ack.prototype.errorCode = 0;

        /**
         * Creates a new Ack instance using the specified properties.
         * @function create
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck=} [properties] Properties to set
         * @returns {socket_api.Ack} Ack instance
         */
        Ack.create = function create(properties) {
            return new Ack(properties);
        };

        /**
         * Encodes the specified Ack message. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encode
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && Object.hasOwnProperty.call(message, "uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessage);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified Ack message, length delimited. Does not implicitly {@link socket_api.Ack.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.IAck} message Ack message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Ack.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Ack message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.Ack();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.uuid = reader.string();
                        break;
                    }
                case 2: {
                        message.errorMessage = reader.string();
                        break;
                    }
                case 4: {
                        message.errorCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Ack message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.Ack
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.Ack} Ack
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Ack.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Ack message.
         * @function verify
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Ack.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an Ack message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.Ack
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.Ack} Ack
         */
        Ack.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.Ack)
                return object;
            let message = new $root.socket_api.Ack();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an Ack message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.Ack
         * @static
         * @param {socket_api.Ack} message Ack
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Ack.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                object.errorMessage = "";
                object.errorCode = 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this Ack to JSON.
         * @function toJSON
         * @memberof socket_api.Ack
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Ack.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Ack
         * @function getTypeUrl
         * @memberof socket_api.Ack
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Ack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.Ack";
        };

        return Ack;
    })();

    socket_api.IlolAck = (function() {

        /**
         * Properties of an IlolAck.
         * @memberof socket_api
         * @interface IIlolAck
         * @property {string|null} [sourceEventId] IlolAck sourceEventId
         */

        /**
         * Constructs a new IlolAck.
         * @memberof socket_api
         * @classdesc Represents an IlolAck.
         * @implements IIlolAck
         * @constructor
         * @param {socket_api.IIlolAck=} [properties] Properties to set
         */
        function IlolAck(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IlolAck sourceEventId.
         * @member {string} sourceEventId
         * @memberof socket_api.IlolAck
         * @instance
         */
        IlolAck.prototype.sourceEventId = "";

        /**
         * Creates a new IlolAck instance using the specified properties.
         * @function create
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IIlolAck=} [properties] Properties to set
         * @returns {socket_api.IlolAck} IlolAck instance
         */
        IlolAck.create = function create(properties) {
            return new IlolAck(properties);
        };

        /**
         * Encodes the specified IlolAck message. Does not implicitly {@link socket_api.IlolAck.verify|verify} messages.
         * @function encode
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IIlolAck} message IlolAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolAck.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            return writer;
        };

        /**
         * Encodes the specified IlolAck message, length delimited. Does not implicitly {@link socket_api.IlolAck.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IIlolAck} message IlolAck message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolAck.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IlolAck message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.IlolAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.IlolAck} IlolAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolAck.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.IlolAck();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IlolAck message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.IlolAck
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.IlolAck} IlolAck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolAck.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IlolAck message.
         * @function verify
         * @memberof socket_api.IlolAck
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IlolAck.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            return null;
        };

        /**
         * Creates an IlolAck message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.IlolAck
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.IlolAck} IlolAck
         */
        IlolAck.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.IlolAck)
                return object;
            let message = new $root.socket_api.IlolAck();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            return message;
        };

        /**
         * Creates a plain object from an IlolAck message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.IlolAck
         * @static
         * @param {socket_api.IlolAck} message IlolAck
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IlolAck.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sourceEventId = "";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            return object;
        };

        /**
         * Converts this IlolAck to JSON.
         * @function toJSON
         * @memberof socket_api.IlolAck
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IlolAck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IlolAck
         * @function getTypeUrl
         * @memberof socket_api.IlolAck
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IlolAck.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.IlolAck";
        };

        return IlolAck;
    })();

    socket_api.IlolError = (function() {

        /**
         * Properties of an IlolError.
         * @memberof socket_api
         * @interface IIlolError
         * @property {string|null} [sourceEventId] IlolError sourceEventId
         * @property {string|null} [reason] IlolError reason
         */

        /**
         * Constructs a new IlolError.
         * @memberof socket_api
         * @classdesc Represents an IlolError.
         * @implements IIlolError
         * @constructor
         * @param {socket_api.IIlolError=} [properties] Properties to set
         */
        function IlolError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IlolError sourceEventId.
         * @member {string} sourceEventId
         * @memberof socket_api.IlolError
         * @instance
         */
        IlolError.prototype.sourceEventId = "";

        /**
         * IlolError reason.
         * @member {string} reason
         * @memberof socket_api.IlolError
         * @instance
         */
        IlolError.prototype.reason = "";

        /**
         * Creates a new IlolError instance using the specified properties.
         * @function create
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IIlolError=} [properties] Properties to set
         * @returns {socket_api.IlolError} IlolError instance
         */
        IlolError.create = function create(properties) {
            return new IlolError(properties);
        };

        /**
         * Encodes the specified IlolError message. Does not implicitly {@link socket_api.IlolError.verify|verify} messages.
         * @function encode
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IIlolError} message IlolError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sourceEventId != null && Object.hasOwnProperty.call(message, "sourceEventId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sourceEventId);
            if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
            return writer;
        };

        /**
         * Encodes the specified IlolError message, length delimited. Does not implicitly {@link socket_api.IlolError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IIlolError} message IlolError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IlolError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IlolError message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.IlolError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.IlolError} IlolError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.IlolError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sourceEventId = reader.string();
                        break;
                    }
                case 2: {
                        message.reason = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IlolError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.IlolError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.IlolError} IlolError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IlolError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IlolError message.
         * @function verify
         * @memberof socket_api.IlolError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IlolError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                if (!$util.isString(message.sourceEventId))
                    return "sourceEventId: string expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                if (!$util.isString(message.reason))
                    return "reason: string expected";
            return null;
        };

        /**
         * Creates an IlolError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.IlolError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.IlolError} IlolError
         */
        IlolError.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.IlolError)
                return object;
            let message = new $root.socket_api.IlolError();
            if (object.sourceEventId != null)
                message.sourceEventId = String(object.sourceEventId);
            if (object.reason != null)
                message.reason = String(object.reason);
            return message;
        };

        /**
         * Creates a plain object from an IlolError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.IlolError
         * @static
         * @param {socket_api.IlolError} message IlolError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IlolError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sourceEventId = "";
                object.reason = "";
            }
            if (message.sourceEventId != null && message.hasOwnProperty("sourceEventId"))
                object.sourceEventId = message.sourceEventId;
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = message.reason;
            return object;
        };

        /**
         * Converts this IlolError to JSON.
         * @function toJSON
         * @memberof socket_api.IlolError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IlolError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for IlolError
         * @function getTypeUrl
         * @memberof socket_api.IlolError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        IlolError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.IlolError";
        };

        return IlolError;
    })();

    socket_api.AsyncProgress = (function() {

        /**
         * Properties of an AsyncProgress.
         * @memberof socket_api
         * @interface IAsyncProgress
         * @property {string|null} [key] AsyncProgress key
         * @property {number|null} [progress] AsyncProgress progress
         * @property {string|null} [info] AsyncProgress info
         * @property {boolean|null} [done] AsyncProgress done
         * @property {string|null} [errorMessage] AsyncProgress errorMessage
         * @property {number|null} [errorCode] AsyncProgress errorCode
         */

        /**
         * Constructs a new AsyncProgress.
         * @memberof socket_api
         * @classdesc Represents an AsyncProgress.
         * @implements IAsyncProgress
         * @constructor
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         */
        function AsyncProgress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AsyncProgress key.
         * @member {string} key
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.key = "";

        /**
         * AsyncProgress progress.
         * @member {number} progress
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.progress = 0;

        /**
         * AsyncProgress info.
         * @member {string} info
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.info = "";

        /**
         * AsyncProgress done.
         * @member {boolean} done
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.done = false;

        /**
         * AsyncProgress errorMessage.
         * @member {string} errorMessage
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorMessage = "";

        /**
         * AsyncProgress errorCode.
         * @member {number} errorCode
         * @memberof socket_api.AsyncProgress
         * @instance
         */
        AsyncProgress.prototype.errorCode = 0;

        /**
         * Creates a new AsyncProgress instance using the specified properties.
         * @function create
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress=} [properties] Properties to set
         * @returns {socket_api.AsyncProgress} AsyncProgress instance
         */
        AsyncProgress.create = function create(properties) {
            return new AsyncProgress(properties);
        };

        /**
         * Encodes the specified AsyncProgress message. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.progress);
            if (message.info != null && Object.hasOwnProperty.call(message, "info"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.info);
            if (message.done != null && Object.hasOwnProperty.call(message, "done"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.done);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.errorMessage);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified AsyncProgress message, length delimited. Does not implicitly {@link socket_api.AsyncProgress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.IAsyncProgress} message AsyncProgress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AsyncProgress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.AsyncProgress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.key = reader.string();
                        break;
                    }
                case 2: {
                        message.progress = reader.double();
                        break;
                    }
                case 3: {
                        message.info = reader.string();
                        break;
                    }
                case 4: {
                        message.done = reader.bool();
                        break;
                    }
                case 5: {
                        message.errorMessage = reader.string();
                        break;
                    }
                case 6: {
                        message.errorCode = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AsyncProgress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.AsyncProgress} AsyncProgress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AsyncProgress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AsyncProgress message.
         * @function verify
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AsyncProgress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (typeof message.progress !== "number")
                    return "progress: number expected";
            if (message.info != null && message.hasOwnProperty("info"))
                if (!$util.isString(message.info))
                    return "info: string expected";
            if (message.done != null && message.hasOwnProperty("done"))
                if (typeof message.done !== "boolean")
                    return "done: boolean expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isInteger(message.errorCode))
                    return "errorCode: integer expected";
            return null;
        };

        /**
         * Creates an AsyncProgress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.AsyncProgress} AsyncProgress
         */
        AsyncProgress.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.AsyncProgress)
                return object;
            let message = new $root.socket_api.AsyncProgress();
            if (object.key != null)
                message.key = String(object.key);
            if (object.progress != null)
                message.progress = Number(object.progress);
            if (object.info != null)
                message.info = String(object.info);
            if (object.done != null)
                message.done = Boolean(object.done);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.errorCode != null)
                message.errorCode = object.errorCode | 0;
            return message;
        };

        /**
         * Creates a plain object from an AsyncProgress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {socket_api.AsyncProgress} message AsyncProgress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AsyncProgress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.key = "";
                object.progress = 0;
                object.info = "";
                object.done = false;
                object.errorMessage = "";
                object.errorCode = 0;
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = options.json && !isFinite(message.progress) ? String(message.progress) : message.progress;
            if (message.info != null && message.hasOwnProperty("info"))
                object.info = message.info;
            if (message.done != null && message.hasOwnProperty("done"))
                object.done = message.done;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this AsyncProgress to JSON.
         * @function toJSON
         * @memberof socket_api.AsyncProgress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AsyncProgress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AsyncProgress
         * @function getTypeUrl
         * @memberof socket_api.AsyncProgress
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AsyncProgress.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.AsyncProgress";
        };

        return AsyncProgress;
    })();

    socket_api.UpgradeApiVersion = (function() {

        /**
         * Properties of an UpgradeApiVersion.
         * @memberof socket_api
         * @interface IUpgradeApiVersion
         * @property {number|null} [latest] UpgradeApiVersion latest
         */

        /**
         * Constructs a new UpgradeApiVersion.
         * @memberof socket_api
         * @classdesc Represents an UpgradeApiVersion.
         * @implements IUpgradeApiVersion
         * @constructor
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         */
        function UpgradeApiVersion(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpgradeApiVersion latest.
         * @member {number} latest
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         */
        UpgradeApiVersion.prototype.latest = 0;

        /**
         * Creates a new UpgradeApiVersion instance using the specified properties.
         * @function create
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion=} [properties] Properties to set
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion instance
         */
        UpgradeApiVersion.create = function create(properties) {
            return new UpgradeApiVersion(properties);
        };

        /**
         * Encodes the specified UpgradeApiVersion message. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.latest != null && Object.hasOwnProperty.call(message, "latest"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.latest);
            return writer;
        };

        /**
         * Encodes the specified UpgradeApiVersion message, length delimited. Does not implicitly {@link socket_api.UpgradeApiVersion.verify|verify} messages.
         * @function encodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.IUpgradeApiVersion} message UpgradeApiVersion message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpgradeApiVersion.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer.
         * @function decode
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.socket_api.UpgradeApiVersion();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.latest = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpgradeApiVersion message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpgradeApiVersion.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpgradeApiVersion message.
         * @function verify
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpgradeApiVersion.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.latest != null && message.hasOwnProperty("latest"))
                if (!$util.isInteger(message.latest))
                    return "latest: integer expected";
            return null;
        };

        /**
         * Creates an UpgradeApiVersion message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {socket_api.UpgradeApiVersion} UpgradeApiVersion
         */
        UpgradeApiVersion.fromObject = function fromObject(object) {
            if (object instanceof $root.socket_api.UpgradeApiVersion)
                return object;
            let message = new $root.socket_api.UpgradeApiVersion();
            if (object.latest != null)
                message.latest = object.latest | 0;
            return message;
        };

        /**
         * Creates a plain object from an UpgradeApiVersion message. Also converts values to other types if specified.
         * @function toObject
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {socket_api.UpgradeApiVersion} message UpgradeApiVersion
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpgradeApiVersion.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.latest = 0;
            if (message.latest != null && message.hasOwnProperty("latest"))
                object.latest = message.latest;
            return object;
        };

        /**
         * Converts this UpgradeApiVersion to JSON.
         * @function toJSON
         * @memberof socket_api.UpgradeApiVersion
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpgradeApiVersion.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UpgradeApiVersion
         * @function getTypeUrl
         * @memberof socket_api.UpgradeApiVersion
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UpgradeApiVersion.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/socket_api.UpgradeApiVersion";
        };

        return UpgradeApiVersion;
    })();

    /**
     * AckErrorCode enum.
     * @name socket_api.AckErrorCode
     * @enum {number}
     * @property {number} error_code_none=0 error_code_none value
     * @property {number} error_code_unauthorized=401 error_code_unauthorized value
     */
    socket_api.AckErrorCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "error_code_none"] = 0;
        values[valuesById[401] = "error_code_unauthorized"] = 401;
        return values;
    })();

    return socket_api;
})();

export const authentication = $root.authentication = (() => {

    /**
     * Namespace authentication.
     * @exports authentication
     * @namespace
     */
    const authentication = {};

    authentication.LoginToken = (function() {

        /**
         * Properties of a LoginToken.
         * @memberof authentication
         * @interface ILoginToken
         * @property {string|null} [token] LoginToken token
         * @property {string|null} [refresh] LoginToken refresh
         */

        /**
         * Constructs a new LoginToken.
         * @memberof authentication
         * @classdesc Represents a LoginToken.
         * @implements ILoginToken
         * @constructor
         * @param {authentication.ILoginToken=} [properties] Properties to set
         */
        function LoginToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginToken token.
         * @member {string} token
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.token = "";

        /**
         * LoginToken refresh.
         * @member {string} refresh
         * @memberof authentication.LoginToken
         * @instance
         */
        LoginToken.prototype.refresh = "";

        /**
         * Creates a new LoginToken instance using the specified properties.
         * @function create
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken=} [properties] Properties to set
         * @returns {authentication.LoginToken} LoginToken instance
         */
        LoginToken.create = function create(properties) {
            return new LoginToken(properties);
        };

        /**
         * Encodes the specified LoginToken message. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            if (message.refresh != null && Object.hasOwnProperty.call(message, "refresh"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.refresh);
            return writer;
        };

        /**
         * Encodes the specified LoginToken message, length delimited. Does not implicitly {@link authentication.LoginToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.ILoginToken} message LoginToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                case 2: {
                        message.refresh = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginToken} LoginToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginToken message.
         * @function verify
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.refresh != null && message.hasOwnProperty("refresh"))
                if (!$util.isString(message.refresh))
                    return "refresh: string expected";
            return null;
        };

        /**
         * Creates a LoginToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginToken} LoginToken
         */
        LoginToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginToken)
                return object;
            let message = new $root.authentication.LoginToken();
            if (object.token != null)
                message.token = String(object.token);
            if (object.refresh != null)
                message.refresh = String(object.refresh);
            return message;
        };

        /**
         * Creates a plain object from a LoginToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginToken
         * @static
         * @param {authentication.LoginToken} message LoginToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.token = "";
                object.refresh = "";
            }
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.refresh != null && message.hasOwnProperty("refresh"))
                object.refresh = message.refresh;
            return object;
        };

        /**
         * Converts this LoginToken to JSON.
         * @function toJSON
         * @memberof authentication.LoginToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginToken
         * @function getTypeUrl
         * @memberof authentication.LoginToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.LoginToken";
        };

        return LoginToken;
    })();

    authentication.LoginError = (function() {

        /**
         * Properties of a LoginError.
         * @memberof authentication
         * @interface ILoginError
         * @property {string|null} [errorText] LoginError errorText
         * @property {string|null} [errorCode] LoginError errorCode
         */

        /**
         * Constructs a new LoginError.
         * @memberof authentication
         * @classdesc Represents a LoginError.
         * @implements ILoginError
         * @constructor
         * @param {authentication.ILoginError=} [properties] Properties to set
         */
        function LoginError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginError errorText.
         * @member {string} errorText
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorText = "";

        /**
         * LoginError errorCode.
         * @member {string} errorCode
         * @memberof authentication.LoginError
         * @instance
         */
        LoginError.prototype.errorCode = "";

        /**
         * Creates a new LoginError instance using the specified properties.
         * @function create
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError=} [properties] Properties to set
         * @returns {authentication.LoginError} LoginError instance
         */
        LoginError.create = function create(properties) {
            return new LoginError(properties);
        };

        /**
         * Encodes the specified LoginError message. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encode
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.errorText != null && Object.hasOwnProperty.call(message, "errorText"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.errorText);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified LoginError message, length delimited. Does not implicitly {@link authentication.LoginError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.ILoginError} message LoginError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.LoginError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.errorText = reader.string();
                        break;
                    }
                case 2: {
                        message.errorCode = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.LoginError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.LoginError} LoginError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginError message.
         * @function verify
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                if (!$util.isString(message.errorText))
                    return "errorText: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a LoginError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.LoginError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.LoginError} LoginError
         */
        LoginError.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.LoginError)
                return object;
            let message = new $root.authentication.LoginError();
            if (object.errorText != null)
                message.errorText = String(object.errorText);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a LoginError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.LoginError
         * @static
         * @param {authentication.LoginError} message LoginError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.errorText = "";
                object.errorCode = "";
            }
            if (message.errorText != null && message.hasOwnProperty("errorText"))
                object.errorText = message.errorText;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this LoginError to JSON.
         * @function toJSON
         * @memberof authentication.LoginError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginError
         * @function getTypeUrl
         * @memberof authentication.LoginError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.LoginError";
        };

        return LoginError;
    })();

    authentication.Login = (function() {

        /**
         * Properties of a Login.
         * @memberof authentication
         * @interface ILogin
         * @property {string|null} [username] Login username
         * @property {string|null} [password] Login password
         */

        /**
         * Constructs a new Login.
         * @memberof authentication
         * @classdesc Represents a Login.
         * @implements ILogin
         * @constructor
         * @param {authentication.ILogin=} [properties] Properties to set
         */
        function Login(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Login username.
         * @member {string} username
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.username = "";

        /**
         * Login password.
         * @member {string} password
         * @memberof authentication.Login
         * @instance
         */
        Login.prototype.password = "";

        /**
         * Creates a new Login instance using the specified properties.
         * @function create
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin=} [properties] Properties to set
         * @returns {authentication.Login} Login instance
         */
        Login.create = function create(properties) {
            return new Login(properties);
        };

        /**
         * Encodes the specified Login message. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encode
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.username != null && Object.hasOwnProperty.call(message, "username"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified Login message, length delimited. Does not implicitly {@link authentication.Login.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {authentication.ILogin} message Login message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Login.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Login message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.Login();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.username = reader.string();
                        break;
                    }
                case 2: {
                        message.password = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Login message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.Login
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.Login} Login
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Login.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Login message.
         * @function verify
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Login.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.username != null && message.hasOwnProperty("username"))
                if (!$util.isString(message.username))
                    return "username: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates a Login message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.Login
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.Login} Login
         */
        Login.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.Login)
                return object;
            let message = new $root.authentication.Login();
            if (object.username != null)
                message.username = String(object.username);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from a Login message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.Login
         * @static
         * @param {authentication.Login} message Login
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Login.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.username = "";
                object.password = "";
            }
            if (message.username != null && message.hasOwnProperty("username"))
                object.username = message.username;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this Login to JSON.
         * @function toJSON
         * @memberof authentication.Login
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Login.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Login
         * @function getTypeUrl
         * @memberof authentication.Login
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Login.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.Login";
        };

        return Login;
    })();

    authentication.RefreshToken = (function() {

        /**
         * Properties of a RefreshToken.
         * @memberof authentication
         * @interface IRefreshToken
         * @property {string|null} [refreshToken] RefreshToken refreshToken
         */

        /**
         * Constructs a new RefreshToken.
         * @memberof authentication
         * @classdesc Represents a RefreshToken.
         * @implements IRefreshToken
         * @constructor
         * @param {authentication.IRefreshToken=} [properties] Properties to set
         */
        function RefreshToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RefreshToken refreshToken.
         * @member {string} refreshToken
         * @memberof authentication.RefreshToken
         * @instance
         */
        RefreshToken.prototype.refreshToken = "";

        /**
         * Creates a new RefreshToken instance using the specified properties.
         * @function create
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.IRefreshToken=} [properties] Properties to set
         * @returns {authentication.RefreshToken} RefreshToken instance
         */
        RefreshToken.create = function create(properties) {
            return new RefreshToken(properties);
        };

        /**
         * Encodes the specified RefreshToken message. Does not implicitly {@link authentication.RefreshToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.IRefreshToken} message RefreshToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            return writer;
        };

        /**
         * Encodes the specified RefreshToken message, length delimited. Does not implicitly {@link authentication.RefreshToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.IRefreshToken} message RefreshToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RefreshToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.RefreshToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.RefreshToken} RefreshToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.RefreshToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.refreshToken = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RefreshToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.RefreshToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.RefreshToken} RefreshToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RefreshToken message.
         * @function verify
         * @memberof authentication.RefreshToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RefreshToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                if (!$util.isString(message.refreshToken))
                    return "refreshToken: string expected";
            return null;
        };

        /**
         * Creates a RefreshToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.RefreshToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.RefreshToken} RefreshToken
         */
        RefreshToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.RefreshToken)
                return object;
            let message = new $root.authentication.RefreshToken();
            if (object.refreshToken != null)
                message.refreshToken = String(object.refreshToken);
            return message;
        };

        /**
         * Creates a plain object from a RefreshToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.RefreshToken
         * @static
         * @param {authentication.RefreshToken} message RefreshToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RefreshToken.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.refreshToken = "";
            if (message.refreshToken != null && message.hasOwnProperty("refreshToken"))
                object.refreshToken = message.refreshToken;
            return object;
        };

        /**
         * Converts this RefreshToken to JSON.
         * @function toJSON
         * @memberof authentication.RefreshToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RefreshToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RefreshToken
         * @function getTypeUrl
         * @memberof authentication.RefreshToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RefreshToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.RefreshToken";
        };

        return RefreshToken;
    })();

    authentication.VerifyToken = (function() {

        /**
         * Properties of a VerifyToken.
         * @memberof authentication
         * @interface IVerifyToken
         */

        /**
         * Constructs a new VerifyToken.
         * @memberof authentication
         * @classdesc Represents a VerifyToken.
         * @implements IVerifyToken
         * @constructor
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         */
        function VerifyToken(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new VerifyToken instance using the specified properties.
         * @function create
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken=} [properties] Properties to set
         * @returns {authentication.VerifyToken} VerifyToken instance
         */
        VerifyToken.create = function create(properties) {
            return new VerifyToken(properties);
        };

        /**
         * Encodes the specified VerifyToken message. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encode
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified VerifyToken message, length delimited. Does not implicitly {@link authentication.VerifyToken.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.IVerifyToken} message VerifyToken message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VerifyToken.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.VerifyToken();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a VerifyToken message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.VerifyToken
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.VerifyToken} VerifyToken
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VerifyToken.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a VerifyToken message.
         * @function verify
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        VerifyToken.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a VerifyToken message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.VerifyToken} VerifyToken
         */
        VerifyToken.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.VerifyToken)
                return object;
            return new $root.authentication.VerifyToken();
        };

        /**
         * Creates a plain object from a VerifyToken message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.VerifyToken
         * @static
         * @param {authentication.VerifyToken} message VerifyToken
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        VerifyToken.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this VerifyToken to JSON.
         * @function toJSON
         * @memberof authentication.VerifyToken
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        VerifyToken.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for VerifyToken
         * @function getTypeUrl
         * @memberof authentication.VerifyToken
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        VerifyToken.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.VerifyToken";
        };

        return VerifyToken;
    })();

    authentication.TokenInvalid = (function() {

        /**
         * Properties of a TokenInvalid.
         * @memberof authentication
         * @interface ITokenInvalid
         */

        /**
         * Constructs a new TokenInvalid.
         * @memberof authentication
         * @classdesc Represents a TokenInvalid.
         * @implements ITokenInvalid
         * @constructor
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         */
        function TokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid=} [properties] Properties to set
         * @returns {authentication.TokenInvalid} TokenInvalid instance
         */
        TokenInvalid.create = function create(properties) {
            return new TokenInvalid(properties);
        };

        /**
         * Encodes the specified TokenInvalid message. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TokenInvalid message, length delimited. Does not implicitly {@link authentication.TokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.ITokenInvalid} message TokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.TokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.TokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.TokenInvalid} TokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TokenInvalid message.
         * @function verify
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.TokenInvalid} TokenInvalid
         */
        TokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.TokenInvalid)
                return object;
            return new $root.authentication.TokenInvalid();
        };

        /**
         * Creates a plain object from a TokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.TokenInvalid
         * @static
         * @param {authentication.TokenInvalid} message TokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.TokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for TokenInvalid
         * @function getTypeUrl
         * @memberof authentication.TokenInvalid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        TokenInvalid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.TokenInvalid";
        };

        return TokenInvalid;
    })();

    authentication.RefreshTokenInvalid = (function() {

        /**
         * Properties of a RefreshTokenInvalid.
         * @memberof authentication
         * @interface IRefreshTokenInvalid
         */

        /**
         * Constructs a new RefreshTokenInvalid.
         * @memberof authentication
         * @classdesc Represents a RefreshTokenInvalid.
         * @implements IRefreshTokenInvalid
         * @constructor
         * @param {authentication.IRefreshTokenInvalid=} [properties] Properties to set
         */
        function RefreshTokenInvalid(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new RefreshTokenInvalid instance using the specified properties.
         * @function create
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.IRefreshTokenInvalid=} [properties] Properties to set
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid instance
         */
        RefreshTokenInvalid.create = function create(properties) {
            return new RefreshTokenInvalid(properties);
        };

        /**
         * Encodes the specified RefreshTokenInvalid message. Does not implicitly {@link authentication.RefreshTokenInvalid.verify|verify} messages.
         * @function encode
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.IRefreshTokenInvalid} message RefreshTokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenInvalid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified RefreshTokenInvalid message, length delimited. Does not implicitly {@link authentication.RefreshTokenInvalid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.IRefreshTokenInvalid} message RefreshTokenInvalid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenInvalid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RefreshTokenInvalid message from the specified reader or buffer.
         * @function decode
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenInvalid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.authentication.RefreshTokenInvalid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RefreshTokenInvalid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenInvalid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RefreshTokenInvalid message.
         * @function verify
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RefreshTokenInvalid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a RefreshTokenInvalid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {authentication.RefreshTokenInvalid} RefreshTokenInvalid
         */
        RefreshTokenInvalid.fromObject = function fromObject(object) {
            if (object instanceof $root.authentication.RefreshTokenInvalid)
                return object;
            return new $root.authentication.RefreshTokenInvalid();
        };

        /**
         * Creates a plain object from a RefreshTokenInvalid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {authentication.RefreshTokenInvalid} message RefreshTokenInvalid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RefreshTokenInvalid.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this RefreshTokenInvalid to JSON.
         * @function toJSON
         * @memberof authentication.RefreshTokenInvalid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RefreshTokenInvalid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for RefreshTokenInvalid
         * @function getTypeUrl
         * @memberof authentication.RefreshTokenInvalid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        RefreshTokenInvalid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/authentication.RefreshTokenInvalid";
        };

        return RefreshTokenInvalid;
    })();

    return authentication;
})();

export const uploader = $root.uploader = (() => {

    /**
     * Namespace uploader.
     * @exports uploader
     * @namespace
     */
    const uploader = {};

    uploader.UploadTask = (function() {

        /**
         * Properties of an UploadTask.
         * @memberof uploader
         * @interface IUploadTask
         * @property {number|Long|null} [created] UploadTask created
         * @property {string|null} [path] UploadTask path
         * @property {string|null} [name] UploadTask name
         * @property {string|null} [mime] UploadTask mime
         * @property {uploader.UploadStatus|null} [status] UploadTask status
         * @property {number|null} [retryCounter] UploadTask retryCounter
         * @property {string|null} [error] UploadTask error
         * @property {string|null} [fingerprint] UploadTask fingerprint
         * @property {string|null} [url] UploadTask url
         * @property {Object.<string,string>|null} [metadata] UploadTask metadata
         */

        /**
         * Constructs a new UploadTask.
         * @memberof uploader
         * @classdesc Represents an UploadTask.
         * @implements IUploadTask
         * @constructor
         * @param {uploader.IUploadTask=} [properties] Properties to set
         */
        function UploadTask(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadTask created.
         * @member {number|Long} created
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.created = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * UploadTask path.
         * @member {string} path
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.path = "";

        /**
         * UploadTask name.
         * @member {string} name
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.name = "";

        /**
         * UploadTask mime.
         * @member {string} mime
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.mime = "";

        /**
         * UploadTask status.
         * @member {uploader.UploadStatus} status
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.status = 0;

        /**
         * UploadTask retryCounter.
         * @member {number} retryCounter
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.retryCounter = 0;

        /**
         * UploadTask error.
         * @member {string} error
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.error = "";

        /**
         * UploadTask fingerprint.
         * @member {string} fingerprint
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.fingerprint = "";

        /**
         * UploadTask url.
         * @member {string} url
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.url = "";

        /**
         * UploadTask metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadTask
         * @instance
         */
        UploadTask.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadTask instance using the specified properties.
         * @function create
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask=} [properties] Properties to set
         * @returns {uploader.UploadTask} UploadTask instance
         */
        UploadTask.create = function create(properties) {
            return new UploadTask(properties);
        };

        /**
         * Encodes the specified UploadTask message. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.created != null && Object.hasOwnProperty.call(message, "created"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.created);
            if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.mime != null && Object.hasOwnProperty.call(message, "mime"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.mime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.retryCounter != null && Object.hasOwnProperty.call(message, "retryCounter"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.retryCounter);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.error);
            if (message.fingerprint != null && Object.hasOwnProperty.call(message, "fingerprint"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.fingerprint);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.url);
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadTask message, length delimited. Does not implicitly {@link uploader.UploadTask.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.IUploadTask} message UploadTask message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadTask.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadTask(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.created = reader.uint64();
                        break;
                    }
                case 2: {
                        message.path = reader.string();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                case 4: {
                        message.mime = reader.string();
                        break;
                    }
                case 5: {
                        message.status = reader.int32();
                        break;
                    }
                case 6: {
                        message.retryCounter = reader.int32();
                        break;
                    }
                case 7: {
                        message.error = reader.string();
                        break;
                    }
                case 8: {
                        message.fingerprint = reader.string();
                        break;
                    }
                case 9: {
                        message.url = reader.string();
                        break;
                    }
                case 10: {
                        if (message.metadata === $util.emptyObject)
                            message.metadata = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.metadata[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadTask message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadTask
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadTask} UploadTask
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadTask.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadTask message.
         * @function verify
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadTask.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.created != null && message.hasOwnProperty("created"))
                if (!$util.isInteger(message.created) && !(message.created && $util.isInteger(message.created.low) && $util.isInteger(message.created.high)))
                    return "created: integer|Long expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.mime != null && message.hasOwnProperty("mime"))
                if (!$util.isString(message.mime))
                    return "mime: string expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 3:
                case 4:
                case 5:
                case 6:
                    break;
                }
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                if (!$util.isInteger(message.retryCounter))
                    return "retryCounter: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                if (!$util.isString(message.fingerprint))
                    return "fingerprint: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadTask message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadTask
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadTask} UploadTask
         */
        UploadTask.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadTask)
                return object;
            let message = new $root.uploader.UploadTask();
            if (object.created != null)
                if ($util.Long)
                    (message.created = $util.Long.fromValue(object.created)).unsigned = true;
                else if (typeof object.created === "string")
                    message.created = parseInt(object.created, 10);
                else if (typeof object.created === "number")
                    message.created = object.created;
                else if (typeof object.created === "object")
                    message.created = new $util.LongBits(object.created.low >>> 0, object.created.high >>> 0).toNumber(true);
            if (object.path != null)
                message.path = String(object.path);
            if (object.name != null)
                message.name = String(object.name);
            if (object.mime != null)
                message.mime = String(object.mime);
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "scheduled":
            case 0:
                message.status = 0;
                break;
            case "uploading":
            case 1:
                message.status = 1;
                break;
            case "paused":
            case 3:
                message.status = 3;
                break;
            case "done":
            case 4:
                message.status = 4;
                break;
            case "error":
            case 5:
                message.status = 5;
                break;
            case "restored":
            case 6:
                message.status = 6;
                break;
            }
            if (object.retryCounter != null)
                message.retryCounter = object.retryCounter | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.fingerprint != null)
                message.fingerprint = String(object.fingerprint);
            if (object.url != null)
                message.url = String(object.url);
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadTask.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadTask message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadTask
         * @static
         * @param {uploader.UploadTask} message UploadTask
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadTask.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.created = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.created = options.longs === String ? "0" : 0;
                object.path = "";
                object.name = "";
                object.mime = "";
                object.status = options.enums === String ? "scheduled" : 0;
                object.retryCounter = 0;
                object.error = "";
                object.fingerprint = "";
                object.url = "";
            }
            if (message.created != null && message.hasOwnProperty("created"))
                if (typeof message.created === "number")
                    object.created = options.longs === String ? String(message.created) : message.created;
                else
                    object.created = options.longs === String ? $util.Long.prototype.toString.call(message.created) : options.longs === Number ? new $util.LongBits(message.created.low >>> 0, message.created.high >>> 0).toNumber(true) : message.created;
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.mime != null && message.hasOwnProperty("mime"))
                object.mime = message.mime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.uploader.UploadStatus[message.status] === undefined ? message.status : $root.uploader.UploadStatus[message.status] : message.status;
            if (message.retryCounter != null && message.hasOwnProperty("retryCounter"))
                object.retryCounter = message.retryCounter;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.fingerprint != null && message.hasOwnProperty("fingerprint"))
                object.fingerprint = message.fingerprint;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadTask to JSON.
         * @function toJSON
         * @memberof uploader.UploadTask
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadTask.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadTask
         * @function getTypeUrl
         * @memberof uploader.UploadTask
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadTask.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadTask";
        };

        return UploadTask;
    })();

    /**
     * UploadStatus enum.
     * @name uploader.UploadStatus
     * @enum {number}
     * @property {number} scheduled=0 scheduled value
     * @property {number} uploading=1 uploading value
     * @property {number} paused=3 paused value
     * @property {number} done=4 done value
     * @property {number} error=5 error value
     * @property {number} restored=6 restored value
     */
    uploader.UploadStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "scheduled"] = 0;
        values[valuesById[1] = "uploading"] = 1;
        values[valuesById[3] = "paused"] = 3;
        values[valuesById[4] = "done"] = 4;
        values[valuesById[5] = "error"] = 5;
        values[valuesById[6] = "restored"] = 6;
        return values;
    })();

    uploader.UploadUFile = (function() {

        /**
         * Properties of an UploadUFile.
         * @memberof uploader
         * @interface IUploadUFile
         * @property {string|null} [fileId] UploadUFile fileId
         * @property {uploader.IUploadTask|null} [task] UploadUFile task
         */

        /**
         * Constructs a new UploadUFile.
         * @memberof uploader
         * @classdesc Represents an UploadUFile.
         * @implements IUploadUFile
         * @constructor
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         */
        function UploadUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadUFile fileId.
         * @member {string} fileId
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.fileId = "";

        /**
         * UploadUFile task.
         * @member {uploader.IUploadTask|null|undefined} task
         * @memberof uploader.UploadUFile
         * @instance
         */
        UploadUFile.prototype.task = null;

        /**
         * Creates a new UploadUFile instance using the specified properties.
         * @function create
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile=} [properties] Properties to set
         * @returns {uploader.UploadUFile} UploadUFile instance
         */
        UploadUFile.create = function create(properties) {
            return new UploadUFile(properties);
        };

        /**
         * Encodes the specified UploadUFile message. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fileId != null && Object.hasOwnProperty.call(message, "fileId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fileId);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.uploader.UploadTask.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadUFile message, length delimited. Does not implicitly {@link uploader.UploadUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.IUploadUFile} message UploadUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.fileId = reader.string();
                        break;
                    }
                case 2: {
                        message.task = $root.uploader.UploadTask.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadUFile} UploadUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadUFile message.
         * @function verify
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                if (!$util.isString(message.fileId))
                    return "fileId: string expected";
            if (message.task != null && message.hasOwnProperty("task")) {
                let error = $root.uploader.UploadTask.verify(message.task);
                if (error)
                    return "task." + error;
            }
            return null;
        };

        /**
         * Creates an UploadUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadUFile} UploadUFile
         */
        UploadUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadUFile)
                return object;
            let message = new $root.uploader.UploadUFile();
            if (object.fileId != null)
                message.fileId = String(object.fileId);
            if (object.task != null) {
                if (typeof object.task !== "object")
                    throw TypeError(".uploader.UploadUFile.task: object expected");
                message.task = $root.uploader.UploadTask.fromObject(object.task);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadUFile
         * @static
         * @param {uploader.UploadUFile} message UploadUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.fileId = "";
                object.task = null;
            }
            if (message.fileId != null && message.hasOwnProperty("fileId"))
                object.fileId = message.fileId;
            if (message.task != null && message.hasOwnProperty("task"))
                object.task = $root.uploader.UploadTask.toObject(message.task, options);
            return object;
        };

        /**
         * Converts this UploadUFile to JSON.
         * @function toJSON
         * @memberof uploader.UploadUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadUFile
         * @function getTypeUrl
         * @memberof uploader.UploadUFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadUFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadUFile";
        };

        return UploadUFile;
    })();

    uploader.DeleteUFile = (function() {

        /**
         * Properties of a DeleteUFile.
         * @memberof uploader
         * @interface IDeleteUFile
         * @property {string|null} [id] DeleteUFile id
         */

        /**
         * Constructs a new DeleteUFile.
         * @memberof uploader
         * @classdesc Represents a DeleteUFile.
         * @implements IDeleteUFile
         * @constructor
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         */
        function DeleteUFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUFile id.
         * @member {string} id
         * @memberof uploader.DeleteUFile
         * @instance
         */
        DeleteUFile.prototype.id = "";

        /**
         * Creates a new DeleteUFile instance using the specified properties.
         * @function create
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile=} [properties] Properties to set
         * @returns {uploader.DeleteUFile} DeleteUFile instance
         */
        DeleteUFile.create = function create(properties) {
            return new DeleteUFile(properties);
        };

        /**
         * Encodes the specified DeleteUFile message. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified DeleteUFile message, length delimited. Does not implicitly {@link uploader.DeleteUFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.IDeleteUFile} message DeleteUFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.DeleteUFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.DeleteUFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.DeleteUFile} DeleteUFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUFile message.
         * @function verify
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a DeleteUFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.DeleteUFile} DeleteUFile
         */
        DeleteUFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.DeleteUFile)
                return object;
            let message = new $root.uploader.DeleteUFile();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.DeleteUFile
         * @static
         * @param {uploader.DeleteUFile} message DeleteUFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this DeleteUFile to JSON.
         * @function toJSON
         * @memberof uploader.DeleteUFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for DeleteUFile
         * @function getTypeUrl
         * @memberof uploader.DeleteUFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        DeleteUFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.DeleteUFile";
        };

        return DeleteUFile;
    })();

    uploader.UploadSlot = (function() {

        /**
         * Properties of an UploadSlot.
         * @memberof uploader
         * @interface IUploadSlot
         * @property {uploader.IUFile|null} [file] UploadSlot file
         * @property {Object.<string,string>|null} [metadata] UploadSlot metadata
         */

        /**
         * Constructs a new UploadSlot.
         * @memberof uploader
         * @classdesc Represents an UploadSlot.
         * @implements IUploadSlot
         * @constructor
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         */
        function UploadSlot(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UploadSlot file.
         * @member {uploader.IUFile|null|undefined} file
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.file = null;

        /**
         * UploadSlot metadata.
         * @member {Object.<string,string>} metadata
         * @memberof uploader.UploadSlot
         * @instance
         */
        UploadSlot.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new UploadSlot instance using the specified properties.
         * @function create
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot=} [properties] Properties to set
         * @returns {uploader.UploadSlot} UploadSlot instance
         */
        UploadSlot.create = function create(properties) {
            return new UploadSlot(properties);
        };

        /**
         * Encodes the specified UploadSlot message. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encode
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.file != null && Object.hasOwnProperty.call(message, "file"))
                $root.uploader.UFile.encode(message.file, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UploadSlot message, length delimited. Does not implicitly {@link uploader.UploadSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.IUploadSlot} message UploadSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UploadSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UploadSlot(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.file = $root.uploader.UFile.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (message.metadata === $util.emptyObject)
                            message.metadata = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = "";
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.metadata[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UploadSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UploadSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UploadSlot} UploadSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UploadSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UploadSlot message.
         * @function verify
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UploadSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.file != null && message.hasOwnProperty("file")) {
                let error = $root.uploader.UFile.verify(message.file);
                if (error)
                    return "file." + error;
            }
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates an UploadSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UploadSlot} UploadSlot
         */
        UploadSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UploadSlot)
                return object;
            let message = new $root.uploader.UploadSlot();
            if (object.file != null) {
                if (typeof object.file !== "object")
                    throw TypeError(".uploader.UploadSlot.file: object expected");
                message.file = $root.uploader.UFile.fromObject(object.file);
            }
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".uploader.UploadSlot.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UploadSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UploadSlot
         * @static
         * @param {uploader.UploadSlot} message UploadSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UploadSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            if (options.defaults)
                object.file = null;
            if (message.file != null && message.hasOwnProperty("file"))
                object.file = $root.uploader.UFile.toObject(message.file, options);
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this UploadSlot to JSON.
         * @function toJSON
         * @memberof uploader.UploadSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UploadSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UploadSlot
         * @function getTypeUrl
         * @memberof uploader.UploadSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UploadSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UploadSlot";
        };

        return UploadSlot;
    })();

    uploader.UFile = (function() {

        /**
         * Properties of a UFile.
         * @memberof uploader
         * @interface IUFile
         * @property {string|null} [id] UFile id
         * @property {string|null} [src] UFile src
         * @property {string|null} [preview] UFile preview
         * @property {string|null} [previewLarge] UFile previewLarge
         * @property {string|null} [name] UFile name
         * @property {string|null} [uploadTask] UFile uploadTask
         * @property {string|null} [localPath] UFile localPath
         */

        /**
         * Constructs a new UFile.
         * @memberof uploader
         * @classdesc Represents a UFile.
         * @implements IUFile
         * @constructor
         * @param {uploader.IUFile=} [properties] Properties to set
         */
        function UFile(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UFile id.
         * @member {string} id
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.id = "";

        /**
         * UFile src.
         * @member {string} src
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.src = "";

        /**
         * UFile preview.
         * @member {string} preview
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.preview = "";

        /**
         * UFile previewLarge.
         * @member {string} previewLarge
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.previewLarge = "";

        /**
         * UFile name.
         * @member {string} name
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.name = "";

        /**
         * UFile uploadTask.
         * @member {string} uploadTask
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.uploadTask = "";

        /**
         * UFile localPath.
         * @member {string} localPath
         * @memberof uploader.UFile
         * @instance
         */
        UFile.prototype.localPath = "";

        /**
         * Creates a new UFile instance using the specified properties.
         * @function create
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile=} [properties] Properties to set
         * @returns {uploader.UFile} UFile instance
         */
        UFile.create = function create(properties) {
            return new UFile(properties);
        };

        /**
         * Encodes the specified UFile message. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encode
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.src != null && Object.hasOwnProperty.call(message, "src"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.src);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.preview);
            if (message.previewLarge != null && Object.hasOwnProperty.call(message, "previewLarge"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.previewLarge);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.uploadTask != null && Object.hasOwnProperty.call(message, "uploadTask"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.uploadTask);
            if (message.localPath != null && Object.hasOwnProperty.call(message, "localPath"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.localPath);
            return writer;
        };

        /**
         * Encodes the specified UFile message, length delimited. Does not implicitly {@link uploader.UFile.verify|verify} messages.
         * @function encodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {uploader.IUFile} message UFile message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UFile.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UFile message from the specified reader or buffer.
         * @function decode
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.uploader.UFile();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.src = reader.string();
                        break;
                    }
                case 3: {
                        message.preview = reader.string();
                        break;
                    }
                case 4: {
                        message.previewLarge = reader.string();
                        break;
                    }
                case 5: {
                        message.name = reader.string();
                        break;
                    }
                case 6: {
                        message.uploadTask = reader.string();
                        break;
                    }
                case 7: {
                        message.localPath = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UFile message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof uploader.UFile
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {uploader.UFile} UFile
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UFile.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UFile message.
         * @function verify
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UFile.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.src != null && message.hasOwnProperty("src"))
                if (!$util.isString(message.src))
                    return "src: string expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (!$util.isString(message.preview))
                    return "preview: string expected";
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                if (!$util.isString(message.previewLarge))
                    return "previewLarge: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                if (!$util.isString(message.uploadTask))
                    return "uploadTask: string expected";
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                if (!$util.isString(message.localPath))
                    return "localPath: string expected";
            return null;
        };

        /**
         * Creates a UFile message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof uploader.UFile
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {uploader.UFile} UFile
         */
        UFile.fromObject = function fromObject(object) {
            if (object instanceof $root.uploader.UFile)
                return object;
            let message = new $root.uploader.UFile();
            if (object.id != null)
                message.id = String(object.id);
            if (object.src != null)
                message.src = String(object.src);
            if (object.preview != null)
                message.preview = String(object.preview);
            if (object.previewLarge != null)
                message.previewLarge = String(object.previewLarge);
            if (object.name != null)
                message.name = String(object.name);
            if (object.uploadTask != null)
                message.uploadTask = String(object.uploadTask);
            if (object.localPath != null)
                message.localPath = String(object.localPath);
            return message;
        };

        /**
         * Creates a plain object from a UFile message. Also converts values to other types if specified.
         * @function toObject
         * @memberof uploader.UFile
         * @static
         * @param {uploader.UFile} message UFile
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UFile.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.src = "";
                object.preview = "";
                object.previewLarge = "";
                object.name = "";
                object.uploadTask = "";
                object.localPath = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.src != null && message.hasOwnProperty("src"))
                object.src = message.src;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            if (message.previewLarge != null && message.hasOwnProperty("previewLarge"))
                object.previewLarge = message.previewLarge;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uploadTask != null && message.hasOwnProperty("uploadTask"))
                object.uploadTask = message.uploadTask;
            if (message.localPath != null && message.hasOwnProperty("localPath"))
                object.localPath = message.localPath;
            return object;
        };

        /**
         * Converts this UFile to JSON.
         * @function toJSON
         * @memberof uploader.UFile
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UFile
         * @function getTypeUrl
         * @memberof uploader.UFile
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UFile.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/uploader.UFile";
        };

        return UFile;
    })();

    return uploader;
})();

export const exports = $root.exports = (() => {

    /**
     * Namespace exports.
     * @exports exports
     * @namespace
     */
    const exports = {};

    exports.ExportSession = (function() {

        /**
         * Properties of an ExportSession.
         * @memberof exports
         * @interface IExportSession
         * @property {string|null} [sessionId] ExportSession sessionId
         * @property {exports.ExportType|null} [exportType] ExportSession exportType
         */

        /**
         * Constructs a new ExportSession.
         * @memberof exports
         * @classdesc Represents an ExportSession.
         * @implements IExportSession
         * @constructor
         * @param {exports.IExportSession=} [properties] Properties to set
         */
        function ExportSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportSession sessionId.
         * @member {string} sessionId
         * @memberof exports.ExportSession
         * @instance
         */
        ExportSession.prototype.sessionId = "";

        /**
         * ExportSession exportType.
         * @member {exports.ExportType} exportType
         * @memberof exports.ExportSession
         * @instance
         */
        ExportSession.prototype.exportType = 0;

        /**
         * Creates a new ExportSession instance using the specified properties.
         * @function create
         * @memberof exports.ExportSession
         * @static
         * @param {exports.IExportSession=} [properties] Properties to set
         * @returns {exports.ExportSession} ExportSession instance
         */
        ExportSession.create = function create(properties) {
            return new ExportSession(properties);
        };

        /**
         * Encodes the specified ExportSession message. Does not implicitly {@link exports.ExportSession.verify|verify} messages.
         * @function encode
         * @memberof exports.ExportSession
         * @static
         * @param {exports.IExportSession} message ExportSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.exportType != null && Object.hasOwnProperty.call(message, "exportType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.exportType);
            return writer;
        };

        /**
         * Encodes the specified ExportSession message, length delimited. Does not implicitly {@link exports.ExportSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof exports.ExportSession
         * @static
         * @param {exports.IExportSession} message ExportSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportSession message from the specified reader or buffer.
         * @function decode
         * @memberof exports.ExportSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {exports.ExportSession} ExportSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.exports.ExportSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.exportType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof exports.ExportSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {exports.ExportSession} ExportSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportSession message.
         * @function verify
         * @memberof exports.ExportSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.exportType != null && message.hasOwnProperty("exportType"))
                switch (message.exportType) {
                default:
                    return "exportType: enum value expected";
                case 0:
                    break;
                }
            return null;
        };

        /**
         * Creates an ExportSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof exports.ExportSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {exports.ExportSession} ExportSession
         */
        ExportSession.fromObject = function fromObject(object) {
            if (object instanceof $root.exports.ExportSession)
                return object;
            let message = new $root.exports.ExportSession();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            switch (object.exportType) {
            default:
                if (typeof object.exportType === "number") {
                    message.exportType = object.exportType;
                    break;
                }
                break;
            case "potrdilo":
            case 0:
                message.exportType = 0;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an ExportSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof exports.ExportSession
         * @static
         * @param {exports.ExportSession} message ExportSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.exportType = options.enums === String ? "potrdilo" : 0;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.exportType != null && message.hasOwnProperty("exportType"))
                object.exportType = options.enums === String ? $root.exports.ExportType[message.exportType] === undefined ? message.exportType : $root.exports.ExportType[message.exportType] : message.exportType;
            return object;
        };

        /**
         * Converts this ExportSession to JSON.
         * @function toJSON
         * @memberof exports.ExportSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExportSession
         * @function getTypeUrl
         * @memberof exports.ExportSession
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExportSession.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/exports.ExportSession";
        };

        return ExportSession;
    })();

    exports.ExportSlot = (function() {

        /**
         * Properties of an ExportSlot.
         * @memberof exports
         * @interface IExportSlot
         * @property {string|null} [url] ExportSlot url
         * @property {string|null} [name] ExportSlot name
         */

        /**
         * Constructs a new ExportSlot.
         * @memberof exports
         * @classdesc Represents an ExportSlot.
         * @implements IExportSlot
         * @constructor
         * @param {exports.IExportSlot=} [properties] Properties to set
         */
        function ExportSlot(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ExportSlot url.
         * @member {string} url
         * @memberof exports.ExportSlot
         * @instance
         */
        ExportSlot.prototype.url = "";

        /**
         * ExportSlot name.
         * @member {string} name
         * @memberof exports.ExportSlot
         * @instance
         */
        ExportSlot.prototype.name = "";

        /**
         * Creates a new ExportSlot instance using the specified properties.
         * @function create
         * @memberof exports.ExportSlot
         * @static
         * @param {exports.IExportSlot=} [properties] Properties to set
         * @returns {exports.ExportSlot} ExportSlot instance
         */
        ExportSlot.create = function create(properties) {
            return new ExportSlot(properties);
        };

        /**
         * Encodes the specified ExportSlot message. Does not implicitly {@link exports.ExportSlot.verify|verify} messages.
         * @function encode
         * @memberof exports.ExportSlot
         * @static
         * @param {exports.IExportSlot} message ExportSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified ExportSlot message, length delimited. Does not implicitly {@link exports.ExportSlot.verify|verify} messages.
         * @function encodeDelimited
         * @memberof exports.ExportSlot
         * @static
         * @param {exports.IExportSlot} message ExportSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportSlot.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ExportSlot message from the specified reader or buffer.
         * @function decode
         * @memberof exports.ExportSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {exports.ExportSlot} ExportSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.exports.ExportSlot();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.url = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ExportSlot message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof exports.ExportSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {exports.ExportSlot} ExportSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportSlot.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ExportSlot message.
         * @function verify
         * @memberof exports.ExportSlot
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportSlot.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates an ExportSlot message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof exports.ExportSlot
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {exports.ExportSlot} ExportSlot
         */
        ExportSlot.fromObject = function fromObject(object) {
            if (object instanceof $root.exports.ExportSlot)
                return object;
            let message = new $root.exports.ExportSlot();
            if (object.url != null)
                message.url = String(object.url);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from an ExportSlot message. Also converts values to other types if specified.
         * @function toObject
         * @memberof exports.ExportSlot
         * @static
         * @param {exports.ExportSlot} message ExportSlot
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportSlot.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                object.name = "";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ExportSlot to JSON.
         * @function toJSON
         * @memberof exports.ExportSlot
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportSlot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ExportSlot
         * @function getTypeUrl
         * @memberof exports.ExportSlot
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ExportSlot.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/exports.ExportSlot";
        };

        return ExportSlot;
    })();

    /**
     * ExportType enum.
     * @name exports.ExportType
     * @enum {number}
     * @property {number} potrdilo=0 potrdilo value
     */
    exports.ExportType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "potrdilo"] = 0;
        return values;
    })();

    return exports;
})();

export const cvv_types = $root.cvv_types = (() => {

    /**
     * Namespace cvv_types.
     * @exports cvv_types
     * @namespace
     */
    const cvv_types = {};

    cvv_types.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof cvv_types
         * @interface IAddress
         * @property {string|null} [address] Address address
         * @property {number|null} [lat] Address lat
         * @property {number|null} [lng] Address lng
         * @property {string|null} [city] Address city
         * @property {string|null} [country] Address country
         * @property {string|null} [line1] Address line1
         * @property {string|null} [postalCode] Address postalCode
         * @property {string|null} [state] Address state
         */

        /**
         * Constructs a new Address.
         * @memberof cvv_types
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {cvv_types.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address address.
         * @member {string} address
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.address = "";

        /**
         * Address lat.
         * @member {number} lat
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.lat = 0;

        /**
         * Address lng.
         * @member {number} lng
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.lng = 0;

        /**
         * Address city.
         * @member {string} city
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.city = "";

        /**
         * Address country.
         * @member {string} country
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.country = "";

        /**
         * Address line1.
         * @member {string} line1
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.line1 = "";

        /**
         * Address postalCode.
         * @member {string} postalCode
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.postalCode = "";

        /**
         * Address state.
         * @member {string} state
         * @memberof cvv_types.Address
         * @instance
         */
        Address.prototype.state = "";

        /**
         * Creates a new Address instance using the specified properties.
         * @function create
         * @memberof cvv_types.Address
         * @static
         * @param {cvv_types.IAddress=} [properties] Properties to set
         * @returns {cvv_types.Address} Address instance
         */
        Address.create = function create(properties) {
            return new Address(properties);
        };

        /**
         * Encodes the specified Address message. Does not implicitly {@link cvv_types.Address.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.Address
         * @static
         * @param {cvv_types.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lat);
            if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.lng);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.city);
            if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.country);
            if (message.line1 != null && Object.hasOwnProperty.call(message, "line1"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.line1);
            if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.postalCode);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.state);
            return writer;
        };

        /**
         * Encodes the specified Address message, length delimited. Does not implicitly {@link cvv_types.Address.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.Address
         * @static
         * @param {cvv_types.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Address message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.Address();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.address = reader.string();
                        break;
                    }
                case 2: {
                        message.lat = reader.double();
                        break;
                    }
                case 3: {
                        message.lng = reader.double();
                        break;
                    }
                case 4: {
                        message.city = reader.string();
                        break;
                    }
                case 5: {
                        message.country = reader.string();
                        break;
                    }
                case 6: {
                        message.line1 = reader.string();
                        break;
                    }
                case 8: {
                        message.postalCode = reader.string();
                        break;
                    }
                case 9: {
                        message.state = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Address message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Address message.
         * @function verify
         * @memberof cvv_types.Address
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Address.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.address != null && message.hasOwnProperty("address"))
                if (!$util.isString(message.address))
                    return "address: string expected";
            if (message.lat != null && message.hasOwnProperty("lat"))
                if (typeof message.lat !== "number")
                    return "lat: number expected";
            if (message.lng != null && message.hasOwnProperty("lng"))
                if (typeof message.lng !== "number")
                    return "lng: number expected";
            if (message.city != null && message.hasOwnProperty("city"))
                if (!$util.isString(message.city))
                    return "city: string expected";
            if (message.country != null && message.hasOwnProperty("country"))
                if (!$util.isString(message.country))
                    return "country: string expected";
            if (message.line1 != null && message.hasOwnProperty("line1"))
                if (!$util.isString(message.line1))
                    return "line1: string expected";
            if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                if (!$util.isString(message.postalCode))
                    return "postalCode: string expected";
            if (message.state != null && message.hasOwnProperty("state"))
                if (!$util.isString(message.state))
                    return "state: string expected";
            return null;
        };

        /**
         * Creates an Address message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.Address
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.Address} Address
         */
        Address.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.Address)
                return object;
            let message = new $root.cvv_types.Address();
            if (object.address != null)
                message.address = String(object.address);
            if (object.lat != null)
                message.lat = Number(object.lat);
            if (object.lng != null)
                message.lng = Number(object.lng);
            if (object.city != null)
                message.city = String(object.city);
            if (object.country != null)
                message.country = String(object.country);
            if (object.line1 != null)
                message.line1 = String(object.line1);
            if (object.postalCode != null)
                message.postalCode = String(object.postalCode);
            if (object.state != null)
                message.state = String(object.state);
            return message;
        };

        /**
         * Creates a plain object from an Address message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.Address
         * @static
         * @param {cvv_types.Address} message Address
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Address.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.address = "";
                object.lat = 0;
                object.lng = 0;
                object.city = "";
                object.country = "";
                object.line1 = "";
                object.postalCode = "";
                object.state = "";
            }
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.lat != null && message.hasOwnProperty("lat"))
                object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
            if (message.lng != null && message.hasOwnProperty("lng"))
                object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
            if (message.city != null && message.hasOwnProperty("city"))
                object.city = message.city;
            if (message.country != null && message.hasOwnProperty("country"))
                object.country = message.country;
            if (message.line1 != null && message.hasOwnProperty("line1"))
                object.line1 = message.line1;
            if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                object.postalCode = message.postalCode;
            if (message.state != null && message.hasOwnProperty("state"))
                object.state = message.state;
            return object;
        };

        /**
         * Converts this Address to JSON.
         * @function toJSON
         * @memberof cvv_types.Address
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Address.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Address
         * @function getTypeUrl
         * @memberof cvv_types.Address
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Address.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.Address";
        };

        return Address;
    })();

    cvv_types.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof cvv_types
         * @interface IDate
         * @property {number|Long|null} [ts] Date ts
         * @property {number|null} [year] Date year
         * @property {number|null} [month] Date month
         * @property {number|null} [day] Date day
         */

        /**
         * Constructs a new Date.
         * @memberof cvv_types
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {cvv_types.IDate=} [properties] Properties to set
         */
        function Date(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Date ts.
         * @member {number|Long} ts
         * @memberof cvv_types.Date
         * @instance
         */
        Date.prototype.ts = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Date year.
         * @member {number} year
         * @memberof cvv_types.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof cvv_types.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof cvv_types.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof cvv_types.Date
         * @static
         * @param {cvv_types.IDate=} [properties] Properties to set
         * @returns {cvv_types.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link cvv_types.Date.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.Date
         * @static
         * @param {cvv_types.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.ts != null && Object.hasOwnProperty.call(message, "ts"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.ts);
            if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.year);
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.month);
            if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.day);
            return writer;
        };

        /**
         * Encodes the specified Date message, length delimited. Does not implicitly {@link cvv_types.Date.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.Date
         * @static
         * @param {cvv_types.IDate} message Date message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.Date();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.ts = reader.uint64();
                        break;
                    }
                case 2: {
                        message.year = reader.int32();
                        break;
                    }
                case 3: {
                        message.month = reader.int32();
                        break;
                    }
                case 4: {
                        message.day = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Date message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Date message.
         * @function verify
         * @memberof cvv_types.Date
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Date.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (!$util.isInteger(message.ts) && !(message.ts && $util.isInteger(message.ts.low) && $util.isInteger(message.ts.high)))
                    return "ts: integer|Long expected";
            if (message.year != null && message.hasOwnProperty("year"))
                if (!$util.isInteger(message.year))
                    return "year: integer expected";
            if (message.month != null && message.hasOwnProperty("month"))
                if (!$util.isInteger(message.month))
                    return "month: integer expected";
            if (message.day != null && message.hasOwnProperty("day"))
                if (!$util.isInteger(message.day))
                    return "day: integer expected";
            return null;
        };

        /**
         * Creates a Date message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.Date
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.Date} Date
         */
        Date.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.Date)
                return object;
            let message = new $root.cvv_types.Date();
            if (object.ts != null)
                if ($util.Long)
                    (message.ts = $util.Long.fromValue(object.ts)).unsigned = true;
                else if (typeof object.ts === "string")
                    message.ts = parseInt(object.ts, 10);
                else if (typeof object.ts === "number")
                    message.ts = object.ts;
                else if (typeof object.ts === "object")
                    message.ts = new $util.LongBits(object.ts.low >>> 0, object.ts.high >>> 0).toNumber(true);
            if (object.year != null)
                message.year = object.year | 0;
            if (object.month != null)
                message.month = object.month | 0;
            if (object.day != null)
                message.day = object.day | 0;
            return message;
        };

        /**
         * Creates a plain object from a Date message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.Date
         * @static
         * @param {cvv_types.Date} message Date
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Date.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, true);
                    object.ts = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.ts = options.longs === String ? "0" : 0;
                object.year = 0;
                object.month = 0;
                object.day = 0;
            }
            if (message.ts != null && message.hasOwnProperty("ts"))
                if (typeof message.ts === "number")
                    object.ts = options.longs === String ? String(message.ts) : message.ts;
                else
                    object.ts = options.longs === String ? $util.Long.prototype.toString.call(message.ts) : options.longs === Number ? new $util.LongBits(message.ts.low >>> 0, message.ts.high >>> 0).toNumber(true) : message.ts;
            if (message.year != null && message.hasOwnProperty("year"))
                object.year = message.year;
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = message.month;
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = message.day;
            return object;
        };

        /**
         * Converts this Date to JSON.
         * @function toJSON
         * @memberof cvv_types.Date
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Date.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Date
         * @function getTypeUrl
         * @memberof cvv_types.Date
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Date.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.Date";
        };

        return Date;
    })();

    /**
     * FormType enum.
     * @name cvv_types.FormType
     * @enum {number}
     * @property {number} mladi_voznik=0 mladi_voznik value
     */
    cvv_types.FormType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "mladi_voznik"] = 0;
        return values;
    })();

    cvv_types.Choices = (function() {

        /**
         * Properties of a Choices.
         * @memberof cvv_types
         * @interface IChoices
         * @property {Array.<cvv_types.IChoice>|null} [choices] Choices choices
         */

        /**
         * Constructs a new Choices.
         * @memberof cvv_types
         * @classdesc Represents a Choices.
         * @implements IChoices
         * @constructor
         * @param {cvv_types.IChoices=} [properties] Properties to set
         */
        function Choices(properties) {
            this.choices = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Choices choices.
         * @member {Array.<cvv_types.IChoice>} choices
         * @memberof cvv_types.Choices
         * @instance
         */
        Choices.prototype.choices = $util.emptyArray;

        /**
         * Creates a new Choices instance using the specified properties.
         * @function create
         * @memberof cvv_types.Choices
         * @static
         * @param {cvv_types.IChoices=} [properties] Properties to set
         * @returns {cvv_types.Choices} Choices instance
         */
        Choices.create = function create(properties) {
            return new Choices(properties);
        };

        /**
         * Encodes the specified Choices message. Does not implicitly {@link cvv_types.Choices.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.Choices
         * @static
         * @param {cvv_types.IChoices} message Choices message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Choices.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.choices != null && message.choices.length)
                for (let i = 0; i < message.choices.length; ++i)
                    $root.cvv_types.Choice.encode(message.choices[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Choices message, length delimited. Does not implicitly {@link cvv_types.Choices.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.Choices
         * @static
         * @param {cvv_types.IChoices} message Choices message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Choices.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Choices message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.Choices
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.Choices} Choices
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Choices.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.Choices();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.choices && message.choices.length))
                            message.choices = [];
                        message.choices.push($root.cvv_types.Choice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Choices message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.Choices
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.Choices} Choices
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Choices.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Choices message.
         * @function verify
         * @memberof cvv_types.Choices
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Choices.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.choices != null && message.hasOwnProperty("choices")) {
                if (!Array.isArray(message.choices))
                    return "choices: array expected";
                for (let i = 0; i < message.choices.length; ++i) {
                    let error = $root.cvv_types.Choice.verify(message.choices[i]);
                    if (error)
                        return "choices." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Choices message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.Choices
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.Choices} Choices
         */
        Choices.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.Choices)
                return object;
            let message = new $root.cvv_types.Choices();
            if (object.choices) {
                if (!Array.isArray(object.choices))
                    throw TypeError(".cvv_types.Choices.choices: array expected");
                message.choices = [];
                for (let i = 0; i < object.choices.length; ++i) {
                    if (typeof object.choices[i] !== "object")
                        throw TypeError(".cvv_types.Choices.choices: object expected");
                    message.choices[i] = $root.cvv_types.Choice.fromObject(object.choices[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a Choices message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.Choices
         * @static
         * @param {cvv_types.Choices} message Choices
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Choices.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.choices = [];
            if (message.choices && message.choices.length) {
                object.choices = [];
                for (let j = 0; j < message.choices.length; ++j)
                    object.choices[j] = $root.cvv_types.Choice.toObject(message.choices[j], options);
            }
            return object;
        };

        /**
         * Converts this Choices to JSON.
         * @function toJSON
         * @memberof cvv_types.Choices
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Choices.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Choices
         * @function getTypeUrl
         * @memberof cvv_types.Choices
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Choices.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.Choices";
        };

        return Choices;
    })();

    cvv_types.Choice = (function() {

        /**
         * Properties of a Choice.
         * @memberof cvv_types
         * @interface IChoice
         * @property {string|null} [text] Choice text
         * @property {number|null} [num] Choice num
         * @property {string|null} [name] Choice name
         */

        /**
         * Constructs a new Choice.
         * @memberof cvv_types
         * @classdesc Represents a Choice.
         * @implements IChoice
         * @constructor
         * @param {cvv_types.IChoice=} [properties] Properties to set
         */
        function Choice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Choice text.
         * @member {string} text
         * @memberof cvv_types.Choice
         * @instance
         */
        Choice.prototype.text = "";

        /**
         * Choice num.
         * @member {number} num
         * @memberof cvv_types.Choice
         * @instance
         */
        Choice.prototype.num = 0;

        /**
         * Choice name.
         * @member {string} name
         * @memberof cvv_types.Choice
         * @instance
         */
        Choice.prototype.name = "";

        /**
         * Creates a new Choice instance using the specified properties.
         * @function create
         * @memberof cvv_types.Choice
         * @static
         * @param {cvv_types.IChoice=} [properties] Properties to set
         * @returns {cvv_types.Choice} Choice instance
         */
        Choice.create = function create(properties) {
            return new Choice(properties);
        };

        /**
         * Encodes the specified Choice message. Does not implicitly {@link cvv_types.Choice.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.Choice
         * @static
         * @param {cvv_types.IChoice} message Choice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Choice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
            if (message.num != null && Object.hasOwnProperty.call(message, "num"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.num);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified Choice message, length delimited. Does not implicitly {@link cvv_types.Choice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.Choice
         * @static
         * @param {cvv_types.IChoice} message Choice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Choice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Choice message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.Choice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.Choice} Choice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Choice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.Choice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.text = reader.string();
                        break;
                    }
                case 2: {
                        message.num = reader.int32();
                        break;
                    }
                case 3: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Choice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.Choice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.Choice} Choice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Choice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Choice message.
         * @function verify
         * @memberof cvv_types.Choice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Choice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.num != null && message.hasOwnProperty("num"))
                if (!$util.isInteger(message.num))
                    return "num: integer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a Choice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.Choice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.Choice} Choice
         */
        Choice.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.Choice)
                return object;
            let message = new $root.cvv_types.Choice();
            if (object.text != null)
                message.text = String(object.text);
            if (object.num != null)
                message.num = object.num | 0;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a Choice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.Choice
         * @static
         * @param {cvv_types.Choice} message Choice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Choice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.text = "";
                object.num = 0;
                object.name = "";
            }
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.num != null && message.hasOwnProperty("num"))
                object.num = message.num;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this Choice to JSON.
         * @function toJSON
         * @memberof cvv_types.Choice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Choice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Choice
         * @function getTypeUrl
         * @memberof cvv_types.Choice
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Choice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.Choice";
        };

        return Choice;
    })();

    cvv_types.Comment = (function() {

        /**
         * Properties of a Comment.
         * @memberof cvv_types
         * @interface IComment
         * @property {string|null} [id] Comment id
         * @property {cvv_types.IDate|null} [date] Comment date
         * @property {string|null} [user] Comment user
         * @property {string|null} [text] Comment text
         * @property {cvv_types.ICommentType|null} [type] Comment type
         */

        /**
         * Constructs a new Comment.
         * @memberof cvv_types
         * @classdesc Represents a Comment.
         * @implements IComment
         * @constructor
         * @param {cvv_types.IComment=} [properties] Properties to set
         */
        function Comment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Comment id.
         * @member {string} id
         * @memberof cvv_types.Comment
         * @instance
         */
        Comment.prototype.id = "";

        /**
         * Comment date.
         * @member {cvv_types.IDate|null|undefined} date
         * @memberof cvv_types.Comment
         * @instance
         */
        Comment.prototype.date = null;

        /**
         * Comment user.
         * @member {string} user
         * @memberof cvv_types.Comment
         * @instance
         */
        Comment.prototype.user = "";

        /**
         * Comment text.
         * @member {string} text
         * @memberof cvv_types.Comment
         * @instance
         */
        Comment.prototype.text = "";

        /**
         * Comment type.
         * @member {cvv_types.ICommentType|null|undefined} type
         * @memberof cvv_types.Comment
         * @instance
         */
        Comment.prototype.type = null;

        /**
         * Creates a new Comment instance using the specified properties.
         * @function create
         * @memberof cvv_types.Comment
         * @static
         * @param {cvv_types.IComment=} [properties] Properties to set
         * @returns {cvv_types.Comment} Comment instance
         */
        Comment.create = function create(properties) {
            return new Comment(properties);
        };

        /**
         * Encodes the specified Comment message. Does not implicitly {@link cvv_types.Comment.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.Comment
         * @static
         * @param {cvv_types.IComment} message Comment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Comment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.cvv_types.Date.encode(message.date, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.user);
            if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.text);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                $root.cvv_types.CommentType.encode(message.type, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Comment message, length delimited. Does not implicitly {@link cvv_types.Comment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.Comment
         * @static
         * @param {cvv_types.IComment} message Comment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Comment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Comment message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.Comment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.Comment} Comment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Comment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.Comment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.date = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.user = reader.string();
                        break;
                    }
                case 4: {
                        message.text = reader.string();
                        break;
                    }
                case 5: {
                        message.type = $root.cvv_types.CommentType.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Comment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.Comment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.Comment} Comment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Comment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Comment message.
         * @function verify
         * @memberof cvv_types.Comment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Comment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.cvv_types.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            if (message.user != null && message.hasOwnProperty("user"))
                if (!$util.isString(message.user))
                    return "user: string expected";
            if (message.text != null && message.hasOwnProperty("text"))
                if (!$util.isString(message.text))
                    return "text: string expected";
            if (message.type != null && message.hasOwnProperty("type")) {
                let error = $root.cvv_types.CommentType.verify(message.type);
                if (error)
                    return "type." + error;
            }
            return null;
        };

        /**
         * Creates a Comment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.Comment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.Comment} Comment
         */
        Comment.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.Comment)
                return object;
            let message = new $root.cvv_types.Comment();
            if (object.id != null)
                message.id = String(object.id);
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".cvv_types.Comment.date: object expected");
                message.date = $root.cvv_types.Date.fromObject(object.date);
            }
            if (object.user != null)
                message.user = String(object.user);
            if (object.text != null)
                message.text = String(object.text);
            if (object.type != null) {
                if (typeof object.type !== "object")
                    throw TypeError(".cvv_types.Comment.type: object expected");
                message.type = $root.cvv_types.CommentType.fromObject(object.type);
            }
            return message;
        };

        /**
         * Creates a plain object from a Comment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.Comment
         * @static
         * @param {cvv_types.Comment} message Comment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Comment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.date = null;
                object.user = "";
                object.text = "";
                object.type = null;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.cvv_types.Date.toObject(message.date, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = message.user;
            if (message.text != null && message.hasOwnProperty("text"))
                object.text = message.text;
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = $root.cvv_types.CommentType.toObject(message.type, options);
            return object;
        };

        /**
         * Converts this Comment to JSON.
         * @function toJSON
         * @memberof cvv_types.Comment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Comment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Comment
         * @function getTypeUrl
         * @memberof cvv_types.Comment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Comment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.Comment";
        };

        return Comment;
    })();

    cvv_types.CvvGrid = (function() {

        /**
         * Properties of a CvvGrid.
         * @memberof cvv_types
         * @interface ICvvGrid
         * @property {cvv_types.IGridRow|null} [header] CvvGrid header
         * @property {Array.<cvv_types.IGridRow>|null} [rows] CvvGrid rows
         * @property {string|null} [templateColumns] CvvGrid templateColumns
         */

        /**
         * Constructs a new CvvGrid.
         * @memberof cvv_types
         * @classdesc Represents a CvvGrid.
         * @implements ICvvGrid
         * @constructor
         * @param {cvv_types.ICvvGrid=} [properties] Properties to set
         */
        function CvvGrid(properties) {
            this.rows = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CvvGrid header.
         * @member {cvv_types.IGridRow|null|undefined} header
         * @memberof cvv_types.CvvGrid
         * @instance
         */
        CvvGrid.prototype.header = null;

        /**
         * CvvGrid rows.
         * @member {Array.<cvv_types.IGridRow>} rows
         * @memberof cvv_types.CvvGrid
         * @instance
         */
        CvvGrid.prototype.rows = $util.emptyArray;

        /**
         * CvvGrid templateColumns.
         * @member {string} templateColumns
         * @memberof cvv_types.CvvGrid
         * @instance
         */
        CvvGrid.prototype.templateColumns = "";

        /**
         * Creates a new CvvGrid instance using the specified properties.
         * @function create
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {cvv_types.ICvvGrid=} [properties] Properties to set
         * @returns {cvv_types.CvvGrid} CvvGrid instance
         */
        CvvGrid.create = function create(properties) {
            return new CvvGrid(properties);
        };

        /**
         * Encodes the specified CvvGrid message. Does not implicitly {@link cvv_types.CvvGrid.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {cvv_types.ICvvGrid} message CvvGrid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CvvGrid.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.header != null && Object.hasOwnProperty.call(message, "header"))
                $root.cvv_types.GridRow.encode(message.header, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rows != null && message.rows.length)
                for (let i = 0; i < message.rows.length; ++i)
                    $root.cvv_types.GridRow.encode(message.rows[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.templateColumns != null && Object.hasOwnProperty.call(message, "templateColumns"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.templateColumns);
            return writer;
        };

        /**
         * Encodes the specified CvvGrid message, length delimited. Does not implicitly {@link cvv_types.CvvGrid.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {cvv_types.ICvvGrid} message CvvGrid message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CvvGrid.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CvvGrid message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.CvvGrid} CvvGrid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CvvGrid.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.CvvGrid();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.header = $root.cvv_types.GridRow.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.rows && message.rows.length))
                            message.rows = [];
                        message.rows.push($root.cvv_types.GridRow.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.templateColumns = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CvvGrid message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.CvvGrid} CvvGrid
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CvvGrid.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CvvGrid message.
         * @function verify
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CvvGrid.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.header != null && message.hasOwnProperty("header")) {
                let error = $root.cvv_types.GridRow.verify(message.header);
                if (error)
                    return "header." + error;
            }
            if (message.rows != null && message.hasOwnProperty("rows")) {
                if (!Array.isArray(message.rows))
                    return "rows: array expected";
                for (let i = 0; i < message.rows.length; ++i) {
                    let error = $root.cvv_types.GridRow.verify(message.rows[i]);
                    if (error)
                        return "rows." + error;
                }
            }
            if (message.templateColumns != null && message.hasOwnProperty("templateColumns"))
                if (!$util.isString(message.templateColumns))
                    return "templateColumns: string expected";
            return null;
        };

        /**
         * Creates a CvvGrid message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.CvvGrid} CvvGrid
         */
        CvvGrid.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.CvvGrid)
                return object;
            let message = new $root.cvv_types.CvvGrid();
            if (object.header != null) {
                if (typeof object.header !== "object")
                    throw TypeError(".cvv_types.CvvGrid.header: object expected");
                message.header = $root.cvv_types.GridRow.fromObject(object.header);
            }
            if (object.rows) {
                if (!Array.isArray(object.rows))
                    throw TypeError(".cvv_types.CvvGrid.rows: array expected");
                message.rows = [];
                for (let i = 0; i < object.rows.length; ++i) {
                    if (typeof object.rows[i] !== "object")
                        throw TypeError(".cvv_types.CvvGrid.rows: object expected");
                    message.rows[i] = $root.cvv_types.GridRow.fromObject(object.rows[i]);
                }
            }
            if (object.templateColumns != null)
                message.templateColumns = String(object.templateColumns);
            return message;
        };

        /**
         * Creates a plain object from a CvvGrid message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {cvv_types.CvvGrid} message CvvGrid
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CvvGrid.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.rows = [];
            if (options.defaults) {
                object.header = null;
                object.templateColumns = "";
            }
            if (message.header != null && message.hasOwnProperty("header"))
                object.header = $root.cvv_types.GridRow.toObject(message.header, options);
            if (message.rows && message.rows.length) {
                object.rows = [];
                for (let j = 0; j < message.rows.length; ++j)
                    object.rows[j] = $root.cvv_types.GridRow.toObject(message.rows[j], options);
            }
            if (message.templateColumns != null && message.hasOwnProperty("templateColumns"))
                object.templateColumns = message.templateColumns;
            return object;
        };

        /**
         * Converts this CvvGrid to JSON.
         * @function toJSON
         * @memberof cvv_types.CvvGrid
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CvvGrid.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CvvGrid
         * @function getTypeUrl
         * @memberof cvv_types.CvvGrid
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CvvGrid.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.CvvGrid";
        };

        return CvvGrid;
    })();

    cvv_types.GridRow = (function() {

        /**
         * Properties of a GridRow.
         * @memberof cvv_types
         * @interface IGridRow
         * @property {Array.<cvv_types.IGridColumn>|null} [cols] GridRow cols
         */

        /**
         * Constructs a new GridRow.
         * @memberof cvv_types
         * @classdesc Represents a GridRow.
         * @implements IGridRow
         * @constructor
         * @param {cvv_types.IGridRow=} [properties] Properties to set
         */
        function GridRow(properties) {
            this.cols = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridRow cols.
         * @member {Array.<cvv_types.IGridColumn>} cols
         * @memberof cvv_types.GridRow
         * @instance
         */
        GridRow.prototype.cols = $util.emptyArray;

        /**
         * Creates a new GridRow instance using the specified properties.
         * @function create
         * @memberof cvv_types.GridRow
         * @static
         * @param {cvv_types.IGridRow=} [properties] Properties to set
         * @returns {cvv_types.GridRow} GridRow instance
         */
        GridRow.create = function create(properties) {
            return new GridRow(properties);
        };

        /**
         * Encodes the specified GridRow message. Does not implicitly {@link cvv_types.GridRow.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.GridRow
         * @static
         * @param {cvv_types.IGridRow} message GridRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridRow.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cols != null && message.cols.length)
                for (let i = 0; i < message.cols.length; ++i)
                    $root.cvv_types.GridColumn.encode(message.cols[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GridRow message, length delimited. Does not implicitly {@link cvv_types.GridRow.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.GridRow
         * @static
         * @param {cvv_types.IGridRow} message GridRow message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridRow.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridRow message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.GridRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.GridRow} GridRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridRow.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.GridRow();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.cols && message.cols.length))
                            message.cols = [];
                        message.cols.push($root.cvv_types.GridColumn.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridRow message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.GridRow
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.GridRow} GridRow
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridRow.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridRow message.
         * @function verify
         * @memberof cvv_types.GridRow
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridRow.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.cols != null && message.hasOwnProperty("cols")) {
                if (!Array.isArray(message.cols))
                    return "cols: array expected";
                for (let i = 0; i < message.cols.length; ++i) {
                    let error = $root.cvv_types.GridColumn.verify(message.cols[i]);
                    if (error)
                        return "cols." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GridRow message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.GridRow
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.GridRow} GridRow
         */
        GridRow.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.GridRow)
                return object;
            let message = new $root.cvv_types.GridRow();
            if (object.cols) {
                if (!Array.isArray(object.cols))
                    throw TypeError(".cvv_types.GridRow.cols: array expected");
                message.cols = [];
                for (let i = 0; i < object.cols.length; ++i) {
                    if (typeof object.cols[i] !== "object")
                        throw TypeError(".cvv_types.GridRow.cols: object expected");
                    message.cols[i] = $root.cvv_types.GridColumn.fromObject(object.cols[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GridRow message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.GridRow
         * @static
         * @param {cvv_types.GridRow} message GridRow
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridRow.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.cols = [];
            if (message.cols && message.cols.length) {
                object.cols = [];
                for (let j = 0; j < message.cols.length; ++j)
                    object.cols[j] = $root.cvv_types.GridColumn.toObject(message.cols[j], options);
            }
            return object;
        };

        /**
         * Converts this GridRow to JSON.
         * @function toJSON
         * @memberof cvv_types.GridRow
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridRow.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridRow
         * @function getTypeUrl
         * @memberof cvv_types.GridRow
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridRow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.GridRow";
        };

        return GridRow;
    })();

    cvv_types.GridColumn = (function() {

        /**
         * Properties of a GridColumn.
         * @memberof cvv_types
         * @interface IGridColumn
         * @property {string|null} [content] GridColumn content
         * @property {number|null} [span] GridColumn span
         * @property {cvv_types.GridColumnStyle|null} [style] GridColumn style
         */

        /**
         * Constructs a new GridColumn.
         * @memberof cvv_types
         * @classdesc Represents a GridColumn.
         * @implements IGridColumn
         * @constructor
         * @param {cvv_types.IGridColumn=} [properties] Properties to set
         */
        function GridColumn(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GridColumn content.
         * @member {string} content
         * @memberof cvv_types.GridColumn
         * @instance
         */
        GridColumn.prototype.content = "";

        /**
         * GridColumn span.
         * @member {number} span
         * @memberof cvv_types.GridColumn
         * @instance
         */
        GridColumn.prototype.span = 0;

        /**
         * GridColumn style.
         * @member {cvv_types.GridColumnStyle} style
         * @memberof cvv_types.GridColumn
         * @instance
         */
        GridColumn.prototype.style = 0;

        /**
         * Creates a new GridColumn instance using the specified properties.
         * @function create
         * @memberof cvv_types.GridColumn
         * @static
         * @param {cvv_types.IGridColumn=} [properties] Properties to set
         * @returns {cvv_types.GridColumn} GridColumn instance
         */
        GridColumn.create = function create(properties) {
            return new GridColumn(properties);
        };

        /**
         * Encodes the specified GridColumn message. Does not implicitly {@link cvv_types.GridColumn.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.GridColumn
         * @static
         * @param {cvv_types.IGridColumn} message GridColumn message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridColumn.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.content != null && Object.hasOwnProperty.call(message, "content"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.content);
            if (message.span != null && Object.hasOwnProperty.call(message, "span"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.span);
            if (message.style != null && Object.hasOwnProperty.call(message, "style"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.style);
            return writer;
        };

        /**
         * Encodes the specified GridColumn message, length delimited. Does not implicitly {@link cvv_types.GridColumn.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.GridColumn
         * @static
         * @param {cvv_types.IGridColumn} message GridColumn message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GridColumn.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GridColumn message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.GridColumn
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.GridColumn} GridColumn
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridColumn.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.GridColumn();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.content = reader.string();
                        break;
                    }
                case 2: {
                        message.span = reader.int32();
                        break;
                    }
                case 3: {
                        message.style = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GridColumn message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.GridColumn
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.GridColumn} GridColumn
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GridColumn.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GridColumn message.
         * @function verify
         * @memberof cvv_types.GridColumn
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GridColumn.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.content != null && message.hasOwnProperty("content"))
                if (!$util.isString(message.content))
                    return "content: string expected";
            if (message.span != null && message.hasOwnProperty("span"))
                if (!$util.isInteger(message.span))
                    return "span: integer expected";
            if (message.style != null && message.hasOwnProperty("style"))
                switch (message.style) {
                default:
                    return "style: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a GridColumn message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.GridColumn
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.GridColumn} GridColumn
         */
        GridColumn.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.GridColumn)
                return object;
            let message = new $root.cvv_types.GridColumn();
            if (object.content != null)
                message.content = String(object.content);
            if (object.span != null)
                message.span = object.span | 0;
            switch (object.style) {
            default:
                if (typeof object.style === "number") {
                    message.style = object.style;
                    break;
                }
                break;
            case "default":
            case 0:
                message.style = 0;
                break;
            case "error":
            case 1:
                message.style = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GridColumn message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.GridColumn
         * @static
         * @param {cvv_types.GridColumn} message GridColumn
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GridColumn.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.content = "";
                object.span = 0;
                object.style = options.enums === String ? "default" : 0;
            }
            if (message.content != null && message.hasOwnProperty("content"))
                object.content = message.content;
            if (message.span != null && message.hasOwnProperty("span"))
                object.span = message.span;
            if (message.style != null && message.hasOwnProperty("style"))
                object.style = options.enums === String ? $root.cvv_types.GridColumnStyle[message.style] === undefined ? message.style : $root.cvv_types.GridColumnStyle[message.style] : message.style;
            return object;
        };

        /**
         * Converts this GridColumn to JSON.
         * @function toJSON
         * @memberof cvv_types.GridColumn
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GridColumn.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GridColumn
         * @function getTypeUrl
         * @memberof cvv_types.GridColumn
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GridColumn.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.GridColumn";
        };

        return GridColumn;
    })();

    /**
     * GridColumnStyle enum.
     * @name cvv_types.GridColumnStyle
     * @enum {number}
     * @property {number} default=0 default value
     * @property {number} error=1 error value
     */
    cvv_types.GridColumnStyle = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "default"] = 0;
        values[valuesById[1] = "error"] = 1;
        return values;
    })();

    cvv_types.CommentType = (function() {

        /**
         * Properties of a CommentType.
         * @memberof cvv_types
         * @interface ICommentType
         * @property {string|null} [id] CommentType id
         * @property {string|null} [label] CommentType label
         * @property {boolean|null} [userCanAdd] CommentType userCanAdd
         * @property {boolean|null} [userCanDelete] CommentType userCanDelete
         */

        /**
         * Constructs a new CommentType.
         * @memberof cvv_types
         * @classdesc Represents a CommentType.
         * @implements ICommentType
         * @constructor
         * @param {cvv_types.ICommentType=} [properties] Properties to set
         */
        function CommentType(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommentType id.
         * @member {string} id
         * @memberof cvv_types.CommentType
         * @instance
         */
        CommentType.prototype.id = "";

        /**
         * CommentType label.
         * @member {string} label
         * @memberof cvv_types.CommentType
         * @instance
         */
        CommentType.prototype.label = "";

        /**
         * CommentType userCanAdd.
         * @member {boolean} userCanAdd
         * @memberof cvv_types.CommentType
         * @instance
         */
        CommentType.prototype.userCanAdd = false;

        /**
         * CommentType userCanDelete.
         * @member {boolean} userCanDelete
         * @memberof cvv_types.CommentType
         * @instance
         */
        CommentType.prototype.userCanDelete = false;

        /**
         * Creates a new CommentType instance using the specified properties.
         * @function create
         * @memberof cvv_types.CommentType
         * @static
         * @param {cvv_types.ICommentType=} [properties] Properties to set
         * @returns {cvv_types.CommentType} CommentType instance
         */
        CommentType.create = function create(properties) {
            return new CommentType(properties);
        };

        /**
         * Encodes the specified CommentType message. Does not implicitly {@link cvv_types.CommentType.verify|verify} messages.
         * @function encode
         * @memberof cvv_types.CommentType
         * @static
         * @param {cvv_types.ICommentType} message CommentType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentType.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.userCanAdd != null && Object.hasOwnProperty.call(message, "userCanAdd"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.userCanAdd);
            if (message.userCanDelete != null && Object.hasOwnProperty.call(message, "userCanDelete"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.userCanDelete);
            return writer;
        };

        /**
         * Encodes the specified CommentType message, length delimited. Does not implicitly {@link cvv_types.CommentType.verify|verify} messages.
         * @function encodeDelimited
         * @memberof cvv_types.CommentType
         * @static
         * @param {cvv_types.ICommentType} message CommentType message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommentType.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CommentType message from the specified reader or buffer.
         * @function decode
         * @memberof cvv_types.CommentType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {cvv_types.CommentType} CommentType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentType.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.cvv_types.CommentType();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.label = reader.string();
                        break;
                    }
                case 3: {
                        message.userCanAdd = reader.bool();
                        break;
                    }
                case 4: {
                        message.userCanDelete = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CommentType message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof cvv_types.CommentType
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {cvv_types.CommentType} CommentType
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommentType.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CommentType message.
         * @function verify
         * @memberof cvv_types.CommentType
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CommentType.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.userCanAdd != null && message.hasOwnProperty("userCanAdd"))
                if (typeof message.userCanAdd !== "boolean")
                    return "userCanAdd: boolean expected";
            if (message.userCanDelete != null && message.hasOwnProperty("userCanDelete"))
                if (typeof message.userCanDelete !== "boolean")
                    return "userCanDelete: boolean expected";
            return null;
        };

        /**
         * Creates a CommentType message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof cvv_types.CommentType
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {cvv_types.CommentType} CommentType
         */
        CommentType.fromObject = function fromObject(object) {
            if (object instanceof $root.cvv_types.CommentType)
                return object;
            let message = new $root.cvv_types.CommentType();
            if (object.id != null)
                message.id = String(object.id);
            if (object.label != null)
                message.label = String(object.label);
            if (object.userCanAdd != null)
                message.userCanAdd = Boolean(object.userCanAdd);
            if (object.userCanDelete != null)
                message.userCanDelete = Boolean(object.userCanDelete);
            return message;
        };

        /**
         * Creates a plain object from a CommentType message. Also converts values to other types if specified.
         * @function toObject
         * @memberof cvv_types.CommentType
         * @static
         * @param {cvv_types.CommentType} message CommentType
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommentType.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.label = "";
                object.userCanAdd = false;
                object.userCanDelete = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.userCanAdd != null && message.hasOwnProperty("userCanAdd"))
                object.userCanAdd = message.userCanAdd;
            if (message.userCanDelete != null && message.hasOwnProperty("userCanDelete"))
                object.userCanDelete = message.userCanDelete;
            return object;
        };

        /**
         * Converts this CommentType to JSON.
         * @function toJSON
         * @memberof cvv_types.CommentType
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommentType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CommentType
         * @function getTypeUrl
         * @memberof cvv_types.CommentType
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CommentType.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/cvv_types.CommentType";
        };

        return CommentType;
    })();

    /**
     * Vozilo enum.
     * @name cvv_types.Vozilo
     * @enum {number}
     * @property {number} svoje=0 svoje value
     * @property {number} najeto=1 najeto value
     * @property {number} najeto_prijatelj=2 najeto_prijatelj value
     * @property {number} najeto_druga_oseba=3 najeto_druga_oseba value
     */
    cvv_types.Vozilo = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "svoje"] = 0;
        values[valuesById[1] = "najeto"] = 1;
        values[valuesById[2] = "najeto_prijatelj"] = 2;
        values[valuesById[3] = "najeto_druga_oseba"] = 3;
        return values;
    })();

    /**
     * CvvErrorCode enum.
     * @name cvv_types.CvvErrorCode
     * @enum {number}
     * @property {number} no_error=0 no_error value
     * @property {number} invalid_validation_code=1 invalid_validation_code value
     * @property {number} validation_error=2 validation_error value
     * @property {number} sms_already_sent=3 sms_already_sent value
     * @property {number} invalid_request_data=4 invalid_request_data value
     * @property {number} payment_still_processing=5 payment_still_processing value
     * @property {number} sms_pin_invalid=6 sms_pin_invalid value
     * @property {number} coupon_invalid=7 coupon_invalid value
     * @property {number} already_submitted=8 already_submitted value
     */
    cvv_types.CvvErrorCode = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "no_error"] = 0;
        values[valuesById[1] = "invalid_validation_code"] = 1;
        values[valuesById[2] = "validation_error"] = 2;
        values[valuesById[3] = "sms_already_sent"] = 3;
        values[valuesById[4] = "invalid_request_data"] = 4;
        values[valuesById[5] = "payment_still_processing"] = 5;
        values[valuesById[6] = "sms_pin_invalid"] = 6;
        values[valuesById[7] = "coupon_invalid"] = 7;
        values[valuesById[8] = "already_submitted"] = 8;
        return values;
    })();

    return cvv_types;
})();

export const admin_tecaji = $root.admin_tecaji = (() => {

    /**
     * Namespace admin_tecaji.
     * @exports admin_tecaji
     * @namespace
     */
    const admin_tecaji = {};

    admin_tecaji.AdminMeta = (function() {

        /**
         * Properties of an AdminMeta.
         * @memberof admin_tecaji
         * @interface IAdminMeta
         * @property {Array.<cvv_types.IChoice>|null} [tecajTipi] AdminMeta tecajTipi
         * @property {Object.<string,cvv_types.IChoices>|null} [tecajTipTecaji] AdminMeta tecajTipTecaji
         * @property {Array.<cvv_types.IChoice>|null} [locations] AdminMeta locations
         */

        /**
         * Constructs a new AdminMeta.
         * @memberof admin_tecaji
         * @classdesc Represents an AdminMeta.
         * @implements IAdminMeta
         * @constructor
         * @param {admin_tecaji.IAdminMeta=} [properties] Properties to set
         */
        function AdminMeta(properties) {
            this.tecajTipi = [];
            this.tecajTipTecaji = {};
            this.locations = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminMeta tecajTipi.
         * @member {Array.<cvv_types.IChoice>} tecajTipi
         * @memberof admin_tecaji.AdminMeta
         * @instance
         */
        AdminMeta.prototype.tecajTipi = $util.emptyArray;

        /**
         * AdminMeta tecajTipTecaji.
         * @member {Object.<string,cvv_types.IChoices>} tecajTipTecaji
         * @memberof admin_tecaji.AdminMeta
         * @instance
         */
        AdminMeta.prototype.tecajTipTecaji = $util.emptyObject;

        /**
         * AdminMeta locations.
         * @member {Array.<cvv_types.IChoice>} locations
         * @memberof admin_tecaji.AdminMeta
         * @instance
         */
        AdminMeta.prototype.locations = $util.emptyArray;

        /**
         * Creates a new AdminMeta instance using the specified properties.
         * @function create
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {admin_tecaji.IAdminMeta=} [properties] Properties to set
         * @returns {admin_tecaji.AdminMeta} AdminMeta instance
         */
        AdminMeta.create = function create(properties) {
            return new AdminMeta(properties);
        };

        /**
         * Encodes the specified AdminMeta message. Does not implicitly {@link admin_tecaji.AdminMeta.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {admin_tecaji.IAdminMeta} message AdminMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecajTipi != null && message.tecajTipi.length)
                for (let i = 0; i < message.tecajTipi.length; ++i)
                    $root.cvv_types.Choice.encode(message.tecajTipi[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tecajTipTecaji != null && Object.hasOwnProperty.call(message, "tecajTipTecaji"))
                for (let keys = Object.keys(message.tecajTipTecaji), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 2, wireType 2 =*/18).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.cvv_types.Choices.encode(message.tecajTipTecaji[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.locations != null && message.locations.length)
                for (let i = 0; i < message.locations.length; ++i)
                    $root.cvv_types.Choice.encode(message.locations[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminMeta message, length delimited. Does not implicitly {@link admin_tecaji.AdminMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {admin_tecaji.IAdminMeta} message AdminMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminMeta message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaji.AdminMeta} AdminMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaji.AdminMeta(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tecajTipi && message.tecajTipi.length))
                            message.tecajTipi = [];
                        message.tecajTipi.push($root.cvv_types.Choice.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        if (message.tecajTipTecaji === $util.emptyObject)
                            message.tecajTipTecaji = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.cvv_types.Choices.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.tecajTipTecaji[key] = value;
                        break;
                    }
                case 3: {
                        if (!(message.locations && message.locations.length))
                            message.locations = [];
                        message.locations.push($root.cvv_types.Choice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaji.AdminMeta} AdminMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminMeta message.
         * @function verify
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecajTipi != null && message.hasOwnProperty("tecajTipi")) {
                if (!Array.isArray(message.tecajTipi))
                    return "tecajTipi: array expected";
                for (let i = 0; i < message.tecajTipi.length; ++i) {
                    let error = $root.cvv_types.Choice.verify(message.tecajTipi[i]);
                    if (error)
                        return "tecajTipi." + error;
                }
            }
            if (message.tecajTipTecaji != null && message.hasOwnProperty("tecajTipTecaji")) {
                if (!$util.isObject(message.tecajTipTecaji))
                    return "tecajTipTecaji: object expected";
                let key = Object.keys(message.tecajTipTecaji);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.cvv_types.Choices.verify(message.tecajTipTecaji[key[i]]);
                    if (error)
                        return "tecajTipTecaji." + error;
                }
            }
            if (message.locations != null && message.hasOwnProperty("locations")) {
                if (!Array.isArray(message.locations))
                    return "locations: array expected";
                for (let i = 0; i < message.locations.length; ++i) {
                    let error = $root.cvv_types.Choice.verify(message.locations[i]);
                    if (error)
                        return "locations." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdminMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaji.AdminMeta} AdminMeta
         */
        AdminMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaji.AdminMeta)
                return object;
            let message = new $root.admin_tecaji.AdminMeta();
            if (object.tecajTipi) {
                if (!Array.isArray(object.tecajTipi))
                    throw TypeError(".admin_tecaji.AdminMeta.tecajTipi: array expected");
                message.tecajTipi = [];
                for (let i = 0; i < object.tecajTipi.length; ++i) {
                    if (typeof object.tecajTipi[i] !== "object")
                        throw TypeError(".admin_tecaji.AdminMeta.tecajTipi: object expected");
                    message.tecajTipi[i] = $root.cvv_types.Choice.fromObject(object.tecajTipi[i]);
                }
            }
            if (object.tecajTipTecaji) {
                if (typeof object.tecajTipTecaji !== "object")
                    throw TypeError(".admin_tecaji.AdminMeta.tecajTipTecaji: object expected");
                message.tecajTipTecaji = {};
                for (let keys = Object.keys(object.tecajTipTecaji), i = 0; i < keys.length; ++i) {
                    if (typeof object.tecajTipTecaji[keys[i]] !== "object")
                        throw TypeError(".admin_tecaji.AdminMeta.tecajTipTecaji: object expected");
                    message.tecajTipTecaji[keys[i]] = $root.cvv_types.Choices.fromObject(object.tecajTipTecaji[keys[i]]);
                }
            }
            if (object.locations) {
                if (!Array.isArray(object.locations))
                    throw TypeError(".admin_tecaji.AdminMeta.locations: array expected");
                message.locations = [];
                for (let i = 0; i < object.locations.length; ++i) {
                    if (typeof object.locations[i] !== "object")
                        throw TypeError(".admin_tecaji.AdminMeta.locations: object expected");
                    message.locations[i] = $root.cvv_types.Choice.fromObject(object.locations[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {admin_tecaji.AdminMeta} message AdminMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.tecajTipi = [];
                object.locations = [];
            }
            if (options.objects || options.defaults)
                object.tecajTipTecaji = {};
            if (message.tecajTipi && message.tecajTipi.length) {
                object.tecajTipi = [];
                for (let j = 0; j < message.tecajTipi.length; ++j)
                    object.tecajTipi[j] = $root.cvv_types.Choice.toObject(message.tecajTipi[j], options);
            }
            let keys2;
            if (message.tecajTipTecaji && (keys2 = Object.keys(message.tecajTipTecaji)).length) {
                object.tecajTipTecaji = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.tecajTipTecaji[keys2[j]] = $root.cvv_types.Choices.toObject(message.tecajTipTecaji[keys2[j]], options);
            }
            if (message.locations && message.locations.length) {
                object.locations = [];
                for (let j = 0; j < message.locations.length; ++j)
                    object.locations[j] = $root.cvv_types.Choice.toObject(message.locations[j], options);
            }
            return object;
        };

        /**
         * Converts this AdminMeta to JSON.
         * @function toJSON
         * @memberof admin_tecaji.AdminMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminMeta
         * @function getTypeUrl
         * @memberof admin_tecaji.AdminMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaji.AdminMeta";
        };

        return AdminMeta;
    })();

    admin_tecaji.AdminLoadCalendar = (function() {

        /**
         * Properties of an AdminLoadCalendar.
         * @memberof admin_tecaji
         * @interface IAdminLoadCalendar
         * @property {cvv_types.IDate|null} [from] AdminLoadCalendar from
         * @property {cvv_types.IDate|null} [to] AdminLoadCalendar to
         * @property {string|null} [tecajTipKey] AdminLoadCalendar tecajTipKey
         * @property {string|null} [tecajKey] AdminLoadCalendar tecajKey
         */

        /**
         * Constructs a new AdminLoadCalendar.
         * @memberof admin_tecaji
         * @classdesc Represents an AdminLoadCalendar.
         * @implements IAdminLoadCalendar
         * @constructor
         * @param {admin_tecaji.IAdminLoadCalendar=} [properties] Properties to set
         */
        function AdminLoadCalendar(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminLoadCalendar from.
         * @member {cvv_types.IDate|null|undefined} from
         * @memberof admin_tecaji.AdminLoadCalendar
         * @instance
         */
        AdminLoadCalendar.prototype.from = null;

        /**
         * AdminLoadCalendar to.
         * @member {cvv_types.IDate|null|undefined} to
         * @memberof admin_tecaji.AdminLoadCalendar
         * @instance
         */
        AdminLoadCalendar.prototype.to = null;

        /**
         * AdminLoadCalendar tecajTipKey.
         * @member {string} tecajTipKey
         * @memberof admin_tecaji.AdminLoadCalendar
         * @instance
         */
        AdminLoadCalendar.prototype.tecajTipKey = "";

        /**
         * AdminLoadCalendar tecajKey.
         * @member {string} tecajKey
         * @memberof admin_tecaji.AdminLoadCalendar
         * @instance
         */
        AdminLoadCalendar.prototype.tecajKey = "";

        /**
         * Creates a new AdminLoadCalendar instance using the specified properties.
         * @function create
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {admin_tecaji.IAdminLoadCalendar=} [properties] Properties to set
         * @returns {admin_tecaji.AdminLoadCalendar} AdminLoadCalendar instance
         */
        AdminLoadCalendar.create = function create(properties) {
            return new AdminLoadCalendar(properties);
        };

        /**
         * Encodes the specified AdminLoadCalendar message. Does not implicitly {@link admin_tecaji.AdminLoadCalendar.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {admin_tecaji.IAdminLoadCalendar} message AdminLoadCalendar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadCalendar.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                $root.cvv_types.Date.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                $root.cvv_types.Date.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tecajTipKey != null && Object.hasOwnProperty.call(message, "tecajTipKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tecajTipKey);
            if (message.tecajKey != null && Object.hasOwnProperty.call(message, "tecajKey"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.tecajKey);
            return writer;
        };

        /**
         * Encodes the specified AdminLoadCalendar message, length delimited. Does not implicitly {@link admin_tecaji.AdminLoadCalendar.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {admin_tecaji.IAdminLoadCalendar} message AdminLoadCalendar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadCalendar.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminLoadCalendar message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaji.AdminLoadCalendar} AdminLoadCalendar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadCalendar.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaji.AdminLoadCalendar();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.from = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.to = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.tecajTipKey = reader.string();
                        break;
                    }
                case 4: {
                        message.tecajKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminLoadCalendar message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaji.AdminLoadCalendar} AdminLoadCalendar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadCalendar.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminLoadCalendar message.
         * @function verify
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminLoadCalendar.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.from != null && message.hasOwnProperty("from")) {
                let error = $root.cvv_types.Date.verify(message.from);
                if (error)
                    return "from." + error;
            }
            if (message.to != null && message.hasOwnProperty("to")) {
                let error = $root.cvv_types.Date.verify(message.to);
                if (error)
                    return "to." + error;
            }
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                if (!$util.isString(message.tecajTipKey))
                    return "tecajTipKey: string expected";
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                if (!$util.isString(message.tecajKey))
                    return "tecajKey: string expected";
            return null;
        };

        /**
         * Creates an AdminLoadCalendar message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaji.AdminLoadCalendar} AdminLoadCalendar
         */
        AdminLoadCalendar.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaji.AdminLoadCalendar)
                return object;
            let message = new $root.admin_tecaji.AdminLoadCalendar();
            if (object.from != null) {
                if (typeof object.from !== "object")
                    throw TypeError(".admin_tecaji.AdminLoadCalendar.from: object expected");
                message.from = $root.cvv_types.Date.fromObject(object.from);
            }
            if (object.to != null) {
                if (typeof object.to !== "object")
                    throw TypeError(".admin_tecaji.AdminLoadCalendar.to: object expected");
                message.to = $root.cvv_types.Date.fromObject(object.to);
            }
            if (object.tecajTipKey != null)
                message.tecajTipKey = String(object.tecajTipKey);
            if (object.tecajKey != null)
                message.tecajKey = String(object.tecajKey);
            return message;
        };

        /**
         * Creates a plain object from an AdminLoadCalendar message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {admin_tecaji.AdminLoadCalendar} message AdminLoadCalendar
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminLoadCalendar.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.from = null;
                object.to = null;
                object.tecajTipKey = "";
                object.tecajKey = "";
            }
            if (message.from != null && message.hasOwnProperty("from"))
                object.from = $root.cvv_types.Date.toObject(message.from, options);
            if (message.to != null && message.hasOwnProperty("to"))
                object.to = $root.cvv_types.Date.toObject(message.to, options);
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                object.tecajTipKey = message.tecajTipKey;
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                object.tecajKey = message.tecajKey;
            return object;
        };

        /**
         * Converts this AdminLoadCalendar to JSON.
         * @function toJSON
         * @memberof admin_tecaji.AdminLoadCalendar
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminLoadCalendar.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminLoadCalendar
         * @function getTypeUrl
         * @memberof admin_tecaji.AdminLoadCalendar
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminLoadCalendar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaji.AdminLoadCalendar";
        };

        return AdminLoadCalendar;
    })();

    admin_tecaji.AdminCalendarData = (function() {

        /**
         * Properties of an AdminCalendarData.
         * @memberof admin_tecaji
         * @interface IAdminCalendarData
         * @property {string|null} [tecajTipKey] AdminCalendarData tecajTipKey
         * @property {string|null} [tecajKey] AdminCalendarData tecajKey
         * @property {Object.<string,admin_tecaji.IAdminCalendarDayData>|null} [days] AdminCalendarData days
         * @property {Array.<string>|null} [tipi] AdminCalendarData tipi
         */

        /**
         * Constructs a new AdminCalendarData.
         * @memberof admin_tecaji
         * @classdesc Represents an AdminCalendarData.
         * @implements IAdminCalendarData
         * @constructor
         * @param {admin_tecaji.IAdminCalendarData=} [properties] Properties to set
         */
        function AdminCalendarData(properties) {
            this.days = {};
            this.tipi = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminCalendarData tecajTipKey.
         * @member {string} tecajTipKey
         * @memberof admin_tecaji.AdminCalendarData
         * @instance
         */
        AdminCalendarData.prototype.tecajTipKey = "";

        /**
         * AdminCalendarData tecajKey.
         * @member {string} tecajKey
         * @memberof admin_tecaji.AdminCalendarData
         * @instance
         */
        AdminCalendarData.prototype.tecajKey = "";

        /**
         * AdminCalendarData days.
         * @member {Object.<string,admin_tecaji.IAdminCalendarDayData>} days
         * @memberof admin_tecaji.AdminCalendarData
         * @instance
         */
        AdminCalendarData.prototype.days = $util.emptyObject;

        /**
         * AdminCalendarData tipi.
         * @member {Array.<string>} tipi
         * @memberof admin_tecaji.AdminCalendarData
         * @instance
         */
        AdminCalendarData.prototype.tipi = $util.emptyArray;

        /**
         * Creates a new AdminCalendarData instance using the specified properties.
         * @function create
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {admin_tecaji.IAdminCalendarData=} [properties] Properties to set
         * @returns {admin_tecaji.AdminCalendarData} AdminCalendarData instance
         */
        AdminCalendarData.create = function create(properties) {
            return new AdminCalendarData(properties);
        };

        /**
         * Encodes the specified AdminCalendarData message. Does not implicitly {@link admin_tecaji.AdminCalendarData.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {admin_tecaji.IAdminCalendarData} message AdminCalendarData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCalendarData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecajTipKey != null && Object.hasOwnProperty.call(message, "tecajTipKey"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tecajTipKey);
            if (message.tecajKey != null && Object.hasOwnProperty.call(message, "tecajKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecajKey);
            if (message.days != null && Object.hasOwnProperty.call(message, "days"))
                for (let keys = Object.keys(message.days), i = 0; i < keys.length; ++i) {
                    writer.uint32(/* id 3, wireType 2 =*/26).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                    $root.admin_tecaji.AdminCalendarDayData.encode(message.days[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                }
            if (message.tipi != null && message.tipi.length)
                for (let i = 0; i < message.tipi.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.tipi[i]);
            return writer;
        };

        /**
         * Encodes the specified AdminCalendarData message, length delimited. Does not implicitly {@link admin_tecaji.AdminCalendarData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {admin_tecaji.IAdminCalendarData} message AdminCalendarData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCalendarData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminCalendarData message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaji.AdminCalendarData} AdminCalendarData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCalendarData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaji.AdminCalendarData(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tecajTipKey = reader.string();
                        break;
                    }
                case 2: {
                        message.tecajKey = reader.string();
                        break;
                    }
                case 3: {
                        if (message.days === $util.emptyObject)
                            message.days = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.admin_tecaji.AdminCalendarDayData.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.days[key] = value;
                        break;
                    }
                case 4: {
                        if (!(message.tipi && message.tipi.length))
                            message.tipi = [];
                        message.tipi.push(reader.string());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminCalendarData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaji.AdminCalendarData} AdminCalendarData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCalendarData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminCalendarData message.
         * @function verify
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminCalendarData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                if (!$util.isString(message.tecajTipKey))
                    return "tecajTipKey: string expected";
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                if (!$util.isString(message.tecajKey))
                    return "tecajKey: string expected";
            if (message.days != null && message.hasOwnProperty("days")) {
                if (!$util.isObject(message.days))
                    return "days: object expected";
                let key = Object.keys(message.days);
                for (let i = 0; i < key.length; ++i) {
                    let error = $root.admin_tecaji.AdminCalendarDayData.verify(message.days[key[i]]);
                    if (error)
                        return "days." + error;
                }
            }
            if (message.tipi != null && message.hasOwnProperty("tipi")) {
                if (!Array.isArray(message.tipi))
                    return "tipi: array expected";
                for (let i = 0; i < message.tipi.length; ++i)
                    if (!$util.isString(message.tipi[i]))
                        return "tipi: string[] expected";
            }
            return null;
        };

        /**
         * Creates an AdminCalendarData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaji.AdminCalendarData} AdminCalendarData
         */
        AdminCalendarData.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaji.AdminCalendarData)
                return object;
            let message = new $root.admin_tecaji.AdminCalendarData();
            if (object.tecajTipKey != null)
                message.tecajTipKey = String(object.tecajTipKey);
            if (object.tecajKey != null)
                message.tecajKey = String(object.tecajKey);
            if (object.days) {
                if (typeof object.days !== "object")
                    throw TypeError(".admin_tecaji.AdminCalendarData.days: object expected");
                message.days = {};
                for (let keys = Object.keys(object.days), i = 0; i < keys.length; ++i) {
                    if (typeof object.days[keys[i]] !== "object")
                        throw TypeError(".admin_tecaji.AdminCalendarData.days: object expected");
                    message.days[keys[i]] = $root.admin_tecaji.AdminCalendarDayData.fromObject(object.days[keys[i]]);
                }
            }
            if (object.tipi) {
                if (!Array.isArray(object.tipi))
                    throw TypeError(".admin_tecaji.AdminCalendarData.tipi: array expected");
                message.tipi = [];
                for (let i = 0; i < object.tipi.length; ++i)
                    message.tipi[i] = String(object.tipi[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminCalendarData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {admin_tecaji.AdminCalendarData} message AdminCalendarData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminCalendarData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tipi = [];
            if (options.objects || options.defaults)
                object.days = {};
            if (options.defaults) {
                object.tecajTipKey = "";
                object.tecajKey = "";
            }
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                object.tecajTipKey = message.tecajTipKey;
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                object.tecajKey = message.tecajKey;
            let keys2;
            if (message.days && (keys2 = Object.keys(message.days)).length) {
                object.days = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.days[keys2[j]] = $root.admin_tecaji.AdminCalendarDayData.toObject(message.days[keys2[j]], options);
            }
            if (message.tipi && message.tipi.length) {
                object.tipi = [];
                for (let j = 0; j < message.tipi.length; ++j)
                    object.tipi[j] = message.tipi[j];
            }
            return object;
        };

        /**
         * Converts this AdminCalendarData to JSON.
         * @function toJSON
         * @memberof admin_tecaji.AdminCalendarData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminCalendarData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminCalendarData
         * @function getTypeUrl
         * @memberof admin_tecaji.AdminCalendarData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminCalendarData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaji.AdminCalendarData";
        };

        return AdminCalendarData;
    })();

    admin_tecaji.AdminCalendarDayData = (function() {

        /**
         * Properties of an AdminCalendarDayData.
         * @memberof admin_tecaji
         * @interface IAdminCalendarDayData
         * @property {Array.<admin_tecaji.IAdminTecajDaySummary>|null} [tecaji] AdminCalendarDayData tecaji
         */

        /**
         * Constructs a new AdminCalendarDayData.
         * @memberof admin_tecaji
         * @classdesc Represents an AdminCalendarDayData.
         * @implements IAdminCalendarDayData
         * @constructor
         * @param {admin_tecaji.IAdminCalendarDayData=} [properties] Properties to set
         */
        function AdminCalendarDayData(properties) {
            this.tecaji = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminCalendarDayData tecaji.
         * @member {Array.<admin_tecaji.IAdminTecajDaySummary>} tecaji
         * @memberof admin_tecaji.AdminCalendarDayData
         * @instance
         */
        AdminCalendarDayData.prototype.tecaji = $util.emptyArray;

        /**
         * Creates a new AdminCalendarDayData instance using the specified properties.
         * @function create
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {admin_tecaji.IAdminCalendarDayData=} [properties] Properties to set
         * @returns {admin_tecaji.AdminCalendarDayData} AdminCalendarDayData instance
         */
        AdminCalendarDayData.create = function create(properties) {
            return new AdminCalendarDayData(properties);
        };

        /**
         * Encodes the specified AdminCalendarDayData message. Does not implicitly {@link admin_tecaji.AdminCalendarDayData.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {admin_tecaji.IAdminCalendarDayData} message AdminCalendarDayData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCalendarDayData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecaji != null && message.tecaji.length)
                for (let i = 0; i < message.tecaji.length; ++i)
                    $root.admin_tecaji.AdminTecajDaySummary.encode(message.tecaji[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminCalendarDayData message, length delimited. Does not implicitly {@link admin_tecaji.AdminCalendarDayData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {admin_tecaji.IAdminCalendarDayData} message AdminCalendarDayData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCalendarDayData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminCalendarDayData message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaji.AdminCalendarDayData} AdminCalendarDayData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCalendarDayData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaji.AdminCalendarDayData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.tecaji && message.tecaji.length))
                            message.tecaji = [];
                        message.tecaji.push($root.admin_tecaji.AdminTecajDaySummary.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminCalendarDayData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaji.AdminCalendarDayData} AdminCalendarDayData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCalendarDayData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminCalendarDayData message.
         * @function verify
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminCalendarDayData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecaji != null && message.hasOwnProperty("tecaji")) {
                if (!Array.isArray(message.tecaji))
                    return "tecaji: array expected";
                for (let i = 0; i < message.tecaji.length; ++i) {
                    let error = $root.admin_tecaji.AdminTecajDaySummary.verify(message.tecaji[i]);
                    if (error)
                        return "tecaji." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdminCalendarDayData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaji.AdminCalendarDayData} AdminCalendarDayData
         */
        AdminCalendarDayData.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaji.AdminCalendarDayData)
                return object;
            let message = new $root.admin_tecaji.AdminCalendarDayData();
            if (object.tecaji) {
                if (!Array.isArray(object.tecaji))
                    throw TypeError(".admin_tecaji.AdminCalendarDayData.tecaji: array expected");
                message.tecaji = [];
                for (let i = 0; i < object.tecaji.length; ++i) {
                    if (typeof object.tecaji[i] !== "object")
                        throw TypeError(".admin_tecaji.AdminCalendarDayData.tecaji: object expected");
                    message.tecaji[i] = $root.admin_tecaji.AdminTecajDaySummary.fromObject(object.tecaji[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminCalendarDayData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {admin_tecaji.AdminCalendarDayData} message AdminCalendarDayData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminCalendarDayData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tecaji = [];
            if (message.tecaji && message.tecaji.length) {
                object.tecaji = [];
                for (let j = 0; j < message.tecaji.length; ++j)
                    object.tecaji[j] = $root.admin_tecaji.AdminTecajDaySummary.toObject(message.tecaji[j], options);
            }
            return object;
        };

        /**
         * Converts this AdminCalendarDayData to JSON.
         * @function toJSON
         * @memberof admin_tecaji.AdminCalendarDayData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminCalendarDayData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminCalendarDayData
         * @function getTypeUrl
         * @memberof admin_tecaji.AdminCalendarDayData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminCalendarDayData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaji.AdminCalendarDayData";
        };

        return AdminCalendarDayData;
    })();

    admin_tecaji.AdminTecajDaySummary = (function() {

        /**
         * Properties of an AdminTecajDaySummary.
         * @memberof admin_tecaji
         * @interface IAdminTecajDaySummary
         * @property {string|null} [id] AdminTecajDaySummary id
         * @property {string|null} [razpisaniTecajId] AdminTecajDaySummary razpisaniTecajId
         * @property {string|null} [tipName] AdminTecajDaySummary tipName
         * @property {string|null} [tecajName] AdminTecajDaySummary tecajName
         * @property {number|null} [dayN] AdminTecajDaySummary dayN
         * @property {cvv_types.IDate|null} [start] AdminTecajDaySummary start
         * @property {cvv_types.IDate|null} [end] AdminTecajDaySummary end
         * @property {number|null} [placesTotal] AdminTecajDaySummary placesTotal
         * @property {number|null} [placesFree] AdminTecajDaySummary placesFree
         * @property {number|null} [placesTaken] AdminTecajDaySummary placesTaken
         * @property {string|null} [locationPrefix] AdminTecajDaySummary locationPrefix
         */

        /**
         * Constructs a new AdminTecajDaySummary.
         * @memberof admin_tecaji
         * @classdesc Represents an AdminTecajDaySummary.
         * @implements IAdminTecajDaySummary
         * @constructor
         * @param {admin_tecaji.IAdminTecajDaySummary=} [properties] Properties to set
         */
        function AdminTecajDaySummary(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminTecajDaySummary id.
         * @member {string} id
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.id = "";

        /**
         * AdminTecajDaySummary razpisaniTecajId.
         * @member {string} razpisaniTecajId
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.razpisaniTecajId = "";

        /**
         * AdminTecajDaySummary tipName.
         * @member {string} tipName
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.tipName = "";

        /**
         * AdminTecajDaySummary tecajName.
         * @member {string} tecajName
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.tecajName = "";

        /**
         * AdminTecajDaySummary dayN.
         * @member {number} dayN
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.dayN = 0;

        /**
         * AdminTecajDaySummary start.
         * @member {cvv_types.IDate|null|undefined} start
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.start = null;

        /**
         * AdminTecajDaySummary end.
         * @member {cvv_types.IDate|null|undefined} end
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.end = null;

        /**
         * AdminTecajDaySummary placesTotal.
         * @member {number} placesTotal
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.placesTotal = 0;

        /**
         * AdminTecajDaySummary placesFree.
         * @member {number} placesFree
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.placesFree = 0;

        /**
         * AdminTecajDaySummary placesTaken.
         * @member {number} placesTaken
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.placesTaken = 0;

        /**
         * AdminTecajDaySummary locationPrefix.
         * @member {string} locationPrefix
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         */
        AdminTecajDaySummary.prototype.locationPrefix = "";

        /**
         * Creates a new AdminTecajDaySummary instance using the specified properties.
         * @function create
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {admin_tecaji.IAdminTecajDaySummary=} [properties] Properties to set
         * @returns {admin_tecaji.AdminTecajDaySummary} AdminTecajDaySummary instance
         */
        AdminTecajDaySummary.create = function create(properties) {
            return new AdminTecajDaySummary(properties);
        };

        /**
         * Encodes the specified AdminTecajDaySummary message. Does not implicitly {@link admin_tecaji.AdminTecajDaySummary.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {admin_tecaji.IAdminTecajDaySummary} message AdminTecajDaySummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminTecajDaySummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tipName != null && Object.hasOwnProperty.call(message, "tipName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tipName);
            if (message.tecajName != null && Object.hasOwnProperty.call(message, "tecajName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tecajName);
            if (message.dayN != null && Object.hasOwnProperty.call(message, "dayN"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.dayN);
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.cvv_types.Date.encode(message.start, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.cvv_types.Date.encode(message.end, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.placesTotal != null && Object.hasOwnProperty.call(message, "placesTotal"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.placesTotal);
            if (message.placesFree != null && Object.hasOwnProperty.call(message, "placesFree"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.placesFree);
            if (message.placesTaken != null && Object.hasOwnProperty.call(message, "placesTaken"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.placesTaken);
            if (message.razpisaniTecajId != null && Object.hasOwnProperty.call(message, "razpisaniTecajId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.razpisaniTecajId);
            if (message.locationPrefix != null && Object.hasOwnProperty.call(message, "locationPrefix"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.locationPrefix);
            return writer;
        };

        /**
         * Encodes the specified AdminTecajDaySummary message, length delimited. Does not implicitly {@link admin_tecaji.AdminTecajDaySummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {admin_tecaji.IAdminTecajDaySummary} message AdminTecajDaySummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminTecajDaySummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminTecajDaySummary message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaji.AdminTecajDaySummary} AdminTecajDaySummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminTecajDaySummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaji.AdminTecajDaySummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 10: {
                        message.razpisaniTecajId = reader.string();
                        break;
                    }
                case 2: {
                        message.tipName = reader.string();
                        break;
                    }
                case 3: {
                        message.tecajName = reader.string();
                        break;
                    }
                case 4: {
                        message.dayN = reader.int32();
                        break;
                    }
                case 5: {
                        message.start = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.end = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.placesTotal = reader.int32();
                        break;
                    }
                case 8: {
                        message.placesFree = reader.int32();
                        break;
                    }
                case 9: {
                        message.placesTaken = reader.int32();
                        break;
                    }
                case 11: {
                        message.locationPrefix = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminTecajDaySummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaji.AdminTecajDaySummary} AdminTecajDaySummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminTecajDaySummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminTecajDaySummary message.
         * @function verify
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminTecajDaySummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.razpisaniTecajId != null && message.hasOwnProperty("razpisaniTecajId"))
                if (!$util.isString(message.razpisaniTecajId))
                    return "razpisaniTecajId: string expected";
            if (message.tipName != null && message.hasOwnProperty("tipName"))
                if (!$util.isString(message.tipName))
                    return "tipName: string expected";
            if (message.tecajName != null && message.hasOwnProperty("tecajName"))
                if (!$util.isString(message.tecajName))
                    return "tecajName: string expected";
            if (message.dayN != null && message.hasOwnProperty("dayN"))
                if (!$util.isInteger(message.dayN))
                    return "dayN: integer expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.cvv_types.Date.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.cvv_types.Date.verify(message.end);
                if (error)
                    return "end." + error;
            }
            if (message.placesTotal != null && message.hasOwnProperty("placesTotal"))
                if (!$util.isInteger(message.placesTotal))
                    return "placesTotal: integer expected";
            if (message.placesFree != null && message.hasOwnProperty("placesFree"))
                if (!$util.isInteger(message.placesFree))
                    return "placesFree: integer expected";
            if (message.placesTaken != null && message.hasOwnProperty("placesTaken"))
                if (!$util.isInteger(message.placesTaken))
                    return "placesTaken: integer expected";
            if (message.locationPrefix != null && message.hasOwnProperty("locationPrefix"))
                if (!$util.isString(message.locationPrefix))
                    return "locationPrefix: string expected";
            return null;
        };

        /**
         * Creates an AdminTecajDaySummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaji.AdminTecajDaySummary} AdminTecajDaySummary
         */
        AdminTecajDaySummary.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaji.AdminTecajDaySummary)
                return object;
            let message = new $root.admin_tecaji.AdminTecajDaySummary();
            if (object.id != null)
                message.id = String(object.id);
            if (object.razpisaniTecajId != null)
                message.razpisaniTecajId = String(object.razpisaniTecajId);
            if (object.tipName != null)
                message.tipName = String(object.tipName);
            if (object.tecajName != null)
                message.tecajName = String(object.tecajName);
            if (object.dayN != null)
                message.dayN = object.dayN | 0;
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".admin_tecaji.AdminTecajDaySummary.start: object expected");
                message.start = $root.cvv_types.Date.fromObject(object.start);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".admin_tecaji.AdminTecajDaySummary.end: object expected");
                message.end = $root.cvv_types.Date.fromObject(object.end);
            }
            if (object.placesTotal != null)
                message.placesTotal = object.placesTotal | 0;
            if (object.placesFree != null)
                message.placesFree = object.placesFree | 0;
            if (object.placesTaken != null)
                message.placesTaken = object.placesTaken | 0;
            if (object.locationPrefix != null)
                message.locationPrefix = String(object.locationPrefix);
            return message;
        };

        /**
         * Creates a plain object from an AdminTecajDaySummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {admin_tecaji.AdminTecajDaySummary} message AdminTecajDaySummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminTecajDaySummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.tipName = "";
                object.tecajName = "";
                object.dayN = 0;
                object.start = null;
                object.end = null;
                object.placesTotal = 0;
                object.placesFree = 0;
                object.placesTaken = 0;
                object.razpisaniTecajId = "";
                object.locationPrefix = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.tipName != null && message.hasOwnProperty("tipName"))
                object.tipName = message.tipName;
            if (message.tecajName != null && message.hasOwnProperty("tecajName"))
                object.tecajName = message.tecajName;
            if (message.dayN != null && message.hasOwnProperty("dayN"))
                object.dayN = message.dayN;
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.cvv_types.Date.toObject(message.start, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.cvv_types.Date.toObject(message.end, options);
            if (message.placesTotal != null && message.hasOwnProperty("placesTotal"))
                object.placesTotal = message.placesTotal;
            if (message.placesFree != null && message.hasOwnProperty("placesFree"))
                object.placesFree = message.placesFree;
            if (message.placesTaken != null && message.hasOwnProperty("placesTaken"))
                object.placesTaken = message.placesTaken;
            if (message.razpisaniTecajId != null && message.hasOwnProperty("razpisaniTecajId"))
                object.razpisaniTecajId = message.razpisaniTecajId;
            if (message.locationPrefix != null && message.hasOwnProperty("locationPrefix"))
                object.locationPrefix = message.locationPrefix;
            return object;
        };

        /**
         * Converts this AdminTecajDaySummary to JSON.
         * @function toJSON
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminTecajDaySummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminTecajDaySummary
         * @function getTypeUrl
         * @memberof admin_tecaji.AdminTecajDaySummary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminTecajDaySummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaji.AdminTecajDaySummary";
        };

        return AdminTecajDaySummary;
    })();

    return admin_tecaji;
})();

export const form = $root.form = (() => {

    /**
     * Namespace form.
     * @exports form
     * @namespace
     */
    const form = {};

    form.LoadForm = (function() {

        /**
         * Properties of a LoadForm.
         * @memberof form
         * @interface ILoadForm
         * @property {string|null} [tipTecaja] LoadForm tipTecaja
         * @property {string|null} [tecaj] LoadForm tecaj
         */

        /**
         * Constructs a new LoadForm.
         * @memberof form
         * @classdesc Represents a LoadForm.
         * @implements ILoadForm
         * @constructor
         * @param {form.ILoadForm=} [properties] Properties to set
         */
        function LoadForm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadForm tipTecaja.
         * @member {string} tipTecaja
         * @memberof form.LoadForm
         * @instance
         */
        LoadForm.prototype.tipTecaja = "";

        /**
         * LoadForm tecaj.
         * @member {string} tecaj
         * @memberof form.LoadForm
         * @instance
         */
        LoadForm.prototype.tecaj = "";

        /**
         * Creates a new LoadForm instance using the specified properties.
         * @function create
         * @memberof form.LoadForm
         * @static
         * @param {form.ILoadForm=} [properties] Properties to set
         * @returns {form.LoadForm} LoadForm instance
         */
        LoadForm.create = function create(properties) {
            return new LoadForm(properties);
        };

        /**
         * Encodes the specified LoadForm message. Does not implicitly {@link form.LoadForm.verify|verify} messages.
         * @function encode
         * @memberof form.LoadForm
         * @static
         * @param {form.ILoadForm} message LoadForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tipTecaja != null && Object.hasOwnProperty.call(message, "tipTecaja"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tipTecaja);
            if (message.tecaj != null && Object.hasOwnProperty.call(message, "tecaj"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecaj);
            return writer;
        };

        /**
         * Encodes the specified LoadForm message, length delimited. Does not implicitly {@link form.LoadForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.LoadForm
         * @static
         * @param {form.ILoadForm} message LoadForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadForm message from the specified reader or buffer.
         * @function decode
         * @memberof form.LoadForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.LoadForm} LoadForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.LoadForm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tipTecaja = reader.string();
                        break;
                    }
                case 2: {
                        message.tecaj = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.LoadForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.LoadForm} LoadForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadForm message.
         * @function verify
         * @memberof form.LoadForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tipTecaja != null && message.hasOwnProperty("tipTecaja"))
                if (!$util.isString(message.tipTecaja))
                    return "tipTecaja: string expected";
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                if (!$util.isString(message.tecaj))
                    return "tecaj: string expected";
            return null;
        };

        /**
         * Creates a LoadForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.LoadForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.LoadForm} LoadForm
         */
        LoadForm.fromObject = function fromObject(object) {
            if (object instanceof $root.form.LoadForm)
                return object;
            let message = new $root.form.LoadForm();
            if (object.tipTecaja != null)
                message.tipTecaja = String(object.tipTecaja);
            if (object.tecaj != null)
                message.tecaj = String(object.tecaj);
            return message;
        };

        /**
         * Creates a plain object from a LoadForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.LoadForm
         * @static
         * @param {form.LoadForm} message LoadForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.tipTecaja = "";
                object.tecaj = "";
            }
            if (message.tipTecaja != null && message.hasOwnProperty("tipTecaja"))
                object.tipTecaja = message.tipTecaja;
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                object.tecaj = message.tecaj;
            return object;
        };

        /**
         * Converts this LoadForm to JSON.
         * @function toJSON
         * @memberof form.LoadForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadForm
         * @function getTypeUrl
         * @memberof form.LoadForm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadForm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.LoadForm";
        };

        return LoadForm;
    })();

    form.ResumeForm = (function() {

        /**
         * Properties of a ResumeForm.
         * @memberof form
         * @interface IResumeForm
         * @property {string|null} [sessionId] ResumeForm sessionId
         */

        /**
         * Constructs a new ResumeForm.
         * @memberof form
         * @classdesc Represents a ResumeForm.
         * @implements IResumeForm
         * @constructor
         * @param {form.IResumeForm=} [properties] Properties to set
         */
        function ResumeForm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResumeForm sessionId.
         * @member {string} sessionId
         * @memberof form.ResumeForm
         * @instance
         */
        ResumeForm.prototype.sessionId = "";

        /**
         * Creates a new ResumeForm instance using the specified properties.
         * @function create
         * @memberof form.ResumeForm
         * @static
         * @param {form.IResumeForm=} [properties] Properties to set
         * @returns {form.ResumeForm} ResumeForm instance
         */
        ResumeForm.create = function create(properties) {
            return new ResumeForm(properties);
        };

        /**
         * Encodes the specified ResumeForm message. Does not implicitly {@link form.ResumeForm.verify|verify} messages.
         * @function encode
         * @memberof form.ResumeForm
         * @static
         * @param {form.IResumeForm} message ResumeForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified ResumeForm message, length delimited. Does not implicitly {@link form.ResumeForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.ResumeForm
         * @static
         * @param {form.IResumeForm} message ResumeForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResumeForm message from the specified reader or buffer.
         * @function decode
         * @memberof form.ResumeForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.ResumeForm} ResumeForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.ResumeForm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResumeForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.ResumeForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.ResumeForm} ResumeForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResumeForm message.
         * @function verify
         * @memberof form.ResumeForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResumeForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates a ResumeForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.ResumeForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.ResumeForm} ResumeForm
         */
        ResumeForm.fromObject = function fromObject(object) {
            if (object instanceof $root.form.ResumeForm)
                return object;
            let message = new $root.form.ResumeForm();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from a ResumeForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.ResumeForm
         * @static
         * @param {form.ResumeForm} message ResumeForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResumeForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this ResumeForm to JSON.
         * @function toJSON
         * @memberof form.ResumeForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResumeForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResumeForm
         * @function getTypeUrl
         * @memberof form.ResumeForm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResumeForm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.ResumeForm";
        };

        return ResumeForm;
    })();

    form.LoadFormSummary = (function() {

        /**
         * Properties of a LoadFormSummary.
         * @memberof form
         * @interface ILoadFormSummary
         * @property {string|null} [sessionId] LoadFormSummary sessionId
         */

        /**
         * Constructs a new LoadFormSummary.
         * @memberof form
         * @classdesc Represents a LoadFormSummary.
         * @implements ILoadFormSummary
         * @constructor
         * @param {form.ILoadFormSummary=} [properties] Properties to set
         */
        function LoadFormSummary(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadFormSummary sessionId.
         * @member {string} sessionId
         * @memberof form.LoadFormSummary
         * @instance
         */
        LoadFormSummary.prototype.sessionId = "";

        /**
         * Creates a new LoadFormSummary instance using the specified properties.
         * @function create
         * @memberof form.LoadFormSummary
         * @static
         * @param {form.ILoadFormSummary=} [properties] Properties to set
         * @returns {form.LoadFormSummary} LoadFormSummary instance
         */
        LoadFormSummary.create = function create(properties) {
            return new LoadFormSummary(properties);
        };

        /**
         * Encodes the specified LoadFormSummary message. Does not implicitly {@link form.LoadFormSummary.verify|verify} messages.
         * @function encode
         * @memberof form.LoadFormSummary
         * @static
         * @param {form.ILoadFormSummary} message LoadFormSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadFormSummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified LoadFormSummary message, length delimited. Does not implicitly {@link form.LoadFormSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.LoadFormSummary
         * @static
         * @param {form.ILoadFormSummary} message LoadFormSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadFormSummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadFormSummary message from the specified reader or buffer.
         * @function decode
         * @memberof form.LoadFormSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.LoadFormSummary} LoadFormSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadFormSummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.LoadFormSummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadFormSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.LoadFormSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.LoadFormSummary} LoadFormSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadFormSummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadFormSummary message.
         * @function verify
         * @memberof form.LoadFormSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadFormSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates a LoadFormSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.LoadFormSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.LoadFormSummary} LoadFormSummary
         */
        LoadFormSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.form.LoadFormSummary)
                return object;
            let message = new $root.form.LoadFormSummary();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from a LoadFormSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.LoadFormSummary
         * @static
         * @param {form.LoadFormSummary} message LoadFormSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadFormSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this LoadFormSummary to JSON.
         * @function toJSON
         * @memberof form.LoadFormSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadFormSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadFormSummary
         * @function getTypeUrl
         * @memberof form.LoadFormSummary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadFormSummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.LoadFormSummary";
        };

        return LoadFormSummary;
    })();

    form.FormSummary = (function() {

        /**
         * Properties of a FormSummary.
         * @memberof form
         * @interface IFormSummary
         * @property {string|null} [sessionId] FormSummary sessionId
         * @property {string|null} [nameSurname] FormSummary nameSurname
         * @property {Array.<form.ILessonData>|null} [lessons] FormSummary lessons
         * @property {string|null} [tecajTip] FormSummary tecajTip
         * @property {string|null} [tecaj] FormSummary tecaj
         * @property {billing.IPricelist|null} [pricelist] FormSummary pricelist
         * @property {billing.PaymentType|null} [paymentType] FormSummary paymentType
         * @property {boolean|null} [participationConfirmed] FormSummary participationConfirmed
         */

        /**
         * Constructs a new FormSummary.
         * @memberof form
         * @classdesc Represents a FormSummary.
         * @implements IFormSummary
         * @constructor
         * @param {form.IFormSummary=} [properties] Properties to set
         */
        function FormSummary(properties) {
            this.lessons = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FormSummary sessionId.
         * @member {string} sessionId
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.sessionId = "";

        /**
         * FormSummary nameSurname.
         * @member {string} nameSurname
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.nameSurname = "";

        /**
         * FormSummary lessons.
         * @member {Array.<form.ILessonData>} lessons
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.lessons = $util.emptyArray;

        /**
         * FormSummary tecajTip.
         * @member {string} tecajTip
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.tecajTip = "";

        /**
         * FormSummary tecaj.
         * @member {string} tecaj
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.tecaj = "";

        /**
         * FormSummary pricelist.
         * @member {billing.IPricelist|null|undefined} pricelist
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.pricelist = null;

        /**
         * FormSummary paymentType.
         * @member {billing.PaymentType} paymentType
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.paymentType = 0;

        /**
         * FormSummary participationConfirmed.
         * @member {boolean} participationConfirmed
         * @memberof form.FormSummary
         * @instance
         */
        FormSummary.prototype.participationConfirmed = false;

        /**
         * Creates a new FormSummary instance using the specified properties.
         * @function create
         * @memberof form.FormSummary
         * @static
         * @param {form.IFormSummary=} [properties] Properties to set
         * @returns {form.FormSummary} FormSummary instance
         */
        FormSummary.create = function create(properties) {
            return new FormSummary(properties);
        };

        /**
         * Encodes the specified FormSummary message. Does not implicitly {@link form.FormSummary.verify|verify} messages.
         * @function encode
         * @memberof form.FormSummary
         * @static
         * @param {form.IFormSummary} message FormSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormSummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nameSurname);
            if (message.lessons != null && message.lessons.length)
                for (let i = 0; i < message.lessons.length; ++i)
                    $root.form.LessonData.encode(message.lessons[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tecajTip != null && Object.hasOwnProperty.call(message, "tecajTip"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tecajTip);
            if (message.tecaj != null && Object.hasOwnProperty.call(message, "tecaj"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.tecaj);
            if (message.participationConfirmed != null && Object.hasOwnProperty.call(message, "participationConfirmed"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.participationConfirmed);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.sessionId);
            if (message.pricelist != null && Object.hasOwnProperty.call(message, "pricelist"))
                $root.billing.Pricelist.encode(message.pricelist, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.paymentType);
            return writer;
        };

        /**
         * Encodes the specified FormSummary message, length delimited. Does not implicitly {@link form.FormSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.FormSummary
         * @static
         * @param {form.IFormSummary} message FormSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormSummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FormSummary message from the specified reader or buffer.
         * @function decode
         * @memberof form.FormSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.FormSummary} FormSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormSummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.FormSummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 6: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 1: {
                        message.nameSurname = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.lessons && message.lessons.length))
                            message.lessons = [];
                        message.lessons.push($root.form.LessonData.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.tecajTip = reader.string();
                        break;
                    }
                case 4: {
                        message.tecaj = reader.string();
                        break;
                    }
                case 7: {
                        message.pricelist = $root.billing.Pricelist.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.paymentType = reader.int32();
                        break;
                    }
                case 5: {
                        message.participationConfirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FormSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.FormSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.FormSummary} FormSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormSummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FormSummary message.
         * @function verify
         * @memberof form.FormSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FormSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            if (message.lessons != null && message.hasOwnProperty("lessons")) {
                if (!Array.isArray(message.lessons))
                    return "lessons: array expected";
                for (let i = 0; i < message.lessons.length; ++i) {
                    let error = $root.form.LessonData.verify(message.lessons[i]);
                    if (error)
                        return "lessons." + error;
                }
            }
            if (message.tecajTip != null && message.hasOwnProperty("tecajTip"))
                if (!$util.isString(message.tecajTip))
                    return "tecajTip: string expected";
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                if (!$util.isString(message.tecaj))
                    return "tecaj: string expected";
            if (message.pricelist != null && message.hasOwnProperty("pricelist")) {
                let error = $root.billing.Pricelist.verify(message.pricelist);
                if (error)
                    return "pricelist." + error;
            }
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                switch (message.paymentType) {
                default:
                    return "paymentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.participationConfirmed != null && message.hasOwnProperty("participationConfirmed"))
                if (typeof message.participationConfirmed !== "boolean")
                    return "participationConfirmed: boolean expected";
            return null;
        };

        /**
         * Creates a FormSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.FormSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.FormSummary} FormSummary
         */
        FormSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.form.FormSummary)
                return object;
            let message = new $root.form.FormSummary();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            if (object.lessons) {
                if (!Array.isArray(object.lessons))
                    throw TypeError(".form.FormSummary.lessons: array expected");
                message.lessons = [];
                for (let i = 0; i < object.lessons.length; ++i) {
                    if (typeof object.lessons[i] !== "object")
                        throw TypeError(".form.FormSummary.lessons: object expected");
                    message.lessons[i] = $root.form.LessonData.fromObject(object.lessons[i]);
                }
            }
            if (object.tecajTip != null)
                message.tecajTip = String(object.tecajTip);
            if (object.tecaj != null)
                message.tecaj = String(object.tecaj);
            if (object.pricelist != null) {
                if (typeof object.pricelist !== "object")
                    throw TypeError(".form.FormSummary.pricelist: object expected");
                message.pricelist = $root.billing.Pricelist.fromObject(object.pricelist);
            }
            switch (object.paymentType) {
            default:
                if (typeof object.paymentType === "number") {
                    message.paymentType = object.paymentType;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.paymentType = 0;
                break;
            case "darilni_bon":
            case 1:
                message.paymentType = 1;
                break;
            case "gotovina":
            case 2:
                message.paymentType = 2;
                break;
            case "predracun":
            case 3:
                message.paymentType = 3;
                break;
            }
            if (object.participationConfirmed != null)
                message.participationConfirmed = Boolean(object.participationConfirmed);
            return message;
        };

        /**
         * Creates a plain object from a FormSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.FormSummary
         * @static
         * @param {form.FormSummary} message FormSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FormSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.lessons = [];
            if (options.defaults) {
                object.nameSurname = "";
                object.tecajTip = "";
                object.tecaj = "";
                object.participationConfirmed = false;
                object.sessionId = "";
                object.pricelist = null;
                object.paymentType = options.enums === String ? "kreditna" : 0;
            }
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.lessons && message.lessons.length) {
                object.lessons = [];
                for (let j = 0; j < message.lessons.length; ++j)
                    object.lessons[j] = $root.form.LessonData.toObject(message.lessons[j], options);
            }
            if (message.tecajTip != null && message.hasOwnProperty("tecajTip"))
                object.tecajTip = message.tecajTip;
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                object.tecaj = message.tecaj;
            if (message.participationConfirmed != null && message.hasOwnProperty("participationConfirmed"))
                object.participationConfirmed = message.participationConfirmed;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.pricelist != null && message.hasOwnProperty("pricelist"))
                object.pricelist = $root.billing.Pricelist.toObject(message.pricelist, options);
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = options.enums === String ? $root.billing.PaymentType[message.paymentType] === undefined ? message.paymentType : $root.billing.PaymentType[message.paymentType] : message.paymentType;
            return object;
        };

        /**
         * Converts this FormSummary to JSON.
         * @function toJSON
         * @memberof form.FormSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FormSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FormSummary
         * @function getTypeUrl
         * @memberof form.FormSummary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FormSummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.FormSummary";
        };

        return FormSummary;
    })();

    form.ResumeFormData = (function() {

        /**
         * Properties of a ResumeFormData.
         * @memberof form
         * @interface IResumeFormData
         * @property {string|null} [json] ResumeFormData json
         */

        /**
         * Constructs a new ResumeFormData.
         * @memberof form
         * @classdesc Represents a ResumeFormData.
         * @implements IResumeFormData
         * @constructor
         * @param {form.IResumeFormData=} [properties] Properties to set
         */
        function ResumeFormData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ResumeFormData json.
         * @member {string} json
         * @memberof form.ResumeFormData
         * @instance
         */
        ResumeFormData.prototype.json = "";

        /**
         * Creates a new ResumeFormData instance using the specified properties.
         * @function create
         * @memberof form.ResumeFormData
         * @static
         * @param {form.IResumeFormData=} [properties] Properties to set
         * @returns {form.ResumeFormData} ResumeFormData instance
         */
        ResumeFormData.create = function create(properties) {
            return new ResumeFormData(properties);
        };

        /**
         * Encodes the specified ResumeFormData message. Does not implicitly {@link form.ResumeFormData.verify|verify} messages.
         * @function encode
         * @memberof form.ResumeFormData
         * @static
         * @param {form.IResumeFormData} message ResumeFormData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeFormData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.json != null && Object.hasOwnProperty.call(message, "json"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.json);
            return writer;
        };

        /**
         * Encodes the specified ResumeFormData message, length delimited. Does not implicitly {@link form.ResumeFormData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.ResumeFormData
         * @static
         * @param {form.IResumeFormData} message ResumeFormData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResumeFormData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResumeFormData message from the specified reader or buffer.
         * @function decode
         * @memberof form.ResumeFormData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.ResumeFormData} ResumeFormData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeFormData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.ResumeFormData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.json = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResumeFormData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.ResumeFormData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.ResumeFormData} ResumeFormData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResumeFormData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResumeFormData message.
         * @function verify
         * @memberof form.ResumeFormData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResumeFormData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.json != null && message.hasOwnProperty("json"))
                if (!$util.isString(message.json))
                    return "json: string expected";
            return null;
        };

        /**
         * Creates a ResumeFormData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.ResumeFormData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.ResumeFormData} ResumeFormData
         */
        ResumeFormData.fromObject = function fromObject(object) {
            if (object instanceof $root.form.ResumeFormData)
                return object;
            let message = new $root.form.ResumeFormData();
            if (object.json != null)
                message.json = String(object.json);
            return message;
        };

        /**
         * Creates a plain object from a ResumeFormData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.ResumeFormData
         * @static
         * @param {form.ResumeFormData} message ResumeFormData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResumeFormData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.json = "";
            if (message.json != null && message.hasOwnProperty("json"))
                object.json = message.json;
            return object;
        };

        /**
         * Converts this ResumeFormData to JSON.
         * @function toJSON
         * @memberof form.ResumeFormData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResumeFormData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ResumeFormData
         * @function getTypeUrl
         * @memberof form.ResumeFormData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ResumeFormData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.ResumeFormData";
        };

        return ResumeFormData;
    })();

    form.FormMeta = (function() {

        /**
         * Properties of a FormMeta.
         * @memberof form
         * @interface IFormMeta
         * @property {form.ITecaj|null} [tecaj] FormMeta tecaj
         * @property {billing.IPricelist|null} [pricelist] FormMeta pricelist
         * @property {Array.<billing.PaymentType>|null} [paymentTypes] FormMeta paymentTypes
         * @property {string|null} [gdpr] FormMeta gdpr
         */

        /**
         * Constructs a new FormMeta.
         * @memberof form
         * @classdesc Represents a FormMeta.
         * @implements IFormMeta
         * @constructor
         * @param {form.IFormMeta=} [properties] Properties to set
         */
        function FormMeta(properties) {
            this.paymentTypes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FormMeta tecaj.
         * @member {form.ITecaj|null|undefined} tecaj
         * @memberof form.FormMeta
         * @instance
         */
        FormMeta.prototype.tecaj = null;

        /**
         * FormMeta pricelist.
         * @member {billing.IPricelist|null|undefined} pricelist
         * @memberof form.FormMeta
         * @instance
         */
        FormMeta.prototype.pricelist = null;

        /**
         * FormMeta paymentTypes.
         * @member {Array.<billing.PaymentType>} paymentTypes
         * @memberof form.FormMeta
         * @instance
         */
        FormMeta.prototype.paymentTypes = $util.emptyArray;

        /**
         * FormMeta gdpr.
         * @member {string} gdpr
         * @memberof form.FormMeta
         * @instance
         */
        FormMeta.prototype.gdpr = "";

        /**
         * Creates a new FormMeta instance using the specified properties.
         * @function create
         * @memberof form.FormMeta
         * @static
         * @param {form.IFormMeta=} [properties] Properties to set
         * @returns {form.FormMeta} FormMeta instance
         */
        FormMeta.create = function create(properties) {
            return new FormMeta(properties);
        };

        /**
         * Encodes the specified FormMeta message. Does not implicitly {@link form.FormMeta.verify|verify} messages.
         * @function encode
         * @memberof form.FormMeta
         * @static
         * @param {form.IFormMeta} message FormMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecaj != null && Object.hasOwnProperty.call(message, "tecaj"))
                $root.form.Tecaj.encode(message.tecaj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.pricelist != null && Object.hasOwnProperty.call(message, "pricelist"))
                $root.billing.Pricelist.encode(message.pricelist, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.paymentTypes != null && message.paymentTypes.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (let i = 0; i < message.paymentTypes.length; ++i)
                    writer.int32(message.paymentTypes[i]);
                writer.ldelim();
            }
            if (message.gdpr != null && Object.hasOwnProperty.call(message, "gdpr"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.gdpr);
            return writer;
        };

        /**
         * Encodes the specified FormMeta message, length delimited. Does not implicitly {@link form.FormMeta.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.FormMeta
         * @static
         * @param {form.IFormMeta} message FormMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormMeta.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FormMeta message from the specified reader or buffer.
         * @function decode
         * @memberof form.FormMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.FormMeta} FormMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.FormMeta();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tecaj = $root.form.Tecaj.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.pricelist = $root.billing.Pricelist.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        if (!(message.paymentTypes && message.paymentTypes.length))
                            message.paymentTypes = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.paymentTypes.push(reader.int32());
                        } else
                            message.paymentTypes.push(reader.int32());
                        break;
                    }
                case 4: {
                        message.gdpr = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FormMeta message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.FormMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.FormMeta} FormMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormMeta.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FormMeta message.
         * @function verify
         * @memberof form.FormMeta
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FormMeta.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecaj != null && message.hasOwnProperty("tecaj")) {
                let error = $root.form.Tecaj.verify(message.tecaj);
                if (error)
                    return "tecaj." + error;
            }
            if (message.pricelist != null && message.hasOwnProperty("pricelist")) {
                let error = $root.billing.Pricelist.verify(message.pricelist);
                if (error)
                    return "pricelist." + error;
            }
            if (message.paymentTypes != null && message.hasOwnProperty("paymentTypes")) {
                if (!Array.isArray(message.paymentTypes))
                    return "paymentTypes: array expected";
                for (let i = 0; i < message.paymentTypes.length; ++i)
                    switch (message.paymentTypes[i]) {
                    default:
                        return "paymentTypes: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            if (message.gdpr != null && message.hasOwnProperty("gdpr"))
                if (!$util.isString(message.gdpr))
                    return "gdpr: string expected";
            return null;
        };

        /**
         * Creates a FormMeta message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.FormMeta
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.FormMeta} FormMeta
         */
        FormMeta.fromObject = function fromObject(object) {
            if (object instanceof $root.form.FormMeta)
                return object;
            let message = new $root.form.FormMeta();
            if (object.tecaj != null) {
                if (typeof object.tecaj !== "object")
                    throw TypeError(".form.FormMeta.tecaj: object expected");
                message.tecaj = $root.form.Tecaj.fromObject(object.tecaj);
            }
            if (object.pricelist != null) {
                if (typeof object.pricelist !== "object")
                    throw TypeError(".form.FormMeta.pricelist: object expected");
                message.pricelist = $root.billing.Pricelist.fromObject(object.pricelist);
            }
            if (object.paymentTypes) {
                if (!Array.isArray(object.paymentTypes))
                    throw TypeError(".form.FormMeta.paymentTypes: array expected");
                message.paymentTypes = [];
                for (let i = 0; i < object.paymentTypes.length; ++i)
                    switch (object.paymentTypes[i]) {
                    default:
                        if (typeof object.paymentTypes[i] === "number") {
                            message.paymentTypes[i] = object.paymentTypes[i];
                            break;
                        }
                    case "kreditna":
                    case 0:
                        message.paymentTypes[i] = 0;
                        break;
                    case "darilni_bon":
                    case 1:
                        message.paymentTypes[i] = 1;
                        break;
                    case "gotovina":
                    case 2:
                        message.paymentTypes[i] = 2;
                        break;
                    case "predracun":
                    case 3:
                        message.paymentTypes[i] = 3;
                        break;
                    }
            }
            if (object.gdpr != null)
                message.gdpr = String(object.gdpr);
            return message;
        };

        /**
         * Creates a plain object from a FormMeta message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.FormMeta
         * @static
         * @param {form.FormMeta} message FormMeta
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FormMeta.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.paymentTypes = [];
            if (options.defaults) {
                object.tecaj = null;
                object.pricelist = null;
                object.gdpr = "";
            }
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                object.tecaj = $root.form.Tecaj.toObject(message.tecaj, options);
            if (message.pricelist != null && message.hasOwnProperty("pricelist"))
                object.pricelist = $root.billing.Pricelist.toObject(message.pricelist, options);
            if (message.paymentTypes && message.paymentTypes.length) {
                object.paymentTypes = [];
                for (let j = 0; j < message.paymentTypes.length; ++j)
                    object.paymentTypes[j] = options.enums === String ? $root.billing.PaymentType[message.paymentTypes[j]] === undefined ? message.paymentTypes[j] : $root.billing.PaymentType[message.paymentTypes[j]] : message.paymentTypes[j];
            }
            if (message.gdpr != null && message.hasOwnProperty("gdpr"))
                object.gdpr = message.gdpr;
            return object;
        };

        /**
         * Converts this FormMeta to JSON.
         * @function toJSON
         * @memberof form.FormMeta
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FormMeta.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FormMeta
         * @function getTypeUrl
         * @memberof form.FormMeta
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FormMeta.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.FormMeta";
        };

        return FormMeta;
    })();

    form.OrderSummary = (function() {

        /**
         * Properties of an OrderSummary.
         * @memberof form
         * @interface IOrderSummary
         * @property {billing.IPricelist|null} [pricelist] OrderSummary pricelist
         * @property {Array.<form.IFormError>|null} [errors] OrderSummary errors
         * @property {billing.PaymentType|null} [paymentType] OrderSummary paymentType
         */

        /**
         * Constructs a new OrderSummary.
         * @memberof form
         * @classdesc Represents an OrderSummary.
         * @implements IOrderSummary
         * @constructor
         * @param {form.IOrderSummary=} [properties] Properties to set
         */
        function OrderSummary(properties) {
            this.errors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderSummary pricelist.
         * @member {billing.IPricelist|null|undefined} pricelist
         * @memberof form.OrderSummary
         * @instance
         */
        OrderSummary.prototype.pricelist = null;

        /**
         * OrderSummary errors.
         * @member {Array.<form.IFormError>} errors
         * @memberof form.OrderSummary
         * @instance
         */
        OrderSummary.prototype.errors = $util.emptyArray;

        /**
         * OrderSummary paymentType.
         * @member {billing.PaymentType} paymentType
         * @memberof form.OrderSummary
         * @instance
         */
        OrderSummary.prototype.paymentType = 0;

        /**
         * Creates a new OrderSummary instance using the specified properties.
         * @function create
         * @memberof form.OrderSummary
         * @static
         * @param {form.IOrderSummary=} [properties] Properties to set
         * @returns {form.OrderSummary} OrderSummary instance
         */
        OrderSummary.create = function create(properties) {
            return new OrderSummary(properties);
        };

        /**
         * Encodes the specified OrderSummary message. Does not implicitly {@link form.OrderSummary.verify|verify} messages.
         * @function encode
         * @memberof form.OrderSummary
         * @static
         * @param {form.IOrderSummary} message OrderSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderSummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pricelist != null && Object.hasOwnProperty.call(message, "pricelist"))
                $root.billing.Pricelist.encode(message.pricelist, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.errors != null && message.errors.length)
                for (let i = 0; i < message.errors.length; ++i)
                    $root.form.FormError.encode(message.errors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.paymentType);
            return writer;
        };

        /**
         * Encodes the specified OrderSummary message, length delimited. Does not implicitly {@link form.OrderSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.OrderSummary
         * @static
         * @param {form.IOrderSummary} message OrderSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderSummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OrderSummary message from the specified reader or buffer.
         * @function decode
         * @memberof form.OrderSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.OrderSummary} OrderSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderSummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.OrderSummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.pricelist = $root.billing.Pricelist.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.errors && message.errors.length))
                            message.errors = [];
                        message.errors.push($root.form.FormError.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.paymentType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OrderSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.OrderSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.OrderSummary} OrderSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderSummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OrderSummary message.
         * @function verify
         * @memberof form.OrderSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OrderSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pricelist != null && message.hasOwnProperty("pricelist")) {
                let error = $root.billing.Pricelist.verify(message.pricelist);
                if (error)
                    return "pricelist." + error;
            }
            if (message.errors != null && message.hasOwnProperty("errors")) {
                if (!Array.isArray(message.errors))
                    return "errors: array expected";
                for (let i = 0; i < message.errors.length; ++i) {
                    let error = $root.form.FormError.verify(message.errors[i]);
                    if (error)
                        return "errors." + error;
                }
            }
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                switch (message.paymentType) {
                default:
                    return "paymentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates an OrderSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.OrderSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.OrderSummary} OrderSummary
         */
        OrderSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.form.OrderSummary)
                return object;
            let message = new $root.form.OrderSummary();
            if (object.pricelist != null) {
                if (typeof object.pricelist !== "object")
                    throw TypeError(".form.OrderSummary.pricelist: object expected");
                message.pricelist = $root.billing.Pricelist.fromObject(object.pricelist);
            }
            if (object.errors) {
                if (!Array.isArray(object.errors))
                    throw TypeError(".form.OrderSummary.errors: array expected");
                message.errors = [];
                for (let i = 0; i < object.errors.length; ++i) {
                    if (typeof object.errors[i] !== "object")
                        throw TypeError(".form.OrderSummary.errors: object expected");
                    message.errors[i] = $root.form.FormError.fromObject(object.errors[i]);
                }
            }
            switch (object.paymentType) {
            default:
                if (typeof object.paymentType === "number") {
                    message.paymentType = object.paymentType;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.paymentType = 0;
                break;
            case "darilni_bon":
            case 1:
                message.paymentType = 1;
                break;
            case "gotovina":
            case 2:
                message.paymentType = 2;
                break;
            case "predracun":
            case 3:
                message.paymentType = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from an OrderSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.OrderSummary
         * @static
         * @param {form.OrderSummary} message OrderSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OrderSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.errors = [];
            if (options.defaults) {
                object.pricelist = null;
                object.paymentType = options.enums === String ? "kreditna" : 0;
            }
            if (message.pricelist != null && message.hasOwnProperty("pricelist"))
                object.pricelist = $root.billing.Pricelist.toObject(message.pricelist, options);
            if (message.errors && message.errors.length) {
                object.errors = [];
                for (let j = 0; j < message.errors.length; ++j)
                    object.errors[j] = $root.form.FormError.toObject(message.errors[j], options);
            }
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = options.enums === String ? $root.billing.PaymentType[message.paymentType] === undefined ? message.paymentType : $root.billing.PaymentType[message.paymentType] : message.paymentType;
            return object;
        };

        /**
         * Converts this OrderSummary to JSON.
         * @function toJSON
         * @memberof form.OrderSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OrderSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for OrderSummary
         * @function getTypeUrl
         * @memberof form.OrderSummary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        OrderSummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.OrderSummary";
        };

        return OrderSummary;
    })();

    form.FormError = (function() {

        /**
         * Properties of a FormError.
         * @memberof form
         * @interface IFormError
         * @property {string|null} [field] FormError field
         * @property {string|null} [description] FormError description
         */

        /**
         * Constructs a new FormError.
         * @memberof form
         * @classdesc Represents a FormError.
         * @implements IFormError
         * @constructor
         * @param {form.IFormError=} [properties] Properties to set
         */
        function FormError(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FormError field.
         * @member {string} field
         * @memberof form.FormError
         * @instance
         */
        FormError.prototype.field = "";

        /**
         * FormError description.
         * @member {string} description
         * @memberof form.FormError
         * @instance
         */
        FormError.prototype.description = "";

        /**
         * Creates a new FormError instance using the specified properties.
         * @function create
         * @memberof form.FormError
         * @static
         * @param {form.IFormError=} [properties] Properties to set
         * @returns {form.FormError} FormError instance
         */
        FormError.create = function create(properties) {
            return new FormError(properties);
        };

        /**
         * Encodes the specified FormError message. Does not implicitly {@link form.FormError.verify|verify} messages.
         * @function encode
         * @memberof form.FormError
         * @static
         * @param {form.IFormError} message FormError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified FormError message, length delimited. Does not implicitly {@link form.FormError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.FormError
         * @static
         * @param {form.IFormError} message FormError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FormError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FormError message from the specified reader or buffer.
         * @function decode
         * @memberof form.FormError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.FormError} FormError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.FormError();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.field = reader.string();
                        break;
                    }
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FormError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.FormError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.FormError} FormError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FormError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FormError message.
         * @function verify
         * @memberof form.FormError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FormError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.field != null && message.hasOwnProperty("field"))
                if (!$util.isString(message.field))
                    return "field: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a FormError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.FormError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.FormError} FormError
         */
        FormError.fromObject = function fromObject(object) {
            if (object instanceof $root.form.FormError)
                return object;
            let message = new $root.form.FormError();
            if (object.field != null)
                message.field = String(object.field);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a FormError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.FormError
         * @static
         * @param {form.FormError} message FormError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FormError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.field = "";
                object.description = "";
            }
            if (message.field != null && message.hasOwnProperty("field"))
                object.field = message.field;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this FormError to JSON.
         * @function toJSON
         * @memberof form.FormError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FormError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for FormError
         * @function getTypeUrl
         * @memberof form.FormError
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        FormError.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.FormError";
        };

        return FormError;
    })();

    form.Tecaj = (function() {

        /**
         * Properties of a Tecaj.
         * @memberof form
         * @interface ITecaj
         * @property {string|null} [id] Tecaj id
         * @property {string|null} [tipName] Tecaj tipName
         * @property {string|null} [tecajName] Tecaj tecajName
         * @property {cvv_types.FormType|null} [formType] Tecaj formType
         */

        /**
         * Constructs a new Tecaj.
         * @memberof form
         * @classdesc Represents a Tecaj.
         * @implements ITecaj
         * @constructor
         * @param {form.ITecaj=} [properties] Properties to set
         */
        function Tecaj(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Tecaj id.
         * @member {string} id
         * @memberof form.Tecaj
         * @instance
         */
        Tecaj.prototype.id = "";

        /**
         * Tecaj tipName.
         * @member {string} tipName
         * @memberof form.Tecaj
         * @instance
         */
        Tecaj.prototype.tipName = "";

        /**
         * Tecaj tecajName.
         * @member {string} tecajName
         * @memberof form.Tecaj
         * @instance
         */
        Tecaj.prototype.tecajName = "";

        /**
         * Tecaj formType.
         * @member {cvv_types.FormType} formType
         * @memberof form.Tecaj
         * @instance
         */
        Tecaj.prototype.formType = 0;

        /**
         * Creates a new Tecaj instance using the specified properties.
         * @function create
         * @memberof form.Tecaj
         * @static
         * @param {form.ITecaj=} [properties] Properties to set
         * @returns {form.Tecaj} Tecaj instance
         */
        Tecaj.create = function create(properties) {
            return new Tecaj(properties);
        };

        /**
         * Encodes the specified Tecaj message. Does not implicitly {@link form.Tecaj.verify|verify} messages.
         * @function encode
         * @memberof form.Tecaj
         * @static
         * @param {form.ITecaj} message Tecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tecaj.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tipName != null && Object.hasOwnProperty.call(message, "tipName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tipName);
            if (message.tecajName != null && Object.hasOwnProperty.call(message, "tecajName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tecajName);
            if (message.formType != null && Object.hasOwnProperty.call(message, "formType"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.formType);
            return writer;
        };

        /**
         * Encodes the specified Tecaj message, length delimited. Does not implicitly {@link form.Tecaj.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.Tecaj
         * @static
         * @param {form.ITecaj} message Tecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Tecaj.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Tecaj message from the specified reader or buffer.
         * @function decode
         * @memberof form.Tecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.Tecaj} Tecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tecaj.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.Tecaj();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.tipName = reader.string();
                        break;
                    }
                case 3: {
                        message.tecajName = reader.string();
                        break;
                    }
                case 4: {
                        message.formType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Tecaj message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.Tecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.Tecaj} Tecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Tecaj.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Tecaj message.
         * @function verify
         * @memberof form.Tecaj
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Tecaj.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.tipName != null && message.hasOwnProperty("tipName"))
                if (!$util.isString(message.tipName))
                    return "tipName: string expected";
            if (message.tecajName != null && message.hasOwnProperty("tecajName"))
                if (!$util.isString(message.tecajName))
                    return "tecajName: string expected";
            if (message.formType != null && message.hasOwnProperty("formType"))
                switch (message.formType) {
                default:
                    return "formType: enum value expected";
                case 0:
                    break;
                }
            return null;
        };

        /**
         * Creates a Tecaj message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.Tecaj
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.Tecaj} Tecaj
         */
        Tecaj.fromObject = function fromObject(object) {
            if (object instanceof $root.form.Tecaj)
                return object;
            let message = new $root.form.Tecaj();
            if (object.id != null)
                message.id = String(object.id);
            if (object.tipName != null)
                message.tipName = String(object.tipName);
            if (object.tecajName != null)
                message.tecajName = String(object.tecajName);
            switch (object.formType) {
            default:
                if (typeof object.formType === "number") {
                    message.formType = object.formType;
                    break;
                }
                break;
            case "mladi_voznik":
            case 0:
                message.formType = 0;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a Tecaj message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.Tecaj
         * @static
         * @param {form.Tecaj} message Tecaj
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Tecaj.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.tipName = "";
                object.tecajName = "";
                object.formType = options.enums === String ? "mladi_voznik" : 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.tipName != null && message.hasOwnProperty("tipName"))
                object.tipName = message.tipName;
            if (message.tecajName != null && message.hasOwnProperty("tecajName"))
                object.tecajName = message.tecajName;
            if (message.formType != null && message.hasOwnProperty("formType"))
                object.formType = options.enums === String ? $root.cvv_types.FormType[message.formType] === undefined ? message.formType : $root.cvv_types.FormType[message.formType] : message.formType;
            return object;
        };

        /**
         * Converts this Tecaj to JSON.
         * @function toJSON
         * @memberof form.Tecaj
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Tecaj.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Tecaj
         * @function getTypeUrl
         * @memberof form.Tecaj
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Tecaj.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.Tecaj";
        };

        return Tecaj;
    })();

    form.SubmitForm = (function() {

        /**
         * Properties of a SubmitForm.
         * @memberof form
         * @interface ISubmitForm
         * @property {string|null} [validationCode] SubmitForm validationCode
         * @property {billing.PaymentType|null} [paymentType] SubmitForm paymentType
         * @property {string|null} [stDarilnegaBona] SubmitForm stDarilnegaBona
         */

        /**
         * Constructs a new SubmitForm.
         * @memberof form
         * @classdesc Represents a SubmitForm.
         * @implements ISubmitForm
         * @constructor
         * @param {form.ISubmitForm=} [properties] Properties to set
         */
        function SubmitForm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SubmitForm validationCode.
         * @member {string} validationCode
         * @memberof form.SubmitForm
         * @instance
         */
        SubmitForm.prototype.validationCode = "";

        /**
         * SubmitForm paymentType.
         * @member {billing.PaymentType} paymentType
         * @memberof form.SubmitForm
         * @instance
         */
        SubmitForm.prototype.paymentType = 0;

        /**
         * SubmitForm stDarilnegaBona.
         * @member {string} stDarilnegaBona
         * @memberof form.SubmitForm
         * @instance
         */
        SubmitForm.prototype.stDarilnegaBona = "";

        /**
         * Creates a new SubmitForm instance using the specified properties.
         * @function create
         * @memberof form.SubmitForm
         * @static
         * @param {form.ISubmitForm=} [properties] Properties to set
         * @returns {form.SubmitForm} SubmitForm instance
         */
        SubmitForm.create = function create(properties) {
            return new SubmitForm(properties);
        };

        /**
         * Encodes the specified SubmitForm message. Does not implicitly {@link form.SubmitForm.verify|verify} messages.
         * @function encode
         * @memberof form.SubmitForm
         * @static
         * @param {form.ISubmitForm} message SubmitForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.validationCode != null && Object.hasOwnProperty.call(message, "validationCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.validationCode);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.paymentType);
            if (message.stDarilnegaBona != null && Object.hasOwnProperty.call(message, "stDarilnegaBona"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.stDarilnegaBona);
            return writer;
        };

        /**
         * Encodes the specified SubmitForm message, length delimited. Does not implicitly {@link form.SubmitForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.SubmitForm
         * @static
         * @param {form.ISubmitForm} message SubmitForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SubmitForm message from the specified reader or buffer.
         * @function decode
         * @memberof form.SubmitForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.SubmitForm} SubmitForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.SubmitForm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.validationCode = reader.string();
                        break;
                    }
                case 2: {
                        message.paymentType = reader.int32();
                        break;
                    }
                case 3: {
                        message.stDarilnegaBona = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SubmitForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.SubmitForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.SubmitForm} SubmitForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SubmitForm message.
         * @function verify
         * @memberof form.SubmitForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SubmitForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                if (!$util.isString(message.validationCode))
                    return "validationCode: string expected";
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                switch (message.paymentType) {
                default:
                    return "paymentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.stDarilnegaBona != null && message.hasOwnProperty("stDarilnegaBona"))
                if (!$util.isString(message.stDarilnegaBona))
                    return "stDarilnegaBona: string expected";
            return null;
        };

        /**
         * Creates a SubmitForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.SubmitForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.SubmitForm} SubmitForm
         */
        SubmitForm.fromObject = function fromObject(object) {
            if (object instanceof $root.form.SubmitForm)
                return object;
            let message = new $root.form.SubmitForm();
            if (object.validationCode != null)
                message.validationCode = String(object.validationCode);
            switch (object.paymentType) {
            default:
                if (typeof object.paymentType === "number") {
                    message.paymentType = object.paymentType;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.paymentType = 0;
                break;
            case "darilni_bon":
            case 1:
                message.paymentType = 1;
                break;
            case "gotovina":
            case 2:
                message.paymentType = 2;
                break;
            case "predracun":
            case 3:
                message.paymentType = 3;
                break;
            }
            if (object.stDarilnegaBona != null)
                message.stDarilnegaBona = String(object.stDarilnegaBona);
            return message;
        };

        /**
         * Creates a plain object from a SubmitForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.SubmitForm
         * @static
         * @param {form.SubmitForm} message SubmitForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SubmitForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.validationCode = "";
                object.paymentType = options.enums === String ? "kreditna" : 0;
                object.stDarilnegaBona = "";
            }
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                object.validationCode = message.validationCode;
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = options.enums === String ? $root.billing.PaymentType[message.paymentType] === undefined ? message.paymentType : $root.billing.PaymentType[message.paymentType] : message.paymentType;
            if (message.stDarilnegaBona != null && message.hasOwnProperty("stDarilnegaBona"))
                object.stDarilnegaBona = message.stDarilnegaBona;
            return object;
        };

        /**
         * Converts this SubmitForm to JSON.
         * @function toJSON
         * @memberof form.SubmitForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SubmitForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SubmitForm
         * @function getTypeUrl
         * @memberof form.SubmitForm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SubmitForm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.SubmitForm";
        };

        return SubmitForm;
    })();

    form.LessonData = (function() {

        /**
         * Properties of a LessonData.
         * @memberof form
         * @interface ILessonData
         * @property {cvv_types.IDate|null} [start] LessonData start
         * @property {cvv_types.IDate|null} [end] LessonData end
         * @property {string|null} [locationName] LessonData locationName
         */

        /**
         * Constructs a new LessonData.
         * @memberof form
         * @classdesc Represents a LessonData.
         * @implements ILessonData
         * @constructor
         * @param {form.ILessonData=} [properties] Properties to set
         */
        function LessonData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LessonData start.
         * @member {cvv_types.IDate|null|undefined} start
         * @memberof form.LessonData
         * @instance
         */
        LessonData.prototype.start = null;

        /**
         * LessonData end.
         * @member {cvv_types.IDate|null|undefined} end
         * @memberof form.LessonData
         * @instance
         */
        LessonData.prototype.end = null;

        /**
         * LessonData locationName.
         * @member {string} locationName
         * @memberof form.LessonData
         * @instance
         */
        LessonData.prototype.locationName = "";

        /**
         * Creates a new LessonData instance using the specified properties.
         * @function create
         * @memberof form.LessonData
         * @static
         * @param {form.ILessonData=} [properties] Properties to set
         * @returns {form.LessonData} LessonData instance
         */
        LessonData.create = function create(properties) {
            return new LessonData(properties);
        };

        /**
         * Encodes the specified LessonData message. Does not implicitly {@link form.LessonData.verify|verify} messages.
         * @function encode
         * @memberof form.LessonData
         * @static
         * @param {form.ILessonData} message LessonData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LessonData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.cvv_types.Date.encode(message.start, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.cvv_types.Date.encode(message.end, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.locationName);
            return writer;
        };

        /**
         * Encodes the specified LessonData message, length delimited. Does not implicitly {@link form.LessonData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof form.LessonData
         * @static
         * @param {form.ILessonData} message LessonData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LessonData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LessonData message from the specified reader or buffer.
         * @function decode
         * @memberof form.LessonData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {form.LessonData} LessonData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LessonData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.form.LessonData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.start = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.end = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.locationName = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LessonData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof form.LessonData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {form.LessonData} LessonData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LessonData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LessonData message.
         * @function verify
         * @memberof form.LessonData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LessonData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.cvv_types.Date.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.cvv_types.Date.verify(message.end);
                if (error)
                    return "end." + error;
            }
            if (message.locationName != null && message.hasOwnProperty("locationName"))
                if (!$util.isString(message.locationName))
                    return "locationName: string expected";
            return null;
        };

        /**
         * Creates a LessonData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof form.LessonData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {form.LessonData} LessonData
         */
        LessonData.fromObject = function fromObject(object) {
            if (object instanceof $root.form.LessonData)
                return object;
            let message = new $root.form.LessonData();
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".form.LessonData.start: object expected");
                message.start = $root.cvv_types.Date.fromObject(object.start);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".form.LessonData.end: object expected");
                message.end = $root.cvv_types.Date.fromObject(object.end);
            }
            if (object.locationName != null)
                message.locationName = String(object.locationName);
            return message;
        };

        /**
         * Creates a plain object from a LessonData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof form.LessonData
         * @static
         * @param {form.LessonData} message LessonData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LessonData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.start = null;
                object.end = null;
                object.locationName = "";
            }
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.cvv_types.Date.toObject(message.start, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.cvv_types.Date.toObject(message.end, options);
            if (message.locationName != null && message.hasOwnProperty("locationName"))
                object.locationName = message.locationName;
            return object;
        };

        /**
         * Converts this LessonData to JSON.
         * @function toJSON
         * @memberof form.LessonData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LessonData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LessonData
         * @function getTypeUrl
         * @memberof form.LessonData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LessonData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/form.LessonData";
        };

        return LessonData;
    })();

    return form;
})();

export const calendar = $root.calendar = (() => {

    /**
     * Namespace calendar.
     * @exports calendar
     * @namespace
     */
    const calendar = {};

    calendar.LoadCalendar = (function() {

        /**
         * Properties of a LoadCalendar.
         * @memberof calendar
         * @interface ILoadCalendar
         * @property {cvv_types.IDate|null} [month] LoadCalendar month
         * @property {string|null} [tecajId] LoadCalendar tecajId
         */

        /**
         * Constructs a new LoadCalendar.
         * @memberof calendar
         * @classdesc Represents a LoadCalendar.
         * @implements ILoadCalendar
         * @constructor
         * @param {calendar.ILoadCalendar=} [properties] Properties to set
         */
        function LoadCalendar(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadCalendar month.
         * @member {cvv_types.IDate|null|undefined} month
         * @memberof calendar.LoadCalendar
         * @instance
         */
        LoadCalendar.prototype.month = null;

        /**
         * LoadCalendar tecajId.
         * @member {string} tecajId
         * @memberof calendar.LoadCalendar
         * @instance
         */
        LoadCalendar.prototype.tecajId = "";

        /**
         * Creates a new LoadCalendar instance using the specified properties.
         * @function create
         * @memberof calendar.LoadCalendar
         * @static
         * @param {calendar.ILoadCalendar=} [properties] Properties to set
         * @returns {calendar.LoadCalendar} LoadCalendar instance
         */
        LoadCalendar.create = function create(properties) {
            return new LoadCalendar(properties);
        };

        /**
         * Encodes the specified LoadCalendar message. Does not implicitly {@link calendar.LoadCalendar.verify|verify} messages.
         * @function encode
         * @memberof calendar.LoadCalendar
         * @static
         * @param {calendar.ILoadCalendar} message LoadCalendar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadCalendar.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                $root.cvv_types.Date.encode(message.month, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tecajId != null && Object.hasOwnProperty.call(message, "tecajId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecajId);
            return writer;
        };

        /**
         * Encodes the specified LoadCalendar message, length delimited. Does not implicitly {@link calendar.LoadCalendar.verify|verify} messages.
         * @function encodeDelimited
         * @memberof calendar.LoadCalendar
         * @static
         * @param {calendar.ILoadCalendar} message LoadCalendar message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadCalendar.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadCalendar message from the specified reader or buffer.
         * @function decode
         * @memberof calendar.LoadCalendar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {calendar.LoadCalendar} LoadCalendar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadCalendar.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.calendar.LoadCalendar();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.month = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.tecajId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadCalendar message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof calendar.LoadCalendar
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {calendar.LoadCalendar} LoadCalendar
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadCalendar.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadCalendar message.
         * @function verify
         * @memberof calendar.LoadCalendar
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadCalendar.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.month != null && message.hasOwnProperty("month")) {
                let error = $root.cvv_types.Date.verify(message.month);
                if (error)
                    return "month." + error;
            }
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                if (!$util.isString(message.tecajId))
                    return "tecajId: string expected";
            return null;
        };

        /**
         * Creates a LoadCalendar message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof calendar.LoadCalendar
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {calendar.LoadCalendar} LoadCalendar
         */
        LoadCalendar.fromObject = function fromObject(object) {
            if (object instanceof $root.calendar.LoadCalendar)
                return object;
            let message = new $root.calendar.LoadCalendar();
            if (object.month != null) {
                if (typeof object.month !== "object")
                    throw TypeError(".calendar.LoadCalendar.month: object expected");
                message.month = $root.cvv_types.Date.fromObject(object.month);
            }
            if (object.tecajId != null)
                message.tecajId = String(object.tecajId);
            return message;
        };

        /**
         * Creates a plain object from a LoadCalendar message. Also converts values to other types if specified.
         * @function toObject
         * @memberof calendar.LoadCalendar
         * @static
         * @param {calendar.LoadCalendar} message LoadCalendar
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadCalendar.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.month = null;
                object.tecajId = "";
            }
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = $root.cvv_types.Date.toObject(message.month, options);
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                object.tecajId = message.tecajId;
            return object;
        };

        /**
         * Converts this LoadCalendar to JSON.
         * @function toJSON
         * @memberof calendar.LoadCalendar
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadCalendar.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadCalendar
         * @function getTypeUrl
         * @memberof calendar.LoadCalendar
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadCalendar.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/calendar.LoadCalendar";
        };

        return LoadCalendar;
    })();

    calendar.CalendarData = (function() {

        /**
         * Properties of a CalendarData.
         * @memberof calendar
         * @interface ICalendarData
         * @property {cvv_types.IDate|null} [month] CalendarData month
         * @property {Array.<number>|null} [daysWithLectures] CalendarData daysWithLectures
         */

        /**
         * Constructs a new CalendarData.
         * @memberof calendar
         * @classdesc Represents a CalendarData.
         * @implements ICalendarData
         * @constructor
         * @param {calendar.ICalendarData=} [properties] Properties to set
         */
        function CalendarData(properties) {
            this.daysWithLectures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalendarData month.
         * @member {cvv_types.IDate|null|undefined} month
         * @memberof calendar.CalendarData
         * @instance
         */
        CalendarData.prototype.month = null;

        /**
         * CalendarData daysWithLectures.
         * @member {Array.<number>} daysWithLectures
         * @memberof calendar.CalendarData
         * @instance
         */
        CalendarData.prototype.daysWithLectures = $util.emptyArray;

        /**
         * Creates a new CalendarData instance using the specified properties.
         * @function create
         * @memberof calendar.CalendarData
         * @static
         * @param {calendar.ICalendarData=} [properties] Properties to set
         * @returns {calendar.CalendarData} CalendarData instance
         */
        CalendarData.create = function create(properties) {
            return new CalendarData(properties);
        };

        /**
         * Encodes the specified CalendarData message. Does not implicitly {@link calendar.CalendarData.verify|verify} messages.
         * @function encode
         * @memberof calendar.CalendarData
         * @static
         * @param {calendar.ICalendarData} message CalendarData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalendarData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                $root.cvv_types.Date.encode(message.month, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.daysWithLectures != null && message.daysWithLectures.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.daysWithLectures.length; ++i)
                    writer.int32(message.daysWithLectures[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified CalendarData message, length delimited. Does not implicitly {@link calendar.CalendarData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof calendar.CalendarData
         * @static
         * @param {calendar.ICalendarData} message CalendarData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalendarData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CalendarData message from the specified reader or buffer.
         * @function decode
         * @memberof calendar.CalendarData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {calendar.CalendarData} CalendarData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalendarData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.calendar.CalendarData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.month = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.daysWithLectures && message.daysWithLectures.length))
                            message.daysWithLectures = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.daysWithLectures.push(reader.int32());
                        } else
                            message.daysWithLectures.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CalendarData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof calendar.CalendarData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {calendar.CalendarData} CalendarData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalendarData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CalendarData message.
         * @function verify
         * @memberof calendar.CalendarData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CalendarData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.month != null && message.hasOwnProperty("month")) {
                let error = $root.cvv_types.Date.verify(message.month);
                if (error)
                    return "month." + error;
            }
            if (message.daysWithLectures != null && message.hasOwnProperty("daysWithLectures")) {
                if (!Array.isArray(message.daysWithLectures))
                    return "daysWithLectures: array expected";
                for (let i = 0; i < message.daysWithLectures.length; ++i)
                    if (!$util.isInteger(message.daysWithLectures[i]))
                        return "daysWithLectures: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a CalendarData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof calendar.CalendarData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {calendar.CalendarData} CalendarData
         */
        CalendarData.fromObject = function fromObject(object) {
            if (object instanceof $root.calendar.CalendarData)
                return object;
            let message = new $root.calendar.CalendarData();
            if (object.month != null) {
                if (typeof object.month !== "object")
                    throw TypeError(".calendar.CalendarData.month: object expected");
                message.month = $root.cvv_types.Date.fromObject(object.month);
            }
            if (object.daysWithLectures) {
                if (!Array.isArray(object.daysWithLectures))
                    throw TypeError(".calendar.CalendarData.daysWithLectures: array expected");
                message.daysWithLectures = [];
                for (let i = 0; i < object.daysWithLectures.length; ++i)
                    message.daysWithLectures[i] = object.daysWithLectures[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a CalendarData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof calendar.CalendarData
         * @static
         * @param {calendar.CalendarData} message CalendarData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CalendarData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.daysWithLectures = [];
            if (options.defaults)
                object.month = null;
            if (message.month != null && message.hasOwnProperty("month"))
                object.month = $root.cvv_types.Date.toObject(message.month, options);
            if (message.daysWithLectures && message.daysWithLectures.length) {
                object.daysWithLectures = [];
                for (let j = 0; j < message.daysWithLectures.length; ++j)
                    object.daysWithLectures[j] = message.daysWithLectures[j];
            }
            return object;
        };

        /**
         * Converts this CalendarData to JSON.
         * @function toJSON
         * @memberof calendar.CalendarData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CalendarData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CalendarData
         * @function getTypeUrl
         * @memberof calendar.CalendarData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CalendarData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/calendar.CalendarData";
        };

        return CalendarData;
    })();

    calendar.LoadCalendarDay = (function() {

        /**
         * Properties of a LoadCalendarDay.
         * @memberof calendar
         * @interface ILoadCalendarDay
         * @property {cvv_types.IDate|null} [day] LoadCalendarDay day
         * @property {string|null} [tecajId] LoadCalendarDay tecajId
         */

        /**
         * Constructs a new LoadCalendarDay.
         * @memberof calendar
         * @classdesc Represents a LoadCalendarDay.
         * @implements ILoadCalendarDay
         * @constructor
         * @param {calendar.ILoadCalendarDay=} [properties] Properties to set
         */
        function LoadCalendarDay(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoadCalendarDay day.
         * @member {cvv_types.IDate|null|undefined} day
         * @memberof calendar.LoadCalendarDay
         * @instance
         */
        LoadCalendarDay.prototype.day = null;

        /**
         * LoadCalendarDay tecajId.
         * @member {string} tecajId
         * @memberof calendar.LoadCalendarDay
         * @instance
         */
        LoadCalendarDay.prototype.tecajId = "";

        /**
         * Creates a new LoadCalendarDay instance using the specified properties.
         * @function create
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {calendar.ILoadCalendarDay=} [properties] Properties to set
         * @returns {calendar.LoadCalendarDay} LoadCalendarDay instance
         */
        LoadCalendarDay.create = function create(properties) {
            return new LoadCalendarDay(properties);
        };

        /**
         * Encodes the specified LoadCalendarDay message. Does not implicitly {@link calendar.LoadCalendarDay.verify|verify} messages.
         * @function encode
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {calendar.ILoadCalendarDay} message LoadCalendarDay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadCalendarDay.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                $root.cvv_types.Date.encode(message.day, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.tecajId != null && Object.hasOwnProperty.call(message, "tecajId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecajId);
            return writer;
        };

        /**
         * Encodes the specified LoadCalendarDay message, length delimited. Does not implicitly {@link calendar.LoadCalendarDay.verify|verify} messages.
         * @function encodeDelimited
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {calendar.ILoadCalendarDay} message LoadCalendarDay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoadCalendarDay.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoadCalendarDay message from the specified reader or buffer.
         * @function decode
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {calendar.LoadCalendarDay} LoadCalendarDay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadCalendarDay.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.calendar.LoadCalendarDay();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.day = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.tecajId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoadCalendarDay message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {calendar.LoadCalendarDay} LoadCalendarDay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoadCalendarDay.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoadCalendarDay message.
         * @function verify
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoadCalendarDay.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.day != null && message.hasOwnProperty("day")) {
                let error = $root.cvv_types.Date.verify(message.day);
                if (error)
                    return "day." + error;
            }
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                if (!$util.isString(message.tecajId))
                    return "tecajId: string expected";
            return null;
        };

        /**
         * Creates a LoadCalendarDay message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {calendar.LoadCalendarDay} LoadCalendarDay
         */
        LoadCalendarDay.fromObject = function fromObject(object) {
            if (object instanceof $root.calendar.LoadCalendarDay)
                return object;
            let message = new $root.calendar.LoadCalendarDay();
            if (object.day != null) {
                if (typeof object.day !== "object")
                    throw TypeError(".calendar.LoadCalendarDay.day: object expected");
                message.day = $root.cvv_types.Date.fromObject(object.day);
            }
            if (object.tecajId != null)
                message.tecajId = String(object.tecajId);
            return message;
        };

        /**
         * Creates a plain object from a LoadCalendarDay message. Also converts values to other types if specified.
         * @function toObject
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {calendar.LoadCalendarDay} message LoadCalendarDay
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoadCalendarDay.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.day = null;
                object.tecajId = "";
            }
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = $root.cvv_types.Date.toObject(message.day, options);
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                object.tecajId = message.tecajId;
            return object;
        };

        /**
         * Converts this LoadCalendarDay to JSON.
         * @function toJSON
         * @memberof calendar.LoadCalendarDay
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoadCalendarDay.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoadCalendarDay
         * @function getTypeUrl
         * @memberof calendar.LoadCalendarDay
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoadCalendarDay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/calendar.LoadCalendarDay";
        };

        return LoadCalendarDay;
    })();

    calendar.CalendarDayData = (function() {

        /**
         * Properties of a CalendarDayData.
         * @memberof calendar
         * @interface ICalendarDayData
         * @property {cvv_types.IDate|null} [day] CalendarDayData day
         * @property {Array.<calendar.IAvailableLecture>|null} [lectures] CalendarDayData lectures
         */

        /**
         * Constructs a new CalendarDayData.
         * @memberof calendar
         * @classdesc Represents a CalendarDayData.
         * @implements ICalendarDayData
         * @constructor
         * @param {calendar.ICalendarDayData=} [properties] Properties to set
         */
        function CalendarDayData(properties) {
            this.lectures = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalendarDayData day.
         * @member {cvv_types.IDate|null|undefined} day
         * @memberof calendar.CalendarDayData
         * @instance
         */
        CalendarDayData.prototype.day = null;

        /**
         * CalendarDayData lectures.
         * @member {Array.<calendar.IAvailableLecture>} lectures
         * @memberof calendar.CalendarDayData
         * @instance
         */
        CalendarDayData.prototype.lectures = $util.emptyArray;

        /**
         * Creates a new CalendarDayData instance using the specified properties.
         * @function create
         * @memberof calendar.CalendarDayData
         * @static
         * @param {calendar.ICalendarDayData=} [properties] Properties to set
         * @returns {calendar.CalendarDayData} CalendarDayData instance
         */
        CalendarDayData.create = function create(properties) {
            return new CalendarDayData(properties);
        };

        /**
         * Encodes the specified CalendarDayData message. Does not implicitly {@link calendar.CalendarDayData.verify|verify} messages.
         * @function encode
         * @memberof calendar.CalendarDayData
         * @static
         * @param {calendar.ICalendarDayData} message CalendarDayData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalendarDayData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                $root.cvv_types.Date.encode(message.day, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lectures != null && message.lectures.length)
                for (let i = 0; i < message.lectures.length; ++i)
                    $root.calendar.AvailableLecture.encode(message.lectures[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CalendarDayData message, length delimited. Does not implicitly {@link calendar.CalendarDayData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof calendar.CalendarDayData
         * @static
         * @param {calendar.ICalendarDayData} message CalendarDayData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalendarDayData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CalendarDayData message from the specified reader or buffer.
         * @function decode
         * @memberof calendar.CalendarDayData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {calendar.CalendarDayData} CalendarDayData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalendarDayData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.calendar.CalendarDayData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.day = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.lectures && message.lectures.length))
                            message.lectures = [];
                        message.lectures.push($root.calendar.AvailableLecture.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CalendarDayData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof calendar.CalendarDayData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {calendar.CalendarDayData} CalendarDayData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalendarDayData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CalendarDayData message.
         * @function verify
         * @memberof calendar.CalendarDayData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CalendarDayData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.day != null && message.hasOwnProperty("day")) {
                let error = $root.cvv_types.Date.verify(message.day);
                if (error)
                    return "day." + error;
            }
            if (message.lectures != null && message.hasOwnProperty("lectures")) {
                if (!Array.isArray(message.lectures))
                    return "lectures: array expected";
                for (let i = 0; i < message.lectures.length; ++i) {
                    let error = $root.calendar.AvailableLecture.verify(message.lectures[i]);
                    if (error)
                        return "lectures." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CalendarDayData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof calendar.CalendarDayData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {calendar.CalendarDayData} CalendarDayData
         */
        CalendarDayData.fromObject = function fromObject(object) {
            if (object instanceof $root.calendar.CalendarDayData)
                return object;
            let message = new $root.calendar.CalendarDayData();
            if (object.day != null) {
                if (typeof object.day !== "object")
                    throw TypeError(".calendar.CalendarDayData.day: object expected");
                message.day = $root.cvv_types.Date.fromObject(object.day);
            }
            if (object.lectures) {
                if (!Array.isArray(object.lectures))
                    throw TypeError(".calendar.CalendarDayData.lectures: array expected");
                message.lectures = [];
                for (let i = 0; i < object.lectures.length; ++i) {
                    if (typeof object.lectures[i] !== "object")
                        throw TypeError(".calendar.CalendarDayData.lectures: object expected");
                    message.lectures[i] = $root.calendar.AvailableLecture.fromObject(object.lectures[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CalendarDayData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof calendar.CalendarDayData
         * @static
         * @param {calendar.CalendarDayData} message CalendarDayData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CalendarDayData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.lectures = [];
            if (options.defaults)
                object.day = null;
            if (message.day != null && message.hasOwnProperty("day"))
                object.day = $root.cvv_types.Date.toObject(message.day, options);
            if (message.lectures && message.lectures.length) {
                object.lectures = [];
                for (let j = 0; j < message.lectures.length; ++j)
                    object.lectures[j] = $root.calendar.AvailableLecture.toObject(message.lectures[j], options);
            }
            return object;
        };

        /**
         * Converts this CalendarDayData to JSON.
         * @function toJSON
         * @memberof calendar.CalendarDayData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CalendarDayData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for CalendarDayData
         * @function getTypeUrl
         * @memberof calendar.CalendarDayData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        CalendarDayData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/calendar.CalendarDayData";
        };

        return CalendarDayData;
    })();

    calendar.AvailableLecture = (function() {

        /**
         * Properties of an AvailableLecture.
         * @memberof calendar
         * @interface IAvailableLecture
         * @property {string|null} [id] AvailableLecture id
         * @property {cvv_types.IDate|null} [dateTime] AvailableLecture dateTime
         * @property {string|null} [location] AvailableLecture location
         * @property {number|null} [freePlaces] AvailableLecture freePlaces
         * @property {Array.<billing.PaymentType>|null} [paymentTypes] AvailableLecture paymentTypes
         */

        /**
         * Constructs a new AvailableLecture.
         * @memberof calendar
         * @classdesc Represents an AvailableLecture.
         * @implements IAvailableLecture
         * @constructor
         * @param {calendar.IAvailableLecture=} [properties] Properties to set
         */
        function AvailableLecture(properties) {
            this.paymentTypes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AvailableLecture id.
         * @member {string} id
         * @memberof calendar.AvailableLecture
         * @instance
         */
        AvailableLecture.prototype.id = "";

        /**
         * AvailableLecture dateTime.
         * @member {cvv_types.IDate|null|undefined} dateTime
         * @memberof calendar.AvailableLecture
         * @instance
         */
        AvailableLecture.prototype.dateTime = null;

        /**
         * AvailableLecture location.
         * @member {string} location
         * @memberof calendar.AvailableLecture
         * @instance
         */
        AvailableLecture.prototype.location = "";

        /**
         * AvailableLecture freePlaces.
         * @member {number} freePlaces
         * @memberof calendar.AvailableLecture
         * @instance
         */
        AvailableLecture.prototype.freePlaces = 0;

        /**
         * AvailableLecture paymentTypes.
         * @member {Array.<billing.PaymentType>} paymentTypes
         * @memberof calendar.AvailableLecture
         * @instance
         */
        AvailableLecture.prototype.paymentTypes = $util.emptyArray;

        /**
         * Creates a new AvailableLecture instance using the specified properties.
         * @function create
         * @memberof calendar.AvailableLecture
         * @static
         * @param {calendar.IAvailableLecture=} [properties] Properties to set
         * @returns {calendar.AvailableLecture} AvailableLecture instance
         */
        AvailableLecture.create = function create(properties) {
            return new AvailableLecture(properties);
        };

        /**
         * Encodes the specified AvailableLecture message. Does not implicitly {@link calendar.AvailableLecture.verify|verify} messages.
         * @function encode
         * @memberof calendar.AvailableLecture
         * @static
         * @param {calendar.IAvailableLecture} message AvailableLecture message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableLecture.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.dateTime != null && Object.hasOwnProperty.call(message, "dateTime"))
                $root.cvv_types.Date.encode(message.dateTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.location);
            if (message.freePlaces != null && Object.hasOwnProperty.call(message, "freePlaces"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.freePlaces);
            if (message.paymentTypes != null && message.paymentTypes.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (let i = 0; i < message.paymentTypes.length; ++i)
                    writer.int32(message.paymentTypes[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified AvailableLecture message, length delimited. Does not implicitly {@link calendar.AvailableLecture.verify|verify} messages.
         * @function encodeDelimited
         * @memberof calendar.AvailableLecture
         * @static
         * @param {calendar.IAvailableLecture} message AvailableLecture message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AvailableLecture.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AvailableLecture message from the specified reader or buffer.
         * @function decode
         * @memberof calendar.AvailableLecture
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {calendar.AvailableLecture} AvailableLecture
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableLecture.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.calendar.AvailableLecture();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.dateTime = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.location = reader.string();
                        break;
                    }
                case 4: {
                        message.freePlaces = reader.int32();
                        break;
                    }
                case 5: {
                        if (!(message.paymentTypes && message.paymentTypes.length))
                            message.paymentTypes = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.paymentTypes.push(reader.int32());
                        } else
                            message.paymentTypes.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AvailableLecture message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof calendar.AvailableLecture
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {calendar.AvailableLecture} AvailableLecture
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AvailableLecture.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AvailableLecture message.
         * @function verify
         * @memberof calendar.AvailableLecture
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AvailableLecture.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.dateTime != null && message.hasOwnProperty("dateTime")) {
                let error = $root.cvv_types.Date.verify(message.dateTime);
                if (error)
                    return "dateTime." + error;
            }
            if (message.location != null && message.hasOwnProperty("location"))
                if (!$util.isString(message.location))
                    return "location: string expected";
            if (message.freePlaces != null && message.hasOwnProperty("freePlaces"))
                if (!$util.isInteger(message.freePlaces))
                    return "freePlaces: integer expected";
            if (message.paymentTypes != null && message.hasOwnProperty("paymentTypes")) {
                if (!Array.isArray(message.paymentTypes))
                    return "paymentTypes: array expected";
                for (let i = 0; i < message.paymentTypes.length; ++i)
                    switch (message.paymentTypes[i]) {
                    default:
                        return "paymentTypes: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates an AvailableLecture message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof calendar.AvailableLecture
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {calendar.AvailableLecture} AvailableLecture
         */
        AvailableLecture.fromObject = function fromObject(object) {
            if (object instanceof $root.calendar.AvailableLecture)
                return object;
            let message = new $root.calendar.AvailableLecture();
            if (object.id != null)
                message.id = String(object.id);
            if (object.dateTime != null) {
                if (typeof object.dateTime !== "object")
                    throw TypeError(".calendar.AvailableLecture.dateTime: object expected");
                message.dateTime = $root.cvv_types.Date.fromObject(object.dateTime);
            }
            if (object.location != null)
                message.location = String(object.location);
            if (object.freePlaces != null)
                message.freePlaces = object.freePlaces | 0;
            if (object.paymentTypes) {
                if (!Array.isArray(object.paymentTypes))
                    throw TypeError(".calendar.AvailableLecture.paymentTypes: array expected");
                message.paymentTypes = [];
                for (let i = 0; i < object.paymentTypes.length; ++i)
                    switch (object.paymentTypes[i]) {
                    default:
                        if (typeof object.paymentTypes[i] === "number") {
                            message.paymentTypes[i] = object.paymentTypes[i];
                            break;
                        }
                    case "kreditna":
                    case 0:
                        message.paymentTypes[i] = 0;
                        break;
                    case "darilni_bon":
                    case 1:
                        message.paymentTypes[i] = 1;
                        break;
                    case "gotovina":
                    case 2:
                        message.paymentTypes[i] = 2;
                        break;
                    case "predracun":
                    case 3:
                        message.paymentTypes[i] = 3;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from an AvailableLecture message. Also converts values to other types if specified.
         * @function toObject
         * @memberof calendar.AvailableLecture
         * @static
         * @param {calendar.AvailableLecture} message AvailableLecture
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AvailableLecture.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.paymentTypes = [];
            if (options.defaults) {
                object.id = "";
                object.dateTime = null;
                object.location = "";
                object.freePlaces = 0;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.dateTime != null && message.hasOwnProperty("dateTime"))
                object.dateTime = $root.cvv_types.Date.toObject(message.dateTime, options);
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = message.location;
            if (message.freePlaces != null && message.hasOwnProperty("freePlaces"))
                object.freePlaces = message.freePlaces;
            if (message.paymentTypes && message.paymentTypes.length) {
                object.paymentTypes = [];
                for (let j = 0; j < message.paymentTypes.length; ++j)
                    object.paymentTypes[j] = options.enums === String ? $root.billing.PaymentType[message.paymentTypes[j]] === undefined ? message.paymentTypes[j] : $root.billing.PaymentType[message.paymentTypes[j]] : message.paymentTypes[j];
            }
            return object;
        };

        /**
         * Converts this AvailableLecture to JSON.
         * @function toJSON
         * @memberof calendar.AvailableLecture
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AvailableLecture.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AvailableLecture
         * @function getTypeUrl
         * @memberof calendar.AvailableLecture
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AvailableLecture.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/calendar.AvailableLecture";
        };

        return AvailableLecture;
    })();

    return calendar;
})();

export const billing = $root.billing = (() => {

    /**
     * Namespace billing.
     * @exports billing
     * @namespace
     */
    const billing = {};

    billing.StripePaymentIntent = (function() {

        /**
         * Properties of a StripePaymentIntent.
         * @memberof billing
         * @interface IStripePaymentIntent
         * @property {string|null} [tmpId] StripePaymentIntent tmpId
         * @property {string|null} [clientSecret] StripePaymentIntent clientSecret
         */

        /**
         * Constructs a new StripePaymentIntent.
         * @memberof billing
         * @classdesc Represents a StripePaymentIntent.
         * @implements IStripePaymentIntent
         * @constructor
         * @param {billing.IStripePaymentIntent=} [properties] Properties to set
         */
        function StripePaymentIntent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StripePaymentIntent tmpId.
         * @member {string} tmpId
         * @memberof billing.StripePaymentIntent
         * @instance
         */
        StripePaymentIntent.prototype.tmpId = "";

        /**
         * StripePaymentIntent clientSecret.
         * @member {string} clientSecret
         * @memberof billing.StripePaymentIntent
         * @instance
         */
        StripePaymentIntent.prototype.clientSecret = "";

        /**
         * Creates a new StripePaymentIntent instance using the specified properties.
         * @function create
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {billing.IStripePaymentIntent=} [properties] Properties to set
         * @returns {billing.StripePaymentIntent} StripePaymentIntent instance
         */
        StripePaymentIntent.create = function create(properties) {
            return new StripePaymentIntent(properties);
        };

        /**
         * Encodes the specified StripePaymentIntent message. Does not implicitly {@link billing.StripePaymentIntent.verify|verify} messages.
         * @function encode
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {billing.IStripePaymentIntent} message StripePaymentIntent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentIntent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tmpId != null && Object.hasOwnProperty.call(message, "tmpId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tmpId);
            if (message.clientSecret != null && Object.hasOwnProperty.call(message, "clientSecret"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.clientSecret);
            return writer;
        };

        /**
         * Encodes the specified StripePaymentIntent message, length delimited. Does not implicitly {@link billing.StripePaymentIntent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {billing.IStripePaymentIntent} message StripePaymentIntent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StripePaymentIntent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StripePaymentIntent message from the specified reader or buffer.
         * @function decode
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {billing.StripePaymentIntent} StripePaymentIntent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentIntent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.billing.StripePaymentIntent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tmpId = reader.string();
                        break;
                    }
                case 2: {
                        message.clientSecret = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StripePaymentIntent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {billing.StripePaymentIntent} StripePaymentIntent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StripePaymentIntent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StripePaymentIntent message.
         * @function verify
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StripePaymentIntent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tmpId != null && message.hasOwnProperty("tmpId"))
                if (!$util.isString(message.tmpId))
                    return "tmpId: string expected";
            if (message.clientSecret != null && message.hasOwnProperty("clientSecret"))
                if (!$util.isString(message.clientSecret))
                    return "clientSecret: string expected";
            return null;
        };

        /**
         * Creates a StripePaymentIntent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {billing.StripePaymentIntent} StripePaymentIntent
         */
        StripePaymentIntent.fromObject = function fromObject(object) {
            if (object instanceof $root.billing.StripePaymentIntent)
                return object;
            let message = new $root.billing.StripePaymentIntent();
            if (object.tmpId != null)
                message.tmpId = String(object.tmpId);
            if (object.clientSecret != null)
                message.clientSecret = String(object.clientSecret);
            return message;
        };

        /**
         * Creates a plain object from a StripePaymentIntent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {billing.StripePaymentIntent} message StripePaymentIntent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StripePaymentIntent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.tmpId = "";
                object.clientSecret = "";
            }
            if (message.tmpId != null && message.hasOwnProperty("tmpId"))
                object.tmpId = message.tmpId;
            if (message.clientSecret != null && message.hasOwnProperty("clientSecret"))
                object.clientSecret = message.clientSecret;
            return object;
        };

        /**
         * Converts this StripePaymentIntent to JSON.
         * @function toJSON
         * @memberof billing.StripePaymentIntent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StripePaymentIntent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for StripePaymentIntent
         * @function getTypeUrl
         * @memberof billing.StripePaymentIntent
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        StripePaymentIntent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/billing.StripePaymentIntent";
        };

        return StripePaymentIntent;
    })();

    billing.Pricelist = (function() {

        /**
         * Properties of a Pricelist.
         * @memberof billing
         * @interface IPricelist
         * @property {string|null} [tmpId] Pricelist tmpId
         * @property {Array.<billing.IPricelistItem>|null} [items] Pricelist items
         * @property {number|null} [total] Pricelist total
         * @property {number|null} [paid] Pricelist paid
         */

        /**
         * Constructs a new Pricelist.
         * @memberof billing
         * @classdesc Represents a Pricelist.
         * @implements IPricelist
         * @constructor
         * @param {billing.IPricelist=} [properties] Properties to set
         */
        function Pricelist(properties) {
            this.items = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Pricelist tmpId.
         * @member {string} tmpId
         * @memberof billing.Pricelist
         * @instance
         */
        Pricelist.prototype.tmpId = "";

        /**
         * Pricelist items.
         * @member {Array.<billing.IPricelistItem>} items
         * @memberof billing.Pricelist
         * @instance
         */
        Pricelist.prototype.items = $util.emptyArray;

        /**
         * Pricelist total.
         * @member {number} total
         * @memberof billing.Pricelist
         * @instance
         */
        Pricelist.prototype.total = 0;

        /**
         * Pricelist paid.
         * @member {number} paid
         * @memberof billing.Pricelist
         * @instance
         */
        Pricelist.prototype.paid = 0;

        /**
         * Creates a new Pricelist instance using the specified properties.
         * @function create
         * @memberof billing.Pricelist
         * @static
         * @param {billing.IPricelist=} [properties] Properties to set
         * @returns {billing.Pricelist} Pricelist instance
         */
        Pricelist.create = function create(properties) {
            return new Pricelist(properties);
        };

        /**
         * Encodes the specified Pricelist message. Does not implicitly {@link billing.Pricelist.verify|verify} messages.
         * @function encode
         * @memberof billing.Pricelist
         * @static
         * @param {billing.IPricelist} message Pricelist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pricelist.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tmpId != null && Object.hasOwnProperty.call(message, "tmpId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tmpId);
            if (message.items != null && message.items.length)
                for (let i = 0; i < message.items.length; ++i)
                    $root.billing.PricelistItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.total);
            if (message.paid != null && Object.hasOwnProperty.call(message, "paid"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.paid);
            return writer;
        };

        /**
         * Encodes the specified Pricelist message, length delimited. Does not implicitly {@link billing.Pricelist.verify|verify} messages.
         * @function encodeDelimited
         * @memberof billing.Pricelist
         * @static
         * @param {billing.IPricelist} message Pricelist message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pricelist.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Pricelist message from the specified reader or buffer.
         * @function decode
         * @memberof billing.Pricelist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {billing.Pricelist} Pricelist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pricelist.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.billing.Pricelist();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tmpId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.items && message.items.length))
                            message.items = [];
                        message.items.push($root.billing.PricelistItem.decode(reader, reader.uint32()));
                        break;
                    }
                case 3: {
                        message.total = reader.int32();
                        break;
                    }
                case 4: {
                        message.paid = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Pricelist message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof billing.Pricelist
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {billing.Pricelist} Pricelist
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pricelist.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Pricelist message.
         * @function verify
         * @memberof billing.Pricelist
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pricelist.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tmpId != null && message.hasOwnProperty("tmpId"))
                if (!$util.isString(message.tmpId))
                    return "tmpId: string expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (let i = 0; i < message.items.length; ++i) {
                    let error = $root.billing.PricelistItem.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            if (message.total != null && message.hasOwnProperty("total"))
                if (!$util.isInteger(message.total))
                    return "total: integer expected";
            if (message.paid != null && message.hasOwnProperty("paid"))
                if (!$util.isInteger(message.paid))
                    return "paid: integer expected";
            return null;
        };

        /**
         * Creates a Pricelist message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof billing.Pricelist
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {billing.Pricelist} Pricelist
         */
        Pricelist.fromObject = function fromObject(object) {
            if (object instanceof $root.billing.Pricelist)
                return object;
            let message = new $root.billing.Pricelist();
            if (object.tmpId != null)
                message.tmpId = String(object.tmpId);
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".billing.Pricelist.items: array expected");
                message.items = [];
                for (let i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".billing.Pricelist.items: object expected");
                    message.items[i] = $root.billing.PricelistItem.fromObject(object.items[i]);
                }
            }
            if (object.total != null)
                message.total = object.total | 0;
            if (object.paid != null)
                message.paid = object.paid | 0;
            return message;
        };

        /**
         * Creates a plain object from a Pricelist message. Also converts values to other types if specified.
         * @function toObject
         * @memberof billing.Pricelist
         * @static
         * @param {billing.Pricelist} message Pricelist
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pricelist.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults) {
                object.tmpId = "";
                object.total = 0;
                object.paid = 0;
            }
            if (message.tmpId != null && message.hasOwnProperty("tmpId"))
                object.tmpId = message.tmpId;
            if (message.items && message.items.length) {
                object.items = [];
                for (let j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.billing.PricelistItem.toObject(message.items[j], options);
            }
            if (message.total != null && message.hasOwnProperty("total"))
                object.total = message.total;
            if (message.paid != null && message.hasOwnProperty("paid"))
                object.paid = message.paid;
            return object;
        };

        /**
         * Converts this Pricelist to JSON.
         * @function toJSON
         * @memberof billing.Pricelist
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pricelist.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Pricelist
         * @function getTypeUrl
         * @memberof billing.Pricelist
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Pricelist.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/billing.Pricelist";
        };

        return Pricelist;
    })();

    billing.PricelistItem = (function() {

        /**
         * Properties of a PricelistItem.
         * @memberof billing
         * @interface IPricelistItem
         * @property {string|null} [code] PricelistItem code
         * @property {string|null} [name] PricelistItem name
         * @property {number|null} [sourceAmount] PricelistItem sourceAmount
         * @property {number|null} [amount] PricelistItem amount
         * @property {Array.<billing.IPriceModifier>|null} [modifiers] PricelistItem modifiers
         * @property {string|null} [serviceId] PricelistItem serviceId
         * @property {string|null} [verboseName] PricelistItem verboseName
         * @property {string|null} [navId] PricelistItem navId
         */

        /**
         * Constructs a new PricelistItem.
         * @memberof billing
         * @classdesc Represents a PricelistItem.
         * @implements IPricelistItem
         * @constructor
         * @param {billing.IPricelistItem=} [properties] Properties to set
         */
        function PricelistItem(properties) {
            this.modifiers = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PricelistItem code.
         * @member {string} code
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.code = "";

        /**
         * PricelistItem name.
         * @member {string} name
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.name = "";

        /**
         * PricelistItem sourceAmount.
         * @member {number} sourceAmount
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.sourceAmount = 0;

        /**
         * PricelistItem amount.
         * @member {number} amount
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.amount = 0;

        /**
         * PricelistItem modifiers.
         * @member {Array.<billing.IPriceModifier>} modifiers
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.modifiers = $util.emptyArray;

        /**
         * PricelistItem serviceId.
         * @member {string} serviceId
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.serviceId = "";

        /**
         * PricelistItem verboseName.
         * @member {string} verboseName
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.verboseName = "";

        /**
         * PricelistItem navId.
         * @member {string} navId
         * @memberof billing.PricelistItem
         * @instance
         */
        PricelistItem.prototype.navId = "";

        /**
         * Creates a new PricelistItem instance using the specified properties.
         * @function create
         * @memberof billing.PricelistItem
         * @static
         * @param {billing.IPricelistItem=} [properties] Properties to set
         * @returns {billing.PricelistItem} PricelistItem instance
         */
        PricelistItem.create = function create(properties) {
            return new PricelistItem(properties);
        };

        /**
         * Encodes the specified PricelistItem message. Does not implicitly {@link billing.PricelistItem.verify|verify} messages.
         * @function encode
         * @memberof billing.PricelistItem
         * @static
         * @param {billing.IPricelistItem} message PricelistItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PricelistItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.sourceAmount != null && Object.hasOwnProperty.call(message, "sourceAmount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sourceAmount);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.amount);
            if (message.modifiers != null && message.modifiers.length)
                for (let i = 0; i < message.modifiers.length; ++i)
                    $root.billing.PriceModifier.encode(message.modifiers[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.serviceId != null && Object.hasOwnProperty.call(message, "serviceId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.serviceId);
            if (message.verboseName != null && Object.hasOwnProperty.call(message, "verboseName"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.verboseName);
            if (message.navId != null && Object.hasOwnProperty.call(message, "navId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.navId);
            return writer;
        };

        /**
         * Encodes the specified PricelistItem message, length delimited. Does not implicitly {@link billing.PricelistItem.verify|verify} messages.
         * @function encodeDelimited
         * @memberof billing.PricelistItem
         * @static
         * @param {billing.IPricelistItem} message PricelistItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PricelistItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PricelistItem message from the specified reader or buffer.
         * @function decode
         * @memberof billing.PricelistItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {billing.PricelistItem} PricelistItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PricelistItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.billing.PricelistItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.code = reader.string();
                        break;
                    }
                case 2: {
                        message.name = reader.string();
                        break;
                    }
                case 3: {
                        message.sourceAmount = reader.int32();
                        break;
                    }
                case 4: {
                        message.amount = reader.int32();
                        break;
                    }
                case 5: {
                        if (!(message.modifiers && message.modifiers.length))
                            message.modifiers = [];
                        message.modifiers.push($root.billing.PriceModifier.decode(reader, reader.uint32()));
                        break;
                    }
                case 6: {
                        message.serviceId = reader.string();
                        break;
                    }
                case 7: {
                        message.verboseName = reader.string();
                        break;
                    }
                case 8: {
                        message.navId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PricelistItem message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof billing.PricelistItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {billing.PricelistItem} PricelistItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PricelistItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PricelistItem message.
         * @function verify
         * @memberof billing.PricelistItem
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PricelistItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.sourceAmount != null && message.hasOwnProperty("sourceAmount"))
                if (!$util.isInteger(message.sourceAmount))
                    return "sourceAmount: integer expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount))
                    return "amount: integer expected";
            if (message.modifiers != null && message.hasOwnProperty("modifiers")) {
                if (!Array.isArray(message.modifiers))
                    return "modifiers: array expected";
                for (let i = 0; i < message.modifiers.length; ++i) {
                    let error = $root.billing.PriceModifier.verify(message.modifiers[i]);
                    if (error)
                        return "modifiers." + error;
                }
            }
            if (message.serviceId != null && message.hasOwnProperty("serviceId"))
                if (!$util.isString(message.serviceId))
                    return "serviceId: string expected";
            if (message.verboseName != null && message.hasOwnProperty("verboseName"))
                if (!$util.isString(message.verboseName))
                    return "verboseName: string expected";
            if (message.navId != null && message.hasOwnProperty("navId"))
                if (!$util.isString(message.navId))
                    return "navId: string expected";
            return null;
        };

        /**
         * Creates a PricelistItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof billing.PricelistItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {billing.PricelistItem} PricelistItem
         */
        PricelistItem.fromObject = function fromObject(object) {
            if (object instanceof $root.billing.PricelistItem)
                return object;
            let message = new $root.billing.PricelistItem();
            if (object.code != null)
                message.code = String(object.code);
            if (object.name != null)
                message.name = String(object.name);
            if (object.sourceAmount != null)
                message.sourceAmount = object.sourceAmount | 0;
            if (object.amount != null)
                message.amount = object.amount | 0;
            if (object.modifiers) {
                if (!Array.isArray(object.modifiers))
                    throw TypeError(".billing.PricelistItem.modifiers: array expected");
                message.modifiers = [];
                for (let i = 0; i < object.modifiers.length; ++i) {
                    if (typeof object.modifiers[i] !== "object")
                        throw TypeError(".billing.PricelistItem.modifiers: object expected");
                    message.modifiers[i] = $root.billing.PriceModifier.fromObject(object.modifiers[i]);
                }
            }
            if (object.serviceId != null)
                message.serviceId = String(object.serviceId);
            if (object.verboseName != null)
                message.verboseName = String(object.verboseName);
            if (object.navId != null)
                message.navId = String(object.navId);
            return message;
        };

        /**
         * Creates a plain object from a PricelistItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof billing.PricelistItem
         * @static
         * @param {billing.PricelistItem} message PricelistItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PricelistItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.modifiers = [];
            if (options.defaults) {
                object.code = "";
                object.name = "";
                object.sourceAmount = 0;
                object.amount = 0;
                object.serviceId = "";
                object.verboseName = "";
                object.navId = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.sourceAmount != null && message.hasOwnProperty("sourceAmount"))
                object.sourceAmount = message.sourceAmount;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = message.amount;
            if (message.modifiers && message.modifiers.length) {
                object.modifiers = [];
                for (let j = 0; j < message.modifiers.length; ++j)
                    object.modifiers[j] = $root.billing.PriceModifier.toObject(message.modifiers[j], options);
            }
            if (message.serviceId != null && message.hasOwnProperty("serviceId"))
                object.serviceId = message.serviceId;
            if (message.verboseName != null && message.hasOwnProperty("verboseName"))
                object.verboseName = message.verboseName;
            if (message.navId != null && message.hasOwnProperty("navId"))
                object.navId = message.navId;
            return object;
        };

        /**
         * Converts this PricelistItem to JSON.
         * @function toJSON
         * @memberof billing.PricelistItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PricelistItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PricelistItem
         * @function getTypeUrl
         * @memberof billing.PricelistItem
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PricelistItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/billing.PricelistItem";
        };

        return PricelistItem;
    })();

    billing.PriceModifier = (function() {

        /**
         * Properties of a PriceModifier.
         * @memberof billing
         * @interface IPriceModifier
         * @property {string|null} [name] PriceModifier name
         * @property {number|null} [add] PriceModifier add
         * @property {number|null} [multiply] PriceModifier multiply
         */

        /**
         * Constructs a new PriceModifier.
         * @memberof billing
         * @classdesc Represents a PriceModifier.
         * @implements IPriceModifier
         * @constructor
         * @param {billing.IPriceModifier=} [properties] Properties to set
         */
        function PriceModifier(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceModifier name.
         * @member {string} name
         * @memberof billing.PriceModifier
         * @instance
         */
        PriceModifier.prototype.name = "";

        /**
         * PriceModifier add.
         * @member {number} add
         * @memberof billing.PriceModifier
         * @instance
         */
        PriceModifier.prototype.add = 0;

        /**
         * PriceModifier multiply.
         * @member {number} multiply
         * @memberof billing.PriceModifier
         * @instance
         */
        PriceModifier.prototype.multiply = 0;

        /**
         * Creates a new PriceModifier instance using the specified properties.
         * @function create
         * @memberof billing.PriceModifier
         * @static
         * @param {billing.IPriceModifier=} [properties] Properties to set
         * @returns {billing.PriceModifier} PriceModifier instance
         */
        PriceModifier.create = function create(properties) {
            return new PriceModifier(properties);
        };

        /**
         * Encodes the specified PriceModifier message. Does not implicitly {@link billing.PriceModifier.verify|verify} messages.
         * @function encode
         * @memberof billing.PriceModifier
         * @static
         * @param {billing.IPriceModifier} message PriceModifier message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceModifier.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.add != null && Object.hasOwnProperty.call(message, "add"))
                writer.uint32(/* id 2, wireType 5 =*/21).float(message.add);
            if (message.multiply != null && Object.hasOwnProperty.call(message, "multiply"))
                writer.uint32(/* id 3, wireType 5 =*/29).float(message.multiply);
            return writer;
        };

        /**
         * Encodes the specified PriceModifier message, length delimited. Does not implicitly {@link billing.PriceModifier.verify|verify} messages.
         * @function encodeDelimited
         * @memberof billing.PriceModifier
         * @static
         * @param {billing.IPriceModifier} message PriceModifier message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceModifier.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PriceModifier message from the specified reader or buffer.
         * @function decode
         * @memberof billing.PriceModifier
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {billing.PriceModifier} PriceModifier
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceModifier.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.billing.PriceModifier();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                case 2: {
                        message.add = reader.float();
                        break;
                    }
                case 3: {
                        message.multiply = reader.float();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PriceModifier message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof billing.PriceModifier
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {billing.PriceModifier} PriceModifier
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceModifier.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PriceModifier message.
         * @function verify
         * @memberof billing.PriceModifier
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PriceModifier.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.add != null && message.hasOwnProperty("add"))
                if (typeof message.add !== "number")
                    return "add: number expected";
            if (message.multiply != null && message.hasOwnProperty("multiply"))
                if (typeof message.multiply !== "number")
                    return "multiply: number expected";
            return null;
        };

        /**
         * Creates a PriceModifier message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof billing.PriceModifier
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {billing.PriceModifier} PriceModifier
         */
        PriceModifier.fromObject = function fromObject(object) {
            if (object instanceof $root.billing.PriceModifier)
                return object;
            let message = new $root.billing.PriceModifier();
            if (object.name != null)
                message.name = String(object.name);
            if (object.add != null)
                message.add = Number(object.add);
            if (object.multiply != null)
                message.multiply = Number(object.multiply);
            return message;
        };

        /**
         * Creates a plain object from a PriceModifier message. Also converts values to other types if specified.
         * @function toObject
         * @memberof billing.PriceModifier
         * @static
         * @param {billing.PriceModifier} message PriceModifier
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PriceModifier.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.add = 0;
                object.multiply = 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.add != null && message.hasOwnProperty("add"))
                object.add = options.json && !isFinite(message.add) ? String(message.add) : message.add;
            if (message.multiply != null && message.hasOwnProperty("multiply"))
                object.multiply = options.json && !isFinite(message.multiply) ? String(message.multiply) : message.multiply;
            return object;
        };

        /**
         * Converts this PriceModifier to JSON.
         * @function toJSON
         * @memberof billing.PriceModifier
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PriceModifier.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PriceModifier
         * @function getTypeUrl
         * @memberof billing.PriceModifier
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PriceModifier.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/billing.PriceModifier";
        };

        return PriceModifier;
    })();

    billing.Payment = (function() {

        /**
         * Properties of a Payment.
         * @memberof billing
         * @interface IPayment
         * @property {billing.PaymentType|null} [type] Payment type
         * @property {billing.PaymentStatus|null} [status] Payment status
         * @property {number|null} [amount] Payment amount
         * @property {number|null} [amountPaid] Payment amountPaid
         */

        /**
         * Constructs a new Payment.
         * @memberof billing
         * @classdesc Represents a Payment.
         * @implements IPayment
         * @constructor
         * @param {billing.IPayment=} [properties] Properties to set
         */
        function Payment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Payment type.
         * @member {billing.PaymentType} type
         * @memberof billing.Payment
         * @instance
         */
        Payment.prototype.type = 0;

        /**
         * Payment status.
         * @member {billing.PaymentStatus} status
         * @memberof billing.Payment
         * @instance
         */
        Payment.prototype.status = 0;

        /**
         * Payment amount.
         * @member {number} amount
         * @memberof billing.Payment
         * @instance
         */
        Payment.prototype.amount = 0;

        /**
         * Payment amountPaid.
         * @member {number} amountPaid
         * @memberof billing.Payment
         * @instance
         */
        Payment.prototype.amountPaid = 0;

        /**
         * Creates a new Payment instance using the specified properties.
         * @function create
         * @memberof billing.Payment
         * @static
         * @param {billing.IPayment=} [properties] Properties to set
         * @returns {billing.Payment} Payment instance
         */
        Payment.create = function create(properties) {
            return new Payment(properties);
        };

        /**
         * Encodes the specified Payment message. Does not implicitly {@link billing.Payment.verify|verify} messages.
         * @function encode
         * @memberof billing.Payment
         * @static
         * @param {billing.IPayment} message Payment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.status);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 3, wireType 1 =*/25).double(message.amount);
            if (message.amountPaid != null && Object.hasOwnProperty.call(message, "amountPaid"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.amountPaid);
            return writer;
        };

        /**
         * Encodes the specified Payment message, length delimited. Does not implicitly {@link billing.Payment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof billing.Payment
         * @static
         * @param {billing.IPayment} message Payment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Payment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Payment message from the specified reader or buffer.
         * @function decode
         * @memberof billing.Payment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {billing.Payment} Payment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.billing.Payment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.status = reader.int32();
                        break;
                    }
                case 3: {
                        message.amount = reader.double();
                        break;
                    }
                case 4: {
                        message.amountPaid = reader.double();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Payment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof billing.Payment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {billing.Payment} Payment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Payment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Payment message.
         * @function verify
         * @memberof billing.Payment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Payment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (typeof message.amount !== "number")
                    return "amount: number expected";
            if (message.amountPaid != null && message.hasOwnProperty("amountPaid"))
                if (typeof message.amountPaid !== "number")
                    return "amountPaid: number expected";
            return null;
        };

        /**
         * Creates a Payment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof billing.Payment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {billing.Payment} Payment
         */
        Payment.fromObject = function fromObject(object) {
            if (object instanceof $root.billing.Payment)
                return object;
            let message = new $root.billing.Payment();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.type = 0;
                break;
            case "darilni_bon":
            case 1:
                message.type = 1;
                break;
            case "gotovina":
            case 2:
                message.type = 2;
                break;
            case "predracun":
            case 3:
                message.type = 3;
                break;
            }
            switch (object.status) {
            default:
                if (typeof object.status === "number") {
                    message.status = object.status;
                    break;
                }
                break;
            case "no_proforma_invoice":
            case 0:
                message.status = 0;
                break;
            case "proforma_invoice":
            case 1:
                message.status = 1;
                break;
            case "amount_mismatch":
            case 2:
                message.status = 2;
                break;
            case "paid":
            case 3:
                message.status = 3;
                break;
            }
            if (object.amount != null)
                message.amount = Number(object.amount);
            if (object.amountPaid != null)
                message.amountPaid = Number(object.amountPaid);
            return message;
        };

        /**
         * Creates a plain object from a Payment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof billing.Payment
         * @static
         * @param {billing.Payment} message Payment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Payment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "kreditna" : 0;
                object.status = options.enums === String ? "no_proforma_invoice" : 0;
                object.amount = 0;
                object.amountPaid = 0;
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.billing.PaymentType[message.type] === undefined ? message.type : $root.billing.PaymentType[message.type] : message.type;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.billing.PaymentStatus[message.status] === undefined ? message.status : $root.billing.PaymentStatus[message.status] : message.status;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = options.json && !isFinite(message.amount) ? String(message.amount) : message.amount;
            if (message.amountPaid != null && message.hasOwnProperty("amountPaid"))
                object.amountPaid = options.json && !isFinite(message.amountPaid) ? String(message.amountPaid) : message.amountPaid;
            return object;
        };

        /**
         * Converts this Payment to JSON.
         * @function toJSON
         * @memberof billing.Payment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Payment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for Payment
         * @function getTypeUrl
         * @memberof billing.Payment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        Payment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/billing.Payment";
        };

        return Payment;
    })();

    /**
     * PaymentType enum.
     * @name billing.PaymentType
     * @enum {number}
     * @property {number} kreditna=0 kreditna value
     * @property {number} darilni_bon=1 darilni_bon value
     * @property {number} gotovina=2 gotovina value
     * @property {number} predracun=3 predracun value
     */
    billing.PaymentType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "kreditna"] = 0;
        values[valuesById[1] = "darilni_bon"] = 1;
        values[valuesById[2] = "gotovina"] = 2;
        values[valuesById[3] = "predracun"] = 3;
        return values;
    })();

    /**
     * PaymentStatus enum.
     * @name billing.PaymentStatus
     * @enum {number}
     * @property {number} no_proforma_invoice=0 no_proforma_invoice value
     * @property {number} proforma_invoice=1 proforma_invoice value
     * @property {number} amount_mismatch=2 amount_mismatch value
     * @property {number} paid=3 paid value
     */
    billing.PaymentStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "no_proforma_invoice"] = 0;
        values[valuesById[1] = "proforma_invoice"] = 1;
        values[valuesById[2] = "amount_mismatch"] = 2;
        values[valuesById[3] = "paid"] = 3;
        return values;
    })();

    billing.NavReceiptProduct = (function() {

        /**
         * Properties of a NavReceiptProduct.
         * @memberof billing
         * @interface INavReceiptProduct
         * @property {string|null} [description] NavReceiptProduct description
         * @property {string|null} [unit] NavReceiptProduct unit
         * @property {number|null} [quantity] NavReceiptProduct quantity
         * @property {number|null} [amount] NavReceiptProduct amount
         * @property {number|null} [discount] NavReceiptProduct discount
         * @property {string|null} [serviceId] NavReceiptProduct serviceId
         */

        /**
         * Constructs a new NavReceiptProduct.
         * @memberof billing
         * @classdesc Represents a NavReceiptProduct.
         * @implements INavReceiptProduct
         * @constructor
         * @param {billing.INavReceiptProduct=} [properties] Properties to set
         */
        function NavReceiptProduct(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NavReceiptProduct description.
         * @member {string} description
         * @memberof billing.NavReceiptProduct
         * @instance
         */
        NavReceiptProduct.prototype.description = "";

        /**
         * NavReceiptProduct unit.
         * @member {string} unit
         * @memberof billing.NavReceiptProduct
         * @instance
         */
        NavReceiptProduct.prototype.unit = "";

        /**
         * NavReceiptProduct quantity.
         * @member {number} quantity
         * @memberof billing.NavReceiptProduct
         * @instance
         */
        NavReceiptProduct.prototype.quantity = 0;

        /**
         * NavReceiptProduct amount.
         * @member {number} amount
         * @memberof billing.NavReceiptProduct
         * @instance
         */
        NavReceiptProduct.prototype.amount = 0;

        /**
         * NavReceiptProduct discount.
         * @member {number} discount
         * @memberof billing.NavReceiptProduct
         * @instance
         */
        NavReceiptProduct.prototype.discount = 0;

        /**
         * NavReceiptProduct serviceId.
         * @member {string} serviceId
         * @memberof billing.NavReceiptProduct
         * @instance
         */
        NavReceiptProduct.prototype.serviceId = "";

        /**
         * Creates a new NavReceiptProduct instance using the specified properties.
         * @function create
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {billing.INavReceiptProduct=} [properties] Properties to set
         * @returns {billing.NavReceiptProduct} NavReceiptProduct instance
         */
        NavReceiptProduct.create = function create(properties) {
            return new NavReceiptProduct(properties);
        };

        /**
         * Encodes the specified NavReceiptProduct message. Does not implicitly {@link billing.NavReceiptProduct.verify|verify} messages.
         * @function encode
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {billing.INavReceiptProduct} message NavReceiptProduct message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NavReceiptProduct.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.unit);
            if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.quantity);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.amount);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 7, wireType 5 =*/61).float(message.discount);
            if (message.serviceId != null && Object.hasOwnProperty.call(message, "serviceId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.serviceId);
            return writer;
        };

        /**
         * Encodes the specified NavReceiptProduct message, length delimited. Does not implicitly {@link billing.NavReceiptProduct.verify|verify} messages.
         * @function encodeDelimited
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {billing.INavReceiptProduct} message NavReceiptProduct message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NavReceiptProduct.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NavReceiptProduct message from the specified reader or buffer.
         * @function decode
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {billing.NavReceiptProduct} NavReceiptProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NavReceiptProduct.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.billing.NavReceiptProduct();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2: {
                        message.description = reader.string();
                        break;
                    }
                case 3: {
                        message.unit = reader.string();
                        break;
                    }
                case 4: {
                        message.quantity = reader.float();
                        break;
                    }
                case 6: {
                        message.amount = reader.int32();
                        break;
                    }
                case 7: {
                        message.discount = reader.float();
                        break;
                    }
                case 8: {
                        message.serviceId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NavReceiptProduct message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {billing.NavReceiptProduct} NavReceiptProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NavReceiptProduct.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NavReceiptProduct message.
         * @function verify
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NavReceiptProduct.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.unit != null && message.hasOwnProperty("unit"))
                if (!$util.isString(message.unit))
                    return "unit: string expected";
            if (message.quantity != null && message.hasOwnProperty("quantity"))
                if (typeof message.quantity !== "number")
                    return "quantity: number expected";
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount))
                    return "amount: integer expected";
            if (message.discount != null && message.hasOwnProperty("discount"))
                if (typeof message.discount !== "number")
                    return "discount: number expected";
            if (message.serviceId != null && message.hasOwnProperty("serviceId"))
                if (!$util.isString(message.serviceId))
                    return "serviceId: string expected";
            return null;
        };

        /**
         * Creates a NavReceiptProduct message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {billing.NavReceiptProduct} NavReceiptProduct
         */
        NavReceiptProduct.fromObject = function fromObject(object) {
            if (object instanceof $root.billing.NavReceiptProduct)
                return object;
            let message = new $root.billing.NavReceiptProduct();
            if (object.description != null)
                message.description = String(object.description);
            if (object.unit != null)
                message.unit = String(object.unit);
            if (object.quantity != null)
                message.quantity = Number(object.quantity);
            if (object.amount != null)
                message.amount = object.amount | 0;
            if (object.discount != null)
                message.discount = Number(object.discount);
            if (object.serviceId != null)
                message.serviceId = String(object.serviceId);
            return message;
        };

        /**
         * Creates a plain object from a NavReceiptProduct message. Also converts values to other types if specified.
         * @function toObject
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {billing.NavReceiptProduct} message NavReceiptProduct
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NavReceiptProduct.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.description = "";
                object.unit = "";
                object.quantity = 0;
                object.amount = 0;
                object.discount = 0;
                object.serviceId = "";
            }
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.unit != null && message.hasOwnProperty("unit"))
                object.unit = message.unit;
            if (message.quantity != null && message.hasOwnProperty("quantity"))
                object.quantity = options.json && !isFinite(message.quantity) ? String(message.quantity) : message.quantity;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = message.amount;
            if (message.discount != null && message.hasOwnProperty("discount"))
                object.discount = options.json && !isFinite(message.discount) ? String(message.discount) : message.discount;
            if (message.serviceId != null && message.hasOwnProperty("serviceId"))
                object.serviceId = message.serviceId;
            return object;
        };

        /**
         * Converts this NavReceiptProduct to JSON.
         * @function toJSON
         * @memberof billing.NavReceiptProduct
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NavReceiptProduct.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for NavReceiptProduct
         * @function getTypeUrl
         * @memberof billing.NavReceiptProduct
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        NavReceiptProduct.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/billing.NavReceiptProduct";
        };

        return NavReceiptProduct;
    })();

    return billing;
})();

export const mladi_voznik = $root.mladi_voznik = (() => {

    /**
     * Namespace mladi_voznik.
     * @exports mladi_voznik
     * @namespace
     */
    const mladi_voznik = {};

    mladi_voznik.SaveMladiVoznikForm = (function() {

        /**
         * Properties of a SaveMladiVoznikForm.
         * @memberof mladi_voznik
         * @interface ISaveMladiVoznikForm
         * @property {mladi_voznik.IMladiVoznikForm|null} [form] SaveMladiVoznikForm form
         * @property {string|null} [validationCode] SaveMladiVoznikForm validationCode
         * @property {billing.PaymentType|null} [paymentType] SaveMladiVoznikForm paymentType
         */

        /**
         * Constructs a new SaveMladiVoznikForm.
         * @memberof mladi_voznik
         * @classdesc Represents a SaveMladiVoznikForm.
         * @implements ISaveMladiVoznikForm
         * @constructor
         * @param {mladi_voznik.ISaveMladiVoznikForm=} [properties] Properties to set
         */
        function SaveMladiVoznikForm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveMladiVoznikForm form.
         * @member {mladi_voznik.IMladiVoznikForm|null|undefined} form
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @instance
         */
        SaveMladiVoznikForm.prototype.form = null;

        /**
         * SaveMladiVoznikForm validationCode.
         * @member {string} validationCode
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @instance
         */
        SaveMladiVoznikForm.prototype.validationCode = "";

        /**
         * SaveMladiVoznikForm paymentType.
         * @member {billing.PaymentType} paymentType
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @instance
         */
        SaveMladiVoznikForm.prototype.paymentType = 0;

        /**
         * Creates a new SaveMladiVoznikForm instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {mladi_voznik.ISaveMladiVoznikForm=} [properties] Properties to set
         * @returns {mladi_voznik.SaveMladiVoznikForm} SaveMladiVoznikForm instance
         */
        SaveMladiVoznikForm.create = function create(properties) {
            return new SaveMladiVoznikForm(properties);
        };

        /**
         * Encodes the specified SaveMladiVoznikForm message. Does not implicitly {@link mladi_voznik.SaveMladiVoznikForm.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {mladi_voznik.ISaveMladiVoznikForm} message SaveMladiVoznikForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveMladiVoznikForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.form != null && Object.hasOwnProperty.call(message, "form"))
                $root.mladi_voznik.MladiVoznikForm.encode(message.form, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.validationCode != null && Object.hasOwnProperty.call(message, "validationCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.validationCode);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.paymentType);
            return writer;
        };

        /**
         * Encodes the specified SaveMladiVoznikForm message, length delimited. Does not implicitly {@link mladi_voznik.SaveMladiVoznikForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {mladi_voznik.ISaveMladiVoznikForm} message SaveMladiVoznikForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveMladiVoznikForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveMladiVoznikForm message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.SaveMladiVoznikForm} SaveMladiVoznikForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveMladiVoznikForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.SaveMladiVoznikForm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.form = $root.mladi_voznik.MladiVoznikForm.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.validationCode = reader.string();
                        break;
                    }
                case 3: {
                        message.paymentType = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveMladiVoznikForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.SaveMladiVoznikForm} SaveMladiVoznikForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveMladiVoznikForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveMladiVoznikForm message.
         * @function verify
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveMladiVoznikForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.form != null && message.hasOwnProperty("form")) {
                let error = $root.mladi_voznik.MladiVoznikForm.verify(message.form);
                if (error)
                    return "form." + error;
            }
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                if (!$util.isString(message.validationCode))
                    return "validationCode: string expected";
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                switch (message.paymentType) {
                default:
                    return "paymentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a SaveMladiVoznikForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.SaveMladiVoznikForm} SaveMladiVoznikForm
         */
        SaveMladiVoznikForm.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.SaveMladiVoznikForm)
                return object;
            let message = new $root.mladi_voznik.SaveMladiVoznikForm();
            if (object.form != null) {
                if (typeof object.form !== "object")
                    throw TypeError(".mladi_voznik.SaveMladiVoznikForm.form: object expected");
                message.form = $root.mladi_voznik.MladiVoznikForm.fromObject(object.form);
            }
            if (object.validationCode != null)
                message.validationCode = String(object.validationCode);
            switch (object.paymentType) {
            default:
                if (typeof object.paymentType === "number") {
                    message.paymentType = object.paymentType;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.paymentType = 0;
                break;
            case "darilni_bon":
            case 1:
                message.paymentType = 1;
                break;
            case "gotovina":
            case 2:
                message.paymentType = 2;
                break;
            case "predracun":
            case 3:
                message.paymentType = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a SaveMladiVoznikForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {mladi_voznik.SaveMladiVoznikForm} message SaveMladiVoznikForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveMladiVoznikForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.form = null;
                object.validationCode = "";
                object.paymentType = options.enums === String ? "kreditna" : 0;
            }
            if (message.form != null && message.hasOwnProperty("form"))
                object.form = $root.mladi_voznik.MladiVoznikForm.toObject(message.form, options);
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                object.validationCode = message.validationCode;
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = options.enums === String ? $root.billing.PaymentType[message.paymentType] === undefined ? message.paymentType : $root.billing.PaymentType[message.paymentType] : message.paymentType;
            return object;
        };

        /**
         * Converts this SaveMladiVoznikForm to JSON.
         * @function toJSON
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveMladiVoznikForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SaveMladiVoznikForm
         * @function getTypeUrl
         * @memberof mladi_voznik.SaveMladiVoznikForm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SaveMladiVoznikForm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.SaveMladiVoznikForm";
        };

        return SaveMladiVoznikForm;
    })();

    mladi_voznik.SaveMladiVoznikFormAdmin = (function() {

        /**
         * Properties of a SaveMladiVoznikFormAdmin.
         * @memberof mladi_voznik
         * @interface ISaveMladiVoznikFormAdmin
         * @property {string|null} [sessionId] SaveMladiVoznikFormAdmin sessionId
         * @property {mladi_voznik.IMladiVoznikForm|null} [form] SaveMladiVoznikFormAdmin form
         */

        /**
         * Constructs a new SaveMladiVoznikFormAdmin.
         * @memberof mladi_voznik
         * @classdesc Represents a SaveMladiVoznikFormAdmin.
         * @implements ISaveMladiVoznikFormAdmin
         * @constructor
         * @param {mladi_voznik.ISaveMladiVoznikFormAdmin=} [properties] Properties to set
         */
        function SaveMladiVoznikFormAdmin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SaveMladiVoznikFormAdmin sessionId.
         * @member {string} sessionId
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @instance
         */
        SaveMladiVoznikFormAdmin.prototype.sessionId = "";

        /**
         * SaveMladiVoznikFormAdmin form.
         * @member {mladi_voznik.IMladiVoznikForm|null|undefined} form
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @instance
         */
        SaveMladiVoznikFormAdmin.prototype.form = null;

        /**
         * Creates a new SaveMladiVoznikFormAdmin instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {mladi_voznik.ISaveMladiVoznikFormAdmin=} [properties] Properties to set
         * @returns {mladi_voznik.SaveMladiVoznikFormAdmin} SaveMladiVoznikFormAdmin instance
         */
        SaveMladiVoznikFormAdmin.create = function create(properties) {
            return new SaveMladiVoznikFormAdmin(properties);
        };

        /**
         * Encodes the specified SaveMladiVoznikFormAdmin message. Does not implicitly {@link mladi_voznik.SaveMladiVoznikFormAdmin.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {mladi_voznik.ISaveMladiVoznikFormAdmin} message SaveMladiVoznikFormAdmin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveMladiVoznikFormAdmin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.form != null && Object.hasOwnProperty.call(message, "form"))
                $root.mladi_voznik.MladiVoznikForm.encode(message.form, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SaveMladiVoznikFormAdmin message, length delimited. Does not implicitly {@link mladi_voznik.SaveMladiVoznikFormAdmin.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {mladi_voznik.ISaveMladiVoznikFormAdmin} message SaveMladiVoznikFormAdmin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SaveMladiVoznikFormAdmin.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SaveMladiVoznikFormAdmin message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.SaveMladiVoznikFormAdmin} SaveMladiVoznikFormAdmin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveMladiVoznikFormAdmin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.SaveMladiVoznikFormAdmin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.form = $root.mladi_voznik.MladiVoznikForm.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SaveMladiVoznikFormAdmin message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.SaveMladiVoznikFormAdmin} SaveMladiVoznikFormAdmin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SaveMladiVoznikFormAdmin.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SaveMladiVoznikFormAdmin message.
         * @function verify
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SaveMladiVoznikFormAdmin.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.form != null && message.hasOwnProperty("form")) {
                let error = $root.mladi_voznik.MladiVoznikForm.verify(message.form);
                if (error)
                    return "form." + error;
            }
            return null;
        };

        /**
         * Creates a SaveMladiVoznikFormAdmin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.SaveMladiVoznikFormAdmin} SaveMladiVoznikFormAdmin
         */
        SaveMladiVoznikFormAdmin.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.SaveMladiVoznikFormAdmin)
                return object;
            let message = new $root.mladi_voznik.SaveMladiVoznikFormAdmin();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.form != null) {
                if (typeof object.form !== "object")
                    throw TypeError(".mladi_voznik.SaveMladiVoznikFormAdmin.form: object expected");
                message.form = $root.mladi_voznik.MladiVoznikForm.fromObject(object.form);
            }
            return message;
        };

        /**
         * Creates a plain object from a SaveMladiVoznikFormAdmin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {mladi_voznik.SaveMladiVoznikFormAdmin} message SaveMladiVoznikFormAdmin
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SaveMladiVoznikFormAdmin.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.form = null;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.form != null && message.hasOwnProperty("form"))
                object.form = $root.mladi_voznik.MladiVoznikForm.toObject(message.form, options);
            return object;
        };

        /**
         * Converts this SaveMladiVoznikFormAdmin to JSON.
         * @function toJSON
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SaveMladiVoznikFormAdmin.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SaveMladiVoznikFormAdmin
         * @function getTypeUrl
         * @memberof mladi_voznik.SaveMladiVoznikFormAdmin
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SaveMladiVoznikFormAdmin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.SaveMladiVoznikFormAdmin";
        };

        return SaveMladiVoznikFormAdmin;
    })();

    mladi_voznik.MladiVoznikForm = (function() {

        /**
         * Properties of a MladiVoznikForm.
         * @memberof mladi_voznik
         * @interface IMladiVoznikForm
         * @property {mladi_voznik.IMladiVoznikFormS1|null} [step1] MladiVoznikForm step1
         * @property {mladi_voznik.IMladiVoznikFormS2|null} [step2] MladiVoznikForm step2
         * @property {mladi_voznik.IMladiVoznikFormS3|null} [step3] MladiVoznikForm step3
         * @property {mladi_voznik.IMladiVoznikFormS4|null} [step4] MladiVoznikForm step4
         * @property {mladi_voznik.IMladiVoznikFormS5|null} [step5] MladiVoznikForm step5
         * @property {mladi_voznik.IMladiVoznikFormS6|null} [step6] MladiVoznikForm step6
         * @property {string|null} [prijatelj] MladiVoznikForm prijatelj
         */

        /**
         * Constructs a new MladiVoznikForm.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikForm.
         * @implements IMladiVoznikForm
         * @constructor
         * @param {mladi_voznik.IMladiVoznikForm=} [properties] Properties to set
         */
        function MladiVoznikForm(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikForm step1.
         * @member {mladi_voznik.IMladiVoznikFormS1|null|undefined} step1
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.step1 = null;

        /**
         * MladiVoznikForm step2.
         * @member {mladi_voznik.IMladiVoznikFormS2|null|undefined} step2
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.step2 = null;

        /**
         * MladiVoznikForm step3.
         * @member {mladi_voznik.IMladiVoznikFormS3|null|undefined} step3
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.step3 = null;

        /**
         * MladiVoznikForm step4.
         * @member {mladi_voznik.IMladiVoznikFormS4|null|undefined} step4
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.step4 = null;

        /**
         * MladiVoznikForm step5.
         * @member {mladi_voznik.IMladiVoznikFormS5|null|undefined} step5
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.step5 = null;

        /**
         * MladiVoznikForm step6.
         * @member {mladi_voznik.IMladiVoznikFormS6|null|undefined} step6
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.step6 = null;

        /**
         * MladiVoznikForm prijatelj.
         * @member {string} prijatelj
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         */
        MladiVoznikForm.prototype.prijatelj = "";

        /**
         * Creates a new MladiVoznikForm instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {mladi_voznik.IMladiVoznikForm=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikForm} MladiVoznikForm instance
         */
        MladiVoznikForm.create = function create(properties) {
            return new MladiVoznikForm(properties);
        };

        /**
         * Encodes the specified MladiVoznikForm message. Does not implicitly {@link mladi_voznik.MladiVoznikForm.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {mladi_voznik.IMladiVoznikForm} message MladiVoznikForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.step1 != null && Object.hasOwnProperty.call(message, "step1"))
                $root.mladi_voznik.MladiVoznikFormS1.encode(message.step1, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.step2 != null && Object.hasOwnProperty.call(message, "step2"))
                $root.mladi_voznik.MladiVoznikFormS2.encode(message.step2, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.step3 != null && Object.hasOwnProperty.call(message, "step3"))
                $root.mladi_voznik.MladiVoznikFormS3.encode(message.step3, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.step4 != null && Object.hasOwnProperty.call(message, "step4"))
                $root.mladi_voznik.MladiVoznikFormS4.encode(message.step4, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.step5 != null && Object.hasOwnProperty.call(message, "step5"))
                $root.mladi_voznik.MladiVoznikFormS5.encode(message.step5, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.step6 != null && Object.hasOwnProperty.call(message, "step6"))
                $root.mladi_voznik.MladiVoznikFormS6.encode(message.step6, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.prijatelj != null && Object.hasOwnProperty.call(message, "prijatelj"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.prijatelj);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikForm message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {mladi_voznik.IMladiVoznikForm} message MladiVoznikForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikForm message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikForm} MladiVoznikForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikForm();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.step1 = $root.mladi_voznik.MladiVoznikFormS1.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.step2 = $root.mladi_voznik.MladiVoznikFormS2.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.step3 = $root.mladi_voznik.MladiVoznikFormS3.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.step4 = $root.mladi_voznik.MladiVoznikFormS4.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.step5 = $root.mladi_voznik.MladiVoznikFormS5.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.step6 = $root.mladi_voznik.MladiVoznikFormS6.decode(reader, reader.uint32());
                        break;
                    }
                case 7: {
                        message.prijatelj = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikForm} MladiVoznikForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikForm message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.step1 != null && message.hasOwnProperty("step1")) {
                let error = $root.mladi_voznik.MladiVoznikFormS1.verify(message.step1);
                if (error)
                    return "step1." + error;
            }
            if (message.step2 != null && message.hasOwnProperty("step2")) {
                let error = $root.mladi_voznik.MladiVoznikFormS2.verify(message.step2);
                if (error)
                    return "step2." + error;
            }
            if (message.step3 != null && message.hasOwnProperty("step3")) {
                let error = $root.mladi_voznik.MladiVoznikFormS3.verify(message.step3);
                if (error)
                    return "step3." + error;
            }
            if (message.step4 != null && message.hasOwnProperty("step4")) {
                let error = $root.mladi_voznik.MladiVoznikFormS4.verify(message.step4);
                if (error)
                    return "step4." + error;
            }
            if (message.step5 != null && message.hasOwnProperty("step5")) {
                let error = $root.mladi_voznik.MladiVoznikFormS5.verify(message.step5);
                if (error)
                    return "step5." + error;
            }
            if (message.step6 != null && message.hasOwnProperty("step6")) {
                let error = $root.mladi_voznik.MladiVoznikFormS6.verify(message.step6);
                if (error)
                    return "step6." + error;
            }
            if (message.prijatelj != null && message.hasOwnProperty("prijatelj"))
                if (!$util.isString(message.prijatelj))
                    return "prijatelj: string expected";
            return null;
        };

        /**
         * Creates a MladiVoznikForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikForm} MladiVoznikForm
         */
        MladiVoznikForm.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikForm)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikForm();
            if (object.step1 != null) {
                if (typeof object.step1 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikForm.step1: object expected");
                message.step1 = $root.mladi_voznik.MladiVoznikFormS1.fromObject(object.step1);
            }
            if (object.step2 != null) {
                if (typeof object.step2 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikForm.step2: object expected");
                message.step2 = $root.mladi_voznik.MladiVoznikFormS2.fromObject(object.step2);
            }
            if (object.step3 != null) {
                if (typeof object.step3 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikForm.step3: object expected");
                message.step3 = $root.mladi_voznik.MladiVoznikFormS3.fromObject(object.step3);
            }
            if (object.step4 != null) {
                if (typeof object.step4 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikForm.step4: object expected");
                message.step4 = $root.mladi_voznik.MladiVoznikFormS4.fromObject(object.step4);
            }
            if (object.step5 != null) {
                if (typeof object.step5 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikForm.step5: object expected");
                message.step5 = $root.mladi_voznik.MladiVoznikFormS5.fromObject(object.step5);
            }
            if (object.step6 != null) {
                if (typeof object.step6 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikForm.step6: object expected");
                message.step6 = $root.mladi_voznik.MladiVoznikFormS6.fromObject(object.step6);
            }
            if (object.prijatelj != null)
                message.prijatelj = String(object.prijatelj);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {mladi_voznik.MladiVoznikForm} message MladiVoznikForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.step1 = null;
                object.step2 = null;
                object.step3 = null;
                object.step4 = null;
                object.step5 = null;
                object.step6 = null;
                object.prijatelj = "";
            }
            if (message.step1 != null && message.hasOwnProperty("step1"))
                object.step1 = $root.mladi_voznik.MladiVoznikFormS1.toObject(message.step1, options);
            if (message.step2 != null && message.hasOwnProperty("step2"))
                object.step2 = $root.mladi_voznik.MladiVoznikFormS2.toObject(message.step2, options);
            if (message.step3 != null && message.hasOwnProperty("step3"))
                object.step3 = $root.mladi_voznik.MladiVoznikFormS3.toObject(message.step3, options);
            if (message.step4 != null && message.hasOwnProperty("step4"))
                object.step4 = $root.mladi_voznik.MladiVoznikFormS4.toObject(message.step4, options);
            if (message.step5 != null && message.hasOwnProperty("step5"))
                object.step5 = $root.mladi_voznik.MladiVoznikFormS5.toObject(message.step5, options);
            if (message.step6 != null && message.hasOwnProperty("step6"))
                object.step6 = $root.mladi_voznik.MladiVoznikFormS6.toObject(message.step6, options);
            if (message.prijatelj != null && message.hasOwnProperty("prijatelj"))
                object.prijatelj = message.prijatelj;
            return object;
        };

        /**
         * Converts this MladiVoznikForm to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikForm
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikForm
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikForm.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikForm";
        };

        return MladiVoznikForm;
    })();

    mladi_voznik.MladiVoznikFormS1 = (function() {

        /**
         * Properties of a MladiVoznikFormS1.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormS1
         * @property {string|null} [nameSurname] MladiVoznikFormS1 nameSurname
         * @property {cvv_types.IAddress|null} [address] MladiVoznikFormS1 address
         * @property {string|null} [email] MladiVoznikFormS1 email
         * @property {string|null} [phone] MladiVoznikFormS1 phone
         * @property {cvv_types.IAddress|null} [mailingAddress] MladiVoznikFormS1 mailingAddress
         * @property {boolean|null} [isStudent] MladiVoznikFormS1 isStudent
         */

        /**
         * Constructs a new MladiVoznikFormS1.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormS1.
         * @implements IMladiVoznikFormS1
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormS1=} [properties] Properties to set
         */
        function MladiVoznikFormS1(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormS1 nameSurname.
         * @member {string} nameSurname
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         */
        MladiVoznikFormS1.prototype.nameSurname = "";

        /**
         * MladiVoznikFormS1 address.
         * @member {cvv_types.IAddress|null|undefined} address
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         */
        MladiVoznikFormS1.prototype.address = null;

        /**
         * MladiVoznikFormS1 email.
         * @member {string} email
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         */
        MladiVoznikFormS1.prototype.email = "";

        /**
         * MladiVoznikFormS1 phone.
         * @member {string} phone
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         */
        MladiVoznikFormS1.prototype.phone = "";

        /**
         * MladiVoznikFormS1 mailingAddress.
         * @member {cvv_types.IAddress|null|undefined} mailingAddress
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         */
        MladiVoznikFormS1.prototype.mailingAddress = null;

        /**
         * MladiVoznikFormS1 isStudent.
         * @member {boolean} isStudent
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         */
        MladiVoznikFormS1.prototype.isStudent = false;

        /**
         * Creates a new MladiVoznikFormS1 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS1=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormS1} MladiVoznikFormS1 instance
         */
        MladiVoznikFormS1.create = function create(properties) {
            return new MladiVoznikFormS1(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormS1 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormS1.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS1} message MladiVoznikFormS1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nameSurname);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.cvv_types.Address.encode(message.address, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            if (message.mailingAddress != null && Object.hasOwnProperty.call(message, "mailingAddress"))
                $root.cvv_types.Address.encode(message.mailingAddress, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.isStudent != null && Object.hasOwnProperty.call(message, "isStudent"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isStudent);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormS1 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormS1.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS1} message MladiVoznikFormS1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS1.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormS1 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormS1} MladiVoznikFormS1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormS1();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nameSurname = reader.string();
                        break;
                    }
                case 2: {
                        message.address = $root.cvv_types.Address.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.email = reader.string();
                        break;
                    }
                case 4: {
                        message.phone = reader.string();
                        break;
                    }
                case 5: {
                        message.mailingAddress = $root.cvv_types.Address.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.isStudent = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormS1 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormS1} MladiVoznikFormS1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS1.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormS1 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormS1.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            if (message.address != null && message.hasOwnProperty("address")) {
                let error = $root.cvv_types.Address.verify(message.address);
                if (error)
                    return "address." + error;
            }
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress")) {
                let error = $root.cvv_types.Address.verify(message.mailingAddress);
                if (error)
                    return "mailingAddress." + error;
            }
            if (message.isStudent != null && message.hasOwnProperty("isStudent"))
                if (typeof message.isStudent !== "boolean")
                    return "isStudent: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormS1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormS1} MladiVoznikFormS1
         */
        MladiVoznikFormS1.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormS1)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormS1();
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            if (object.address != null) {
                if (typeof object.address !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS1.address: object expected");
                message.address = $root.cvv_types.Address.fromObject(object.address);
            }
            if (object.email != null)
                message.email = String(object.email);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.mailingAddress != null) {
                if (typeof object.mailingAddress !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS1.mailingAddress: object expected");
                message.mailingAddress = $root.cvv_types.Address.fromObject(object.mailingAddress);
            }
            if (object.isStudent != null)
                message.isStudent = Boolean(object.isStudent);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormS1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {mladi_voznik.MladiVoznikFormS1} message MladiVoznikFormS1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormS1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.nameSurname = "";
                object.address = null;
                object.email = "";
                object.phone = "";
                object.mailingAddress = null;
                object.isStudent = false;
            }
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = $root.cvv_types.Address.toObject(message.address, options);
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                object.mailingAddress = $root.cvv_types.Address.toObject(message.mailingAddress, options);
            if (message.isStudent != null && message.hasOwnProperty("isStudent"))
                object.isStudent = message.isStudent;
            return object;
        };

        /**
         * Converts this MladiVoznikFormS1 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormS1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormS1
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormS1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormS1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormS1";
        };

        return MladiVoznikFormS1;
    })();

    mladi_voznik.MladiVoznikFormS2 = (function() {

        /**
         * Properties of a MladiVoznikFormS2.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormS2
         * @property {calendar.IAvailableLecture|null} [date] MladiVoznikFormS2 date
         */

        /**
         * Constructs a new MladiVoznikFormS2.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormS2.
         * @implements IMladiVoznikFormS2
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormS2=} [properties] Properties to set
         */
        function MladiVoznikFormS2(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormS2 date.
         * @member {calendar.IAvailableLecture|null|undefined} date
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @instance
         */
        MladiVoznikFormS2.prototype.date = null;

        /**
         * Creates a new MladiVoznikFormS2 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS2=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormS2} MladiVoznikFormS2 instance
         */
        MladiVoznikFormS2.create = function create(properties) {
            return new MladiVoznikFormS2(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormS2 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormS2.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS2} message MladiVoznikFormS2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.calendar.AvailableLecture.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormS2 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormS2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS2} message MladiVoznikFormS2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormS2 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormS2} MladiVoznikFormS2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormS2();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.date = $root.calendar.AvailableLecture.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormS2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormS2} MladiVoznikFormS2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormS2 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormS2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.calendar.AvailableLecture.verify(message.date);
                if (error)
                    return "date." + error;
            }
            return null;
        };

        /**
         * Creates a MladiVoznikFormS2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormS2} MladiVoznikFormS2
         */
        MladiVoznikFormS2.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormS2)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormS2();
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS2.date: object expected");
                message.date = $root.calendar.AvailableLecture.fromObject(object.date);
            }
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormS2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {mladi_voznik.MladiVoznikFormS2} message MladiVoznikFormS2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormS2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.date = null;
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.calendar.AvailableLecture.toObject(message.date, options);
            return object;
        };

        /**
         * Converts this MladiVoznikFormS2 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormS2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormS2
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormS2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormS2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormS2";
        };

        return MladiVoznikFormS2;
    })();

    mladi_voznik.MladiVoznikFormS3 = (function() {

        /**
         * Properties of a MladiVoznikFormS3.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormS3
         * @property {string|null} [emso] MladiVoznikFormS3 emso
         * @property {string|null} [stVozniske] MladiVoznikFormS3 stVozniske
         * @property {cvv_types.IDate|null} [datumIzdajeVozniske] MladiVoznikFormS3 datumIzdajeVozniske
         * @property {string|null} [krajRojstva] MladiVoznikFormS3 krajRojstva
         * @property {boolean|null} [veljavnoVoznisko] MladiVoznikFormS3 veljavnoVoznisko
         * @property {cvv_types.IDate|null} [datumOpravljenegaIntenzivnegaTecaja] MladiVoznikFormS3 datumOpravljenegaIntenzivnegaTecaja
         * @property {mladi_voznik.KazenskeTockeNapotilo|null} [napotilo] MladiVoznikFormS3 napotilo
         * @property {string|null} [napotiloStevilka] MladiVoznikFormS3 napotiloStevilka
         * @property {cvv_types.IDate|null} [napotiloDatum] MladiVoznikFormS3 napotiloDatum
         * @property {string|null} [napotiloPristojnoSodisce] MladiVoznikFormS3 napotiloPristojnoSodisce
         */

        /**
         * Constructs a new MladiVoznikFormS3.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormS3.
         * @implements IMladiVoznikFormS3
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormS3=} [properties] Properties to set
         */
        function MladiVoznikFormS3(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormS3 emso.
         * @member {string} emso
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.emso = "";

        /**
         * MladiVoznikFormS3 stVozniske.
         * @member {string} stVozniske
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.stVozniske = "";

        /**
         * MladiVoznikFormS3 datumIzdajeVozniske.
         * @member {cvv_types.IDate|null|undefined} datumIzdajeVozniske
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.datumIzdajeVozniske = null;

        /**
         * MladiVoznikFormS3 krajRojstva.
         * @member {string} krajRojstva
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.krajRojstva = "";

        /**
         * MladiVoznikFormS3 veljavnoVoznisko.
         * @member {boolean} veljavnoVoznisko
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.veljavnoVoznisko = false;

        /**
         * MladiVoznikFormS3 datumOpravljenegaIntenzivnegaTecaja.
         * @member {cvv_types.IDate|null|undefined} datumOpravljenegaIntenzivnegaTecaja
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.datumOpravljenegaIntenzivnegaTecaja = null;

        /**
         * MladiVoznikFormS3 napotilo.
         * @member {mladi_voznik.KazenskeTockeNapotilo} napotilo
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.napotilo = 0;

        /**
         * MladiVoznikFormS3 napotiloStevilka.
         * @member {string} napotiloStevilka
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.napotiloStevilka = "";

        /**
         * MladiVoznikFormS3 napotiloDatum.
         * @member {cvv_types.IDate|null|undefined} napotiloDatum
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.napotiloDatum = null;

        /**
         * MladiVoznikFormS3 napotiloPristojnoSodisce.
         * @member {string} napotiloPristojnoSodisce
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         */
        MladiVoznikFormS3.prototype.napotiloPristojnoSodisce = "";

        /**
         * Creates a new MladiVoznikFormS3 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS3=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormS3} MladiVoznikFormS3 instance
         */
        MladiVoznikFormS3.create = function create(properties) {
            return new MladiVoznikFormS3(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormS3 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormS3.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS3} message MladiVoznikFormS3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS3.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emso != null && Object.hasOwnProperty.call(message, "emso"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.emso);
            if (message.stVozniske != null && Object.hasOwnProperty.call(message, "stVozniske"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.stVozniske);
            if (message.datumIzdajeVozniske != null && Object.hasOwnProperty.call(message, "datumIzdajeVozniske"))
                $root.cvv_types.Date.encode(message.datumIzdajeVozniske, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.krajRojstva != null && Object.hasOwnProperty.call(message, "krajRojstva"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.krajRojstva);
            if (message.veljavnoVoznisko != null && Object.hasOwnProperty.call(message, "veljavnoVoznisko"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.veljavnoVoznisko);
            if (message.datumOpravljenegaIntenzivnegaTecaja != null && Object.hasOwnProperty.call(message, "datumOpravljenegaIntenzivnegaTecaja"))
                $root.cvv_types.Date.encode(message.datumOpravljenegaIntenzivnegaTecaja, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.napotilo != null && Object.hasOwnProperty.call(message, "napotilo"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.napotilo);
            if (message.napotiloStevilka != null && Object.hasOwnProperty.call(message, "napotiloStevilka"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.napotiloStevilka);
            if (message.napotiloDatum != null && Object.hasOwnProperty.call(message, "napotiloDatum"))
                $root.cvv_types.Date.encode(message.napotiloDatum, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.napotiloPristojnoSodisce != null && Object.hasOwnProperty.call(message, "napotiloPristojnoSodisce"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.napotiloPristojnoSodisce);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormS3 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormS3.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS3} message MladiVoznikFormS3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS3.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormS3 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormS3} MladiVoznikFormS3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS3.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormS3();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.emso = reader.string();
                        break;
                    }
                case 2: {
                        message.stVozniske = reader.string();
                        break;
                    }
                case 3: {
                        message.datumIzdajeVozniske = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.krajRojstva = reader.string();
                        break;
                    }
                case 6: {
                        message.veljavnoVoznisko = reader.bool();
                        break;
                    }
                case 7: {
                        message.datumOpravljenegaIntenzivnegaTecaja = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.napotilo = reader.int32();
                        break;
                    }
                case 9: {
                        message.napotiloStevilka = reader.string();
                        break;
                    }
                case 10: {
                        message.napotiloDatum = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.napotiloPristojnoSodisce = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormS3 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormS3} MladiVoznikFormS3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS3.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormS3 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormS3.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emso != null && message.hasOwnProperty("emso"))
                if (!$util.isString(message.emso))
                    return "emso: string expected";
            if (message.stVozniske != null && message.hasOwnProperty("stVozniske"))
                if (!$util.isString(message.stVozniske))
                    return "stVozniske: string expected";
            if (message.datumIzdajeVozniske != null && message.hasOwnProperty("datumIzdajeVozniske")) {
                let error = $root.cvv_types.Date.verify(message.datumIzdajeVozniske);
                if (error)
                    return "datumIzdajeVozniske." + error;
            }
            if (message.krajRojstva != null && message.hasOwnProperty("krajRojstva"))
                if (!$util.isString(message.krajRojstva))
                    return "krajRojstva: string expected";
            if (message.veljavnoVoznisko != null && message.hasOwnProperty("veljavnoVoznisko"))
                if (typeof message.veljavnoVoznisko !== "boolean")
                    return "veljavnoVoznisko: boolean expected";
            if (message.datumOpravljenegaIntenzivnegaTecaja != null && message.hasOwnProperty("datumOpravljenegaIntenzivnegaTecaja")) {
                let error = $root.cvv_types.Date.verify(message.datumOpravljenegaIntenzivnegaTecaja);
                if (error)
                    return "datumOpravljenegaIntenzivnegaTecaja." + error;
            }
            if (message.napotilo != null && message.hasOwnProperty("napotilo"))
                switch (message.napotilo) {
                default:
                    return "napotilo: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.napotiloStevilka != null && message.hasOwnProperty("napotiloStevilka"))
                if (!$util.isString(message.napotiloStevilka))
                    return "napotiloStevilka: string expected";
            if (message.napotiloDatum != null && message.hasOwnProperty("napotiloDatum")) {
                let error = $root.cvv_types.Date.verify(message.napotiloDatum);
                if (error)
                    return "napotiloDatum." + error;
            }
            if (message.napotiloPristojnoSodisce != null && message.hasOwnProperty("napotiloPristojnoSodisce"))
                if (!$util.isString(message.napotiloPristojnoSodisce))
                    return "napotiloPristojnoSodisce: string expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormS3 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormS3} MladiVoznikFormS3
         */
        MladiVoznikFormS3.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormS3)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormS3();
            if (object.emso != null)
                message.emso = String(object.emso);
            if (object.stVozniske != null)
                message.stVozniske = String(object.stVozniske);
            if (object.datumIzdajeVozniske != null) {
                if (typeof object.datumIzdajeVozniske !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS3.datumIzdajeVozniske: object expected");
                message.datumIzdajeVozniske = $root.cvv_types.Date.fromObject(object.datumIzdajeVozniske);
            }
            if (object.krajRojstva != null)
                message.krajRojstva = String(object.krajRojstva);
            if (object.veljavnoVoznisko != null)
                message.veljavnoVoznisko = Boolean(object.veljavnoVoznisko);
            if (object.datumOpravljenegaIntenzivnegaTecaja != null) {
                if (typeof object.datumOpravljenegaIntenzivnegaTecaja !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS3.datumOpravljenegaIntenzivnegaTecaja: object expected");
                message.datumOpravljenegaIntenzivnegaTecaja = $root.cvv_types.Date.fromObject(object.datumOpravljenegaIntenzivnegaTecaja);
            }
            switch (object.napotilo) {
            default:
                if (typeof object.napotilo === "number") {
                    message.napotilo = object.napotilo;
                    break;
                }
                break;
            case "sodisce":
            case 0:
                message.napotilo = 0;
                break;
            case "zdravstveni_pregled":
            case 1:
                message.napotilo = 1;
                break;
            case "prostovoljno":
            case 2:
                message.napotilo = 2;
                break;
            }
            if (object.napotiloStevilka != null)
                message.napotiloStevilka = String(object.napotiloStevilka);
            if (object.napotiloDatum != null) {
                if (typeof object.napotiloDatum !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS3.napotiloDatum: object expected");
                message.napotiloDatum = $root.cvv_types.Date.fromObject(object.napotiloDatum);
            }
            if (object.napotiloPristojnoSodisce != null)
                message.napotiloPristojnoSodisce = String(object.napotiloPristojnoSodisce);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormS3 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {mladi_voznik.MladiVoznikFormS3} message MladiVoznikFormS3
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormS3.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.emso = "";
                object.stVozniske = "";
                object.datumIzdajeVozniske = null;
                object.krajRojstva = "";
                object.veljavnoVoznisko = false;
                object.datumOpravljenegaIntenzivnegaTecaja = null;
                object.napotilo = options.enums === String ? "sodisce" : 0;
                object.napotiloStevilka = "";
                object.napotiloDatum = null;
                object.napotiloPristojnoSodisce = "";
            }
            if (message.emso != null && message.hasOwnProperty("emso"))
                object.emso = message.emso;
            if (message.stVozniske != null && message.hasOwnProperty("stVozniske"))
                object.stVozniske = message.stVozniske;
            if (message.datumIzdajeVozniske != null && message.hasOwnProperty("datumIzdajeVozniske"))
                object.datumIzdajeVozniske = $root.cvv_types.Date.toObject(message.datumIzdajeVozniske, options);
            if (message.krajRojstva != null && message.hasOwnProperty("krajRojstva"))
                object.krajRojstva = message.krajRojstva;
            if (message.veljavnoVoznisko != null && message.hasOwnProperty("veljavnoVoznisko"))
                object.veljavnoVoznisko = message.veljavnoVoznisko;
            if (message.datumOpravljenegaIntenzivnegaTecaja != null && message.hasOwnProperty("datumOpravljenegaIntenzivnegaTecaja"))
                object.datumOpravljenegaIntenzivnegaTecaja = $root.cvv_types.Date.toObject(message.datumOpravljenegaIntenzivnegaTecaja, options);
            if (message.napotilo != null && message.hasOwnProperty("napotilo"))
                object.napotilo = options.enums === String ? $root.mladi_voznik.KazenskeTockeNapotilo[message.napotilo] === undefined ? message.napotilo : $root.mladi_voznik.KazenskeTockeNapotilo[message.napotilo] : message.napotilo;
            if (message.napotiloStevilka != null && message.hasOwnProperty("napotiloStevilka"))
                object.napotiloStevilka = message.napotiloStevilka;
            if (message.napotiloDatum != null && message.hasOwnProperty("napotiloDatum"))
                object.napotiloDatum = $root.cvv_types.Date.toObject(message.napotiloDatum, options);
            if (message.napotiloPristojnoSodisce != null && message.hasOwnProperty("napotiloPristojnoSodisce"))
                object.napotiloPristojnoSodisce = message.napotiloPristojnoSodisce;
            return object;
        };

        /**
         * Converts this MladiVoznikFormS3 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormS3.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormS3
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormS3
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormS3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormS3";
        };

        return MladiVoznikFormS3;
    })();

    mladi_voznik.MladiVoznikFormS4 = (function() {

        /**
         * Properties of a MladiVoznikFormS4.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormS4
         * @property {cvv_types.Vozilo|null} [vozilo] MladiVoznikFormS4 vozilo
         * @property {boolean|null} [vSkupiniSPrijateljem] MladiVoznikFormS4 vSkupiniSPrijateljem
         * @property {boolean|null} [kosilo] MladiVoznikFormS4 kosilo
         * @property {string|null} [voziloPrijateljEmail] MladiVoznikFormS4 voziloPrijateljEmail
         * @property {string|null} [skupinaPrijateljIme] MladiVoznikFormS4 skupinaPrijateljIme
         * @property {boolean|null} [clanstvo] MladiVoznikFormS4 clanstvo
         * @property {string|null} [eIzpitKoda] MladiVoznikFormS4 eIzpitKoda
         * @property {string|null} [opomba] MladiVoznikFormS4 opomba
         */

        /**
         * Constructs a new MladiVoznikFormS4.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormS4.
         * @implements IMladiVoznikFormS4
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormS4=} [properties] Properties to set
         */
        function MladiVoznikFormS4(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormS4 vozilo.
         * @member {cvv_types.Vozilo} vozilo
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.vozilo = 0;

        /**
         * MladiVoznikFormS4 vSkupiniSPrijateljem.
         * @member {boolean} vSkupiniSPrijateljem
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.vSkupiniSPrijateljem = false;

        /**
         * MladiVoznikFormS4 kosilo.
         * @member {boolean} kosilo
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.kosilo = false;

        /**
         * MladiVoznikFormS4 voziloPrijateljEmail.
         * @member {string} voziloPrijateljEmail
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.voziloPrijateljEmail = "";

        /**
         * MladiVoznikFormS4 skupinaPrijateljIme.
         * @member {string} skupinaPrijateljIme
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.skupinaPrijateljIme = "";

        /**
         * MladiVoznikFormS4 clanstvo.
         * @member {boolean} clanstvo
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.clanstvo = false;

        /**
         * MladiVoznikFormS4 eIzpitKoda.
         * @member {string} eIzpitKoda
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.eIzpitKoda = "";

        /**
         * MladiVoznikFormS4 opomba.
         * @member {string} opomba
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         */
        MladiVoznikFormS4.prototype.opomba = "";

        /**
         * Creates a new MladiVoznikFormS4 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS4=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormS4} MladiVoznikFormS4 instance
         */
        MladiVoznikFormS4.create = function create(properties) {
            return new MladiVoznikFormS4(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormS4 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormS4.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS4} message MladiVoznikFormS4 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS4.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.vozilo != null && Object.hasOwnProperty.call(message, "vozilo"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.vozilo);
            if (message.vSkupiniSPrijateljem != null && Object.hasOwnProperty.call(message, "vSkupiniSPrijateljem"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.vSkupiniSPrijateljem);
            if (message.kosilo != null && Object.hasOwnProperty.call(message, "kosilo"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.kosilo);
            if (message.voziloPrijateljEmail != null && Object.hasOwnProperty.call(message, "voziloPrijateljEmail"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.voziloPrijateljEmail);
            if (message.skupinaPrijateljIme != null && Object.hasOwnProperty.call(message, "skupinaPrijateljIme"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.skupinaPrijateljIme);
            if (message.clanstvo != null && Object.hasOwnProperty.call(message, "clanstvo"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.clanstvo);
            if (message.eIzpitKoda != null && Object.hasOwnProperty.call(message, "eIzpitKoda"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.eIzpitKoda);
            if (message.opomba != null && Object.hasOwnProperty.call(message, "opomba"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.opomba);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormS4 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormS4.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS4} message MladiVoznikFormS4 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS4.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormS4 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormS4} MladiVoznikFormS4
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS4.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormS4();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.vozilo = reader.int32();
                        break;
                    }
                case 2: {
                        message.vSkupiniSPrijateljem = reader.bool();
                        break;
                    }
                case 3: {
                        message.kosilo = reader.bool();
                        break;
                    }
                case 4: {
                        message.voziloPrijateljEmail = reader.string();
                        break;
                    }
                case 5: {
                        message.skupinaPrijateljIme = reader.string();
                        break;
                    }
                case 6: {
                        message.clanstvo = reader.bool();
                        break;
                    }
                case 7: {
                        message.eIzpitKoda = reader.string();
                        break;
                    }
                case 8: {
                        message.opomba = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormS4 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormS4} MladiVoznikFormS4
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS4.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormS4 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormS4.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.vozilo != null && message.hasOwnProperty("vozilo"))
                switch (message.vozilo) {
                default:
                    return "vozilo: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.vSkupiniSPrijateljem != null && message.hasOwnProperty("vSkupiniSPrijateljem"))
                if (typeof message.vSkupiniSPrijateljem !== "boolean")
                    return "vSkupiniSPrijateljem: boolean expected";
            if (message.kosilo != null && message.hasOwnProperty("kosilo"))
                if (typeof message.kosilo !== "boolean")
                    return "kosilo: boolean expected";
            if (message.voziloPrijateljEmail != null && message.hasOwnProperty("voziloPrijateljEmail"))
                if (!$util.isString(message.voziloPrijateljEmail))
                    return "voziloPrijateljEmail: string expected";
            if (message.skupinaPrijateljIme != null && message.hasOwnProperty("skupinaPrijateljIme"))
                if (!$util.isString(message.skupinaPrijateljIme))
                    return "skupinaPrijateljIme: string expected";
            if (message.clanstvo != null && message.hasOwnProperty("clanstvo"))
                if (typeof message.clanstvo !== "boolean")
                    return "clanstvo: boolean expected";
            if (message.eIzpitKoda != null && message.hasOwnProperty("eIzpitKoda"))
                if (!$util.isString(message.eIzpitKoda))
                    return "eIzpitKoda: string expected";
            if (message.opomba != null && message.hasOwnProperty("opomba"))
                if (!$util.isString(message.opomba))
                    return "opomba: string expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormS4 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormS4} MladiVoznikFormS4
         */
        MladiVoznikFormS4.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormS4)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormS4();
            switch (object.vozilo) {
            default:
                if (typeof object.vozilo === "number") {
                    message.vozilo = object.vozilo;
                    break;
                }
                break;
            case "svoje":
            case 0:
                message.vozilo = 0;
                break;
            case "najeto":
            case 1:
                message.vozilo = 1;
                break;
            case "najeto_prijatelj":
            case 2:
                message.vozilo = 2;
                break;
            case "najeto_druga_oseba":
            case 3:
                message.vozilo = 3;
                break;
            }
            if (object.vSkupiniSPrijateljem != null)
                message.vSkupiniSPrijateljem = Boolean(object.vSkupiniSPrijateljem);
            if (object.kosilo != null)
                message.kosilo = Boolean(object.kosilo);
            if (object.voziloPrijateljEmail != null)
                message.voziloPrijateljEmail = String(object.voziloPrijateljEmail);
            if (object.skupinaPrijateljIme != null)
                message.skupinaPrijateljIme = String(object.skupinaPrijateljIme);
            if (object.clanstvo != null)
                message.clanstvo = Boolean(object.clanstvo);
            if (object.eIzpitKoda != null)
                message.eIzpitKoda = String(object.eIzpitKoda);
            if (object.opomba != null)
                message.opomba = String(object.opomba);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormS4 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {mladi_voznik.MladiVoznikFormS4} message MladiVoznikFormS4
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormS4.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.vozilo = options.enums === String ? "svoje" : 0;
                object.vSkupiniSPrijateljem = false;
                object.kosilo = false;
                object.voziloPrijateljEmail = "";
                object.skupinaPrijateljIme = "";
                object.clanstvo = false;
                object.eIzpitKoda = "";
                object.opomba = "";
            }
            if (message.vozilo != null && message.hasOwnProperty("vozilo"))
                object.vozilo = options.enums === String ? $root.cvv_types.Vozilo[message.vozilo] === undefined ? message.vozilo : $root.cvv_types.Vozilo[message.vozilo] : message.vozilo;
            if (message.vSkupiniSPrijateljem != null && message.hasOwnProperty("vSkupiniSPrijateljem"))
                object.vSkupiniSPrijateljem = message.vSkupiniSPrijateljem;
            if (message.kosilo != null && message.hasOwnProperty("kosilo"))
                object.kosilo = message.kosilo;
            if (message.voziloPrijateljEmail != null && message.hasOwnProperty("voziloPrijateljEmail"))
                object.voziloPrijateljEmail = message.voziloPrijateljEmail;
            if (message.skupinaPrijateljIme != null && message.hasOwnProperty("skupinaPrijateljIme"))
                object.skupinaPrijateljIme = message.skupinaPrijateljIme;
            if (message.clanstvo != null && message.hasOwnProperty("clanstvo"))
                object.clanstvo = message.clanstvo;
            if (message.eIzpitKoda != null && message.hasOwnProperty("eIzpitKoda"))
                object.eIzpitKoda = message.eIzpitKoda;
            if (message.opomba != null && message.hasOwnProperty("opomba"))
                object.opomba = message.opomba;
            return object;
        };

        /**
         * Converts this MladiVoznikFormS4 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormS4.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormS4
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormS4
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormS4.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormS4";
        };

        return MladiVoznikFormS4;
    })();

    mladi_voznik.MladiVoznikFormS5 = (function() {

        /**
         * Properties of a MladiVoznikFormS5.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormS5
         * @property {Object.<string,boolean>|null} [agreements] MladiVoznikFormS5 agreements
         */

        /**
         * Constructs a new MladiVoznikFormS5.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormS5.
         * @implements IMladiVoznikFormS5
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormS5=} [properties] Properties to set
         */
        function MladiVoznikFormS5(properties) {
            this.agreements = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormS5 agreements.
         * @member {Object.<string,boolean>} agreements
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @instance
         */
        MladiVoznikFormS5.prototype.agreements = $util.emptyObject;

        /**
         * Creates a new MladiVoznikFormS5 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS5=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormS5} MladiVoznikFormS5 instance
         */
        MladiVoznikFormS5.create = function create(properties) {
            return new MladiVoznikFormS5(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormS5 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormS5.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS5} message MladiVoznikFormS5 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS5.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.agreements != null && Object.hasOwnProperty.call(message, "agreements"))
                for (let keys = Object.keys(message.agreements), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 0 =*/16).bool(message.agreements[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormS5 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormS5.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS5} message MladiVoznikFormS5 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS5.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormS5 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormS5} MladiVoznikFormS5
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS5.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormS5(), key, value;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (message.agreements === $util.emptyObject)
                            message.agreements = {};
                        let end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = false;
                        while (reader.pos < end2) {
                            let tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = reader.bool();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.agreements[key] = value;
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormS5 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormS5} MladiVoznikFormS5
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS5.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormS5 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormS5.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.agreements != null && message.hasOwnProperty("agreements")) {
                if (!$util.isObject(message.agreements))
                    return "agreements: object expected";
                let key = Object.keys(message.agreements);
                for (let i = 0; i < key.length; ++i)
                    if (typeof message.agreements[key[i]] !== "boolean")
                        return "agreements: boolean{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a MladiVoznikFormS5 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormS5} MladiVoznikFormS5
         */
        MladiVoznikFormS5.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormS5)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormS5();
            if (object.agreements) {
                if (typeof object.agreements !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormS5.agreements: object expected");
                message.agreements = {};
                for (let keys = Object.keys(object.agreements), i = 0; i < keys.length; ++i)
                    message.agreements[keys[i]] = Boolean(object.agreements[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormS5 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {mladi_voznik.MladiVoznikFormS5} message MladiVoznikFormS5
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormS5.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.agreements = {};
            let keys2;
            if (message.agreements && (keys2 = Object.keys(message.agreements)).length) {
                object.agreements = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.agreements[keys2[j]] = message.agreements[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this MladiVoznikFormS5 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormS5.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormS5
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormS5
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormS5.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormS5";
        };

        return MladiVoznikFormS5;
    })();

    mladi_voznik.MladiVoznikFormS6 = (function() {

        /**
         * Properties of a MladiVoznikFormS6.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormS6
         * @property {string|null} [kjeSteSlisaliZaNas] MladiVoznikFormS6 kjeSteSlisaliZaNas
         * @property {string|null} [promoCode] MladiVoznikFormS6 promoCode
         */

        /**
         * Constructs a new MladiVoznikFormS6.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormS6.
         * @implements IMladiVoznikFormS6
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormS6=} [properties] Properties to set
         */
        function MladiVoznikFormS6(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormS6 kjeSteSlisaliZaNas.
         * @member {string} kjeSteSlisaliZaNas
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @instance
         */
        MladiVoznikFormS6.prototype.kjeSteSlisaliZaNas = "";

        /**
         * MladiVoznikFormS6 promoCode.
         * @member {string} promoCode
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @instance
         */
        MladiVoznikFormS6.prototype.promoCode = "";

        /**
         * Creates a new MladiVoznikFormS6 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS6=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormS6} MladiVoznikFormS6 instance
         */
        MladiVoznikFormS6.create = function create(properties) {
            return new MladiVoznikFormS6(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormS6 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormS6.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS6} message MladiVoznikFormS6 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS6.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.kjeSteSlisaliZaNas != null && Object.hasOwnProperty.call(message, "kjeSteSlisaliZaNas"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.kjeSteSlisaliZaNas);
            if (message.promoCode != null && Object.hasOwnProperty.call(message, "promoCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.promoCode);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormS6 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormS6.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {mladi_voznik.IMladiVoznikFormS6} message MladiVoznikFormS6 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormS6.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormS6 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormS6} MladiVoznikFormS6
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS6.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormS6();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.kjeSteSlisaliZaNas = reader.string();
                        break;
                    }
                case 2: {
                        message.promoCode = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormS6 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormS6} MladiVoznikFormS6
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormS6.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormS6 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormS6.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.kjeSteSlisaliZaNas != null && message.hasOwnProperty("kjeSteSlisaliZaNas"))
                if (!$util.isString(message.kjeSteSlisaliZaNas))
                    return "kjeSteSlisaliZaNas: string expected";
            if (message.promoCode != null && message.hasOwnProperty("promoCode"))
                if (!$util.isString(message.promoCode))
                    return "promoCode: string expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormS6 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormS6} MladiVoznikFormS6
         */
        MladiVoznikFormS6.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormS6)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormS6();
            if (object.kjeSteSlisaliZaNas != null)
                message.kjeSteSlisaliZaNas = String(object.kjeSteSlisaliZaNas);
            if (object.promoCode != null)
                message.promoCode = String(object.promoCode);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormS6 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {mladi_voznik.MladiVoznikFormS6} message MladiVoznikFormS6
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormS6.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.kjeSteSlisaliZaNas = "";
                object.promoCode = "";
            }
            if (message.kjeSteSlisaliZaNas != null && message.hasOwnProperty("kjeSteSlisaliZaNas"))
                object.kjeSteSlisaliZaNas = message.kjeSteSlisaliZaNas;
            if (message.promoCode != null && message.hasOwnProperty("promoCode"))
                object.promoCode = message.promoCode;
            return object;
        };

        /**
         * Converts this MladiVoznikFormS6 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormS6.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormS6
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormS6
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormS6.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormS6";
        };

        return MladiVoznikFormS6;
    })();

    mladi_voznik.MladiVoznikFormConfig = (function() {

        /**
         * Properties of a MladiVoznikFormConfig.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfig
         * @property {mladi_voznik.IMladiVoznikFormConfigS1|null} [s1] MladiVoznikFormConfig s1
         * @property {mladi_voznik.IMladiVoznikFormConfigS2|null} [s2] MladiVoznikFormConfig s2
         * @property {mladi_voznik.IMladiVoznikFormConfigS3|null} [s3] MladiVoznikFormConfig s3
         * @property {mladi_voznik.IMladiVoznikFormConfigS4|null} [s4] MladiVoznikFormConfig s4
         * @property {mladi_voznik.IMladiVoznikFormConfigS5|null} [s5] MladiVoznikFormConfig s5
         * @property {mladi_voznik.IMladiVoznikFormConfigS6|null} [s6] MladiVoznikFormConfig s6
         */

        /**
         * Constructs a new MladiVoznikFormConfig.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfig.
         * @implements IMladiVoznikFormConfig
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfig=} [properties] Properties to set
         */
        function MladiVoznikFormConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfig s1.
         * @member {mladi_voznik.IMladiVoznikFormConfigS1|null|undefined} s1
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         */
        MladiVoznikFormConfig.prototype.s1 = null;

        /**
         * MladiVoznikFormConfig s2.
         * @member {mladi_voznik.IMladiVoznikFormConfigS2|null|undefined} s2
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         */
        MladiVoznikFormConfig.prototype.s2 = null;

        /**
         * MladiVoznikFormConfig s3.
         * @member {mladi_voznik.IMladiVoznikFormConfigS3|null|undefined} s3
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         */
        MladiVoznikFormConfig.prototype.s3 = null;

        /**
         * MladiVoznikFormConfig s4.
         * @member {mladi_voznik.IMladiVoznikFormConfigS4|null|undefined} s4
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         */
        MladiVoznikFormConfig.prototype.s4 = null;

        /**
         * MladiVoznikFormConfig s5.
         * @member {mladi_voznik.IMladiVoznikFormConfigS5|null|undefined} s5
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         */
        MladiVoznikFormConfig.prototype.s5 = null;

        /**
         * MladiVoznikFormConfig s6.
         * @member {mladi_voznik.IMladiVoznikFormConfigS6|null|undefined} s6
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         */
        MladiVoznikFormConfig.prototype.s6 = null;

        /**
         * Creates a new MladiVoznikFormConfig instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfig=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfig} MladiVoznikFormConfig instance
         */
        MladiVoznikFormConfig.create = function create(properties) {
            return new MladiVoznikFormConfig(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfig message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfig.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfig} message MladiVoznikFormConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.s1 != null && Object.hasOwnProperty.call(message, "s1"))
                $root.mladi_voznik.MladiVoznikFormConfigS1.encode(message.s1, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.s2 != null && Object.hasOwnProperty.call(message, "s2"))
                $root.mladi_voznik.MladiVoznikFormConfigS2.encode(message.s2, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.s3 != null && Object.hasOwnProperty.call(message, "s3"))
                $root.mladi_voznik.MladiVoznikFormConfigS3.encode(message.s3, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.s4 != null && Object.hasOwnProperty.call(message, "s4"))
                $root.mladi_voznik.MladiVoznikFormConfigS4.encode(message.s4, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.s5 != null && Object.hasOwnProperty.call(message, "s5"))
                $root.mladi_voznik.MladiVoznikFormConfigS5.encode(message.s5, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.s6 != null && Object.hasOwnProperty.call(message, "s6"))
                $root.mladi_voznik.MladiVoznikFormConfigS6.encode(message.s6, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfig message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfig} message MladiVoznikFormConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfig message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfig} MladiVoznikFormConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.s1 = $root.mladi_voznik.MladiVoznikFormConfigS1.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.s2 = $root.mladi_voznik.MladiVoznikFormConfigS2.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.s3 = $root.mladi_voznik.MladiVoznikFormConfigS3.decode(reader, reader.uint32());
                        break;
                    }
                case 4: {
                        message.s4 = $root.mladi_voznik.MladiVoznikFormConfigS4.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.s5 = $root.mladi_voznik.MladiVoznikFormConfigS5.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.s6 = $root.mladi_voznik.MladiVoznikFormConfigS6.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfig} MladiVoznikFormConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfig message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.s1 != null && message.hasOwnProperty("s1")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfigS1.verify(message.s1);
                if (error)
                    return "s1." + error;
            }
            if (message.s2 != null && message.hasOwnProperty("s2")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfigS2.verify(message.s2);
                if (error)
                    return "s2." + error;
            }
            if (message.s3 != null && message.hasOwnProperty("s3")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfigS3.verify(message.s3);
                if (error)
                    return "s3." + error;
            }
            if (message.s4 != null && message.hasOwnProperty("s4")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfigS4.verify(message.s4);
                if (error)
                    return "s4." + error;
            }
            if (message.s5 != null && message.hasOwnProperty("s5")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfigS5.verify(message.s5);
                if (error)
                    return "s5." + error;
            }
            if (message.s6 != null && message.hasOwnProperty("s6")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfigS6.verify(message.s6);
                if (error)
                    return "s6." + error;
            }
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfig} MladiVoznikFormConfig
         */
        MladiVoznikFormConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfig)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfig();
            if (object.s1 != null) {
                if (typeof object.s1 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormConfig.s1: object expected");
                message.s1 = $root.mladi_voznik.MladiVoznikFormConfigS1.fromObject(object.s1);
            }
            if (object.s2 != null) {
                if (typeof object.s2 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormConfig.s2: object expected");
                message.s2 = $root.mladi_voznik.MladiVoznikFormConfigS2.fromObject(object.s2);
            }
            if (object.s3 != null) {
                if (typeof object.s3 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormConfig.s3: object expected");
                message.s3 = $root.mladi_voznik.MladiVoznikFormConfigS3.fromObject(object.s3);
            }
            if (object.s4 != null) {
                if (typeof object.s4 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormConfig.s4: object expected");
                message.s4 = $root.mladi_voznik.MladiVoznikFormConfigS4.fromObject(object.s4);
            }
            if (object.s5 != null) {
                if (typeof object.s5 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormConfig.s5: object expected");
                message.s5 = $root.mladi_voznik.MladiVoznikFormConfigS5.fromObject(object.s5);
            }
            if (object.s6 != null) {
                if (typeof object.s6 !== "object")
                    throw TypeError(".mladi_voznik.MladiVoznikFormConfig.s6: object expected");
                message.s6 = $root.mladi_voznik.MladiVoznikFormConfigS6.fromObject(object.s6);
            }
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfig} message MladiVoznikFormConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.s1 = null;
                object.s2 = null;
                object.s3 = null;
                object.s4 = null;
                object.s5 = null;
                object.s6 = null;
            }
            if (message.s1 != null && message.hasOwnProperty("s1"))
                object.s1 = $root.mladi_voznik.MladiVoznikFormConfigS1.toObject(message.s1, options);
            if (message.s2 != null && message.hasOwnProperty("s2"))
                object.s2 = $root.mladi_voznik.MladiVoznikFormConfigS2.toObject(message.s2, options);
            if (message.s3 != null && message.hasOwnProperty("s3"))
                object.s3 = $root.mladi_voznik.MladiVoznikFormConfigS3.toObject(message.s3, options);
            if (message.s4 != null && message.hasOwnProperty("s4"))
                object.s4 = $root.mladi_voznik.MladiVoznikFormConfigS4.toObject(message.s4, options);
            if (message.s5 != null && message.hasOwnProperty("s5"))
                object.s5 = $root.mladi_voznik.MladiVoznikFormConfigS5.toObject(message.s5, options);
            if (message.s6 != null && message.hasOwnProperty("s6"))
                object.s6 = $root.mladi_voznik.MladiVoznikFormConfigS6.toObject(message.s6, options);
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfig to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfig
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfig
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfig";
        };

        return MladiVoznikFormConfig;
    })();

    mladi_voznik.MladiVoznikFormConfigS1 = (function() {

        /**
         * Properties of a MladiVoznikFormConfigS1.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfigS1
         * @property {boolean|null} [hide] MladiVoznikFormConfigS1 hide
         * @property {boolean|null} [nameSurname] MladiVoznikFormConfigS1 nameSurname
         * @property {boolean|null} [address] MladiVoznikFormConfigS1 address
         * @property {boolean|null} [mailingAddress] MladiVoznikFormConfigS1 mailingAddress
         * @property {boolean|null} [email] MladiVoznikFormConfigS1 email
         * @property {boolean|null} [phone] MladiVoznikFormConfigS1 phone
         * @property {boolean|null} [isStudent] MladiVoznikFormConfigS1 isStudent
         */

        /**
         * Constructs a new MladiVoznikFormConfigS1.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfigS1.
         * @implements IMladiVoznikFormConfigS1
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfigS1=} [properties] Properties to set
         */
        function MladiVoznikFormConfigS1(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfigS1 hide.
         * @member {boolean} hide
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.hide = false;

        /**
         * MladiVoznikFormConfigS1 nameSurname.
         * @member {boolean} nameSurname
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.nameSurname = false;

        /**
         * MladiVoznikFormConfigS1 address.
         * @member {boolean} address
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.address = false;

        /**
         * MladiVoznikFormConfigS1 mailingAddress.
         * @member {boolean} mailingAddress
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.mailingAddress = false;

        /**
         * MladiVoznikFormConfigS1 email.
         * @member {boolean} email
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.email = false;

        /**
         * MladiVoznikFormConfigS1 phone.
         * @member {boolean} phone
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.phone = false;

        /**
         * MladiVoznikFormConfigS1 isStudent.
         * @member {boolean} isStudent
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         */
        MladiVoznikFormConfigS1.prototype.isStudent = false;

        /**
         * Creates a new MladiVoznikFormConfigS1 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS1=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfigS1} MladiVoznikFormConfigS1 instance
         */
        MladiVoznikFormConfigS1.create = function create(properties) {
            return new MladiVoznikFormConfigS1(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS1 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS1.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS1} message MladiVoznikFormConfigS1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS1.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hide != null && Object.hasOwnProperty.call(message, "hide"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hide);
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.nameSurname);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.address);
            if (message.mailingAddress != null && Object.hasOwnProperty.call(message, "mailingAddress"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.mailingAddress);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.phone);
            if (message.isStudent != null && Object.hasOwnProperty.call(message, "isStudent"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isStudent);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS1 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS1.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS1} message MladiVoznikFormConfigS1 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS1.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfigS1 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfigS1} MladiVoznikFormConfigS1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS1.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfigS1();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hide = reader.bool();
                        break;
                    }
                case 2: {
                        message.nameSurname = reader.bool();
                        break;
                    }
                case 3: {
                        message.address = reader.bool();
                        break;
                    }
                case 4: {
                        message.mailingAddress = reader.bool();
                        break;
                    }
                case 5: {
                        message.email = reader.bool();
                        break;
                    }
                case 6: {
                        message.phone = reader.bool();
                        break;
                    }
                case 7: {
                        message.isStudent = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfigS1 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfigS1} MladiVoznikFormConfigS1
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS1.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfigS1 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfigS1.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hide != null && message.hasOwnProperty("hide"))
                if (typeof message.hide !== "boolean")
                    return "hide: boolean expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (typeof message.nameSurname !== "boolean")
                    return "nameSurname: boolean expected";
            if (message.address != null && message.hasOwnProperty("address"))
                if (typeof message.address !== "boolean")
                    return "address: boolean expected";
            if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                if (typeof message.mailingAddress !== "boolean")
                    return "mailingAddress: boolean expected";
            if (message.email != null && message.hasOwnProperty("email"))
                if (typeof message.email !== "boolean")
                    return "email: boolean expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (typeof message.phone !== "boolean")
                    return "phone: boolean expected";
            if (message.isStudent != null && message.hasOwnProperty("isStudent"))
                if (typeof message.isStudent !== "boolean")
                    return "isStudent: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfigS1 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfigS1} MladiVoznikFormConfigS1
         */
        MladiVoznikFormConfigS1.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfigS1)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfigS1();
            if (object.hide != null)
                message.hide = Boolean(object.hide);
            if (object.nameSurname != null)
                message.nameSurname = Boolean(object.nameSurname);
            if (object.address != null)
                message.address = Boolean(object.address);
            if (object.mailingAddress != null)
                message.mailingAddress = Boolean(object.mailingAddress);
            if (object.email != null)
                message.email = Boolean(object.email);
            if (object.phone != null)
                message.phone = Boolean(object.phone);
            if (object.isStudent != null)
                message.isStudent = Boolean(object.isStudent);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfigS1 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfigS1} message MladiVoznikFormConfigS1
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfigS1.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hide = false;
                object.nameSurname = false;
                object.address = false;
                object.mailingAddress = false;
                object.email = false;
                object.phone = false;
                object.isStudent = false;
            }
            if (message.hide != null && message.hasOwnProperty("hide"))
                object.hide = message.hide;
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = message.address;
            if (message.mailingAddress != null && message.hasOwnProperty("mailingAddress"))
                object.mailingAddress = message.mailingAddress;
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.isStudent != null && message.hasOwnProperty("isStudent"))
                object.isStudent = message.isStudent;
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfigS1 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfigS1.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfigS1
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfigS1
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfigS1.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfigS1";
        };

        return MladiVoznikFormConfigS1;
    })();

    mladi_voznik.MladiVoznikFormConfigS2 = (function() {

        /**
         * Properties of a MladiVoznikFormConfigS2.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfigS2
         * @property {boolean|null} [hide] MladiVoznikFormConfigS2 hide
         */

        /**
         * Constructs a new MladiVoznikFormConfigS2.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfigS2.
         * @implements IMladiVoznikFormConfigS2
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfigS2=} [properties] Properties to set
         */
        function MladiVoznikFormConfigS2(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfigS2 hide.
         * @member {boolean} hide
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @instance
         */
        MladiVoznikFormConfigS2.prototype.hide = false;

        /**
         * Creates a new MladiVoznikFormConfigS2 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS2=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfigS2} MladiVoznikFormConfigS2 instance
         */
        MladiVoznikFormConfigS2.create = function create(properties) {
            return new MladiVoznikFormConfigS2(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS2 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS2.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS2} message MladiVoznikFormConfigS2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS2.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hide != null && Object.hasOwnProperty.call(message, "hide"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hide);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS2 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS2.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS2} message MladiVoznikFormConfigS2 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS2.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfigS2 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfigS2} MladiVoznikFormConfigS2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS2.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfigS2();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hide = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfigS2 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfigS2} MladiVoznikFormConfigS2
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS2.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfigS2 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfigS2.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hide != null && message.hasOwnProperty("hide"))
                if (typeof message.hide !== "boolean")
                    return "hide: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfigS2 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfigS2} MladiVoznikFormConfigS2
         */
        MladiVoznikFormConfigS2.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfigS2)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfigS2();
            if (object.hide != null)
                message.hide = Boolean(object.hide);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfigS2 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfigS2} message MladiVoznikFormConfigS2
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfigS2.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.hide = false;
            if (message.hide != null && message.hasOwnProperty("hide"))
                object.hide = message.hide;
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfigS2 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfigS2.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfigS2
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfigS2
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfigS2.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfigS2";
        };

        return MladiVoznikFormConfigS2;
    })();

    mladi_voznik.MladiVoznikFormConfigS3 = (function() {

        /**
         * Properties of a MladiVoznikFormConfigS3.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfigS3
         * @property {boolean|null} [hide] MladiVoznikFormConfigS3 hide
         * @property {boolean|null} [emso] MladiVoznikFormConfigS3 emso
         * @property {boolean|null} [stVozniske] MladiVoznikFormConfigS3 stVozniske
         * @property {boolean|null} [datumIzdajeVozniske] MladiVoznikFormConfigS3 datumIzdajeVozniske
         * @property {boolean|null} [krajRojstva] MladiVoznikFormConfigS3 krajRojstva
         * @property {boolean|null} [veljavnoVoznisko] MladiVoznikFormConfigS3 veljavnoVoznisko
         * @property {boolean|null} [datumOpravljenegaIntenzivnegaTecaja] MladiVoznikFormConfigS3 datumOpravljenegaIntenzivnegaTecaja
         * @property {boolean|null} [veljavnoVozniskoKazenskeTocke] MladiVoznikFormConfigS3 veljavnoVozniskoKazenskeTocke
         */

        /**
         * Constructs a new MladiVoznikFormConfigS3.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfigS3.
         * @implements IMladiVoznikFormConfigS3
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfigS3=} [properties] Properties to set
         */
        function MladiVoznikFormConfigS3(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfigS3 hide.
         * @member {boolean} hide
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.hide = false;

        /**
         * MladiVoznikFormConfigS3 emso.
         * @member {boolean} emso
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.emso = false;

        /**
         * MladiVoznikFormConfigS3 stVozniske.
         * @member {boolean} stVozniske
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.stVozniske = false;

        /**
         * MladiVoznikFormConfigS3 datumIzdajeVozniske.
         * @member {boolean} datumIzdajeVozniske
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.datumIzdajeVozniske = false;

        /**
         * MladiVoznikFormConfigS3 krajRojstva.
         * @member {boolean} krajRojstva
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.krajRojstva = false;

        /**
         * MladiVoznikFormConfigS3 veljavnoVoznisko.
         * @member {boolean} veljavnoVoznisko
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.veljavnoVoznisko = false;

        /**
         * MladiVoznikFormConfigS3 datumOpravljenegaIntenzivnegaTecaja.
         * @member {boolean} datumOpravljenegaIntenzivnegaTecaja
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.datumOpravljenegaIntenzivnegaTecaja = false;

        /**
         * MladiVoznikFormConfigS3 veljavnoVozniskoKazenskeTocke.
         * @member {boolean} veljavnoVozniskoKazenskeTocke
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         */
        MladiVoznikFormConfigS3.prototype.veljavnoVozniskoKazenskeTocke = false;

        /**
         * Creates a new MladiVoznikFormConfigS3 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS3=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfigS3} MladiVoznikFormConfigS3 instance
         */
        MladiVoznikFormConfigS3.create = function create(properties) {
            return new MladiVoznikFormConfigS3(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS3 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS3.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS3} message MladiVoznikFormConfigS3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS3.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hide != null && Object.hasOwnProperty.call(message, "hide"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hide);
            if (message.emso != null && Object.hasOwnProperty.call(message, "emso"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.emso);
            if (message.stVozniske != null && Object.hasOwnProperty.call(message, "stVozniske"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.stVozniske);
            if (message.datumIzdajeVozniske != null && Object.hasOwnProperty.call(message, "datumIzdajeVozniske"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.datumIzdajeVozniske);
            if (message.krajRojstva != null && Object.hasOwnProperty.call(message, "krajRojstva"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.krajRojstva);
            if (message.veljavnoVoznisko != null && Object.hasOwnProperty.call(message, "veljavnoVoznisko"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.veljavnoVoznisko);
            if (message.datumOpravljenegaIntenzivnegaTecaja != null && Object.hasOwnProperty.call(message, "datumOpravljenegaIntenzivnegaTecaja"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.datumOpravljenegaIntenzivnegaTecaja);
            if (message.veljavnoVozniskoKazenskeTocke != null && Object.hasOwnProperty.call(message, "veljavnoVozniskoKazenskeTocke"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.veljavnoVozniskoKazenskeTocke);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS3 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS3.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS3} message MladiVoznikFormConfigS3 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS3.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfigS3 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfigS3} MladiVoznikFormConfigS3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS3.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfigS3();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hide = reader.bool();
                        break;
                    }
                case 2: {
                        message.emso = reader.bool();
                        break;
                    }
                case 3: {
                        message.stVozniske = reader.bool();
                        break;
                    }
                case 4: {
                        message.datumIzdajeVozniske = reader.bool();
                        break;
                    }
                case 6: {
                        message.krajRojstva = reader.bool();
                        break;
                    }
                case 7: {
                        message.veljavnoVoznisko = reader.bool();
                        break;
                    }
                case 8: {
                        message.datumOpravljenegaIntenzivnegaTecaja = reader.bool();
                        break;
                    }
                case 9: {
                        message.veljavnoVozniskoKazenskeTocke = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfigS3 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfigS3} MladiVoznikFormConfigS3
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS3.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfigS3 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfigS3.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hide != null && message.hasOwnProperty("hide"))
                if (typeof message.hide !== "boolean")
                    return "hide: boolean expected";
            if (message.emso != null && message.hasOwnProperty("emso"))
                if (typeof message.emso !== "boolean")
                    return "emso: boolean expected";
            if (message.stVozniske != null && message.hasOwnProperty("stVozniske"))
                if (typeof message.stVozniske !== "boolean")
                    return "stVozniske: boolean expected";
            if (message.datumIzdajeVozniske != null && message.hasOwnProperty("datumIzdajeVozniske"))
                if (typeof message.datumIzdajeVozniske !== "boolean")
                    return "datumIzdajeVozniske: boolean expected";
            if (message.krajRojstva != null && message.hasOwnProperty("krajRojstva"))
                if (typeof message.krajRojstva !== "boolean")
                    return "krajRojstva: boolean expected";
            if (message.veljavnoVoznisko != null && message.hasOwnProperty("veljavnoVoznisko"))
                if (typeof message.veljavnoVoznisko !== "boolean")
                    return "veljavnoVoznisko: boolean expected";
            if (message.datumOpravljenegaIntenzivnegaTecaja != null && message.hasOwnProperty("datumOpravljenegaIntenzivnegaTecaja"))
                if (typeof message.datumOpravljenegaIntenzivnegaTecaja !== "boolean")
                    return "datumOpravljenegaIntenzivnegaTecaja: boolean expected";
            if (message.veljavnoVozniskoKazenskeTocke != null && message.hasOwnProperty("veljavnoVozniskoKazenskeTocke"))
                if (typeof message.veljavnoVozniskoKazenskeTocke !== "boolean")
                    return "veljavnoVozniskoKazenskeTocke: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfigS3 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfigS3} MladiVoznikFormConfigS3
         */
        MladiVoznikFormConfigS3.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfigS3)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfigS3();
            if (object.hide != null)
                message.hide = Boolean(object.hide);
            if (object.emso != null)
                message.emso = Boolean(object.emso);
            if (object.stVozniske != null)
                message.stVozniske = Boolean(object.stVozniske);
            if (object.datumIzdajeVozniske != null)
                message.datumIzdajeVozniske = Boolean(object.datumIzdajeVozniske);
            if (object.krajRojstva != null)
                message.krajRojstva = Boolean(object.krajRojstva);
            if (object.veljavnoVoznisko != null)
                message.veljavnoVoznisko = Boolean(object.veljavnoVoznisko);
            if (object.datumOpravljenegaIntenzivnegaTecaja != null)
                message.datumOpravljenegaIntenzivnegaTecaja = Boolean(object.datumOpravljenegaIntenzivnegaTecaja);
            if (object.veljavnoVozniskoKazenskeTocke != null)
                message.veljavnoVozniskoKazenskeTocke = Boolean(object.veljavnoVozniskoKazenskeTocke);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfigS3 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfigS3} message MladiVoznikFormConfigS3
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfigS3.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hide = false;
                object.emso = false;
                object.stVozniske = false;
                object.datumIzdajeVozniske = false;
                object.krajRojstva = false;
                object.veljavnoVoznisko = false;
                object.datumOpravljenegaIntenzivnegaTecaja = false;
                object.veljavnoVozniskoKazenskeTocke = false;
            }
            if (message.hide != null && message.hasOwnProperty("hide"))
                object.hide = message.hide;
            if (message.emso != null && message.hasOwnProperty("emso"))
                object.emso = message.emso;
            if (message.stVozniske != null && message.hasOwnProperty("stVozniske"))
                object.stVozniske = message.stVozniske;
            if (message.datumIzdajeVozniske != null && message.hasOwnProperty("datumIzdajeVozniske"))
                object.datumIzdajeVozniske = message.datumIzdajeVozniske;
            if (message.krajRojstva != null && message.hasOwnProperty("krajRojstva"))
                object.krajRojstva = message.krajRojstva;
            if (message.veljavnoVoznisko != null && message.hasOwnProperty("veljavnoVoznisko"))
                object.veljavnoVoznisko = message.veljavnoVoznisko;
            if (message.datumOpravljenegaIntenzivnegaTecaja != null && message.hasOwnProperty("datumOpravljenegaIntenzivnegaTecaja"))
                object.datumOpravljenegaIntenzivnegaTecaja = message.datumOpravljenegaIntenzivnegaTecaja;
            if (message.veljavnoVozniskoKazenskeTocke != null && message.hasOwnProperty("veljavnoVozniskoKazenskeTocke"))
                object.veljavnoVozniskoKazenskeTocke = message.veljavnoVozniskoKazenskeTocke;
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfigS3 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfigS3.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfigS3
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfigS3
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfigS3.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfigS3";
        };

        return MladiVoznikFormConfigS3;
    })();

    mladi_voznik.MladiVoznikFormConfigS4 = (function() {

        /**
         * Properties of a MladiVoznikFormConfigS4.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfigS4
         * @property {boolean|null} [hide] MladiVoznikFormConfigS4 hide
         * @property {boolean|null} [najemVozila] MladiVoznikFormConfigS4 najemVozila
         * @property {boolean|null} [najemVozilaSPrijateljem] MladiVoznikFormConfigS4 najemVozilaSPrijateljem
         * @property {boolean|null} [najemVozilaZDrugoOsebo] MladiVoznikFormConfigS4 najemVozilaZDrugoOsebo
         * @property {boolean|null} [vSkupiniSPrijateljem] MladiVoznikFormConfigS4 vSkupiniSPrijateljem
         * @property {boolean|null} [eIzpitKoda] MladiVoznikFormConfigS4 eIzpitKoda
         * @property {boolean|null} [kosilo] MladiVoznikFormConfigS4 kosilo
         * @property {boolean|null} [opomba] MladiVoznikFormConfigS4 opomba
         */

        /**
         * Constructs a new MladiVoznikFormConfigS4.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfigS4.
         * @implements IMladiVoznikFormConfigS4
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfigS4=} [properties] Properties to set
         */
        function MladiVoznikFormConfigS4(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfigS4 hide.
         * @member {boolean} hide
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.hide = false;

        /**
         * MladiVoznikFormConfigS4 najemVozila.
         * @member {boolean} najemVozila
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.najemVozila = false;

        /**
         * MladiVoznikFormConfigS4 najemVozilaSPrijateljem.
         * @member {boolean} najemVozilaSPrijateljem
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.najemVozilaSPrijateljem = false;

        /**
         * MladiVoznikFormConfigS4 najemVozilaZDrugoOsebo.
         * @member {boolean} najemVozilaZDrugoOsebo
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.najemVozilaZDrugoOsebo = false;

        /**
         * MladiVoznikFormConfigS4 vSkupiniSPrijateljem.
         * @member {boolean} vSkupiniSPrijateljem
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.vSkupiniSPrijateljem = false;

        /**
         * MladiVoznikFormConfigS4 eIzpitKoda.
         * @member {boolean} eIzpitKoda
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.eIzpitKoda = false;

        /**
         * MladiVoznikFormConfigS4 kosilo.
         * @member {boolean} kosilo
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.kosilo = false;

        /**
         * MladiVoznikFormConfigS4 opomba.
         * @member {boolean} opomba
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         */
        MladiVoznikFormConfigS4.prototype.opomba = false;

        /**
         * Creates a new MladiVoznikFormConfigS4 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS4=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfigS4} MladiVoznikFormConfigS4 instance
         */
        MladiVoznikFormConfigS4.create = function create(properties) {
            return new MladiVoznikFormConfigS4(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS4 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS4.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS4} message MladiVoznikFormConfigS4 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS4.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hide != null && Object.hasOwnProperty.call(message, "hide"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hide);
            if (message.najemVozila != null && Object.hasOwnProperty.call(message, "najemVozila"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.najemVozila);
            if (message.najemVozilaSPrijateljem != null && Object.hasOwnProperty.call(message, "najemVozilaSPrijateljem"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.najemVozilaSPrijateljem);
            if (message.najemVozilaZDrugoOsebo != null && Object.hasOwnProperty.call(message, "najemVozilaZDrugoOsebo"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.najemVozilaZDrugoOsebo);
            if (message.vSkupiniSPrijateljem != null && Object.hasOwnProperty.call(message, "vSkupiniSPrijateljem"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.vSkupiniSPrijateljem);
            if (message.eIzpitKoda != null && Object.hasOwnProperty.call(message, "eIzpitKoda"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.eIzpitKoda);
            if (message.kosilo != null && Object.hasOwnProperty.call(message, "kosilo"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.kosilo);
            if (message.opomba != null && Object.hasOwnProperty.call(message, "opomba"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.opomba);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS4 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS4.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS4} message MladiVoznikFormConfigS4 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS4.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfigS4 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfigS4} MladiVoznikFormConfigS4
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS4.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfigS4();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hide = reader.bool();
                        break;
                    }
                case 2: {
                        message.najemVozila = reader.bool();
                        break;
                    }
                case 3: {
                        message.najemVozilaSPrijateljem = reader.bool();
                        break;
                    }
                case 4: {
                        message.najemVozilaZDrugoOsebo = reader.bool();
                        break;
                    }
                case 5: {
                        message.vSkupiniSPrijateljem = reader.bool();
                        break;
                    }
                case 6: {
                        message.eIzpitKoda = reader.bool();
                        break;
                    }
                case 7: {
                        message.kosilo = reader.bool();
                        break;
                    }
                case 8: {
                        message.opomba = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfigS4 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfigS4} MladiVoznikFormConfigS4
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS4.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfigS4 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfigS4.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hide != null && message.hasOwnProperty("hide"))
                if (typeof message.hide !== "boolean")
                    return "hide: boolean expected";
            if (message.najemVozila != null && message.hasOwnProperty("najemVozila"))
                if (typeof message.najemVozila !== "boolean")
                    return "najemVozila: boolean expected";
            if (message.najemVozilaSPrijateljem != null && message.hasOwnProperty("najemVozilaSPrijateljem"))
                if (typeof message.najemVozilaSPrijateljem !== "boolean")
                    return "najemVozilaSPrijateljem: boolean expected";
            if (message.najemVozilaZDrugoOsebo != null && message.hasOwnProperty("najemVozilaZDrugoOsebo"))
                if (typeof message.najemVozilaZDrugoOsebo !== "boolean")
                    return "najemVozilaZDrugoOsebo: boolean expected";
            if (message.vSkupiniSPrijateljem != null && message.hasOwnProperty("vSkupiniSPrijateljem"))
                if (typeof message.vSkupiniSPrijateljem !== "boolean")
                    return "vSkupiniSPrijateljem: boolean expected";
            if (message.eIzpitKoda != null && message.hasOwnProperty("eIzpitKoda"))
                if (typeof message.eIzpitKoda !== "boolean")
                    return "eIzpitKoda: boolean expected";
            if (message.kosilo != null && message.hasOwnProperty("kosilo"))
                if (typeof message.kosilo !== "boolean")
                    return "kosilo: boolean expected";
            if (message.opomba != null && message.hasOwnProperty("opomba"))
                if (typeof message.opomba !== "boolean")
                    return "opomba: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfigS4 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfigS4} MladiVoznikFormConfigS4
         */
        MladiVoznikFormConfigS4.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfigS4)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfigS4();
            if (object.hide != null)
                message.hide = Boolean(object.hide);
            if (object.najemVozila != null)
                message.najemVozila = Boolean(object.najemVozila);
            if (object.najemVozilaSPrijateljem != null)
                message.najemVozilaSPrijateljem = Boolean(object.najemVozilaSPrijateljem);
            if (object.najemVozilaZDrugoOsebo != null)
                message.najemVozilaZDrugoOsebo = Boolean(object.najemVozilaZDrugoOsebo);
            if (object.vSkupiniSPrijateljem != null)
                message.vSkupiniSPrijateljem = Boolean(object.vSkupiniSPrijateljem);
            if (object.eIzpitKoda != null)
                message.eIzpitKoda = Boolean(object.eIzpitKoda);
            if (object.kosilo != null)
                message.kosilo = Boolean(object.kosilo);
            if (object.opomba != null)
                message.opomba = Boolean(object.opomba);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfigS4 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfigS4} message MladiVoznikFormConfigS4
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfigS4.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hide = false;
                object.najemVozila = false;
                object.najemVozilaSPrijateljem = false;
                object.najemVozilaZDrugoOsebo = false;
                object.vSkupiniSPrijateljem = false;
                object.eIzpitKoda = false;
                object.kosilo = false;
                object.opomba = false;
            }
            if (message.hide != null && message.hasOwnProperty("hide"))
                object.hide = message.hide;
            if (message.najemVozila != null && message.hasOwnProperty("najemVozila"))
                object.najemVozila = message.najemVozila;
            if (message.najemVozilaSPrijateljem != null && message.hasOwnProperty("najemVozilaSPrijateljem"))
                object.najemVozilaSPrijateljem = message.najemVozilaSPrijateljem;
            if (message.najemVozilaZDrugoOsebo != null && message.hasOwnProperty("najemVozilaZDrugoOsebo"))
                object.najemVozilaZDrugoOsebo = message.najemVozilaZDrugoOsebo;
            if (message.vSkupiniSPrijateljem != null && message.hasOwnProperty("vSkupiniSPrijateljem"))
                object.vSkupiniSPrijateljem = message.vSkupiniSPrijateljem;
            if (message.eIzpitKoda != null && message.hasOwnProperty("eIzpitKoda"))
                object.eIzpitKoda = message.eIzpitKoda;
            if (message.kosilo != null && message.hasOwnProperty("kosilo"))
                object.kosilo = message.kosilo;
            if (message.opomba != null && message.hasOwnProperty("opomba"))
                object.opomba = message.opomba;
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfigS4 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfigS4.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfigS4
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfigS4
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfigS4.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfigS4";
        };

        return MladiVoznikFormConfigS4;
    })();

    mladi_voznik.MladiVoznikFormConfigS5 = (function() {

        /**
         * Properties of a MladiVoznikFormConfigS5.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfigS5
         * @property {boolean|null} [hide] MladiVoznikFormConfigS5 hide
         */

        /**
         * Constructs a new MladiVoznikFormConfigS5.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfigS5.
         * @implements IMladiVoznikFormConfigS5
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfigS5=} [properties] Properties to set
         */
        function MladiVoznikFormConfigS5(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfigS5 hide.
         * @member {boolean} hide
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @instance
         */
        MladiVoznikFormConfigS5.prototype.hide = false;

        /**
         * Creates a new MladiVoznikFormConfigS5 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS5=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfigS5} MladiVoznikFormConfigS5 instance
         */
        MladiVoznikFormConfigS5.create = function create(properties) {
            return new MladiVoznikFormConfigS5(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS5 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS5.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS5} message MladiVoznikFormConfigS5 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS5.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hide != null && Object.hasOwnProperty.call(message, "hide"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hide);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS5 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS5.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS5} message MladiVoznikFormConfigS5 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS5.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfigS5 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfigS5} MladiVoznikFormConfigS5
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS5.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfigS5();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hide = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfigS5 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfigS5} MladiVoznikFormConfigS5
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS5.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfigS5 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfigS5.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hide != null && message.hasOwnProperty("hide"))
                if (typeof message.hide !== "boolean")
                    return "hide: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfigS5 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfigS5} MladiVoznikFormConfigS5
         */
        MladiVoznikFormConfigS5.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfigS5)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfigS5();
            if (object.hide != null)
                message.hide = Boolean(object.hide);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfigS5 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfigS5} message MladiVoznikFormConfigS5
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfigS5.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.hide = false;
            if (message.hide != null && message.hasOwnProperty("hide"))
                object.hide = message.hide;
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfigS5 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfigS5.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfigS5
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfigS5
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfigS5.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfigS5";
        };

        return MladiVoznikFormConfigS5;
    })();

    mladi_voznik.MladiVoznikFormConfigS6 = (function() {

        /**
         * Properties of a MladiVoznikFormConfigS6.
         * @memberof mladi_voznik
         * @interface IMladiVoznikFormConfigS6
         * @property {boolean|null} [hide] MladiVoznikFormConfigS6 hide
         * @property {boolean|null} [nacinKontaktiranja] MladiVoznikFormConfigS6 nacinKontaktiranja
         * @property {boolean|null} [kjeSteSlisaliZaNas] MladiVoznikFormConfigS6 kjeSteSlisaliZaNas
         * @property {boolean|null} [promocijskaKoda] MladiVoznikFormConfigS6 promocijskaKoda
         * @property {boolean|null} [darilniBon] MladiVoznikFormConfigS6 darilniBon
         */

        /**
         * Constructs a new MladiVoznikFormConfigS6.
         * @memberof mladi_voznik
         * @classdesc Represents a MladiVoznikFormConfigS6.
         * @implements IMladiVoznikFormConfigS6
         * @constructor
         * @param {mladi_voznik.IMladiVoznikFormConfigS6=} [properties] Properties to set
         */
        function MladiVoznikFormConfigS6(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MladiVoznikFormConfigS6 hide.
         * @member {boolean} hide
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @instance
         */
        MladiVoznikFormConfigS6.prototype.hide = false;

        /**
         * MladiVoznikFormConfigS6 nacinKontaktiranja.
         * @member {boolean} nacinKontaktiranja
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @instance
         */
        MladiVoznikFormConfigS6.prototype.nacinKontaktiranja = false;

        /**
         * MladiVoznikFormConfigS6 kjeSteSlisaliZaNas.
         * @member {boolean} kjeSteSlisaliZaNas
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @instance
         */
        MladiVoznikFormConfigS6.prototype.kjeSteSlisaliZaNas = false;

        /**
         * MladiVoznikFormConfigS6 promocijskaKoda.
         * @member {boolean} promocijskaKoda
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @instance
         */
        MladiVoznikFormConfigS6.prototype.promocijskaKoda = false;

        /**
         * MladiVoznikFormConfigS6 darilniBon.
         * @member {boolean} darilniBon
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @instance
         */
        MladiVoznikFormConfigS6.prototype.darilniBon = false;

        /**
         * Creates a new MladiVoznikFormConfigS6 instance using the specified properties.
         * @function create
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS6=} [properties] Properties to set
         * @returns {mladi_voznik.MladiVoznikFormConfigS6} MladiVoznikFormConfigS6 instance
         */
        MladiVoznikFormConfigS6.create = function create(properties) {
            return new MladiVoznikFormConfigS6(properties);
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS6 message. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS6.verify|verify} messages.
         * @function encode
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS6} message MladiVoznikFormConfigS6 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS6.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hide != null && Object.hasOwnProperty.call(message, "hide"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hide);
            if (message.nacinKontaktiranja != null && Object.hasOwnProperty.call(message, "nacinKontaktiranja"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.nacinKontaktiranja);
            if (message.kjeSteSlisaliZaNas != null && Object.hasOwnProperty.call(message, "kjeSteSlisaliZaNas"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.kjeSteSlisaliZaNas);
            if (message.promocijskaKoda != null && Object.hasOwnProperty.call(message, "promocijskaKoda"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.promocijskaKoda);
            if (message.darilniBon != null && Object.hasOwnProperty.call(message, "darilniBon"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.darilniBon);
            return writer;
        };

        /**
         * Encodes the specified MladiVoznikFormConfigS6 message, length delimited. Does not implicitly {@link mladi_voznik.MladiVoznikFormConfigS6.verify|verify} messages.
         * @function encodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {mladi_voznik.IMladiVoznikFormConfigS6} message MladiVoznikFormConfigS6 message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MladiVoznikFormConfigS6.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MladiVoznikFormConfigS6 message from the specified reader or buffer.
         * @function decode
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {mladi_voznik.MladiVoznikFormConfigS6} MladiVoznikFormConfigS6
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS6.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.mladi_voznik.MladiVoznikFormConfigS6();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.hide = reader.bool();
                        break;
                    }
                case 2: {
                        message.nacinKontaktiranja = reader.bool();
                        break;
                    }
                case 3: {
                        message.kjeSteSlisaliZaNas = reader.bool();
                        break;
                    }
                case 4: {
                        message.promocijskaKoda = reader.bool();
                        break;
                    }
                case 5: {
                        message.darilniBon = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MladiVoznikFormConfigS6 message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {mladi_voznik.MladiVoznikFormConfigS6} MladiVoznikFormConfigS6
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MladiVoznikFormConfigS6.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MladiVoznikFormConfigS6 message.
         * @function verify
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MladiVoznikFormConfigS6.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.hide != null && message.hasOwnProperty("hide"))
                if (typeof message.hide !== "boolean")
                    return "hide: boolean expected";
            if (message.nacinKontaktiranja != null && message.hasOwnProperty("nacinKontaktiranja"))
                if (typeof message.nacinKontaktiranja !== "boolean")
                    return "nacinKontaktiranja: boolean expected";
            if (message.kjeSteSlisaliZaNas != null && message.hasOwnProperty("kjeSteSlisaliZaNas"))
                if (typeof message.kjeSteSlisaliZaNas !== "boolean")
                    return "kjeSteSlisaliZaNas: boolean expected";
            if (message.promocijskaKoda != null && message.hasOwnProperty("promocijskaKoda"))
                if (typeof message.promocijskaKoda !== "boolean")
                    return "promocijskaKoda: boolean expected";
            if (message.darilniBon != null && message.hasOwnProperty("darilniBon"))
                if (typeof message.darilniBon !== "boolean")
                    return "darilniBon: boolean expected";
            return null;
        };

        /**
         * Creates a MladiVoznikFormConfigS6 message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {mladi_voznik.MladiVoznikFormConfigS6} MladiVoznikFormConfigS6
         */
        MladiVoznikFormConfigS6.fromObject = function fromObject(object) {
            if (object instanceof $root.mladi_voznik.MladiVoznikFormConfigS6)
                return object;
            let message = new $root.mladi_voznik.MladiVoznikFormConfigS6();
            if (object.hide != null)
                message.hide = Boolean(object.hide);
            if (object.nacinKontaktiranja != null)
                message.nacinKontaktiranja = Boolean(object.nacinKontaktiranja);
            if (object.kjeSteSlisaliZaNas != null)
                message.kjeSteSlisaliZaNas = Boolean(object.kjeSteSlisaliZaNas);
            if (object.promocijskaKoda != null)
                message.promocijskaKoda = Boolean(object.promocijskaKoda);
            if (object.darilniBon != null)
                message.darilniBon = Boolean(object.darilniBon);
            return message;
        };

        /**
         * Creates a plain object from a MladiVoznikFormConfigS6 message. Also converts values to other types if specified.
         * @function toObject
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {mladi_voznik.MladiVoznikFormConfigS6} message MladiVoznikFormConfigS6
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MladiVoznikFormConfigS6.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.hide = false;
                object.nacinKontaktiranja = false;
                object.kjeSteSlisaliZaNas = false;
                object.promocijskaKoda = false;
                object.darilniBon = false;
            }
            if (message.hide != null && message.hasOwnProperty("hide"))
                object.hide = message.hide;
            if (message.nacinKontaktiranja != null && message.hasOwnProperty("nacinKontaktiranja"))
                object.nacinKontaktiranja = message.nacinKontaktiranja;
            if (message.kjeSteSlisaliZaNas != null && message.hasOwnProperty("kjeSteSlisaliZaNas"))
                object.kjeSteSlisaliZaNas = message.kjeSteSlisaliZaNas;
            if (message.promocijskaKoda != null && message.hasOwnProperty("promocijskaKoda"))
                object.promocijskaKoda = message.promocijskaKoda;
            if (message.darilniBon != null && message.hasOwnProperty("darilniBon"))
                object.darilniBon = message.darilniBon;
            return object;
        };

        /**
         * Converts this MladiVoznikFormConfigS6 to JSON.
         * @function toJSON
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MladiVoznikFormConfigS6.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for MladiVoznikFormConfigS6
         * @function getTypeUrl
         * @memberof mladi_voznik.MladiVoznikFormConfigS6
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        MladiVoznikFormConfigS6.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/mladi_voznik.MladiVoznikFormConfigS6";
        };

        return MladiVoznikFormConfigS6;
    })();

    /**
     * KazenskeTockeNapotilo enum.
     * @name mladi_voznik.KazenskeTockeNapotilo
     * @enum {number}
     * @property {number} sodisce=0 sodisce value
     * @property {number} zdravstveni_pregled=1 zdravstveni_pregled value
     * @property {number} prostovoljno=2 prostovoljno value
     */
    mladi_voznik.KazenskeTockeNapotilo = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "sodisce"] = 0;
        values[valuesById[1] = "zdravstveni_pregled"] = 1;
        values[valuesById[2] = "prostovoljno"] = 2;
        return values;
    })();

    return mladi_voznik;
})();

export const admin_tecaj = $root.admin_tecaj = (() => {

    /**
     * Namespace admin_tecaj.
     * @exports admin_tecaj
     * @namespace
     */
    const admin_tecaj = {};

    admin_tecaj.AdminLoadRazpisaniTecaj = (function() {

        /**
         * Properties of an AdminLoadRazpisaniTecaj.
         * @memberof admin_tecaj
         * @interface IAdminLoadRazpisaniTecaj
         * @property {string|null} [id] AdminLoadRazpisaniTecaj id
         * @property {string|null} [tecajTipKey] AdminLoadRazpisaniTecaj tecajTipKey
         * @property {string|null} [tecajKey] AdminLoadRazpisaniTecaj tecajKey
         */

        /**
         * Constructs a new AdminLoadRazpisaniTecaj.
         * @memberof admin_tecaj
         * @classdesc Represents an AdminLoadRazpisaniTecaj.
         * @implements IAdminLoadRazpisaniTecaj
         * @constructor
         * @param {admin_tecaj.IAdminLoadRazpisaniTecaj=} [properties] Properties to set
         */
        function AdminLoadRazpisaniTecaj(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminLoadRazpisaniTecaj id.
         * @member {string} id
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @instance
         */
        AdminLoadRazpisaniTecaj.prototype.id = "";

        /**
         * AdminLoadRazpisaniTecaj tecajTipKey.
         * @member {string} tecajTipKey
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @instance
         */
        AdminLoadRazpisaniTecaj.prototype.tecajTipKey = "";

        /**
         * AdminLoadRazpisaniTecaj tecajKey.
         * @member {string} tecajKey
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @instance
         */
        AdminLoadRazpisaniTecaj.prototype.tecajKey = "";

        /**
         * Creates a new AdminLoadRazpisaniTecaj instance using the specified properties.
         * @function create
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminLoadRazpisaniTecaj=} [properties] Properties to set
         * @returns {admin_tecaj.AdminLoadRazpisaniTecaj} AdminLoadRazpisaniTecaj instance
         */
        AdminLoadRazpisaniTecaj.create = function create(properties) {
            return new AdminLoadRazpisaniTecaj(properties);
        };

        /**
         * Encodes the specified AdminLoadRazpisaniTecaj message. Does not implicitly {@link admin_tecaj.AdminLoadRazpisaniTecaj.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminLoadRazpisaniTecaj} message AdminLoadRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadRazpisaniTecaj.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tecajTipKey != null && Object.hasOwnProperty.call(message, "tecajTipKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecajTipKey);
            if (message.tecajKey != null && Object.hasOwnProperty.call(message, "tecajKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tecajKey);
            return writer;
        };

        /**
         * Encodes the specified AdminLoadRazpisaniTecaj message, length delimited. Does not implicitly {@link admin_tecaj.AdminLoadRazpisaniTecaj.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminLoadRazpisaniTecaj} message AdminLoadRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadRazpisaniTecaj.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminLoadRazpisaniTecaj message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaj.AdminLoadRazpisaniTecaj} AdminLoadRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadRazpisaniTecaj.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaj.AdminLoadRazpisaniTecaj();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 2: {
                        message.tecajTipKey = reader.string();
                        break;
                    }
                case 3: {
                        message.tecajKey = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminLoadRazpisaniTecaj message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaj.AdminLoadRazpisaniTecaj} AdminLoadRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadRazpisaniTecaj.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminLoadRazpisaniTecaj message.
         * @function verify
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminLoadRazpisaniTecaj.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                if (!$util.isString(message.tecajTipKey))
                    return "tecajTipKey: string expected";
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                if (!$util.isString(message.tecajKey))
                    return "tecajKey: string expected";
            return null;
        };

        /**
         * Creates an AdminLoadRazpisaniTecaj message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaj.AdminLoadRazpisaniTecaj} AdminLoadRazpisaniTecaj
         */
        AdminLoadRazpisaniTecaj.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaj.AdminLoadRazpisaniTecaj)
                return object;
            let message = new $root.admin_tecaj.AdminLoadRazpisaniTecaj();
            if (object.id != null)
                message.id = String(object.id);
            if (object.tecajTipKey != null)
                message.tecajTipKey = String(object.tecajTipKey);
            if (object.tecajKey != null)
                message.tecajKey = String(object.tecajKey);
            return message;
        };

        /**
         * Creates a plain object from an AdminLoadRazpisaniTecaj message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {admin_tecaj.AdminLoadRazpisaniTecaj} message AdminLoadRazpisaniTecaj
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminLoadRazpisaniTecaj.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.tecajTipKey = "";
                object.tecajKey = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                object.tecajTipKey = message.tecajTipKey;
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                object.tecajKey = message.tecajKey;
            return object;
        };

        /**
         * Converts this AdminLoadRazpisaniTecaj to JSON.
         * @function toJSON
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminLoadRazpisaniTecaj.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminLoadRazpisaniTecaj
         * @function getTypeUrl
         * @memberof admin_tecaj.AdminLoadRazpisaniTecaj
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminLoadRazpisaniTecaj.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaj.AdminLoadRazpisaniTecaj";
        };

        return AdminLoadRazpisaniTecaj;
    })();

    admin_tecaj.AdminRazpisaniTecaj = (function() {

        /**
         * Properties of an AdminRazpisaniTecaj.
         * @memberof admin_tecaj
         * @interface IAdminRazpisaniTecaj
         * @property {string|null} [id] AdminRazpisaniTecaj id
         * @property {number|null} [placesTotal] AdminRazpisaniTecaj placesTotal
         * @property {string|null} [comment] AdminRazpisaniTecaj comment
         * @property {string|null} [commentInternal] AdminRazpisaniTecaj commentInternal
         * @property {Array.<admin_tecaj.IAdminRazpisaniTecajDay>|null} [days] AdminRazpisaniTecaj days
         * @property {boolean|null} [published] AdminRazpisaniTecaj published
         * @property {cvv_types.IChoice|null} [tecajTip] AdminRazpisaniTecaj tecajTip
         * @property {cvv_types.IChoice|null} [tecaj] AdminRazpisaniTecaj tecaj
         * @property {cvv_types.IChoice|null} [instructor] AdminRazpisaniTecaj instructor
         * @property {boolean|null} [paymentKreditna] AdminRazpisaniTecaj paymentKreditna
         * @property {boolean|null} [paymentGotovina] AdminRazpisaniTecaj paymentGotovina
         * @property {boolean|null} [paymentDarilniBon] AdminRazpisaniTecaj paymentDarilniBon
         */

        /**
         * Constructs a new AdminRazpisaniTecaj.
         * @memberof admin_tecaj
         * @classdesc Represents an AdminRazpisaniTecaj.
         * @implements IAdminRazpisaniTecaj
         * @constructor
         * @param {admin_tecaj.IAdminRazpisaniTecaj=} [properties] Properties to set
         */
        function AdminRazpisaniTecaj(properties) {
            this.days = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminRazpisaniTecaj id.
         * @member {string} id
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.id = "";

        /**
         * AdminRazpisaniTecaj placesTotal.
         * @member {number} placesTotal
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.placesTotal = 0;

        /**
         * AdminRazpisaniTecaj comment.
         * @member {string} comment
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.comment = "";

        /**
         * AdminRazpisaniTecaj commentInternal.
         * @member {string} commentInternal
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.commentInternal = "";

        /**
         * AdminRazpisaniTecaj days.
         * @member {Array.<admin_tecaj.IAdminRazpisaniTecajDay>} days
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.days = $util.emptyArray;

        /**
         * AdminRazpisaniTecaj published.
         * @member {boolean} published
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.published = false;

        /**
         * AdminRazpisaniTecaj tecajTip.
         * @member {cvv_types.IChoice|null|undefined} tecajTip
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.tecajTip = null;

        /**
         * AdminRazpisaniTecaj tecaj.
         * @member {cvv_types.IChoice|null|undefined} tecaj
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.tecaj = null;

        /**
         * AdminRazpisaniTecaj instructor.
         * @member {cvv_types.IChoice|null|undefined} instructor
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.instructor = null;

        /**
         * AdminRazpisaniTecaj paymentKreditna.
         * @member {boolean} paymentKreditna
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.paymentKreditna = false;

        /**
         * AdminRazpisaniTecaj paymentGotovina.
         * @member {boolean} paymentGotovina
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.paymentGotovina = false;

        /**
         * AdminRazpisaniTecaj paymentDarilniBon.
         * @member {boolean} paymentDarilniBon
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         */
        AdminRazpisaniTecaj.prototype.paymentDarilniBon = false;

        /**
         * Creates a new AdminRazpisaniTecaj instance using the specified properties.
         * @function create
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminRazpisaniTecaj=} [properties] Properties to set
         * @returns {admin_tecaj.AdminRazpisaniTecaj} AdminRazpisaniTecaj instance
         */
        AdminRazpisaniTecaj.create = function create(properties) {
            return new AdminRazpisaniTecaj(properties);
        };

        /**
         * Encodes the specified AdminRazpisaniTecaj message. Does not implicitly {@link admin_tecaj.AdminRazpisaniTecaj.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminRazpisaniTecaj} message AdminRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminRazpisaniTecaj.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.placesTotal != null && Object.hasOwnProperty.call(message, "placesTotal"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.placesTotal);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.comment);
            if (message.days != null && message.days.length)
                for (let i = 0; i < message.days.length; ++i)
                    $root.admin_tecaj.AdminRazpisaniTecajDay.encode(message.days[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.published != null && Object.hasOwnProperty.call(message, "published"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.published);
            if (message.tecajTip != null && Object.hasOwnProperty.call(message, "tecajTip"))
                $root.cvv_types.Choice.encode(message.tecajTip, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.tecaj != null && Object.hasOwnProperty.call(message, "tecaj"))
                $root.cvv_types.Choice.encode(message.tecaj, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.commentInternal != null && Object.hasOwnProperty.call(message, "commentInternal"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.commentInternal);
            if (message.instructor != null && Object.hasOwnProperty.call(message, "instructor"))
                $root.cvv_types.Choice.encode(message.instructor, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.paymentKreditna != null && Object.hasOwnProperty.call(message, "paymentKreditna"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.paymentKreditna);
            if (message.paymentGotovina != null && Object.hasOwnProperty.call(message, "paymentGotovina"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.paymentGotovina);
            if (message.paymentDarilniBon != null && Object.hasOwnProperty.call(message, "paymentDarilniBon"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.paymentDarilniBon);
            return writer;
        };

        /**
         * Encodes the specified AdminRazpisaniTecaj message, length delimited. Does not implicitly {@link admin_tecaj.AdminRazpisaniTecaj.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminRazpisaniTecaj} message AdminRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminRazpisaniTecaj.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminRazpisaniTecaj message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaj.AdminRazpisaniTecaj} AdminRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminRazpisaniTecaj.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaj.AdminRazpisaniTecaj();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                case 3: {
                        message.placesTotal = reader.int32();
                        break;
                    }
                case 5: {
                        message.comment = reader.string();
                        break;
                    }
                case 10: {
                        message.commentInternal = reader.string();
                        break;
                    }
                case 6: {
                        if (!(message.days && message.days.length))
                            message.days = [];
                        message.days.push($root.admin_tecaj.AdminRazpisaniTecajDay.decode(reader, reader.uint32()));
                        break;
                    }
                case 7: {
                        message.published = reader.bool();
                        break;
                    }
                case 8: {
                        message.tecajTip = $root.cvv_types.Choice.decode(reader, reader.uint32());
                        break;
                    }
                case 9: {
                        message.tecaj = $root.cvv_types.Choice.decode(reader, reader.uint32());
                        break;
                    }
                case 11: {
                        message.instructor = $root.cvv_types.Choice.decode(reader, reader.uint32());
                        break;
                    }
                case 12: {
                        message.paymentKreditna = reader.bool();
                        break;
                    }
                case 13: {
                        message.paymentGotovina = reader.bool();
                        break;
                    }
                case 14: {
                        message.paymentDarilniBon = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminRazpisaniTecaj message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaj.AdminRazpisaniTecaj} AdminRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminRazpisaniTecaj.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminRazpisaniTecaj message.
         * @function verify
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminRazpisaniTecaj.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.placesTotal != null && message.hasOwnProperty("placesTotal"))
                if (!$util.isInteger(message.placesTotal))
                    return "placesTotal: integer expected";
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            if (message.commentInternal != null && message.hasOwnProperty("commentInternal"))
                if (!$util.isString(message.commentInternal))
                    return "commentInternal: string expected";
            if (message.days != null && message.hasOwnProperty("days")) {
                if (!Array.isArray(message.days))
                    return "days: array expected";
                for (let i = 0; i < message.days.length; ++i) {
                    let error = $root.admin_tecaj.AdminRazpisaniTecajDay.verify(message.days[i]);
                    if (error)
                        return "days." + error;
                }
            }
            if (message.published != null && message.hasOwnProperty("published"))
                if (typeof message.published !== "boolean")
                    return "published: boolean expected";
            if (message.tecajTip != null && message.hasOwnProperty("tecajTip")) {
                let error = $root.cvv_types.Choice.verify(message.tecajTip);
                if (error)
                    return "tecajTip." + error;
            }
            if (message.tecaj != null && message.hasOwnProperty("tecaj")) {
                let error = $root.cvv_types.Choice.verify(message.tecaj);
                if (error)
                    return "tecaj." + error;
            }
            if (message.instructor != null && message.hasOwnProperty("instructor")) {
                let error = $root.cvv_types.Choice.verify(message.instructor);
                if (error)
                    return "instructor." + error;
            }
            if (message.paymentKreditna != null && message.hasOwnProperty("paymentKreditna"))
                if (typeof message.paymentKreditna !== "boolean")
                    return "paymentKreditna: boolean expected";
            if (message.paymentGotovina != null && message.hasOwnProperty("paymentGotovina"))
                if (typeof message.paymentGotovina !== "boolean")
                    return "paymentGotovina: boolean expected";
            if (message.paymentDarilniBon != null && message.hasOwnProperty("paymentDarilniBon"))
                if (typeof message.paymentDarilniBon !== "boolean")
                    return "paymentDarilniBon: boolean expected";
            return null;
        };

        /**
         * Creates an AdminRazpisaniTecaj message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaj.AdminRazpisaniTecaj} AdminRazpisaniTecaj
         */
        AdminRazpisaniTecaj.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaj.AdminRazpisaniTecaj)
                return object;
            let message = new $root.admin_tecaj.AdminRazpisaniTecaj();
            if (object.id != null)
                message.id = String(object.id);
            if (object.placesTotal != null)
                message.placesTotal = object.placesTotal | 0;
            if (object.comment != null)
                message.comment = String(object.comment);
            if (object.commentInternal != null)
                message.commentInternal = String(object.commentInternal);
            if (object.days) {
                if (!Array.isArray(object.days))
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecaj.days: array expected");
                message.days = [];
                for (let i = 0; i < object.days.length; ++i) {
                    if (typeof object.days[i] !== "object")
                        throw TypeError(".admin_tecaj.AdminRazpisaniTecaj.days: object expected");
                    message.days[i] = $root.admin_tecaj.AdminRazpisaniTecajDay.fromObject(object.days[i]);
                }
            }
            if (object.published != null)
                message.published = Boolean(object.published);
            if (object.tecajTip != null) {
                if (typeof object.tecajTip !== "object")
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecaj.tecajTip: object expected");
                message.tecajTip = $root.cvv_types.Choice.fromObject(object.tecajTip);
            }
            if (object.tecaj != null) {
                if (typeof object.tecaj !== "object")
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecaj.tecaj: object expected");
                message.tecaj = $root.cvv_types.Choice.fromObject(object.tecaj);
            }
            if (object.instructor != null) {
                if (typeof object.instructor !== "object")
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecaj.instructor: object expected");
                message.instructor = $root.cvv_types.Choice.fromObject(object.instructor);
            }
            if (object.paymentKreditna != null)
                message.paymentKreditna = Boolean(object.paymentKreditna);
            if (object.paymentGotovina != null)
                message.paymentGotovina = Boolean(object.paymentGotovina);
            if (object.paymentDarilniBon != null)
                message.paymentDarilniBon = Boolean(object.paymentDarilniBon);
            return message;
        };

        /**
         * Creates a plain object from an AdminRazpisaniTecaj message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {admin_tecaj.AdminRazpisaniTecaj} message AdminRazpisaniTecaj
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminRazpisaniTecaj.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.days = [];
            if (options.defaults) {
                object.id = "";
                object.placesTotal = 0;
                object.comment = "";
                object.published = false;
                object.tecajTip = null;
                object.tecaj = null;
                object.commentInternal = "";
                object.instructor = null;
                object.paymentKreditna = false;
                object.paymentGotovina = false;
                object.paymentDarilniBon = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.placesTotal != null && message.hasOwnProperty("placesTotal"))
                object.placesTotal = message.placesTotal;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            if (message.days && message.days.length) {
                object.days = [];
                for (let j = 0; j < message.days.length; ++j)
                    object.days[j] = $root.admin_tecaj.AdminRazpisaniTecajDay.toObject(message.days[j], options);
            }
            if (message.published != null && message.hasOwnProperty("published"))
                object.published = message.published;
            if (message.tecajTip != null && message.hasOwnProperty("tecajTip"))
                object.tecajTip = $root.cvv_types.Choice.toObject(message.tecajTip, options);
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                object.tecaj = $root.cvv_types.Choice.toObject(message.tecaj, options);
            if (message.commentInternal != null && message.hasOwnProperty("commentInternal"))
                object.commentInternal = message.commentInternal;
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                object.instructor = $root.cvv_types.Choice.toObject(message.instructor, options);
            if (message.paymentKreditna != null && message.hasOwnProperty("paymentKreditna"))
                object.paymentKreditna = message.paymentKreditna;
            if (message.paymentGotovina != null && message.hasOwnProperty("paymentGotovina"))
                object.paymentGotovina = message.paymentGotovina;
            if (message.paymentDarilniBon != null && message.hasOwnProperty("paymentDarilniBon"))
                object.paymentDarilniBon = message.paymentDarilniBon;
            return object;
        };

        /**
         * Converts this AdminRazpisaniTecaj to JSON.
         * @function toJSON
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminRazpisaniTecaj.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminRazpisaniTecaj
         * @function getTypeUrl
         * @memberof admin_tecaj.AdminRazpisaniTecaj
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminRazpisaniTecaj.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaj.AdminRazpisaniTecaj";
        };

        return AdminRazpisaniTecaj;
    })();

    admin_tecaj.AdminSaveRazpisaniTecaj = (function() {

        /**
         * Properties of an AdminSaveRazpisaniTecaj.
         * @memberof admin_tecaj
         * @interface IAdminSaveRazpisaniTecaj
         * @property {admin_tecaj.IAdminRazpisaniTecaj|null} [tecaj] AdminSaveRazpisaniTecaj tecaj
         */

        /**
         * Constructs a new AdminSaveRazpisaniTecaj.
         * @memberof admin_tecaj
         * @classdesc Represents an AdminSaveRazpisaniTecaj.
         * @implements IAdminSaveRazpisaniTecaj
         * @constructor
         * @param {admin_tecaj.IAdminSaveRazpisaniTecaj=} [properties] Properties to set
         */
        function AdminSaveRazpisaniTecaj(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminSaveRazpisaniTecaj tecaj.
         * @member {admin_tecaj.IAdminRazpisaniTecaj|null|undefined} tecaj
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @instance
         */
        AdminSaveRazpisaniTecaj.prototype.tecaj = null;

        /**
         * Creates a new AdminSaveRazpisaniTecaj instance using the specified properties.
         * @function create
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminSaveRazpisaniTecaj=} [properties] Properties to set
         * @returns {admin_tecaj.AdminSaveRazpisaniTecaj} AdminSaveRazpisaniTecaj instance
         */
        AdminSaveRazpisaniTecaj.create = function create(properties) {
            return new AdminSaveRazpisaniTecaj(properties);
        };

        /**
         * Encodes the specified AdminSaveRazpisaniTecaj message. Does not implicitly {@link admin_tecaj.AdminSaveRazpisaniTecaj.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminSaveRazpisaniTecaj} message AdminSaveRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminSaveRazpisaniTecaj.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecaj != null && Object.hasOwnProperty.call(message, "tecaj"))
                $root.admin_tecaj.AdminRazpisaniTecaj.encode(message.tecaj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminSaveRazpisaniTecaj message, length delimited. Does not implicitly {@link admin_tecaj.AdminSaveRazpisaniTecaj.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminSaveRazpisaniTecaj} message AdminSaveRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminSaveRazpisaniTecaj.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminSaveRazpisaniTecaj message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaj.AdminSaveRazpisaniTecaj} AdminSaveRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminSaveRazpisaniTecaj.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaj.AdminSaveRazpisaniTecaj();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tecaj = $root.admin_tecaj.AdminRazpisaniTecaj.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminSaveRazpisaniTecaj message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaj.AdminSaveRazpisaniTecaj} AdminSaveRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminSaveRazpisaniTecaj.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminSaveRazpisaniTecaj message.
         * @function verify
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminSaveRazpisaniTecaj.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecaj != null && message.hasOwnProperty("tecaj")) {
                let error = $root.admin_tecaj.AdminRazpisaniTecaj.verify(message.tecaj);
                if (error)
                    return "tecaj." + error;
            }
            return null;
        };

        /**
         * Creates an AdminSaveRazpisaniTecaj message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaj.AdminSaveRazpisaniTecaj} AdminSaveRazpisaniTecaj
         */
        AdminSaveRazpisaniTecaj.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaj.AdminSaveRazpisaniTecaj)
                return object;
            let message = new $root.admin_tecaj.AdminSaveRazpisaniTecaj();
            if (object.tecaj != null) {
                if (typeof object.tecaj !== "object")
                    throw TypeError(".admin_tecaj.AdminSaveRazpisaniTecaj.tecaj: object expected");
                message.tecaj = $root.admin_tecaj.AdminRazpisaniTecaj.fromObject(object.tecaj);
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminSaveRazpisaniTecaj message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {admin_tecaj.AdminSaveRazpisaniTecaj} message AdminSaveRazpisaniTecaj
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminSaveRazpisaniTecaj.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.tecaj = null;
            if (message.tecaj != null && message.hasOwnProperty("tecaj"))
                object.tecaj = $root.admin_tecaj.AdminRazpisaniTecaj.toObject(message.tecaj, options);
            return object;
        };

        /**
         * Converts this AdminSaveRazpisaniTecaj to JSON.
         * @function toJSON
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminSaveRazpisaniTecaj.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminSaveRazpisaniTecaj
         * @function getTypeUrl
         * @memberof admin_tecaj.AdminSaveRazpisaniTecaj
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminSaveRazpisaniTecaj.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaj.AdminSaveRazpisaniTecaj";
        };

        return AdminSaveRazpisaniTecaj;
    })();

    admin_tecaj.AdminDeleteRazpisaniTecaj = (function() {

        /**
         * Properties of an AdminDeleteRazpisaniTecaj.
         * @memberof admin_tecaj
         * @interface IAdminDeleteRazpisaniTecaj
         * @property {string|null} [tecajId] AdminDeleteRazpisaniTecaj tecajId
         */

        /**
         * Constructs a new AdminDeleteRazpisaniTecaj.
         * @memberof admin_tecaj
         * @classdesc Represents an AdminDeleteRazpisaniTecaj.
         * @implements IAdminDeleteRazpisaniTecaj
         * @constructor
         * @param {admin_tecaj.IAdminDeleteRazpisaniTecaj=} [properties] Properties to set
         */
        function AdminDeleteRazpisaniTecaj(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminDeleteRazpisaniTecaj tecajId.
         * @member {string} tecajId
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @instance
         */
        AdminDeleteRazpisaniTecaj.prototype.tecajId = "";

        /**
         * Creates a new AdminDeleteRazpisaniTecaj instance using the specified properties.
         * @function create
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminDeleteRazpisaniTecaj=} [properties] Properties to set
         * @returns {admin_tecaj.AdminDeleteRazpisaniTecaj} AdminDeleteRazpisaniTecaj instance
         */
        AdminDeleteRazpisaniTecaj.create = function create(properties) {
            return new AdminDeleteRazpisaniTecaj(properties);
        };

        /**
         * Encodes the specified AdminDeleteRazpisaniTecaj message. Does not implicitly {@link admin_tecaj.AdminDeleteRazpisaniTecaj.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminDeleteRazpisaniTecaj} message AdminDeleteRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminDeleteRazpisaniTecaj.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecajId != null && Object.hasOwnProperty.call(message, "tecajId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tecajId);
            return writer;
        };

        /**
         * Encodes the specified AdminDeleteRazpisaniTecaj message, length delimited. Does not implicitly {@link admin_tecaj.AdminDeleteRazpisaniTecaj.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {admin_tecaj.IAdminDeleteRazpisaniTecaj} message AdminDeleteRazpisaniTecaj message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminDeleteRazpisaniTecaj.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminDeleteRazpisaniTecaj message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaj.AdminDeleteRazpisaniTecaj} AdminDeleteRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminDeleteRazpisaniTecaj.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaj.AdminDeleteRazpisaniTecaj();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tecajId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminDeleteRazpisaniTecaj message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaj.AdminDeleteRazpisaniTecaj} AdminDeleteRazpisaniTecaj
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminDeleteRazpisaniTecaj.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminDeleteRazpisaniTecaj message.
         * @function verify
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminDeleteRazpisaniTecaj.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                if (!$util.isString(message.tecajId))
                    return "tecajId: string expected";
            return null;
        };

        /**
         * Creates an AdminDeleteRazpisaniTecaj message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaj.AdminDeleteRazpisaniTecaj} AdminDeleteRazpisaniTecaj
         */
        AdminDeleteRazpisaniTecaj.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaj.AdminDeleteRazpisaniTecaj)
                return object;
            let message = new $root.admin_tecaj.AdminDeleteRazpisaniTecaj();
            if (object.tecajId != null)
                message.tecajId = String(object.tecajId);
            return message;
        };

        /**
         * Creates a plain object from an AdminDeleteRazpisaniTecaj message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {admin_tecaj.AdminDeleteRazpisaniTecaj} message AdminDeleteRazpisaniTecaj
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminDeleteRazpisaniTecaj.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.tecajId = "";
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                object.tecajId = message.tecajId;
            return object;
        };

        /**
         * Converts this AdminDeleteRazpisaniTecaj to JSON.
         * @function toJSON
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminDeleteRazpisaniTecaj.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminDeleteRazpisaniTecaj
         * @function getTypeUrl
         * @memberof admin_tecaj.AdminDeleteRazpisaniTecaj
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminDeleteRazpisaniTecaj.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaj.AdminDeleteRazpisaniTecaj";
        };

        return AdminDeleteRazpisaniTecaj;
    })();

    admin_tecaj.AdminRazpisaniTecajDay = (function() {

        /**
         * Properties of an AdminRazpisaniTecajDay.
         * @memberof admin_tecaj
         * @interface IAdminRazpisaniTecajDay
         * @property {cvv_types.IChoice|null} [location] AdminRazpisaniTecajDay location
         * @property {cvv_types.IDate|null} [start] AdminRazpisaniTecajDay start
         * @property {cvv_types.IDate|null} [end] AdminRazpisaniTecajDay end
         */

        /**
         * Constructs a new AdminRazpisaniTecajDay.
         * @memberof admin_tecaj
         * @classdesc Represents an AdminRazpisaniTecajDay.
         * @implements IAdminRazpisaniTecajDay
         * @constructor
         * @param {admin_tecaj.IAdminRazpisaniTecajDay=} [properties] Properties to set
         */
        function AdminRazpisaniTecajDay(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminRazpisaniTecajDay location.
         * @member {cvv_types.IChoice|null|undefined} location
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @instance
         */
        AdminRazpisaniTecajDay.prototype.location = null;

        /**
         * AdminRazpisaniTecajDay start.
         * @member {cvv_types.IDate|null|undefined} start
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @instance
         */
        AdminRazpisaniTecajDay.prototype.start = null;

        /**
         * AdminRazpisaniTecajDay end.
         * @member {cvv_types.IDate|null|undefined} end
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @instance
         */
        AdminRazpisaniTecajDay.prototype.end = null;

        /**
         * Creates a new AdminRazpisaniTecajDay instance using the specified properties.
         * @function create
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {admin_tecaj.IAdminRazpisaniTecajDay=} [properties] Properties to set
         * @returns {admin_tecaj.AdminRazpisaniTecajDay} AdminRazpisaniTecajDay instance
         */
        AdminRazpisaniTecajDay.create = function create(properties) {
            return new AdminRazpisaniTecajDay(properties);
        };

        /**
         * Encodes the specified AdminRazpisaniTecajDay message. Does not implicitly {@link admin_tecaj.AdminRazpisaniTecajDay.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {admin_tecaj.IAdminRazpisaniTecajDay} message AdminRazpisaniTecajDay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminRazpisaniTecajDay.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                $root.cvv_types.Choice.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                $root.cvv_types.Date.encode(message.start, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                $root.cvv_types.Date.encode(message.end, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminRazpisaniTecajDay message, length delimited. Does not implicitly {@link admin_tecaj.AdminRazpisaniTecajDay.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {admin_tecaj.IAdminRazpisaniTecajDay} message AdminRazpisaniTecajDay message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminRazpisaniTecajDay.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminRazpisaniTecajDay message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaj.AdminRazpisaniTecajDay} AdminRazpisaniTecajDay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminRazpisaniTecajDay.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaj.AdminRazpisaniTecajDay();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.location = $root.cvv_types.Choice.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        message.start = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.end = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminRazpisaniTecajDay message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaj.AdminRazpisaniTecajDay} AdminRazpisaniTecajDay
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminRazpisaniTecajDay.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminRazpisaniTecajDay message.
         * @function verify
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminRazpisaniTecajDay.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.location != null && message.hasOwnProperty("location")) {
                let error = $root.cvv_types.Choice.verify(message.location);
                if (error)
                    return "location." + error;
            }
            if (message.start != null && message.hasOwnProperty("start")) {
                let error = $root.cvv_types.Date.verify(message.start);
                if (error)
                    return "start." + error;
            }
            if (message.end != null && message.hasOwnProperty("end")) {
                let error = $root.cvv_types.Date.verify(message.end);
                if (error)
                    return "end." + error;
            }
            return null;
        };

        /**
         * Creates an AdminRazpisaniTecajDay message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaj.AdminRazpisaniTecajDay} AdminRazpisaniTecajDay
         */
        AdminRazpisaniTecajDay.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaj.AdminRazpisaniTecajDay)
                return object;
            let message = new $root.admin_tecaj.AdminRazpisaniTecajDay();
            if (object.location != null) {
                if (typeof object.location !== "object")
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecajDay.location: object expected");
                message.location = $root.cvv_types.Choice.fromObject(object.location);
            }
            if (object.start != null) {
                if (typeof object.start !== "object")
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecajDay.start: object expected");
                message.start = $root.cvv_types.Date.fromObject(object.start);
            }
            if (object.end != null) {
                if (typeof object.end !== "object")
                    throw TypeError(".admin_tecaj.AdminRazpisaniTecajDay.end: object expected");
                message.end = $root.cvv_types.Date.fromObject(object.end);
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminRazpisaniTecajDay message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {admin_tecaj.AdminRazpisaniTecajDay} message AdminRazpisaniTecajDay
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminRazpisaniTecajDay.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.location = null;
                object.start = null;
                object.end = null;
            }
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = $root.cvv_types.Choice.toObject(message.location, options);
            if (message.start != null && message.hasOwnProperty("start"))
                object.start = $root.cvv_types.Date.toObject(message.start, options);
            if (message.end != null && message.hasOwnProperty("end"))
                object.end = $root.cvv_types.Date.toObject(message.end, options);
            return object;
        };

        /**
         * Converts this AdminRazpisaniTecajDay to JSON.
         * @function toJSON
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminRazpisaniTecajDay.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminRazpisaniTecajDay
         * @function getTypeUrl
         * @memberof admin_tecaj.AdminRazpisaniTecajDay
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminRazpisaniTecajDay.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaj.AdminRazpisaniTecajDay";
        };

        return AdminRazpisaniTecajDay;
    })();

    admin_tecaj.AdminTecajInstructors = (function() {

        /**
         * Properties of an AdminTecajInstructors.
         * @memberof admin_tecaj
         * @interface IAdminTecajInstructors
         * @property {string|null} [tecajId] AdminTecajInstructors tecajId
         * @property {Array.<cvv_types.IChoice>|null} [instructors] AdminTecajInstructors instructors
         */

        /**
         * Constructs a new AdminTecajInstructors.
         * @memberof admin_tecaj
         * @classdesc Represents an AdminTecajInstructors.
         * @implements IAdminTecajInstructors
         * @constructor
         * @param {admin_tecaj.IAdminTecajInstructors=} [properties] Properties to set
         */
        function AdminTecajInstructors(properties) {
            this.instructors = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminTecajInstructors tecajId.
         * @member {string} tecajId
         * @memberof admin_tecaj.AdminTecajInstructors
         * @instance
         */
        AdminTecajInstructors.prototype.tecajId = "";

        /**
         * AdminTecajInstructors instructors.
         * @member {Array.<cvv_types.IChoice>} instructors
         * @memberof admin_tecaj.AdminTecajInstructors
         * @instance
         */
        AdminTecajInstructors.prototype.instructors = $util.emptyArray;

        /**
         * Creates a new AdminTecajInstructors instance using the specified properties.
         * @function create
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {admin_tecaj.IAdminTecajInstructors=} [properties] Properties to set
         * @returns {admin_tecaj.AdminTecajInstructors} AdminTecajInstructors instance
         */
        AdminTecajInstructors.create = function create(properties) {
            return new AdminTecajInstructors(properties);
        };

        /**
         * Encodes the specified AdminTecajInstructors message. Does not implicitly {@link admin_tecaj.AdminTecajInstructors.verify|verify} messages.
         * @function encode
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {admin_tecaj.IAdminTecajInstructors} message AdminTecajInstructors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminTecajInstructors.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecajId != null && Object.hasOwnProperty.call(message, "tecajId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tecajId);
            if (message.instructors != null && message.instructors.length)
                for (let i = 0; i < message.instructors.length; ++i)
                    $root.cvv_types.Choice.encode(message.instructors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminTecajInstructors message, length delimited. Does not implicitly {@link admin_tecaj.AdminTecajInstructors.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {admin_tecaj.IAdminTecajInstructors} message AdminTecajInstructors message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminTecajInstructors.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminTecajInstructors message from the specified reader or buffer.
         * @function decode
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_tecaj.AdminTecajInstructors} AdminTecajInstructors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminTecajInstructors.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_tecaj.AdminTecajInstructors();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tecajId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.instructors && message.instructors.length))
                            message.instructors = [];
                        message.instructors.push($root.cvv_types.Choice.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminTecajInstructors message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_tecaj.AdminTecajInstructors} AdminTecajInstructors
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminTecajInstructors.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminTecajInstructors message.
         * @function verify
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminTecajInstructors.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                if (!$util.isString(message.tecajId))
                    return "tecajId: string expected";
            if (message.instructors != null && message.hasOwnProperty("instructors")) {
                if (!Array.isArray(message.instructors))
                    return "instructors: array expected";
                for (let i = 0; i < message.instructors.length; ++i) {
                    let error = $root.cvv_types.Choice.verify(message.instructors[i]);
                    if (error)
                        return "instructors." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdminTecajInstructors message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_tecaj.AdminTecajInstructors} AdminTecajInstructors
         */
        AdminTecajInstructors.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_tecaj.AdminTecajInstructors)
                return object;
            let message = new $root.admin_tecaj.AdminTecajInstructors();
            if (object.tecajId != null)
                message.tecajId = String(object.tecajId);
            if (object.instructors) {
                if (!Array.isArray(object.instructors))
                    throw TypeError(".admin_tecaj.AdminTecajInstructors.instructors: array expected");
                message.instructors = [];
                for (let i = 0; i < object.instructors.length; ++i) {
                    if (typeof object.instructors[i] !== "object")
                        throw TypeError(".admin_tecaj.AdminTecajInstructors.instructors: object expected");
                    message.instructors[i] = $root.cvv_types.Choice.fromObject(object.instructors[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminTecajInstructors message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {admin_tecaj.AdminTecajInstructors} message AdminTecajInstructors
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminTecajInstructors.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.instructors = [];
            if (options.defaults)
                object.tecajId = "";
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                object.tecajId = message.tecajId;
            if (message.instructors && message.instructors.length) {
                object.instructors = [];
                for (let j = 0; j < message.instructors.length; ++j)
                    object.instructors[j] = $root.cvv_types.Choice.toObject(message.instructors[j], options);
            }
            return object;
        };

        /**
         * Converts this AdminTecajInstructors to JSON.
         * @function toJSON
         * @memberof admin_tecaj.AdminTecajInstructors
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminTecajInstructors.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminTecajInstructors
         * @function getTypeUrl
         * @memberof admin_tecaj.AdminTecajInstructors
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminTecajInstructors.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_tecaj.AdminTecajInstructors";
        };

        return AdminTecajInstructors;
    })();

    return admin_tecaj;
})();

export const participation = $root.participation = (() => {

    /**
     * Namespace participation.
     * @exports participation
     * @namespace
     */
    const participation = {};

    participation.SetSMSParticipation = (function() {

        /**
         * Properties of a SetSMSParticipation.
         * @memberof participation
         * @interface ISetSMSParticipation
         * @property {string|null} [formSessionId] SetSMSParticipation formSessionId
         * @property {boolean|null} [confirmed] SetSMSParticipation confirmed
         */

        /**
         * Constructs a new SetSMSParticipation.
         * @memberof participation
         * @classdesc Represents a SetSMSParticipation.
         * @implements ISetSMSParticipation
         * @constructor
         * @param {participation.ISetSMSParticipation=} [properties] Properties to set
         */
        function SetSMSParticipation(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetSMSParticipation formSessionId.
         * @member {string} formSessionId
         * @memberof participation.SetSMSParticipation
         * @instance
         */
        SetSMSParticipation.prototype.formSessionId = "";

        /**
         * SetSMSParticipation confirmed.
         * @member {boolean} confirmed
         * @memberof participation.SetSMSParticipation
         * @instance
         */
        SetSMSParticipation.prototype.confirmed = false;

        /**
         * Creates a new SetSMSParticipation instance using the specified properties.
         * @function create
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {participation.ISetSMSParticipation=} [properties] Properties to set
         * @returns {participation.SetSMSParticipation} SetSMSParticipation instance
         */
        SetSMSParticipation.create = function create(properties) {
            return new SetSMSParticipation(properties);
        };

        /**
         * Encodes the specified SetSMSParticipation message. Does not implicitly {@link participation.SetSMSParticipation.verify|verify} messages.
         * @function encode
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {participation.ISetSMSParticipation} message SetSMSParticipation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetSMSParticipation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.formSessionId != null && Object.hasOwnProperty.call(message, "formSessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.formSessionId);
            if (message.confirmed != null && Object.hasOwnProperty.call(message, "confirmed"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.confirmed);
            return writer;
        };

        /**
         * Encodes the specified SetSMSParticipation message, length delimited. Does not implicitly {@link participation.SetSMSParticipation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {participation.ISetSMSParticipation} message SetSMSParticipation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetSMSParticipation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetSMSParticipation message from the specified reader or buffer.
         * @function decode
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {participation.SetSMSParticipation} SetSMSParticipation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetSMSParticipation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.participation.SetSMSParticipation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.formSessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.confirmed = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetSMSParticipation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {participation.SetSMSParticipation} SetSMSParticipation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetSMSParticipation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetSMSParticipation message.
         * @function verify
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetSMSParticipation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.formSessionId != null && message.hasOwnProperty("formSessionId"))
                if (!$util.isString(message.formSessionId))
                    return "formSessionId: string expected";
            if (message.confirmed != null && message.hasOwnProperty("confirmed"))
                if (typeof message.confirmed !== "boolean")
                    return "confirmed: boolean expected";
            return null;
        };

        /**
         * Creates a SetSMSParticipation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {participation.SetSMSParticipation} SetSMSParticipation
         */
        SetSMSParticipation.fromObject = function fromObject(object) {
            if (object instanceof $root.participation.SetSMSParticipation)
                return object;
            let message = new $root.participation.SetSMSParticipation();
            if (object.formSessionId != null)
                message.formSessionId = String(object.formSessionId);
            if (object.confirmed != null)
                message.confirmed = Boolean(object.confirmed);
            return message;
        };

        /**
         * Creates a plain object from a SetSMSParticipation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {participation.SetSMSParticipation} message SetSMSParticipation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetSMSParticipation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.formSessionId = "";
                object.confirmed = false;
            }
            if (message.formSessionId != null && message.hasOwnProperty("formSessionId"))
                object.formSessionId = message.formSessionId;
            if (message.confirmed != null && message.hasOwnProperty("confirmed"))
                object.confirmed = message.confirmed;
            return object;
        };

        /**
         * Converts this SetSMSParticipation to JSON.
         * @function toJSON
         * @memberof participation.SetSMSParticipation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetSMSParticipation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for SetSMSParticipation
         * @function getTypeUrl
         * @memberof participation.SetSMSParticipation
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        SetSMSParticipation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/participation.SetSMSParticipation";
        };

        return SetSMSParticipation;
    })();

    return participation;
})();

export const admin_participants = $root.admin_participants = (() => {

    /**
     * Namespace admin_participants.
     * @exports admin_participants
     * @namespace
     */
    const admin_participants = {};

    admin_participants.AdminLoadParticipants = (function() {

        /**
         * Properties of an AdminLoadParticipants.
         * @memberof admin_participants
         * @interface IAdminLoadParticipants
         * @property {number|null} [page] AdminLoadParticipants page
         * @property {string|null} [tecajTipKey] AdminLoadParticipants tecajTipKey
         * @property {string|null} [tecajKey] AdminLoadParticipants tecajKey
         * @property {string|null} [search] AdminLoadParticipants search
         * @property {cvv_types.IDate|null} [date] AdminLoadParticipants date
         * @property {string|null} [tecajId] AdminLoadParticipants tecajId
         */

        /**
         * Constructs a new AdminLoadParticipants.
         * @memberof admin_participants
         * @classdesc Represents an AdminLoadParticipants.
         * @implements IAdminLoadParticipants
         * @constructor
         * @param {admin_participants.IAdminLoadParticipants=} [properties] Properties to set
         */
        function AdminLoadParticipants(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminLoadParticipants page.
         * @member {number} page
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         */
        AdminLoadParticipants.prototype.page = 0;

        /**
         * AdminLoadParticipants tecajTipKey.
         * @member {string} tecajTipKey
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         */
        AdminLoadParticipants.prototype.tecajTipKey = "";

        /**
         * AdminLoadParticipants tecajKey.
         * @member {string} tecajKey
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         */
        AdminLoadParticipants.prototype.tecajKey = "";

        /**
         * AdminLoadParticipants search.
         * @member {string} search
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         */
        AdminLoadParticipants.prototype.search = "";

        /**
         * AdminLoadParticipants date.
         * @member {cvv_types.IDate|null|undefined} date
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         */
        AdminLoadParticipants.prototype.date = null;

        /**
         * AdminLoadParticipants tecajId.
         * @member {string} tecajId
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         */
        AdminLoadParticipants.prototype.tecajId = "";

        /**
         * Creates a new AdminLoadParticipants instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {admin_participants.IAdminLoadParticipants=} [properties] Properties to set
         * @returns {admin_participants.AdminLoadParticipants} AdminLoadParticipants instance
         */
        AdminLoadParticipants.create = function create(properties) {
            return new AdminLoadParticipants(properties);
        };

        /**
         * Encodes the specified AdminLoadParticipants message. Does not implicitly {@link admin_participants.AdminLoadParticipants.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {admin_participants.IAdminLoadParticipants} message AdminLoadParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadParticipants.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.tecajTipKey != null && Object.hasOwnProperty.call(message, "tecajTipKey"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecajTipKey);
            if (message.tecajKey != null && Object.hasOwnProperty.call(message, "tecajKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.tecajKey);
            if (message.search != null && Object.hasOwnProperty.call(message, "search"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.search);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.cvv_types.Date.encode(message.date, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.tecajId != null && Object.hasOwnProperty.call(message, "tecajId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.tecajId);
            return writer;
        };

        /**
         * Encodes the specified AdminLoadParticipants message, length delimited. Does not implicitly {@link admin_participants.AdminLoadParticipants.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {admin_participants.IAdminLoadParticipants} message AdminLoadParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadParticipants.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminLoadParticipants message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminLoadParticipants} AdminLoadParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadParticipants.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminLoadParticipants();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.page = reader.int32();
                        break;
                    }
                case 2: {
                        message.tecajTipKey = reader.string();
                        break;
                    }
                case 3: {
                        message.tecajKey = reader.string();
                        break;
                    }
                case 4: {
                        message.search = reader.string();
                        break;
                    }
                case 5: {
                        message.date = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.tecajId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminLoadParticipants message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminLoadParticipants} AdminLoadParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadParticipants.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminLoadParticipants message.
         * @function verify
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminLoadParticipants.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.page != null && message.hasOwnProperty("page"))
                if (!$util.isInteger(message.page))
                    return "page: integer expected";
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                if (!$util.isString(message.tecajTipKey))
                    return "tecajTipKey: string expected";
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                if (!$util.isString(message.tecajKey))
                    return "tecajKey: string expected";
            if (message.search != null && message.hasOwnProperty("search"))
                if (!$util.isString(message.search))
                    return "search: string expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.cvv_types.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                if (!$util.isString(message.tecajId))
                    return "tecajId: string expected";
            return null;
        };

        /**
         * Creates an AdminLoadParticipants message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminLoadParticipants} AdminLoadParticipants
         */
        AdminLoadParticipants.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminLoadParticipants)
                return object;
            let message = new $root.admin_participants.AdminLoadParticipants();
            if (object.page != null)
                message.page = object.page | 0;
            if (object.tecajTipKey != null)
                message.tecajTipKey = String(object.tecajTipKey);
            if (object.tecajKey != null)
                message.tecajKey = String(object.tecajKey);
            if (object.search != null)
                message.search = String(object.search);
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".admin_participants.AdminLoadParticipants.date: object expected");
                message.date = $root.cvv_types.Date.fromObject(object.date);
            }
            if (object.tecajId != null)
                message.tecajId = String(object.tecajId);
            return message;
        };

        /**
         * Creates a plain object from an AdminLoadParticipants message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {admin_participants.AdminLoadParticipants} message AdminLoadParticipants
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminLoadParticipants.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.page = 0;
                object.tecajTipKey = "";
                object.tecajKey = "";
                object.search = "";
                object.date = null;
                object.tecajId = "";
            }
            if (message.page != null && message.hasOwnProperty("page"))
                object.page = message.page;
            if (message.tecajTipKey != null && message.hasOwnProperty("tecajTipKey"))
                object.tecajTipKey = message.tecajTipKey;
            if (message.tecajKey != null && message.hasOwnProperty("tecajKey"))
                object.tecajKey = message.tecajKey;
            if (message.search != null && message.hasOwnProperty("search"))
                object.search = message.search;
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.cvv_types.Date.toObject(message.date, options);
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                object.tecajId = message.tecajId;
            return object;
        };

        /**
         * Converts this AdminLoadParticipants to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminLoadParticipants
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminLoadParticipants.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminLoadParticipants
         * @function getTypeUrl
         * @memberof admin_participants.AdminLoadParticipants
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminLoadParticipants.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminLoadParticipants";
        };

        return AdminLoadParticipants;
    })();

    admin_participants.AdminParticipants = (function() {

        /**
         * Properties of an AdminParticipants.
         * @memberof admin_participants
         * @interface IAdminParticipants
         * @property {Array.<admin_participants.IParticipantSummary>|null} [participants] AdminParticipants participants
         * @property {number|null} [nPages] AdminParticipants nPages
         */

        /**
         * Constructs a new AdminParticipants.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipants.
         * @implements IAdminParticipants
         * @constructor
         * @param {admin_participants.IAdminParticipants=} [properties] Properties to set
         */
        function AdminParticipants(properties) {
            this.participants = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipants participants.
         * @member {Array.<admin_participants.IParticipantSummary>} participants
         * @memberof admin_participants.AdminParticipants
         * @instance
         */
        AdminParticipants.prototype.participants = $util.emptyArray;

        /**
         * AdminParticipants nPages.
         * @member {number} nPages
         * @memberof admin_participants.AdminParticipants
         * @instance
         */
        AdminParticipants.prototype.nPages = 0;

        /**
         * Creates a new AdminParticipants instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {admin_participants.IAdminParticipants=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipants} AdminParticipants instance
         */
        AdminParticipants.create = function create(properties) {
            return new AdminParticipants(properties);
        };

        /**
         * Encodes the specified AdminParticipants message. Does not implicitly {@link admin_participants.AdminParticipants.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {admin_participants.IAdminParticipants} message AdminParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipants.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.participants != null && message.participants.length)
                for (let i = 0; i < message.participants.length; ++i)
                    $root.admin_participants.ParticipantSummary.encode(message.participants[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.nPages != null && Object.hasOwnProperty.call(message, "nPages"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nPages);
            return writer;
        };

        /**
         * Encodes the specified AdminParticipants message, length delimited. Does not implicitly {@link admin_participants.AdminParticipants.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {admin_participants.IAdminParticipants} message AdminParticipants message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipants.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipants message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipants} AdminParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipants.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipants();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.participants && message.participants.length))
                            message.participants = [];
                        message.participants.push($root.admin_participants.ParticipantSummary.decode(reader, reader.uint32()));
                        break;
                    }
                case 2: {
                        message.nPages = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipants message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipants} AdminParticipants
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipants.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipants message.
         * @function verify
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipants.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.participants != null && message.hasOwnProperty("participants")) {
                if (!Array.isArray(message.participants))
                    return "participants: array expected";
                for (let i = 0; i < message.participants.length; ++i) {
                    let error = $root.admin_participants.ParticipantSummary.verify(message.participants[i]);
                    if (error)
                        return "participants." + error;
                }
            }
            if (message.nPages != null && message.hasOwnProperty("nPages"))
                if (!$util.isInteger(message.nPages))
                    return "nPages: integer expected";
            return null;
        };

        /**
         * Creates an AdminParticipants message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipants} AdminParticipants
         */
        AdminParticipants.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipants)
                return object;
            let message = new $root.admin_participants.AdminParticipants();
            if (object.participants) {
                if (!Array.isArray(object.participants))
                    throw TypeError(".admin_participants.AdminParticipants.participants: array expected");
                message.participants = [];
                for (let i = 0; i < object.participants.length; ++i) {
                    if (typeof object.participants[i] !== "object")
                        throw TypeError(".admin_participants.AdminParticipants.participants: object expected");
                    message.participants[i] = $root.admin_participants.ParticipantSummary.fromObject(object.participants[i]);
                }
            }
            if (object.nPages != null)
                message.nPages = object.nPages | 0;
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipants message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {admin_participants.AdminParticipants} message AdminParticipants
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipants.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.participants = [];
            if (options.defaults)
                object.nPages = 0;
            if (message.participants && message.participants.length) {
                object.participants = [];
                for (let j = 0; j < message.participants.length; ++j)
                    object.participants[j] = $root.admin_participants.ParticipantSummary.toObject(message.participants[j], options);
            }
            if (message.nPages != null && message.hasOwnProperty("nPages"))
                object.nPages = message.nPages;
            return object;
        };

        /**
         * Converts this AdminParticipants to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipants
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipants.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipants
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipants
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipants.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipants";
        };

        return AdminParticipants;
    })();

    admin_participants.AdminLoadParticipant = (function() {

        /**
         * Properties of an AdminLoadParticipant.
         * @memberof admin_participants
         * @interface IAdminLoadParticipant
         * @property {string|null} [id] AdminLoadParticipant id
         */

        /**
         * Constructs a new AdminLoadParticipant.
         * @memberof admin_participants
         * @classdesc Represents an AdminLoadParticipant.
         * @implements IAdminLoadParticipant
         * @constructor
         * @param {admin_participants.IAdminLoadParticipant=} [properties] Properties to set
         */
        function AdminLoadParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminLoadParticipant id.
         * @member {string} id
         * @memberof admin_participants.AdminLoadParticipant
         * @instance
         */
        AdminLoadParticipant.prototype.id = "";

        /**
         * Creates a new AdminLoadParticipant instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {admin_participants.IAdminLoadParticipant=} [properties] Properties to set
         * @returns {admin_participants.AdminLoadParticipant} AdminLoadParticipant instance
         */
        AdminLoadParticipant.create = function create(properties) {
            return new AdminLoadParticipant(properties);
        };

        /**
         * Encodes the specified AdminLoadParticipant message. Does not implicitly {@link admin_participants.AdminLoadParticipant.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {admin_participants.IAdminLoadParticipant} message AdminLoadParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified AdminLoadParticipant message, length delimited. Does not implicitly {@link admin_participants.AdminLoadParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {admin_participants.IAdminLoadParticipant} message AdminLoadParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminLoadParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminLoadParticipant} AdminLoadParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminLoadParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.id = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminLoadParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminLoadParticipant} AdminLoadParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminLoadParticipant message.
         * @function verify
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminLoadParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates an AdminLoadParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminLoadParticipant} AdminLoadParticipant
         */
        AdminLoadParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminLoadParticipant)
                return object;
            let message = new $root.admin_participants.AdminLoadParticipant();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from an AdminLoadParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {admin_participants.AdminLoadParticipant} message AdminLoadParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminLoadParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this AdminLoadParticipant to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminLoadParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminLoadParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminLoadParticipant
         * @function getTypeUrl
         * @memberof admin_participants.AdminLoadParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminLoadParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminLoadParticipant";
        };

        return AdminLoadParticipant;
    })();

    admin_participants.AdminParticipant = (function() {

        /**
         * Properties of an AdminParticipant.
         * @memberof admin_participants
         * @interface IAdminParticipant
         * @property {admin_participants.IParticipantSummary|null} [summary] AdminParticipant summary
         * @property {Array.<calendar.IAvailableLecture>|null} [alternativeDates] AdminParticipant alternativeDates
         * @property {mladi_voznik.IMladiVoznikForm|null} [form] AdminParticipant form
         * @property {mladi_voznik.IMladiVoznikFormConfig|null} [formConfig] AdminParticipant formConfig
         * @property {string|null} [clanskaStevilka] AdminParticipant clanskaStevilka
         * @property {Array.<admin_participants.IPaymentEntry>|null} [payments] AdminParticipant payments
         * @property {number|null} [amountPaid] AdminParticipant amountPaid
         * @property {number|null} [amountTotal] AdminParticipant amountTotal
         */

        /**
         * Constructs a new AdminParticipant.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipant.
         * @implements IAdminParticipant
         * @constructor
         * @param {admin_participants.IAdminParticipant=} [properties] Properties to set
         */
        function AdminParticipant(properties) {
            this.alternativeDates = [];
            this.payments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipant summary.
         * @member {admin_participants.IParticipantSummary|null|undefined} summary
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.summary = null;

        /**
         * AdminParticipant alternativeDates.
         * @member {Array.<calendar.IAvailableLecture>} alternativeDates
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.alternativeDates = $util.emptyArray;

        /**
         * AdminParticipant form.
         * @member {mladi_voznik.IMladiVoznikForm|null|undefined} form
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.form = null;

        /**
         * AdminParticipant formConfig.
         * @member {mladi_voznik.IMladiVoznikFormConfig|null|undefined} formConfig
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.formConfig = null;

        /**
         * AdminParticipant clanskaStevilka.
         * @member {string} clanskaStevilka
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.clanskaStevilka = "";

        /**
         * AdminParticipant payments.
         * @member {Array.<admin_participants.IPaymentEntry>} payments
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.payments = $util.emptyArray;

        /**
         * AdminParticipant amountPaid.
         * @member {number} amountPaid
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.amountPaid = 0;

        /**
         * AdminParticipant amountTotal.
         * @member {number} amountTotal
         * @memberof admin_participants.AdminParticipant
         * @instance
         */
        AdminParticipant.prototype.amountTotal = 0;

        /**
         * Creates a new AdminParticipant instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {admin_participants.IAdminParticipant=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipant} AdminParticipant instance
         */
        AdminParticipant.create = function create(properties) {
            return new AdminParticipant(properties);
        };

        /**
         * Encodes the specified AdminParticipant message. Does not implicitly {@link admin_participants.AdminParticipant.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {admin_participants.IAdminParticipant} message AdminParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                $root.admin_participants.ParticipantSummary.encode(message.summary, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.alternativeDates != null && message.alternativeDates.length)
                for (let i = 0; i < message.alternativeDates.length; ++i)
                    $root.calendar.AvailableLecture.encode(message.alternativeDates[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.form != null && Object.hasOwnProperty.call(message, "form"))
                $root.mladi_voznik.MladiVoznikForm.encode(message.form, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.formConfig != null && Object.hasOwnProperty.call(message, "formConfig"))
                $root.mladi_voznik.MladiVoznikFormConfig.encode(message.formConfig, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.clanskaStevilka != null && Object.hasOwnProperty.call(message, "clanskaStevilka"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.clanskaStevilka);
            if (message.payments != null && message.payments.length)
                for (let i = 0; i < message.payments.length; ++i)
                    $root.admin_participants.PaymentEntry.encode(message.payments[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.amountPaid != null && Object.hasOwnProperty.call(message, "amountPaid"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.amountPaid);
            if (message.amountTotal != null && Object.hasOwnProperty.call(message, "amountTotal"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.amountTotal);
            return writer;
        };

        /**
         * Encodes the specified AdminParticipant message, length delimited. Does not implicitly {@link admin_participants.AdminParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {admin_participants.IAdminParticipant} message AdminParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipant} AdminParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.summary = $root.admin_participants.ParticipantSummary.decode(reader, reader.uint32());
                        break;
                    }
                case 2: {
                        if (!(message.alternativeDates && message.alternativeDates.length))
                            message.alternativeDates = [];
                        message.alternativeDates.push($root.calendar.AvailableLecture.decode(reader, reader.uint32()));
                        break;
                    }
                case 4: {
                        message.form = $root.mladi_voznik.MladiVoznikForm.decode(reader, reader.uint32());
                        break;
                    }
                case 5: {
                        message.formConfig = $root.mladi_voznik.MladiVoznikFormConfig.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.clanskaStevilka = reader.string();
                        break;
                    }
                case 7: {
                        if (!(message.payments && message.payments.length))
                            message.payments = [];
                        message.payments.push($root.admin_participants.PaymentEntry.decode(reader, reader.uint32()));
                        break;
                    }
                case 8: {
                        message.amountPaid = reader.int32();
                        break;
                    }
                case 9: {
                        message.amountTotal = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipant} AdminParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipant message.
         * @function verify
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.summary != null && message.hasOwnProperty("summary")) {
                let error = $root.admin_participants.ParticipantSummary.verify(message.summary);
                if (error)
                    return "summary." + error;
            }
            if (message.alternativeDates != null && message.hasOwnProperty("alternativeDates")) {
                if (!Array.isArray(message.alternativeDates))
                    return "alternativeDates: array expected";
                for (let i = 0; i < message.alternativeDates.length; ++i) {
                    let error = $root.calendar.AvailableLecture.verify(message.alternativeDates[i]);
                    if (error)
                        return "alternativeDates." + error;
                }
            }
            if (message.form != null && message.hasOwnProperty("form")) {
                let error = $root.mladi_voznik.MladiVoznikForm.verify(message.form);
                if (error)
                    return "form." + error;
            }
            if (message.formConfig != null && message.hasOwnProperty("formConfig")) {
                let error = $root.mladi_voznik.MladiVoznikFormConfig.verify(message.formConfig);
                if (error)
                    return "formConfig." + error;
            }
            if (message.clanskaStevilka != null && message.hasOwnProperty("clanskaStevilka"))
                if (!$util.isString(message.clanskaStevilka))
                    return "clanskaStevilka: string expected";
            if (message.payments != null && message.hasOwnProperty("payments")) {
                if (!Array.isArray(message.payments))
                    return "payments: array expected";
                for (let i = 0; i < message.payments.length; ++i) {
                    let error = $root.admin_participants.PaymentEntry.verify(message.payments[i]);
                    if (error)
                        return "payments." + error;
                }
            }
            if (message.amountPaid != null && message.hasOwnProperty("amountPaid"))
                if (!$util.isInteger(message.amountPaid))
                    return "amountPaid: integer expected";
            if (message.amountTotal != null && message.hasOwnProperty("amountTotal"))
                if (!$util.isInteger(message.amountTotal))
                    return "amountTotal: integer expected";
            return null;
        };

        /**
         * Creates an AdminParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipant} AdminParticipant
         */
        AdminParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipant)
                return object;
            let message = new $root.admin_participants.AdminParticipant();
            if (object.summary != null) {
                if (typeof object.summary !== "object")
                    throw TypeError(".admin_participants.AdminParticipant.summary: object expected");
                message.summary = $root.admin_participants.ParticipantSummary.fromObject(object.summary);
            }
            if (object.alternativeDates) {
                if (!Array.isArray(object.alternativeDates))
                    throw TypeError(".admin_participants.AdminParticipant.alternativeDates: array expected");
                message.alternativeDates = [];
                for (let i = 0; i < object.alternativeDates.length; ++i) {
                    if (typeof object.alternativeDates[i] !== "object")
                        throw TypeError(".admin_participants.AdminParticipant.alternativeDates: object expected");
                    message.alternativeDates[i] = $root.calendar.AvailableLecture.fromObject(object.alternativeDates[i]);
                }
            }
            if (object.form != null) {
                if (typeof object.form !== "object")
                    throw TypeError(".admin_participants.AdminParticipant.form: object expected");
                message.form = $root.mladi_voznik.MladiVoznikForm.fromObject(object.form);
            }
            if (object.formConfig != null) {
                if (typeof object.formConfig !== "object")
                    throw TypeError(".admin_participants.AdminParticipant.formConfig: object expected");
                message.formConfig = $root.mladi_voznik.MladiVoznikFormConfig.fromObject(object.formConfig);
            }
            if (object.clanskaStevilka != null)
                message.clanskaStevilka = String(object.clanskaStevilka);
            if (object.payments) {
                if (!Array.isArray(object.payments))
                    throw TypeError(".admin_participants.AdminParticipant.payments: array expected");
                message.payments = [];
                for (let i = 0; i < object.payments.length; ++i) {
                    if (typeof object.payments[i] !== "object")
                        throw TypeError(".admin_participants.AdminParticipant.payments: object expected");
                    message.payments[i] = $root.admin_participants.PaymentEntry.fromObject(object.payments[i]);
                }
            }
            if (object.amountPaid != null)
                message.amountPaid = object.amountPaid | 0;
            if (object.amountTotal != null)
                message.amountTotal = object.amountTotal | 0;
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {admin_participants.AdminParticipant} message AdminParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.alternativeDates = [];
                object.payments = [];
            }
            if (options.defaults) {
                object.summary = null;
                object.form = null;
                object.formConfig = null;
                object.clanskaStevilka = "";
                object.amountPaid = 0;
                object.amountTotal = 0;
            }
            if (message.summary != null && message.hasOwnProperty("summary"))
                object.summary = $root.admin_participants.ParticipantSummary.toObject(message.summary, options);
            if (message.alternativeDates && message.alternativeDates.length) {
                object.alternativeDates = [];
                for (let j = 0; j < message.alternativeDates.length; ++j)
                    object.alternativeDates[j] = $root.calendar.AvailableLecture.toObject(message.alternativeDates[j], options);
            }
            if (message.form != null && message.hasOwnProperty("form"))
                object.form = $root.mladi_voznik.MladiVoznikForm.toObject(message.form, options);
            if (message.formConfig != null && message.hasOwnProperty("formConfig"))
                object.formConfig = $root.mladi_voznik.MladiVoznikFormConfig.toObject(message.formConfig, options);
            if (message.clanskaStevilka != null && message.hasOwnProperty("clanskaStevilka"))
                object.clanskaStevilka = message.clanskaStevilka;
            if (message.payments && message.payments.length) {
                object.payments = [];
                for (let j = 0; j < message.payments.length; ++j)
                    object.payments[j] = $root.admin_participants.PaymentEntry.toObject(message.payments[j], options);
            }
            if (message.amountPaid != null && message.hasOwnProperty("amountPaid"))
                object.amountPaid = message.amountPaid;
            if (message.amountTotal != null && message.hasOwnProperty("amountTotal"))
                object.amountTotal = message.amountTotal;
            return object;
        };

        /**
         * Converts this AdminParticipant to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipant
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipant";
        };

        return AdminParticipant;
    })();

    admin_participants.PaymentEntry = (function() {

        /**
         * Properties of a PaymentEntry.
         * @memberof admin_participants
         * @interface IPaymentEntry
         * @property {billing.PaymentType|null} [type] PaymentEntry type
         * @property {number|null} [amount] PaymentEntry amount
         * @property {string|null} [darilniBon] PaymentEntry darilniBon
         * @property {string|null} [stripePayment] PaymentEntry stripePayment
         * @property {cvv_types.IDate|null} [date] PaymentEntry date
         * @property {string|null} [comment] PaymentEntry comment
         */

        /**
         * Constructs a new PaymentEntry.
         * @memberof admin_participants
         * @classdesc Represents a PaymentEntry.
         * @implements IPaymentEntry
         * @constructor
         * @param {admin_participants.IPaymentEntry=} [properties] Properties to set
         */
        function PaymentEntry(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaymentEntry type.
         * @member {billing.PaymentType} type
         * @memberof admin_participants.PaymentEntry
         * @instance
         */
        PaymentEntry.prototype.type = 0;

        /**
         * PaymentEntry amount.
         * @member {number} amount
         * @memberof admin_participants.PaymentEntry
         * @instance
         */
        PaymentEntry.prototype.amount = 0;

        /**
         * PaymentEntry darilniBon.
         * @member {string} darilniBon
         * @memberof admin_participants.PaymentEntry
         * @instance
         */
        PaymentEntry.prototype.darilniBon = "";

        /**
         * PaymentEntry stripePayment.
         * @member {string} stripePayment
         * @memberof admin_participants.PaymentEntry
         * @instance
         */
        PaymentEntry.prototype.stripePayment = "";

        /**
         * PaymentEntry date.
         * @member {cvv_types.IDate|null|undefined} date
         * @memberof admin_participants.PaymentEntry
         * @instance
         */
        PaymentEntry.prototype.date = null;

        /**
         * PaymentEntry comment.
         * @member {string} comment
         * @memberof admin_participants.PaymentEntry
         * @instance
         */
        PaymentEntry.prototype.comment = "";

        /**
         * Creates a new PaymentEntry instance using the specified properties.
         * @function create
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {admin_participants.IPaymentEntry=} [properties] Properties to set
         * @returns {admin_participants.PaymentEntry} PaymentEntry instance
         */
        PaymentEntry.create = function create(properties) {
            return new PaymentEntry(properties);
        };

        /**
         * Encodes the specified PaymentEntry message. Does not implicitly {@link admin_participants.PaymentEntry.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {admin_participants.IPaymentEntry} message PaymentEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentEntry.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.amount);
            if (message.darilniBon != null && Object.hasOwnProperty.call(message, "darilniBon"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.darilniBon);
            if (message.stripePayment != null && Object.hasOwnProperty.call(message, "stripePayment"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.stripePayment);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                $root.cvv_types.Date.encode(message.date, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.comment);
            return writer;
        };

        /**
         * Encodes the specified PaymentEntry message, length delimited. Does not implicitly {@link admin_participants.PaymentEntry.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {admin_participants.IPaymentEntry} message PaymentEntry message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaymentEntry.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PaymentEntry message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.PaymentEntry} PaymentEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentEntry.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.PaymentEntry();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.type = reader.int32();
                        break;
                    }
                case 2: {
                        message.amount = reader.int32();
                        break;
                    }
                case 3: {
                        message.darilniBon = reader.string();
                        break;
                    }
                case 4: {
                        message.stripePayment = reader.string();
                        break;
                    }
                case 5: {
                        message.date = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 6: {
                        message.comment = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PaymentEntry message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.PaymentEntry} PaymentEntry
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaymentEntry.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PaymentEntry message.
         * @function verify
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PaymentEntry.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.type != null && message.hasOwnProperty("type"))
                switch (message.type) {
                default:
                    return "type: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.amount != null && message.hasOwnProperty("amount"))
                if (!$util.isInteger(message.amount))
                    return "amount: integer expected";
            if (message.darilniBon != null && message.hasOwnProperty("darilniBon"))
                if (!$util.isString(message.darilniBon))
                    return "darilniBon: string expected";
            if (message.stripePayment != null && message.hasOwnProperty("stripePayment"))
                if (!$util.isString(message.stripePayment))
                    return "stripePayment: string expected";
            if (message.date != null && message.hasOwnProperty("date")) {
                let error = $root.cvv_types.Date.verify(message.date);
                if (error)
                    return "date." + error;
            }
            if (message.comment != null && message.hasOwnProperty("comment"))
                if (!$util.isString(message.comment))
                    return "comment: string expected";
            return null;
        };

        /**
         * Creates a PaymentEntry message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.PaymentEntry} PaymentEntry
         */
        PaymentEntry.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.PaymentEntry)
                return object;
            let message = new $root.admin_participants.PaymentEntry();
            switch (object.type) {
            default:
                if (typeof object.type === "number") {
                    message.type = object.type;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.type = 0;
                break;
            case "darilni_bon":
            case 1:
                message.type = 1;
                break;
            case "gotovina":
            case 2:
                message.type = 2;
                break;
            case "predracun":
            case 3:
                message.type = 3;
                break;
            }
            if (object.amount != null)
                message.amount = object.amount | 0;
            if (object.darilniBon != null)
                message.darilniBon = String(object.darilniBon);
            if (object.stripePayment != null)
                message.stripePayment = String(object.stripePayment);
            if (object.date != null) {
                if (typeof object.date !== "object")
                    throw TypeError(".admin_participants.PaymentEntry.date: object expected");
                message.date = $root.cvv_types.Date.fromObject(object.date);
            }
            if (object.comment != null)
                message.comment = String(object.comment);
            return message;
        };

        /**
         * Creates a plain object from a PaymentEntry message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {admin_participants.PaymentEntry} message PaymentEntry
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PaymentEntry.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.type = options.enums === String ? "kreditna" : 0;
                object.amount = 0;
                object.darilniBon = "";
                object.stripePayment = "";
                object.date = null;
                object.comment = "";
            }
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = options.enums === String ? $root.billing.PaymentType[message.type] === undefined ? message.type : $root.billing.PaymentType[message.type] : message.type;
            if (message.amount != null && message.hasOwnProperty("amount"))
                object.amount = message.amount;
            if (message.darilniBon != null && message.hasOwnProperty("darilniBon"))
                object.darilniBon = message.darilniBon;
            if (message.stripePayment != null && message.hasOwnProperty("stripePayment"))
                object.stripePayment = message.stripePayment;
            if (message.date != null && message.hasOwnProperty("date"))
                object.date = $root.cvv_types.Date.toObject(message.date, options);
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = message.comment;
            return object;
        };

        /**
         * Converts this PaymentEntry to JSON.
         * @function toJSON
         * @memberof admin_participants.PaymentEntry
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PaymentEntry.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PaymentEntry
         * @function getTypeUrl
         * @memberof admin_participants.PaymentEntry
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PaymentEntry.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.PaymentEntry";
        };

        return PaymentEntry;
    })();

    admin_participants.AdminParticipantComments = (function() {

        /**
         * Properties of an AdminParticipantComments.
         * @memberof admin_participants
         * @interface IAdminParticipantComments
         * @property {Array.<cvv_types.IComment>|null} [comments] AdminParticipantComments comments
         */

        /**
         * Constructs a new AdminParticipantComments.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipantComments.
         * @implements IAdminParticipantComments
         * @constructor
         * @param {admin_participants.IAdminParticipantComments=} [properties] Properties to set
         */
        function AdminParticipantComments(properties) {
            this.comments = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipantComments comments.
         * @member {Array.<cvv_types.IComment>} comments
         * @memberof admin_participants.AdminParticipantComments
         * @instance
         */
        AdminParticipantComments.prototype.comments = $util.emptyArray;

        /**
         * Creates a new AdminParticipantComments instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {admin_participants.IAdminParticipantComments=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipantComments} AdminParticipantComments instance
         */
        AdminParticipantComments.create = function create(properties) {
            return new AdminParticipantComments(properties);
        };

        /**
         * Encodes the specified AdminParticipantComments message. Does not implicitly {@link admin_participants.AdminParticipantComments.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {admin_participants.IAdminParticipantComments} message AdminParticipantComments message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantComments.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.comments != null && message.comments.length)
                for (let i = 0; i < message.comments.length; ++i)
                    $root.cvv_types.Comment.encode(message.comments[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminParticipantComments message, length delimited. Does not implicitly {@link admin_participants.AdminParticipantComments.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {admin_participants.IAdminParticipantComments} message AdminParticipantComments message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantComments.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipantComments message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipantComments} AdminParticipantComments
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantComments.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipantComments();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.comments && message.comments.length))
                            message.comments = [];
                        message.comments.push($root.cvv_types.Comment.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipantComments message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipantComments} AdminParticipantComments
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantComments.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipantComments message.
         * @function verify
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipantComments.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.comments != null && message.hasOwnProperty("comments")) {
                if (!Array.isArray(message.comments))
                    return "comments: array expected";
                for (let i = 0; i < message.comments.length; ++i) {
                    let error = $root.cvv_types.Comment.verify(message.comments[i]);
                    if (error)
                        return "comments." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdminParticipantComments message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipantComments} AdminParticipantComments
         */
        AdminParticipantComments.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipantComments)
                return object;
            let message = new $root.admin_participants.AdminParticipantComments();
            if (object.comments) {
                if (!Array.isArray(object.comments))
                    throw TypeError(".admin_participants.AdminParticipantComments.comments: array expected");
                message.comments = [];
                for (let i = 0; i < object.comments.length; ++i) {
                    if (typeof object.comments[i] !== "object")
                        throw TypeError(".admin_participants.AdminParticipantComments.comments: object expected");
                    message.comments[i] = $root.cvv_types.Comment.fromObject(object.comments[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipantComments message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {admin_participants.AdminParticipantComments} message AdminParticipantComments
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipantComments.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.comments = [];
            if (message.comments && message.comments.length) {
                object.comments = [];
                for (let j = 0; j < message.comments.length; ++j)
                    object.comments[j] = $root.cvv_types.Comment.toObject(message.comments[j], options);
            }
            return object;
        };

        /**
         * Converts this AdminParticipantComments to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipantComments
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipantComments.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipantComments
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipantComments
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipantComments.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipantComments";
        };

        return AdminParticipantComments;
    })();

    admin_participants.AdminParticipantSaveComment = (function() {

        /**
         * Properties of an AdminParticipantSaveComment.
         * @memberof admin_participants
         * @interface IAdminParticipantSaveComment
         * @property {string|null} [sessionId] AdminParticipantSaveComment sessionId
         * @property {cvv_types.IComment|null} [comment] AdminParticipantSaveComment comment
         */

        /**
         * Constructs a new AdminParticipantSaveComment.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipantSaveComment.
         * @implements IAdminParticipantSaveComment
         * @constructor
         * @param {admin_participants.IAdminParticipantSaveComment=} [properties] Properties to set
         */
        function AdminParticipantSaveComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipantSaveComment sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminParticipantSaveComment
         * @instance
         */
        AdminParticipantSaveComment.prototype.sessionId = "";

        /**
         * AdminParticipantSaveComment comment.
         * @member {cvv_types.IComment|null|undefined} comment
         * @memberof admin_participants.AdminParticipantSaveComment
         * @instance
         */
        AdminParticipantSaveComment.prototype.comment = null;

        /**
         * Creates a new AdminParticipantSaveComment instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {admin_participants.IAdminParticipantSaveComment=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipantSaveComment} AdminParticipantSaveComment instance
         */
        AdminParticipantSaveComment.create = function create(properties) {
            return new AdminParticipantSaveComment(properties);
        };

        /**
         * Encodes the specified AdminParticipantSaveComment message. Does not implicitly {@link admin_participants.AdminParticipantSaveComment.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {admin_participants.IAdminParticipantSaveComment} message AdminParticipantSaveComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantSaveComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                $root.cvv_types.Comment.encode(message.comment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminParticipantSaveComment message, length delimited. Does not implicitly {@link admin_participants.AdminParticipantSaveComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {admin_participants.IAdminParticipantSaveComment} message AdminParticipantSaveComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantSaveComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipantSaveComment message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipantSaveComment} AdminParticipantSaveComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantSaveComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipantSaveComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.comment = $root.cvv_types.Comment.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipantSaveComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipantSaveComment} AdminParticipantSaveComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantSaveComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipantSaveComment message.
         * @function verify
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipantSaveComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.comment != null && message.hasOwnProperty("comment")) {
                let error = $root.cvv_types.Comment.verify(message.comment);
                if (error)
                    return "comment." + error;
            }
            return null;
        };

        /**
         * Creates an AdminParticipantSaveComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipantSaveComment} AdminParticipantSaveComment
         */
        AdminParticipantSaveComment.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipantSaveComment)
                return object;
            let message = new $root.admin_participants.AdminParticipantSaveComment();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.comment != null) {
                if (typeof object.comment !== "object")
                    throw TypeError(".admin_participants.AdminParticipantSaveComment.comment: object expected");
                message.comment = $root.cvv_types.Comment.fromObject(object.comment);
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipantSaveComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {admin_participants.AdminParticipantSaveComment} message AdminParticipantSaveComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipantSaveComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.comment = null;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.comment != null && message.hasOwnProperty("comment"))
                object.comment = $root.cvv_types.Comment.toObject(message.comment, options);
            return object;
        };

        /**
         * Converts this AdminParticipantSaveComment to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipantSaveComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipantSaveComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipantSaveComment
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipantSaveComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipantSaveComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipantSaveComment";
        };

        return AdminParticipantSaveComment;
    })();

    admin_participants.AdminParticipantDeleteComment = (function() {

        /**
         * Properties of an AdminParticipantDeleteComment.
         * @memberof admin_participants
         * @interface IAdminParticipantDeleteComment
         * @property {string|null} [commentId] AdminParticipantDeleteComment commentId
         */

        /**
         * Constructs a new AdminParticipantDeleteComment.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipantDeleteComment.
         * @implements IAdminParticipantDeleteComment
         * @constructor
         * @param {admin_participants.IAdminParticipantDeleteComment=} [properties] Properties to set
         */
        function AdminParticipantDeleteComment(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipantDeleteComment commentId.
         * @member {string} commentId
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @instance
         */
        AdminParticipantDeleteComment.prototype.commentId = "";

        /**
         * Creates a new AdminParticipantDeleteComment instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {admin_participants.IAdminParticipantDeleteComment=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipantDeleteComment} AdminParticipantDeleteComment instance
         */
        AdminParticipantDeleteComment.create = function create(properties) {
            return new AdminParticipantDeleteComment(properties);
        };

        /**
         * Encodes the specified AdminParticipantDeleteComment message. Does not implicitly {@link admin_participants.AdminParticipantDeleteComment.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {admin_participants.IAdminParticipantDeleteComment} message AdminParticipantDeleteComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantDeleteComment.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commentId != null && Object.hasOwnProperty.call(message, "commentId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.commentId);
            return writer;
        };

        /**
         * Encodes the specified AdminParticipantDeleteComment message, length delimited. Does not implicitly {@link admin_participants.AdminParticipantDeleteComment.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {admin_participants.IAdminParticipantDeleteComment} message AdminParticipantDeleteComment message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantDeleteComment.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipantDeleteComment message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipantDeleteComment} AdminParticipantDeleteComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantDeleteComment.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipantDeleteComment();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.commentId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipantDeleteComment message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipantDeleteComment} AdminParticipantDeleteComment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantDeleteComment.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipantDeleteComment message.
         * @function verify
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipantDeleteComment.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                if (!$util.isString(message.commentId))
                    return "commentId: string expected";
            return null;
        };

        /**
         * Creates an AdminParticipantDeleteComment message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipantDeleteComment} AdminParticipantDeleteComment
         */
        AdminParticipantDeleteComment.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipantDeleteComment)
                return object;
            let message = new $root.admin_participants.AdminParticipantDeleteComment();
            if (object.commentId != null)
                message.commentId = String(object.commentId);
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipantDeleteComment message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {admin_participants.AdminParticipantDeleteComment} message AdminParticipantDeleteComment
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipantDeleteComment.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.commentId = "";
            if (message.commentId != null && message.hasOwnProperty("commentId"))
                object.commentId = message.commentId;
            return object;
        };

        /**
         * Converts this AdminParticipantDeleteComment to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipantDeleteComment.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipantDeleteComment
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipantDeleteComment
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipantDeleteComment.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipantDeleteComment";
        };

        return AdminParticipantDeleteComment;
    })();

    admin_participants.AdminArchiveParticipant = (function() {

        /**
         * Properties of an AdminArchiveParticipant.
         * @memberof admin_participants
         * @interface IAdminArchiveParticipant
         * @property {string|null} [sessionId] AdminArchiveParticipant sessionId
         */

        /**
         * Constructs a new AdminArchiveParticipant.
         * @memberof admin_participants
         * @classdesc Represents an AdminArchiveParticipant.
         * @implements IAdminArchiveParticipant
         * @constructor
         * @param {admin_participants.IAdminArchiveParticipant=} [properties] Properties to set
         */
        function AdminArchiveParticipant(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminArchiveParticipant sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminArchiveParticipant
         * @instance
         */
        AdminArchiveParticipant.prototype.sessionId = "";

        /**
         * Creates a new AdminArchiveParticipant instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {admin_participants.IAdminArchiveParticipant=} [properties] Properties to set
         * @returns {admin_participants.AdminArchiveParticipant} AdminArchiveParticipant instance
         */
        AdminArchiveParticipant.create = function create(properties) {
            return new AdminArchiveParticipant(properties);
        };

        /**
         * Encodes the specified AdminArchiveParticipant message. Does not implicitly {@link admin_participants.AdminArchiveParticipant.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {admin_participants.IAdminArchiveParticipant} message AdminArchiveParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminArchiveParticipant.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified AdminArchiveParticipant message, length delimited. Does not implicitly {@link admin_participants.AdminArchiveParticipant.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {admin_participants.IAdminArchiveParticipant} message AdminArchiveParticipant message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminArchiveParticipant.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminArchiveParticipant message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminArchiveParticipant} AdminArchiveParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminArchiveParticipant.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminArchiveParticipant();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminArchiveParticipant message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminArchiveParticipant} AdminArchiveParticipant
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminArchiveParticipant.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminArchiveParticipant message.
         * @function verify
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminArchiveParticipant.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an AdminArchiveParticipant message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminArchiveParticipant} AdminArchiveParticipant
         */
        AdminArchiveParticipant.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminArchiveParticipant)
                return object;
            let message = new $root.admin_participants.AdminArchiveParticipant();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an AdminArchiveParticipant message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {admin_participants.AdminArchiveParticipant} message AdminArchiveParticipant
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminArchiveParticipant.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this AdminArchiveParticipant to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminArchiveParticipant
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminArchiveParticipant.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminArchiveParticipant
         * @function getTypeUrl
         * @memberof admin_participants.AdminArchiveParticipant
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminArchiveParticipant.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminArchiveParticipant";
        };

        return AdminArchiveParticipant;
    })();

    admin_participants.AdminParticipantSetNewDate = (function() {

        /**
         * Properties of an AdminParticipantSetNewDate.
         * @memberof admin_participants
         * @interface IAdminParticipantSetNewDate
         * @property {string|null} [sessionId] AdminParticipantSetNewDate sessionId
         * @property {calendar.IAvailableLecture|null} [lectureDate] AdminParticipantSetNewDate lectureDate
         */

        /**
         * Constructs a new AdminParticipantSetNewDate.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipantSetNewDate.
         * @implements IAdminParticipantSetNewDate
         * @constructor
         * @param {admin_participants.IAdminParticipantSetNewDate=} [properties] Properties to set
         */
        function AdminParticipantSetNewDate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipantSetNewDate sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @instance
         */
        AdminParticipantSetNewDate.prototype.sessionId = "";

        /**
         * AdminParticipantSetNewDate lectureDate.
         * @member {calendar.IAvailableLecture|null|undefined} lectureDate
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @instance
         */
        AdminParticipantSetNewDate.prototype.lectureDate = null;

        /**
         * Creates a new AdminParticipantSetNewDate instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {admin_participants.IAdminParticipantSetNewDate=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipantSetNewDate} AdminParticipantSetNewDate instance
         */
        AdminParticipantSetNewDate.create = function create(properties) {
            return new AdminParticipantSetNewDate(properties);
        };

        /**
         * Encodes the specified AdminParticipantSetNewDate message. Does not implicitly {@link admin_participants.AdminParticipantSetNewDate.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {admin_participants.IAdminParticipantSetNewDate} message AdminParticipantSetNewDate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantSetNewDate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.lectureDate != null && Object.hasOwnProperty.call(message, "lectureDate"))
                $root.calendar.AvailableLecture.encode(message.lectureDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminParticipantSetNewDate message, length delimited. Does not implicitly {@link admin_participants.AdminParticipantSetNewDate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {admin_participants.IAdminParticipantSetNewDate} message AdminParticipantSetNewDate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantSetNewDate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipantSetNewDate message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipantSetNewDate} AdminParticipantSetNewDate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantSetNewDate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipantSetNewDate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.lectureDate = $root.calendar.AvailableLecture.decode(reader, reader.uint32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipantSetNewDate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipantSetNewDate} AdminParticipantSetNewDate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantSetNewDate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipantSetNewDate message.
         * @function verify
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipantSetNewDate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.lectureDate != null && message.hasOwnProperty("lectureDate")) {
                let error = $root.calendar.AvailableLecture.verify(message.lectureDate);
                if (error)
                    return "lectureDate." + error;
            }
            return null;
        };

        /**
         * Creates an AdminParticipantSetNewDate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipantSetNewDate} AdminParticipantSetNewDate
         */
        AdminParticipantSetNewDate.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipantSetNewDate)
                return object;
            let message = new $root.admin_participants.AdminParticipantSetNewDate();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.lectureDate != null) {
                if (typeof object.lectureDate !== "object")
                    throw TypeError(".admin_participants.AdminParticipantSetNewDate.lectureDate: object expected");
                message.lectureDate = $root.calendar.AvailableLecture.fromObject(object.lectureDate);
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipantSetNewDate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {admin_participants.AdminParticipantSetNewDate} message AdminParticipantSetNewDate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipantSetNewDate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.lectureDate = null;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.lectureDate != null && message.hasOwnProperty("lectureDate"))
                object.lectureDate = $root.calendar.AvailableLecture.toObject(message.lectureDate, options);
            return object;
        };

        /**
         * Converts this AdminParticipantSetNewDate to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipantSetNewDate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipantSetNewDate
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipantSetNewDate
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipantSetNewDate.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipantSetNewDate";
        };

        return AdminParticipantSetNewDate;
    })();

    admin_participants.AdminParticipantSetPresence = (function() {

        /**
         * Properties of an AdminParticipantSetPresence.
         * @memberof admin_participants
         * @interface IAdminParticipantSetPresence
         * @property {string|null} [sessionId] AdminParticipantSetPresence sessionId
         * @property {Array.<number>|null} [daysPresent] AdminParticipantSetPresence daysPresent
         */

        /**
         * Constructs a new AdminParticipantSetPresence.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipantSetPresence.
         * @implements IAdminParticipantSetPresence
         * @constructor
         * @param {admin_participants.IAdminParticipantSetPresence=} [properties] Properties to set
         */
        function AdminParticipantSetPresence(properties) {
            this.daysPresent = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipantSetPresence sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminParticipantSetPresence
         * @instance
         */
        AdminParticipantSetPresence.prototype.sessionId = "";

        /**
         * AdminParticipantSetPresence daysPresent.
         * @member {Array.<number>} daysPresent
         * @memberof admin_participants.AdminParticipantSetPresence
         * @instance
         */
        AdminParticipantSetPresence.prototype.daysPresent = $util.emptyArray;

        /**
         * Creates a new AdminParticipantSetPresence instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {admin_participants.IAdminParticipantSetPresence=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipantSetPresence} AdminParticipantSetPresence instance
         */
        AdminParticipantSetPresence.create = function create(properties) {
            return new AdminParticipantSetPresence(properties);
        };

        /**
         * Encodes the specified AdminParticipantSetPresence message. Does not implicitly {@link admin_participants.AdminParticipantSetPresence.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {admin_participants.IAdminParticipantSetPresence} message AdminParticipantSetPresence message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantSetPresence.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.daysPresent != null && message.daysPresent.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.daysPresent.length; ++i)
                    writer.int32(message.daysPresent[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified AdminParticipantSetPresence message, length delimited. Does not implicitly {@link admin_participants.AdminParticipantSetPresence.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {admin_participants.IAdminParticipantSetPresence} message AdminParticipantSetPresence message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantSetPresence.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipantSetPresence message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipantSetPresence} AdminParticipantSetPresence
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantSetPresence.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipantSetPresence();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        if (!(message.daysPresent && message.daysPresent.length))
                            message.daysPresent = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.daysPresent.push(reader.int32());
                        } else
                            message.daysPresent.push(reader.int32());
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipantSetPresence message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipantSetPresence} AdminParticipantSetPresence
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantSetPresence.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipantSetPresence message.
         * @function verify
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipantSetPresence.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.daysPresent != null && message.hasOwnProperty("daysPresent")) {
                if (!Array.isArray(message.daysPresent))
                    return "daysPresent: array expected";
                for (let i = 0; i < message.daysPresent.length; ++i)
                    if (!$util.isInteger(message.daysPresent[i]))
                        return "daysPresent: integer[] expected";
            }
            return null;
        };

        /**
         * Creates an AdminParticipantSetPresence message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipantSetPresence} AdminParticipantSetPresence
         */
        AdminParticipantSetPresence.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipantSetPresence)
                return object;
            let message = new $root.admin_participants.AdminParticipantSetPresence();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.daysPresent) {
                if (!Array.isArray(object.daysPresent))
                    throw TypeError(".admin_participants.AdminParticipantSetPresence.daysPresent: array expected");
                message.daysPresent = [];
                for (let i = 0; i < object.daysPresent.length; ++i)
                    message.daysPresent[i] = object.daysPresent[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipantSetPresence message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {admin_participants.AdminParticipantSetPresence} message AdminParticipantSetPresence
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipantSetPresence.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.daysPresent = [];
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.daysPresent && message.daysPresent.length) {
                object.daysPresent = [];
                for (let j = 0; j < message.daysPresent.length; ++j)
                    object.daysPresent[j] = message.daysPresent[j];
            }
            return object;
        };

        /**
         * Converts this AdminParticipantSetPresence to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipantSetPresence
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipantSetPresence.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipantSetPresence
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipantSetPresence
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipantSetPresence.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipantSetPresence";
        };

        return AdminParticipantSetPresence;
    })();

    admin_participants.AdminLoadCommentTypes = (function() {

        /**
         * Properties of an AdminLoadCommentTypes.
         * @memberof admin_participants
         * @interface IAdminLoadCommentTypes
         */

        /**
         * Constructs a new AdminLoadCommentTypes.
         * @memberof admin_participants
         * @classdesc Represents an AdminLoadCommentTypes.
         * @implements IAdminLoadCommentTypes
         * @constructor
         * @param {admin_participants.IAdminLoadCommentTypes=} [properties] Properties to set
         */
        function AdminLoadCommentTypes(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AdminLoadCommentTypes instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {admin_participants.IAdminLoadCommentTypes=} [properties] Properties to set
         * @returns {admin_participants.AdminLoadCommentTypes} AdminLoadCommentTypes instance
         */
        AdminLoadCommentTypes.create = function create(properties) {
            return new AdminLoadCommentTypes(properties);
        };

        /**
         * Encodes the specified AdminLoadCommentTypes message. Does not implicitly {@link admin_participants.AdminLoadCommentTypes.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {admin_participants.IAdminLoadCommentTypes} message AdminLoadCommentTypes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadCommentTypes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AdminLoadCommentTypes message, length delimited. Does not implicitly {@link admin_participants.AdminLoadCommentTypes.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {admin_participants.IAdminLoadCommentTypes} message AdminLoadCommentTypes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminLoadCommentTypes.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminLoadCommentTypes message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminLoadCommentTypes} AdminLoadCommentTypes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadCommentTypes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminLoadCommentTypes();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminLoadCommentTypes message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminLoadCommentTypes} AdminLoadCommentTypes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminLoadCommentTypes.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminLoadCommentTypes message.
         * @function verify
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminLoadCommentTypes.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AdminLoadCommentTypes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminLoadCommentTypes} AdminLoadCommentTypes
         */
        AdminLoadCommentTypes.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminLoadCommentTypes)
                return object;
            return new $root.admin_participants.AdminLoadCommentTypes();
        };

        /**
         * Creates a plain object from an AdminLoadCommentTypes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {admin_participants.AdminLoadCommentTypes} message AdminLoadCommentTypes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminLoadCommentTypes.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AdminLoadCommentTypes to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminLoadCommentTypes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminLoadCommentTypes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminLoadCommentTypes
         * @function getTypeUrl
         * @memberof admin_participants.AdminLoadCommentTypes
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminLoadCommentTypes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminLoadCommentTypes";
        };

        return AdminLoadCommentTypes;
    })();

    admin_participants.AdminCommentTypes = (function() {

        /**
         * Properties of an AdminCommentTypes.
         * @memberof admin_participants
         * @interface IAdminCommentTypes
         * @property {Array.<cvv_types.ICommentType>|null} [types] AdminCommentTypes types
         */

        /**
         * Constructs a new AdminCommentTypes.
         * @memberof admin_participants
         * @classdesc Represents an AdminCommentTypes.
         * @implements IAdminCommentTypes
         * @constructor
         * @param {admin_participants.IAdminCommentTypes=} [properties] Properties to set
         */
        function AdminCommentTypes(properties) {
            this.types = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminCommentTypes types.
         * @member {Array.<cvv_types.ICommentType>} types
         * @memberof admin_participants.AdminCommentTypes
         * @instance
         */
        AdminCommentTypes.prototype.types = $util.emptyArray;

        /**
         * Creates a new AdminCommentTypes instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {admin_participants.IAdminCommentTypes=} [properties] Properties to set
         * @returns {admin_participants.AdminCommentTypes} AdminCommentTypes instance
         */
        AdminCommentTypes.create = function create(properties) {
            return new AdminCommentTypes(properties);
        };

        /**
         * Encodes the specified AdminCommentTypes message. Does not implicitly {@link admin_participants.AdminCommentTypes.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {admin_participants.IAdminCommentTypes} message AdminCommentTypes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCommentTypes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.types != null && message.types.length)
                for (let i = 0; i < message.types.length; ++i)
                    $root.cvv_types.CommentType.encode(message.types[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AdminCommentTypes message, length delimited. Does not implicitly {@link admin_participants.AdminCommentTypes.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {admin_participants.IAdminCommentTypes} message AdminCommentTypes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCommentTypes.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminCommentTypes message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminCommentTypes} AdminCommentTypes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCommentTypes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminCommentTypes();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        if (!(message.types && message.types.length))
                            message.types = [];
                        message.types.push($root.cvv_types.CommentType.decode(reader, reader.uint32()));
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminCommentTypes message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminCommentTypes} AdminCommentTypes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCommentTypes.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminCommentTypes message.
         * @function verify
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminCommentTypes.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.types != null && message.hasOwnProperty("types")) {
                if (!Array.isArray(message.types))
                    return "types: array expected";
                for (let i = 0; i < message.types.length; ++i) {
                    let error = $root.cvv_types.CommentType.verify(message.types[i]);
                    if (error)
                        return "types." + error;
                }
            }
            return null;
        };

        /**
         * Creates an AdminCommentTypes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminCommentTypes} AdminCommentTypes
         */
        AdminCommentTypes.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminCommentTypes)
                return object;
            let message = new $root.admin_participants.AdminCommentTypes();
            if (object.types) {
                if (!Array.isArray(object.types))
                    throw TypeError(".admin_participants.AdminCommentTypes.types: array expected");
                message.types = [];
                for (let i = 0; i < object.types.length; ++i) {
                    if (typeof object.types[i] !== "object")
                        throw TypeError(".admin_participants.AdminCommentTypes.types: object expected");
                    message.types[i] = $root.cvv_types.CommentType.fromObject(object.types[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an AdminCommentTypes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {admin_participants.AdminCommentTypes} message AdminCommentTypes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminCommentTypes.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.types = [];
            if (message.types && message.types.length) {
                object.types = [];
                for (let j = 0; j < message.types.length; ++j)
                    object.types[j] = $root.cvv_types.CommentType.toObject(message.types[j], options);
            }
            return object;
        };

        /**
         * Converts this AdminCommentTypes to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminCommentTypes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminCommentTypes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminCommentTypes
         * @function getTypeUrl
         * @memberof admin_participants.AdminCommentTypes
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminCommentTypes.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminCommentTypes";
        };

        return AdminCommentTypes;
    })();

    admin_participants.AdminParticipantResendConfirmationMail = (function() {

        /**
         * Properties of an AdminParticipantResendConfirmationMail.
         * @memberof admin_participants
         * @interface IAdminParticipantResendConfirmationMail
         * @property {string|null} [sessionId] AdminParticipantResendConfirmationMail sessionId
         */

        /**
         * Constructs a new AdminParticipantResendConfirmationMail.
         * @memberof admin_participants
         * @classdesc Represents an AdminParticipantResendConfirmationMail.
         * @implements IAdminParticipantResendConfirmationMail
         * @constructor
         * @param {admin_participants.IAdminParticipantResendConfirmationMail=} [properties] Properties to set
         */
        function AdminParticipantResendConfirmationMail(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminParticipantResendConfirmationMail sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @instance
         */
        AdminParticipantResendConfirmationMail.prototype.sessionId = "";

        /**
         * Creates a new AdminParticipantResendConfirmationMail instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {admin_participants.IAdminParticipantResendConfirmationMail=} [properties] Properties to set
         * @returns {admin_participants.AdminParticipantResendConfirmationMail} AdminParticipantResendConfirmationMail instance
         */
        AdminParticipantResendConfirmationMail.create = function create(properties) {
            return new AdminParticipantResendConfirmationMail(properties);
        };

        /**
         * Encodes the specified AdminParticipantResendConfirmationMail message. Does not implicitly {@link admin_participants.AdminParticipantResendConfirmationMail.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {admin_participants.IAdminParticipantResendConfirmationMail} message AdminParticipantResendConfirmationMail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantResendConfirmationMail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified AdminParticipantResendConfirmationMail message, length delimited. Does not implicitly {@link admin_participants.AdminParticipantResendConfirmationMail.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {admin_participants.IAdminParticipantResendConfirmationMail} message AdminParticipantResendConfirmationMail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminParticipantResendConfirmationMail.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminParticipantResendConfirmationMail message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminParticipantResendConfirmationMail} AdminParticipantResendConfirmationMail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantResendConfirmationMail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminParticipantResendConfirmationMail();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminParticipantResendConfirmationMail message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminParticipantResendConfirmationMail} AdminParticipantResendConfirmationMail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminParticipantResendConfirmationMail.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminParticipantResendConfirmationMail message.
         * @function verify
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminParticipantResendConfirmationMail.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an AdminParticipantResendConfirmationMail message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminParticipantResendConfirmationMail} AdminParticipantResendConfirmationMail
         */
        AdminParticipantResendConfirmationMail.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminParticipantResendConfirmationMail)
                return object;
            let message = new $root.admin_participants.AdminParticipantResendConfirmationMail();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an AdminParticipantResendConfirmationMail message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {admin_participants.AdminParticipantResendConfirmationMail} message AdminParticipantResendConfirmationMail
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminParticipantResendConfirmationMail.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this AdminParticipantResendConfirmationMail to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminParticipantResendConfirmationMail.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminParticipantResendConfirmationMail
         * @function getTypeUrl
         * @memberof admin_participants.AdminParticipantResendConfirmationMail
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminParticipantResendConfirmationMail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminParticipantResendConfirmationMail";
        };

        return AdminParticipantResendConfirmationMail;
    })();

    admin_participants.AdminSendPredracun = (function() {

        /**
         * Properties of an AdminSendPredracun.
         * @memberof admin_participants
         * @interface IAdminSendPredracun
         * @property {string|null} [sessionId] AdminSendPredracun sessionId
         */

        /**
         * Constructs a new AdminSendPredracun.
         * @memberof admin_participants
         * @classdesc Represents an AdminSendPredracun.
         * @implements IAdminSendPredracun
         * @constructor
         * @param {admin_participants.IAdminSendPredracun=} [properties] Properties to set
         */
        function AdminSendPredracun(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminSendPredracun sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminSendPredracun
         * @instance
         */
        AdminSendPredracun.prototype.sessionId = "";

        /**
         * Creates a new AdminSendPredracun instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {admin_participants.IAdminSendPredracun=} [properties] Properties to set
         * @returns {admin_participants.AdminSendPredracun} AdminSendPredracun instance
         */
        AdminSendPredracun.create = function create(properties) {
            return new AdminSendPredracun(properties);
        };

        /**
         * Encodes the specified AdminSendPredracun message. Does not implicitly {@link admin_participants.AdminSendPredracun.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {admin_participants.IAdminSendPredracun} message AdminSendPredracun message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminSendPredracun.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified AdminSendPredracun message, length delimited. Does not implicitly {@link admin_participants.AdminSendPredracun.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {admin_participants.IAdminSendPredracun} message AdminSendPredracun message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminSendPredracun.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminSendPredracun message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminSendPredracun} AdminSendPredracun
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminSendPredracun.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminSendPredracun();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminSendPredracun message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminSendPredracun} AdminSendPredracun
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminSendPredracun.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminSendPredracun message.
         * @function verify
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminSendPredracun.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an AdminSendPredracun message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminSendPredracun} AdminSendPredracun
         */
        AdminSendPredracun.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminSendPredracun)
                return object;
            let message = new $root.admin_participants.AdminSendPredracun();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an AdminSendPredracun message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {admin_participants.AdminSendPredracun} message AdminSendPredracun
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminSendPredracun.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this AdminSendPredracun to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminSendPredracun
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminSendPredracun.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminSendPredracun
         * @function getTypeUrl
         * @memberof admin_participants.AdminSendPredracun
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminSendPredracun.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminSendPredracun";
        };

        return AdminSendPredracun;
    })();

    admin_participants.AdminPaymentAtCvv = (function() {

        /**
         * Properties of an AdminPaymentAtCvv.
         * @memberof admin_participants
         * @interface IAdminPaymentAtCvv
         * @property {string|null} [sessionId] AdminPaymentAtCvv sessionId
         */

        /**
         * Constructs a new AdminPaymentAtCvv.
         * @memberof admin_participants
         * @classdesc Represents an AdminPaymentAtCvv.
         * @implements IAdminPaymentAtCvv
         * @constructor
         * @param {admin_participants.IAdminPaymentAtCvv=} [properties] Properties to set
         */
        function AdminPaymentAtCvv(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminPaymentAtCvv sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminPaymentAtCvv
         * @instance
         */
        AdminPaymentAtCvv.prototype.sessionId = "";

        /**
         * Creates a new AdminPaymentAtCvv instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {admin_participants.IAdminPaymentAtCvv=} [properties] Properties to set
         * @returns {admin_participants.AdminPaymentAtCvv} AdminPaymentAtCvv instance
         */
        AdminPaymentAtCvv.create = function create(properties) {
            return new AdminPaymentAtCvv(properties);
        };

        /**
         * Encodes the specified AdminPaymentAtCvv message. Does not implicitly {@link admin_participants.AdminPaymentAtCvv.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {admin_participants.IAdminPaymentAtCvv} message AdminPaymentAtCvv message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminPaymentAtCvv.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified AdminPaymentAtCvv message, length delimited. Does not implicitly {@link admin_participants.AdminPaymentAtCvv.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {admin_participants.IAdminPaymentAtCvv} message AdminPaymentAtCvv message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminPaymentAtCvv.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminPaymentAtCvv message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminPaymentAtCvv} AdminPaymentAtCvv
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminPaymentAtCvv.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminPaymentAtCvv();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminPaymentAtCvv message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminPaymentAtCvv} AdminPaymentAtCvv
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminPaymentAtCvv.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminPaymentAtCvv message.
         * @function verify
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminPaymentAtCvv.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an AdminPaymentAtCvv message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminPaymentAtCvv} AdminPaymentAtCvv
         */
        AdminPaymentAtCvv.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminPaymentAtCvv)
                return object;
            let message = new $root.admin_participants.AdminPaymentAtCvv();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an AdminPaymentAtCvv message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {admin_participants.AdminPaymentAtCvv} message AdminPaymentAtCvv
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminPaymentAtCvv.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this AdminPaymentAtCvv to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminPaymentAtCvv
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminPaymentAtCvv.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminPaymentAtCvv
         * @function getTypeUrl
         * @memberof admin_participants.AdminPaymentAtCvv
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminPaymentAtCvv.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminPaymentAtCvv";
        };

        return AdminPaymentAtCvv;
    })();

    admin_participants.AdminUnovciDarilniBon = (function() {

        /**
         * Properties of an AdminUnovciDarilniBon.
         * @memberof admin_participants
         * @interface IAdminUnovciDarilniBon
         * @property {string|null} [sessionId] AdminUnovciDarilniBon sessionId
         * @property {string|null} [stDarilnegaBona] AdminUnovciDarilniBon stDarilnegaBona
         */

        /**
         * Constructs a new AdminUnovciDarilniBon.
         * @memberof admin_participants
         * @classdesc Represents an AdminUnovciDarilniBon.
         * @implements IAdminUnovciDarilniBon
         * @constructor
         * @param {admin_participants.IAdminUnovciDarilniBon=} [properties] Properties to set
         */
        function AdminUnovciDarilniBon(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminUnovciDarilniBon sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @instance
         */
        AdminUnovciDarilniBon.prototype.sessionId = "";

        /**
         * AdminUnovciDarilniBon stDarilnegaBona.
         * @member {string} stDarilnegaBona
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @instance
         */
        AdminUnovciDarilniBon.prototype.stDarilnegaBona = "";

        /**
         * Creates a new AdminUnovciDarilniBon instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {admin_participants.IAdminUnovciDarilniBon=} [properties] Properties to set
         * @returns {admin_participants.AdminUnovciDarilniBon} AdminUnovciDarilniBon instance
         */
        AdminUnovciDarilniBon.create = function create(properties) {
            return new AdminUnovciDarilniBon(properties);
        };

        /**
         * Encodes the specified AdminUnovciDarilniBon message. Does not implicitly {@link admin_participants.AdminUnovciDarilniBon.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {admin_participants.IAdminUnovciDarilniBon} message AdminUnovciDarilniBon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminUnovciDarilniBon.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            if (message.stDarilnegaBona != null && Object.hasOwnProperty.call(message, "stDarilnegaBona"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.stDarilnegaBona);
            return writer;
        };

        /**
         * Encodes the specified AdminUnovciDarilniBon message, length delimited. Does not implicitly {@link admin_participants.AdminUnovciDarilniBon.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {admin_participants.IAdminUnovciDarilniBon} message AdminUnovciDarilniBon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminUnovciDarilniBon.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminUnovciDarilniBon message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminUnovciDarilniBon} AdminUnovciDarilniBon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminUnovciDarilniBon.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminUnovciDarilniBon();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 2: {
                        message.stDarilnegaBona = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminUnovciDarilniBon message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminUnovciDarilniBon} AdminUnovciDarilniBon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminUnovciDarilniBon.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminUnovciDarilniBon message.
         * @function verify
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminUnovciDarilniBon.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.stDarilnegaBona != null && message.hasOwnProperty("stDarilnegaBona"))
                if (!$util.isString(message.stDarilnegaBona))
                    return "stDarilnegaBona: string expected";
            return null;
        };

        /**
         * Creates an AdminUnovciDarilniBon message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminUnovciDarilniBon} AdminUnovciDarilniBon
         */
        AdminUnovciDarilniBon.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminUnovciDarilniBon)
                return object;
            let message = new $root.admin_participants.AdminUnovciDarilniBon();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.stDarilnegaBona != null)
                message.stDarilnegaBona = String(object.stDarilnegaBona);
            return message;
        };

        /**
         * Creates a plain object from an AdminUnovciDarilniBon message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {admin_participants.AdminUnovciDarilniBon} message AdminUnovciDarilniBon
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminUnovciDarilniBon.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sessionId = "";
                object.stDarilnegaBona = "";
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.stDarilnegaBona != null && message.hasOwnProperty("stDarilnegaBona"))
                object.stDarilnegaBona = message.stDarilnegaBona;
            return object;
        };

        /**
         * Converts this AdminUnovciDarilniBon to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminUnovciDarilniBon.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminUnovciDarilniBon
         * @function getTypeUrl
         * @memberof admin_participants.AdminUnovciDarilniBon
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminUnovciDarilniBon.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminUnovciDarilniBon";
        };

        return AdminUnovciDarilniBon;
    })();

    admin_participants.AdminCreateInvoice = (function() {

        /**
         * Properties of an AdminCreateInvoice.
         * @memberof admin_participants
         * @interface IAdminCreateInvoice
         * @property {string|null} [sessionId] AdminCreateInvoice sessionId
         */

        /**
         * Constructs a new AdminCreateInvoice.
         * @memberof admin_participants
         * @classdesc Represents an AdminCreateInvoice.
         * @implements IAdminCreateInvoice
         * @constructor
         * @param {admin_participants.IAdminCreateInvoice=} [properties] Properties to set
         */
        function AdminCreateInvoice(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AdminCreateInvoice sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.AdminCreateInvoice
         * @instance
         */
        AdminCreateInvoice.prototype.sessionId = "";

        /**
         * Creates a new AdminCreateInvoice instance using the specified properties.
         * @function create
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {admin_participants.IAdminCreateInvoice=} [properties] Properties to set
         * @returns {admin_participants.AdminCreateInvoice} AdminCreateInvoice instance
         */
        AdminCreateInvoice.create = function create(properties) {
            return new AdminCreateInvoice(properties);
        };

        /**
         * Encodes the specified AdminCreateInvoice message. Does not implicitly {@link admin_participants.AdminCreateInvoice.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {admin_participants.IAdminCreateInvoice} message AdminCreateInvoice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCreateInvoice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified AdminCreateInvoice message, length delimited. Does not implicitly {@link admin_participants.AdminCreateInvoice.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {admin_participants.IAdminCreateInvoice} message AdminCreateInvoice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AdminCreateInvoice.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AdminCreateInvoice message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.AdminCreateInvoice} AdminCreateInvoice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCreateInvoice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.AdminCreateInvoice();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AdminCreateInvoice message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.AdminCreateInvoice} AdminCreateInvoice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AdminCreateInvoice.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AdminCreateInvoice message.
         * @function verify
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AdminCreateInvoice.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates an AdminCreateInvoice message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.AdminCreateInvoice} AdminCreateInvoice
         */
        AdminCreateInvoice.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.AdminCreateInvoice)
                return object;
            let message = new $root.admin_participants.AdminCreateInvoice();
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from an AdminCreateInvoice message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {admin_participants.AdminCreateInvoice} message AdminCreateInvoice
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AdminCreateInvoice.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sessionId = "";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this AdminCreateInvoice to JSON.
         * @function toJSON
         * @memberof admin_participants.AdminCreateInvoice
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AdminCreateInvoice.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for AdminCreateInvoice
         * @function getTypeUrl
         * @memberof admin_participants.AdminCreateInvoice
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        AdminCreateInvoice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.AdminCreateInvoice";
        };

        return AdminCreateInvoice;
    })();

    admin_participants.ParticipantSummary = (function() {

        /**
         * Properties of a ParticipantSummary.
         * @memberof admin_participants
         * @interface IParticipantSummary
         * @property {string|null} [tecajTipName] ParticipantSummary tecajTipName
         * @property {string|null} [tecajName] ParticipantSummary tecajName
         * @property {string|null} [locationPrefix] ParticipantSummary locationPrefix
         * @property {string|null} [location] ParticipantSummary location
         * @property {string|null} [nameSurname] ParticipantSummary nameSurname
         * @property {billing.PaymentType|null} [paymentType] ParticipantSummary paymentType
         * @property {billing.PaymentStatus|null} [paymentStatus] ParticipantSummary paymentStatus
         * @property {cvv_types.IDate|null} [tecajStart] ParticipantSummary tecajStart
         * @property {cvv_types.IDate|null} [tecajEnd] ParticipantSummary tecajEnd
         * @property {string|null} [sessionId] ParticipantSummary sessionId
         * @property {string|null} [sklic] ParticipantSummary sklic
         * @property {number|null} [nDays] ParticipantSummary nDays
         * @property {Array.<number>|null} [daysPresent] ParticipantSummary daysPresent
         * @property {cvv_types.Vozilo|null} [vozilo] ParticipantSummary vozilo
         * @property {boolean|null} [archived] ParticipantSummary archived
         * @property {boolean|null} [racunIzdan] ParticipantSummary racunIzdan
         * @property {string|null} [stevilkaPotrdila] ParticipantSummary stevilkaPotrdila
         * @property {string|null} [navInvoiceAdvance] ParticipantSummary navInvoiceAdvance
         * @property {string|null} [navInvoice] ParticipantSummary navInvoice
         * @property {string|null} [navInvoiceProforma] ParticipantSummary navInvoiceProforma
         */

        /**
         * Constructs a new ParticipantSummary.
         * @memberof admin_participants
         * @classdesc Represents a ParticipantSummary.
         * @implements IParticipantSummary
         * @constructor
         * @param {admin_participants.IParticipantSummary=} [properties] Properties to set
         */
        function ParticipantSummary(properties) {
            this.daysPresent = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ParticipantSummary tecajTipName.
         * @member {string} tecajTipName
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.tecajTipName = "";

        /**
         * ParticipantSummary tecajName.
         * @member {string} tecajName
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.tecajName = "";

        /**
         * ParticipantSummary locationPrefix.
         * @member {string} locationPrefix
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.locationPrefix = "";

        /**
         * ParticipantSummary location.
         * @member {string} location
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.location = "";

        /**
         * ParticipantSummary nameSurname.
         * @member {string} nameSurname
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.nameSurname = "";

        /**
         * ParticipantSummary paymentType.
         * @member {billing.PaymentType} paymentType
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.paymentType = 0;

        /**
         * ParticipantSummary paymentStatus.
         * @member {billing.PaymentStatus} paymentStatus
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.paymentStatus = 0;

        /**
         * ParticipantSummary tecajStart.
         * @member {cvv_types.IDate|null|undefined} tecajStart
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.tecajStart = null;

        /**
         * ParticipantSummary tecajEnd.
         * @member {cvv_types.IDate|null|undefined} tecajEnd
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.tecajEnd = null;

        /**
         * ParticipantSummary sessionId.
         * @member {string} sessionId
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.sessionId = "";

        /**
         * ParticipantSummary sklic.
         * @member {string} sklic
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.sklic = "";

        /**
         * ParticipantSummary nDays.
         * @member {number} nDays
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.nDays = 0;

        /**
         * ParticipantSummary daysPresent.
         * @member {Array.<number>} daysPresent
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.daysPresent = $util.emptyArray;

        /**
         * ParticipantSummary vozilo.
         * @member {cvv_types.Vozilo} vozilo
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.vozilo = 0;

        /**
         * ParticipantSummary archived.
         * @member {boolean} archived
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.archived = false;

        /**
         * ParticipantSummary racunIzdan.
         * @member {boolean} racunIzdan
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.racunIzdan = false;

        /**
         * ParticipantSummary stevilkaPotrdila.
         * @member {string} stevilkaPotrdila
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.stevilkaPotrdila = "";

        /**
         * ParticipantSummary navInvoiceAdvance.
         * @member {string} navInvoiceAdvance
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.navInvoiceAdvance = "";

        /**
         * ParticipantSummary navInvoice.
         * @member {string} navInvoice
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.navInvoice = "";

        /**
         * ParticipantSummary navInvoiceProforma.
         * @member {string} navInvoiceProforma
         * @memberof admin_participants.ParticipantSummary
         * @instance
         */
        ParticipantSummary.prototype.navInvoiceProforma = "";

        /**
         * Creates a new ParticipantSummary instance using the specified properties.
         * @function create
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {admin_participants.IParticipantSummary=} [properties] Properties to set
         * @returns {admin_participants.ParticipantSummary} ParticipantSummary instance
         */
        ParticipantSummary.create = function create(properties) {
            return new ParticipantSummary(properties);
        };

        /**
         * Encodes the specified ParticipantSummary message. Does not implicitly {@link admin_participants.ParticipantSummary.verify|verify} messages.
         * @function encode
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {admin_participants.IParticipantSummary} message ParticipantSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ParticipantSummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tecajTipName != null && Object.hasOwnProperty.call(message, "tecajTipName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tecajTipName);
            if (message.tecajName != null && Object.hasOwnProperty.call(message, "tecajName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tecajName);
            if (message.locationPrefix != null && Object.hasOwnProperty.call(message, "locationPrefix"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.locationPrefix);
            if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.location);
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.nameSurname);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.paymentType);
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.paymentStatus);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.sessionId);
            if (message.tecajStart != null && Object.hasOwnProperty.call(message, "tecajStart"))
                $root.cvv_types.Date.encode(message.tecajStart, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.sklic != null && Object.hasOwnProperty.call(message, "sklic"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.sklic);
            if (message.nDays != null && Object.hasOwnProperty.call(message, "nDays"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.nDays);
            if (message.daysPresent != null && message.daysPresent.length) {
                writer.uint32(/* id 12, wireType 2 =*/98).fork();
                for (let i = 0; i < message.daysPresent.length; ++i)
                    writer.int32(message.daysPresent[i]);
                writer.ldelim();
            }
            if (message.vozilo != null && Object.hasOwnProperty.call(message, "vozilo"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.vozilo);
            if (message.archived != null && Object.hasOwnProperty.call(message, "archived"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.archived);
            if (message.tecajEnd != null && Object.hasOwnProperty.call(message, "tecajEnd"))
                $root.cvv_types.Date.encode(message.tecajEnd, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.racunIzdan != null && Object.hasOwnProperty.call(message, "racunIzdan"))
                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.racunIzdan);
            if (message.stevilkaPotrdila != null && Object.hasOwnProperty.call(message, "stevilkaPotrdila"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.stevilkaPotrdila);
            if (message.navInvoiceAdvance != null && Object.hasOwnProperty.call(message, "navInvoiceAdvance"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.navInvoiceAdvance);
            if (message.navInvoice != null && Object.hasOwnProperty.call(message, "navInvoice"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.navInvoice);
            if (message.navInvoiceProforma != null && Object.hasOwnProperty.call(message, "navInvoiceProforma"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.navInvoiceProforma);
            return writer;
        };

        /**
         * Encodes the specified ParticipantSummary message, length delimited. Does not implicitly {@link admin_participants.ParticipantSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {admin_participants.IParticipantSummary} message ParticipantSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ParticipantSummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ParticipantSummary message from the specified reader or buffer.
         * @function decode
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {admin_participants.ParticipantSummary} ParticipantSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ParticipantSummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.admin_participants.ParticipantSummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.tecajTipName = reader.string();
                        break;
                    }
                case 2: {
                        message.tecajName = reader.string();
                        break;
                    }
                case 3: {
                        message.locationPrefix = reader.string();
                        break;
                    }
                case 4: {
                        message.location = reader.string();
                        break;
                    }
                case 5: {
                        message.nameSurname = reader.string();
                        break;
                    }
                case 6: {
                        message.paymentType = reader.int32();
                        break;
                    }
                case 7: {
                        message.paymentStatus = reader.int32();
                        break;
                    }
                case 9: {
                        message.tecajStart = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 15: {
                        message.tecajEnd = $root.cvv_types.Date.decode(reader, reader.uint32());
                        break;
                    }
                case 8: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 10: {
                        message.sklic = reader.string();
                        break;
                    }
                case 11: {
                        message.nDays = reader.int32();
                        break;
                    }
                case 12: {
                        if (!(message.daysPresent && message.daysPresent.length))
                            message.daysPresent = [];
                        if ((tag & 7) === 2) {
                            let end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.daysPresent.push(reader.int32());
                        } else
                            message.daysPresent.push(reader.int32());
                        break;
                    }
                case 13: {
                        message.vozilo = reader.int32();
                        break;
                    }
                case 14: {
                        message.archived = reader.bool();
                        break;
                    }
                case 16: {
                        message.racunIzdan = reader.bool();
                        break;
                    }
                case 17: {
                        message.stevilkaPotrdila = reader.string();
                        break;
                    }
                case 18: {
                        message.navInvoiceAdvance = reader.string();
                        break;
                    }
                case 19: {
                        message.navInvoice = reader.string();
                        break;
                    }
                case 20: {
                        message.navInvoiceProforma = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ParticipantSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {admin_participants.ParticipantSummary} ParticipantSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ParticipantSummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ParticipantSummary message.
         * @function verify
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ParticipantSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tecajTipName != null && message.hasOwnProperty("tecajTipName"))
                if (!$util.isString(message.tecajTipName))
                    return "tecajTipName: string expected";
            if (message.tecajName != null && message.hasOwnProperty("tecajName"))
                if (!$util.isString(message.tecajName))
                    return "tecajName: string expected";
            if (message.locationPrefix != null && message.hasOwnProperty("locationPrefix"))
                if (!$util.isString(message.locationPrefix))
                    return "locationPrefix: string expected";
            if (message.location != null && message.hasOwnProperty("location"))
                if (!$util.isString(message.location))
                    return "location: string expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                switch (message.paymentType) {
                default:
                    return "paymentType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                switch (message.paymentStatus) {
                default:
                    return "paymentStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.tecajStart != null && message.hasOwnProperty("tecajStart")) {
                let error = $root.cvv_types.Date.verify(message.tecajStart);
                if (error)
                    return "tecajStart." + error;
            }
            if (message.tecajEnd != null && message.hasOwnProperty("tecajEnd")) {
                let error = $root.cvv_types.Date.verify(message.tecajEnd);
                if (error)
                    return "tecajEnd." + error;
            }
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.sklic != null && message.hasOwnProperty("sklic"))
                if (!$util.isString(message.sklic))
                    return "sklic: string expected";
            if (message.nDays != null && message.hasOwnProperty("nDays"))
                if (!$util.isInteger(message.nDays))
                    return "nDays: integer expected";
            if (message.daysPresent != null && message.hasOwnProperty("daysPresent")) {
                if (!Array.isArray(message.daysPresent))
                    return "daysPresent: array expected";
                for (let i = 0; i < message.daysPresent.length; ++i)
                    if (!$util.isInteger(message.daysPresent[i]))
                        return "daysPresent: integer[] expected";
            }
            if (message.vozilo != null && message.hasOwnProperty("vozilo"))
                switch (message.vozilo) {
                default:
                    return "vozilo: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.archived != null && message.hasOwnProperty("archived"))
                if (typeof message.archived !== "boolean")
                    return "archived: boolean expected";
            if (message.racunIzdan != null && message.hasOwnProperty("racunIzdan"))
                if (typeof message.racunIzdan !== "boolean")
                    return "racunIzdan: boolean expected";
            if (message.stevilkaPotrdila != null && message.hasOwnProperty("stevilkaPotrdila"))
                if (!$util.isString(message.stevilkaPotrdila))
                    return "stevilkaPotrdila: string expected";
            if (message.navInvoiceAdvance != null && message.hasOwnProperty("navInvoiceAdvance"))
                if (!$util.isString(message.navInvoiceAdvance))
                    return "navInvoiceAdvance: string expected";
            if (message.navInvoice != null && message.hasOwnProperty("navInvoice"))
                if (!$util.isString(message.navInvoice))
                    return "navInvoice: string expected";
            if (message.navInvoiceProforma != null && message.hasOwnProperty("navInvoiceProforma"))
                if (!$util.isString(message.navInvoiceProforma))
                    return "navInvoiceProforma: string expected";
            return null;
        };

        /**
         * Creates a ParticipantSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {admin_participants.ParticipantSummary} ParticipantSummary
         */
        ParticipantSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.admin_participants.ParticipantSummary)
                return object;
            let message = new $root.admin_participants.ParticipantSummary();
            if (object.tecajTipName != null)
                message.tecajTipName = String(object.tecajTipName);
            if (object.tecajName != null)
                message.tecajName = String(object.tecajName);
            if (object.locationPrefix != null)
                message.locationPrefix = String(object.locationPrefix);
            if (object.location != null)
                message.location = String(object.location);
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            switch (object.paymentType) {
            default:
                if (typeof object.paymentType === "number") {
                    message.paymentType = object.paymentType;
                    break;
                }
                break;
            case "kreditna":
            case 0:
                message.paymentType = 0;
                break;
            case "darilni_bon":
            case 1:
                message.paymentType = 1;
                break;
            case "gotovina":
            case 2:
                message.paymentType = 2;
                break;
            case "predracun":
            case 3:
                message.paymentType = 3;
                break;
            }
            switch (object.paymentStatus) {
            default:
                if (typeof object.paymentStatus === "number") {
                    message.paymentStatus = object.paymentStatus;
                    break;
                }
                break;
            case "no_proforma_invoice":
            case 0:
                message.paymentStatus = 0;
                break;
            case "proforma_invoice":
            case 1:
                message.paymentStatus = 1;
                break;
            case "amount_mismatch":
            case 2:
                message.paymentStatus = 2;
                break;
            case "paid":
            case 3:
                message.paymentStatus = 3;
                break;
            }
            if (object.tecajStart != null) {
                if (typeof object.tecajStart !== "object")
                    throw TypeError(".admin_participants.ParticipantSummary.tecajStart: object expected");
                message.tecajStart = $root.cvv_types.Date.fromObject(object.tecajStart);
            }
            if (object.tecajEnd != null) {
                if (typeof object.tecajEnd !== "object")
                    throw TypeError(".admin_participants.ParticipantSummary.tecajEnd: object expected");
                message.tecajEnd = $root.cvv_types.Date.fromObject(object.tecajEnd);
            }
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.sklic != null)
                message.sklic = String(object.sklic);
            if (object.nDays != null)
                message.nDays = object.nDays | 0;
            if (object.daysPresent) {
                if (!Array.isArray(object.daysPresent))
                    throw TypeError(".admin_participants.ParticipantSummary.daysPresent: array expected");
                message.daysPresent = [];
                for (let i = 0; i < object.daysPresent.length; ++i)
                    message.daysPresent[i] = object.daysPresent[i] | 0;
            }
            switch (object.vozilo) {
            default:
                if (typeof object.vozilo === "number") {
                    message.vozilo = object.vozilo;
                    break;
                }
                break;
            case "svoje":
            case 0:
                message.vozilo = 0;
                break;
            case "najeto":
            case 1:
                message.vozilo = 1;
                break;
            case "najeto_prijatelj":
            case 2:
                message.vozilo = 2;
                break;
            case "najeto_druga_oseba":
            case 3:
                message.vozilo = 3;
                break;
            }
            if (object.archived != null)
                message.archived = Boolean(object.archived);
            if (object.racunIzdan != null)
                message.racunIzdan = Boolean(object.racunIzdan);
            if (object.stevilkaPotrdila != null)
                message.stevilkaPotrdila = String(object.stevilkaPotrdila);
            if (object.navInvoiceAdvance != null)
                message.navInvoiceAdvance = String(object.navInvoiceAdvance);
            if (object.navInvoice != null)
                message.navInvoice = String(object.navInvoice);
            if (object.navInvoiceProforma != null)
                message.navInvoiceProforma = String(object.navInvoiceProforma);
            return message;
        };

        /**
         * Creates a plain object from a ParticipantSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {admin_participants.ParticipantSummary} message ParticipantSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ParticipantSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.daysPresent = [];
            if (options.defaults) {
                object.tecajTipName = "";
                object.tecajName = "";
                object.locationPrefix = "";
                object.location = "";
                object.nameSurname = "";
                object.paymentType = options.enums === String ? "kreditna" : 0;
                object.paymentStatus = options.enums === String ? "no_proforma_invoice" : 0;
                object.sessionId = "";
                object.tecajStart = null;
                object.sklic = "";
                object.nDays = 0;
                object.vozilo = options.enums === String ? "svoje" : 0;
                object.archived = false;
                object.tecajEnd = null;
                object.racunIzdan = false;
                object.stevilkaPotrdila = "";
                object.navInvoiceAdvance = "";
                object.navInvoice = "";
                object.navInvoiceProforma = "";
            }
            if (message.tecajTipName != null && message.hasOwnProperty("tecajTipName"))
                object.tecajTipName = message.tecajTipName;
            if (message.tecajName != null && message.hasOwnProperty("tecajName"))
                object.tecajName = message.tecajName;
            if (message.locationPrefix != null && message.hasOwnProperty("locationPrefix"))
                object.locationPrefix = message.locationPrefix;
            if (message.location != null && message.hasOwnProperty("location"))
                object.location = message.location;
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                object.paymentType = options.enums === String ? $root.billing.PaymentType[message.paymentType] === undefined ? message.paymentType : $root.billing.PaymentType[message.paymentType] : message.paymentType;
            if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                object.paymentStatus = options.enums === String ? $root.billing.PaymentStatus[message.paymentStatus] === undefined ? message.paymentStatus : $root.billing.PaymentStatus[message.paymentStatus] : message.paymentStatus;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.tecajStart != null && message.hasOwnProperty("tecajStart"))
                object.tecajStart = $root.cvv_types.Date.toObject(message.tecajStart, options);
            if (message.sklic != null && message.hasOwnProperty("sklic"))
                object.sklic = message.sklic;
            if (message.nDays != null && message.hasOwnProperty("nDays"))
                object.nDays = message.nDays;
            if (message.daysPresent && message.daysPresent.length) {
                object.daysPresent = [];
                for (let j = 0; j < message.daysPresent.length; ++j)
                    object.daysPresent[j] = message.daysPresent[j];
            }
            if (message.vozilo != null && message.hasOwnProperty("vozilo"))
                object.vozilo = options.enums === String ? $root.cvv_types.Vozilo[message.vozilo] === undefined ? message.vozilo : $root.cvv_types.Vozilo[message.vozilo] : message.vozilo;
            if (message.archived != null && message.hasOwnProperty("archived"))
                object.archived = message.archived;
            if (message.tecajEnd != null && message.hasOwnProperty("tecajEnd"))
                object.tecajEnd = $root.cvv_types.Date.toObject(message.tecajEnd, options);
            if (message.racunIzdan != null && message.hasOwnProperty("racunIzdan"))
                object.racunIzdan = message.racunIzdan;
            if (message.stevilkaPotrdila != null && message.hasOwnProperty("stevilkaPotrdila"))
                object.stevilkaPotrdila = message.stevilkaPotrdila;
            if (message.navInvoiceAdvance != null && message.hasOwnProperty("navInvoiceAdvance"))
                object.navInvoiceAdvance = message.navInvoiceAdvance;
            if (message.navInvoice != null && message.hasOwnProperty("navInvoice"))
                object.navInvoice = message.navInvoice;
            if (message.navInvoiceProforma != null && message.hasOwnProperty("navInvoiceProforma"))
                object.navInvoiceProforma = message.navInvoiceProforma;
            return object;
        };

        /**
         * Converts this ParticipantSummary to JSON.
         * @function toJSON
         * @memberof admin_participants.ParticipantSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ParticipantSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for ParticipantSummary
         * @function getTypeUrl
         * @memberof admin_participants.ParticipantSummary
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        ParticipantSummary.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/admin_participants.ParticipantSummary";
        };

        return ParticipantSummary;
    })();

    return admin_participants;
})();

export const phone_login = $root.phone_login = (() => {

    /**
     * Namespace phone_login.
     * @exports phone_login
     * @namespace
     */
    const phone_login = {};

    phone_login.PhoneLoginRequest = (function() {

        /**
         * Properties of a PhoneLoginRequest.
         * @memberof phone_login
         * @interface IPhoneLoginRequest
         * @property {string|null} [phone] PhoneLoginRequest phone
         * @property {string|null} [captcha] PhoneLoginRequest captcha
         */

        /**
         * Constructs a new PhoneLoginRequest.
         * @memberof phone_login
         * @classdesc Represents a PhoneLoginRequest.
         * @implements IPhoneLoginRequest
         * @constructor
         * @param {phone_login.IPhoneLoginRequest=} [properties] Properties to set
         */
        function PhoneLoginRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneLoginRequest phone.
         * @member {string} phone
         * @memberof phone_login.PhoneLoginRequest
         * @instance
         */
        PhoneLoginRequest.prototype.phone = "";

        /**
         * PhoneLoginRequest captcha.
         * @member {string} captcha
         * @memberof phone_login.PhoneLoginRequest
         * @instance
         */
        PhoneLoginRequest.prototype.captcha = "";

        /**
         * Creates a new PhoneLoginRequest instance using the specified properties.
         * @function create
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {phone_login.IPhoneLoginRequest=} [properties] Properties to set
         * @returns {phone_login.PhoneLoginRequest} PhoneLoginRequest instance
         */
        PhoneLoginRequest.create = function create(properties) {
            return new PhoneLoginRequest(properties);
        };

        /**
         * Encodes the specified PhoneLoginRequest message. Does not implicitly {@link phone_login.PhoneLoginRequest.verify|verify} messages.
         * @function encode
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {phone_login.IPhoneLoginRequest} message PhoneLoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneLoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.captcha != null && Object.hasOwnProperty.call(message, "captcha"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.captcha);
            return writer;
        };

        /**
         * Encodes the specified PhoneLoginRequest message, length delimited. Does not implicitly {@link phone_login.PhoneLoginRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {phone_login.IPhoneLoginRequest} message PhoneLoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneLoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneLoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {phone_login.PhoneLoginRequest} PhoneLoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneLoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.phone_login.PhoneLoginRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.phone = reader.string();
                        break;
                    }
                case 2: {
                        message.captcha = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneLoginRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {phone_login.PhoneLoginRequest} PhoneLoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneLoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneLoginRequest message.
         * @function verify
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneLoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.captcha != null && message.hasOwnProperty("captcha"))
                if (!$util.isString(message.captcha))
                    return "captcha: string expected";
            return null;
        };

        /**
         * Creates a PhoneLoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {phone_login.PhoneLoginRequest} PhoneLoginRequest
         */
        PhoneLoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.phone_login.PhoneLoginRequest)
                return object;
            let message = new $root.phone_login.PhoneLoginRequest();
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.captcha != null)
                message.captcha = String(object.captcha);
            return message;
        };

        /**
         * Creates a plain object from a PhoneLoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {phone_login.PhoneLoginRequest} message PhoneLoginRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneLoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.phone = "";
                object.captcha = "";
            }
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.captcha != null && message.hasOwnProperty("captcha"))
                object.captcha = message.captcha;
            return object;
        };

        /**
         * Converts this PhoneLoginRequest to JSON.
         * @function toJSON
         * @memberof phone_login.PhoneLoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneLoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneLoginRequest
         * @function getTypeUrl
         * @memberof phone_login.PhoneLoginRequest
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneLoginRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/phone_login.PhoneLoginRequest";
        };

        return PhoneLoginRequest;
    })();

    phone_login.PhoneLoginWithPin = (function() {

        /**
         * Properties of a PhoneLoginWithPin.
         * @memberof phone_login
         * @interface IPhoneLoginWithPin
         * @property {string|null} [nameSurname] PhoneLoginWithPin nameSurname
         * @property {cvv_types.IAddress|null} [address] PhoneLoginWithPin address
         * @property {string|null} [email] PhoneLoginWithPin email
         * @property {string|null} [phone] PhoneLoginWithPin phone
         * @property {string|null} [pin] PhoneLoginWithPin pin
         * @property {string|null} [tecajId] PhoneLoginWithPin tecajId
         * @property {string|null} [sessionId] PhoneLoginWithPin sessionId
         */

        /**
         * Constructs a new PhoneLoginWithPin.
         * @memberof phone_login
         * @classdesc Represents a PhoneLoginWithPin.
         * @implements IPhoneLoginWithPin
         * @constructor
         * @param {phone_login.IPhoneLoginWithPin=} [properties] Properties to set
         */
        function PhoneLoginWithPin(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneLoginWithPin nameSurname.
         * @member {string} nameSurname
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.nameSurname = "";

        /**
         * PhoneLoginWithPin address.
         * @member {cvv_types.IAddress|null|undefined} address
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.address = null;

        /**
         * PhoneLoginWithPin email.
         * @member {string} email
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.email = "";

        /**
         * PhoneLoginWithPin phone.
         * @member {string} phone
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.phone = "";

        /**
         * PhoneLoginWithPin pin.
         * @member {string} pin
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.pin = "";

        /**
         * PhoneLoginWithPin tecajId.
         * @member {string} tecajId
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.tecajId = "";

        /**
         * PhoneLoginWithPin sessionId.
         * @member {string} sessionId
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         */
        PhoneLoginWithPin.prototype.sessionId = "";

        /**
         * Creates a new PhoneLoginWithPin instance using the specified properties.
         * @function create
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {phone_login.IPhoneLoginWithPin=} [properties] Properties to set
         * @returns {phone_login.PhoneLoginWithPin} PhoneLoginWithPin instance
         */
        PhoneLoginWithPin.create = function create(properties) {
            return new PhoneLoginWithPin(properties);
        };

        /**
         * Encodes the specified PhoneLoginWithPin message. Does not implicitly {@link phone_login.PhoneLoginWithPin.verify|verify} messages.
         * @function encode
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {phone_login.IPhoneLoginWithPin} message PhoneLoginWithPin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneLoginWithPin.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.nameSurname != null && Object.hasOwnProperty.call(message, "nameSurname"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.nameSurname);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.cvv_types.Address.encode(message.address, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            if (message.pin != null && Object.hasOwnProperty.call(message, "pin"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.pin);
            if (message.tecajId != null && Object.hasOwnProperty.call(message, "tecajId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.tecajId);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.sessionId);
            return writer;
        };

        /**
         * Encodes the specified PhoneLoginWithPin message, length delimited. Does not implicitly {@link phone_login.PhoneLoginWithPin.verify|verify} messages.
         * @function encodeDelimited
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {phone_login.IPhoneLoginWithPin} message PhoneLoginWithPin message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneLoginWithPin.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneLoginWithPin message from the specified reader or buffer.
         * @function decode
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {phone_login.PhoneLoginWithPin} PhoneLoginWithPin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneLoginWithPin.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.phone_login.PhoneLoginWithPin();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.nameSurname = reader.string();
                        break;
                    }
                case 2: {
                        message.address = $root.cvv_types.Address.decode(reader, reader.uint32());
                        break;
                    }
                case 3: {
                        message.email = reader.string();
                        break;
                    }
                case 4: {
                        message.phone = reader.string();
                        break;
                    }
                case 5: {
                        message.pin = reader.string();
                        break;
                    }
                case 6: {
                        message.tecajId = reader.string();
                        break;
                    }
                case 7: {
                        message.sessionId = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneLoginWithPin message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {phone_login.PhoneLoginWithPin} PhoneLoginWithPin
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneLoginWithPin.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneLoginWithPin message.
         * @function verify
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneLoginWithPin.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                if (!$util.isString(message.nameSurname))
                    return "nameSurname: string expected";
            if (message.address != null && message.hasOwnProperty("address")) {
                let error = $root.cvv_types.Address.verify(message.address);
                if (error)
                    return "address." + error;
            }
            if (message.email != null && message.hasOwnProperty("email"))
                if (!$util.isString(message.email))
                    return "email: string expected";
            if (message.phone != null && message.hasOwnProperty("phone"))
                if (!$util.isString(message.phone))
                    return "phone: string expected";
            if (message.pin != null && message.hasOwnProperty("pin"))
                if (!$util.isString(message.pin))
                    return "pin: string expected";
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                if (!$util.isString(message.tecajId))
                    return "tecajId: string expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            return null;
        };

        /**
         * Creates a PhoneLoginWithPin message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {phone_login.PhoneLoginWithPin} PhoneLoginWithPin
         */
        PhoneLoginWithPin.fromObject = function fromObject(object) {
            if (object instanceof $root.phone_login.PhoneLoginWithPin)
                return object;
            let message = new $root.phone_login.PhoneLoginWithPin();
            if (object.nameSurname != null)
                message.nameSurname = String(object.nameSurname);
            if (object.address != null) {
                if (typeof object.address !== "object")
                    throw TypeError(".phone_login.PhoneLoginWithPin.address: object expected");
                message.address = $root.cvv_types.Address.fromObject(object.address);
            }
            if (object.email != null)
                message.email = String(object.email);
            if (object.phone != null)
                message.phone = String(object.phone);
            if (object.pin != null)
                message.pin = String(object.pin);
            if (object.tecajId != null)
                message.tecajId = String(object.tecajId);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            return message;
        };

        /**
         * Creates a plain object from a PhoneLoginWithPin message. Also converts values to other types if specified.
         * @function toObject
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {phone_login.PhoneLoginWithPin} message PhoneLoginWithPin
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneLoginWithPin.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.nameSurname = "";
                object.address = null;
                object.email = "";
                object.phone = "";
                object.pin = "";
                object.tecajId = "";
                object.sessionId = "";
            }
            if (message.nameSurname != null && message.hasOwnProperty("nameSurname"))
                object.nameSurname = message.nameSurname;
            if (message.address != null && message.hasOwnProperty("address"))
                object.address = $root.cvv_types.Address.toObject(message.address, options);
            if (message.email != null && message.hasOwnProperty("email"))
                object.email = message.email;
            if (message.phone != null && message.hasOwnProperty("phone"))
                object.phone = message.phone;
            if (message.pin != null && message.hasOwnProperty("pin"))
                object.pin = message.pin;
            if (message.tecajId != null && message.hasOwnProperty("tecajId"))
                object.tecajId = message.tecajId;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            return object;
        };

        /**
         * Converts this PhoneLoginWithPin to JSON.
         * @function toJSON
         * @memberof phone_login.PhoneLoginWithPin
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneLoginWithPin.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneLoginWithPin
         * @function getTypeUrl
         * @memberof phone_login.PhoneLoginWithPin
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneLoginWithPin.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/phone_login.PhoneLoginWithPin";
        };

        return PhoneLoginWithPin;
    })();

    phone_login.PhoneLoginResult = (function() {

        /**
         * Properties of a PhoneLoginResult.
         * @memberof phone_login
         * @interface IPhoneLoginResult
         * @property {string|null} [validationCode] PhoneLoginResult validationCode
         * @property {boolean|null} [jeClan] PhoneLoginResult jeClan
         * @property {string|null} [sessionId] PhoneLoginResult sessionId
         * @property {number|null} [memberCategory] PhoneLoginResult memberCategory
         */

        /**
         * Constructs a new PhoneLoginResult.
         * @memberof phone_login
         * @classdesc Represents a PhoneLoginResult.
         * @implements IPhoneLoginResult
         * @constructor
         * @param {phone_login.IPhoneLoginResult=} [properties] Properties to set
         */
        function PhoneLoginResult(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhoneLoginResult validationCode.
         * @member {string} validationCode
         * @memberof phone_login.PhoneLoginResult
         * @instance
         */
        PhoneLoginResult.prototype.validationCode = "";

        /**
         * PhoneLoginResult jeClan.
         * @member {boolean} jeClan
         * @memberof phone_login.PhoneLoginResult
         * @instance
         */
        PhoneLoginResult.prototype.jeClan = false;

        /**
         * PhoneLoginResult sessionId.
         * @member {string} sessionId
         * @memberof phone_login.PhoneLoginResult
         * @instance
         */
        PhoneLoginResult.prototype.sessionId = "";

        /**
         * PhoneLoginResult memberCategory.
         * @member {number} memberCategory
         * @memberof phone_login.PhoneLoginResult
         * @instance
         */
        PhoneLoginResult.prototype.memberCategory = 0;

        /**
         * Creates a new PhoneLoginResult instance using the specified properties.
         * @function create
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {phone_login.IPhoneLoginResult=} [properties] Properties to set
         * @returns {phone_login.PhoneLoginResult} PhoneLoginResult instance
         */
        PhoneLoginResult.create = function create(properties) {
            return new PhoneLoginResult(properties);
        };

        /**
         * Encodes the specified PhoneLoginResult message. Does not implicitly {@link phone_login.PhoneLoginResult.verify|verify} messages.
         * @function encode
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {phone_login.IPhoneLoginResult} message PhoneLoginResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneLoginResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.validationCode != null && Object.hasOwnProperty.call(message, "validationCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.validationCode);
            if (message.jeClan != null && Object.hasOwnProperty.call(message, "jeClan"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.jeClan);
            if (message.sessionId != null && Object.hasOwnProperty.call(message, "sessionId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.sessionId);
            if (message.memberCategory != null && Object.hasOwnProperty.call(message, "memberCategory"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.memberCategory);
            return writer;
        };

        /**
         * Encodes the specified PhoneLoginResult message, length delimited. Does not implicitly {@link phone_login.PhoneLoginResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {phone_login.IPhoneLoginResult} message PhoneLoginResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhoneLoginResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PhoneLoginResult message from the specified reader or buffer.
         * @function decode
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {phone_login.PhoneLoginResult} PhoneLoginResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneLoginResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.phone_login.PhoneLoginResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.validationCode = reader.string();
                        break;
                    }
                case 2: {
                        message.jeClan = reader.bool();
                        break;
                    }
                case 3: {
                        message.sessionId = reader.string();
                        break;
                    }
                case 4: {
                        message.memberCategory = reader.int32();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PhoneLoginResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {phone_login.PhoneLoginResult} PhoneLoginResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhoneLoginResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PhoneLoginResult message.
         * @function verify
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PhoneLoginResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                if (!$util.isString(message.validationCode))
                    return "validationCode: string expected";
            if (message.jeClan != null && message.hasOwnProperty("jeClan"))
                if (typeof message.jeClan !== "boolean")
                    return "jeClan: boolean expected";
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                if (!$util.isString(message.sessionId))
                    return "sessionId: string expected";
            if (message.memberCategory != null && message.hasOwnProperty("memberCategory"))
                if (!$util.isInteger(message.memberCategory))
                    return "memberCategory: integer expected";
            return null;
        };

        /**
         * Creates a PhoneLoginResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {phone_login.PhoneLoginResult} PhoneLoginResult
         */
        PhoneLoginResult.fromObject = function fromObject(object) {
            if (object instanceof $root.phone_login.PhoneLoginResult)
                return object;
            let message = new $root.phone_login.PhoneLoginResult();
            if (object.validationCode != null)
                message.validationCode = String(object.validationCode);
            if (object.jeClan != null)
                message.jeClan = Boolean(object.jeClan);
            if (object.sessionId != null)
                message.sessionId = String(object.sessionId);
            if (object.memberCategory != null)
                message.memberCategory = object.memberCategory | 0;
            return message;
        };

        /**
         * Creates a plain object from a PhoneLoginResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {phone_login.PhoneLoginResult} message PhoneLoginResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PhoneLoginResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.validationCode = "";
                object.jeClan = false;
                object.sessionId = "";
                object.memberCategory = 0;
            }
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                object.validationCode = message.validationCode;
            if (message.jeClan != null && message.hasOwnProperty("jeClan"))
                object.jeClan = message.jeClan;
            if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                object.sessionId = message.sessionId;
            if (message.memberCategory != null && message.hasOwnProperty("memberCategory"))
                object.memberCategory = message.memberCategory;
            return object;
        };

        /**
         * Converts this PhoneLoginResult to JSON.
         * @function toJSON
         * @memberof phone_login.PhoneLoginResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PhoneLoginResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for PhoneLoginResult
         * @function getTypeUrl
         * @memberof phone_login.PhoneLoginResult
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        PhoneLoginResult.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/phone_login.PhoneLoginResult";
        };

        return PhoneLoginResult;
    })();

    return phone_login;
})();

export const amzs_cvv = $root.amzs_cvv = (() => {

    /**
     * Namespace amzs_cvv.
     * @exports amzs_cvv
     * @namespace
     */
    const amzs_cvv = {};

    amzs_cvv.GetMagicLink = (function() {

        /**
         * Properties of a GetMagicLink.
         * @memberof amzs_cvv
         * @interface IGetMagicLink
         * @property {string|null} [mail] GetMagicLink mail
         */

        /**
         * Constructs a new GetMagicLink.
         * @memberof amzs_cvv
         * @classdesc Represents a GetMagicLink.
         * @implements IGetMagicLink
         * @constructor
         * @param {amzs_cvv.IGetMagicLink=} [properties] Properties to set
         */
        function GetMagicLink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMagicLink mail.
         * @member {string} mail
         * @memberof amzs_cvv.GetMagicLink
         * @instance
         */
        GetMagicLink.prototype.mail = "";

        /**
         * Creates a new GetMagicLink instance using the specified properties.
         * @function create
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {amzs_cvv.IGetMagicLink=} [properties] Properties to set
         * @returns {amzs_cvv.GetMagicLink} GetMagicLink instance
         */
        GetMagicLink.create = function create(properties) {
            return new GetMagicLink(properties);
        };

        /**
         * Encodes the specified GetMagicLink message. Does not implicitly {@link amzs_cvv.GetMagicLink.verify|verify} messages.
         * @function encode
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {amzs_cvv.IGetMagicLink} message GetMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMagicLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mail != null && Object.hasOwnProperty.call(message, "mail"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mail);
            return writer;
        };

        /**
         * Encodes the specified GetMagicLink message, length delimited. Does not implicitly {@link amzs_cvv.GetMagicLink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {amzs_cvv.IGetMagicLink} message GetMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMagicLink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetMagicLink message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_cvv.GetMagicLink} GetMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMagicLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_cvv.GetMagicLink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.mail = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetMagicLink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_cvv.GetMagicLink} GetMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMagicLink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetMagicLink message.
         * @function verify
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetMagicLink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mail != null && message.hasOwnProperty("mail"))
                if (!$util.isString(message.mail))
                    return "mail: string expected";
            return null;
        };

        /**
         * Creates a GetMagicLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_cvv.GetMagicLink} GetMagicLink
         */
        GetMagicLink.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_cvv.GetMagicLink)
                return object;
            let message = new $root.amzs_cvv.GetMagicLink();
            if (object.mail != null)
                message.mail = String(object.mail);
            return message;
        };

        /**
         * Creates a plain object from a GetMagicLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {amzs_cvv.GetMagicLink} message GetMagicLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetMagicLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mail = "";
            if (message.mail != null && message.hasOwnProperty("mail"))
                object.mail = message.mail;
            return object;
        };

        /**
         * Converts this GetMagicLink to JSON.
         * @function toJSON
         * @memberof amzs_cvv.GetMagicLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetMagicLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for GetMagicLink
         * @function getTypeUrl
         * @memberof amzs_cvv.GetMagicLink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        GetMagicLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amzs_cvv.GetMagicLink";
        };

        return GetMagicLink;
    })();

    amzs_cvv.LoginMagicLink = (function() {

        /**
         * Properties of a LoginMagicLink.
         * @memberof amzs_cvv
         * @interface ILoginMagicLink
         * @property {string|null} [token] LoginMagicLink token
         */

        /**
         * Constructs a new LoginMagicLink.
         * @memberof amzs_cvv
         * @classdesc Represents a LoginMagicLink.
         * @implements ILoginMagicLink
         * @constructor
         * @param {amzs_cvv.ILoginMagicLink=} [properties] Properties to set
         */
        function LoginMagicLink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginMagicLink token.
         * @member {string} token
         * @memberof amzs_cvv.LoginMagicLink
         * @instance
         */
        LoginMagicLink.prototype.token = "";

        /**
         * Creates a new LoginMagicLink instance using the specified properties.
         * @function create
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {amzs_cvv.ILoginMagicLink=} [properties] Properties to set
         * @returns {amzs_cvv.LoginMagicLink} LoginMagicLink instance
         */
        LoginMagicLink.create = function create(properties) {
            return new LoginMagicLink(properties);
        };

        /**
         * Encodes the specified LoginMagicLink message. Does not implicitly {@link amzs_cvv.LoginMagicLink.verify|verify} messages.
         * @function encode
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {amzs_cvv.ILoginMagicLink} message LoginMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified LoginMagicLink message, length delimited. Does not implicitly {@link amzs_cvv.LoginMagicLink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {amzs_cvv.ILoginMagicLink} message LoginMagicLink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginMagicLink message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_cvv.LoginMagicLink} LoginMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_cvv.LoginMagicLink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.token = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginMagicLink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_cvv.LoginMagicLink} LoginMagicLink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginMagicLink message.
         * @function verify
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginMagicLink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates a LoginMagicLink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_cvv.LoginMagicLink} LoginMagicLink
         */
        LoginMagicLink.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_cvv.LoginMagicLink)
                return object;
            let message = new $root.amzs_cvv.LoginMagicLink();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from a LoginMagicLink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {amzs_cvv.LoginMagicLink} message LoginMagicLink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginMagicLink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this LoginMagicLink to JSON.
         * @function toJSON
         * @memberof amzs_cvv.LoginMagicLink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginMagicLink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginMagicLink
         * @function getTypeUrl
         * @memberof amzs_cvv.LoginMagicLink
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginMagicLink.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amzs_cvv.LoginMagicLink";
        };

        return LoginMagicLink;
    })();

    amzs_cvv.LoginMagicLinkStatus = (function() {

        /**
         * Properties of a LoginMagicLinkStatus.
         * @memberof amzs_cvv
         * @interface ILoginMagicLinkStatus
         * @property {string|null} [status] LoginMagicLinkStatus status
         * @property {boolean|null} [error] LoginMagicLinkStatus error
         */

        /**
         * Constructs a new LoginMagicLinkStatus.
         * @memberof amzs_cvv
         * @classdesc Represents a LoginMagicLinkStatus.
         * @implements ILoginMagicLinkStatus
         * @constructor
         * @param {amzs_cvv.ILoginMagicLinkStatus=} [properties] Properties to set
         */
        function LoginMagicLinkStatus(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginMagicLinkStatus status.
         * @member {string} status
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @instance
         */
        LoginMagicLinkStatus.prototype.status = "";

        /**
         * LoginMagicLinkStatus error.
         * @member {boolean} error
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @instance
         */
        LoginMagicLinkStatus.prototype.error = false;

        /**
         * Creates a new LoginMagicLinkStatus instance using the specified properties.
         * @function create
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {amzs_cvv.ILoginMagicLinkStatus=} [properties] Properties to set
         * @returns {amzs_cvv.LoginMagicLinkStatus} LoginMagicLinkStatus instance
         */
        LoginMagicLinkStatus.create = function create(properties) {
            return new LoginMagicLinkStatus(properties);
        };

        /**
         * Encodes the specified LoginMagicLinkStatus message. Does not implicitly {@link amzs_cvv.LoginMagicLinkStatus.verify|verify} messages.
         * @function encode
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {amzs_cvv.ILoginMagicLinkStatus} message LoginMagicLinkStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLinkStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.status);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.error);
            return writer;
        };

        /**
         * Encodes the specified LoginMagicLinkStatus message, length delimited. Does not implicitly {@link amzs_cvv.LoginMagicLinkStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {amzs_cvv.ILoginMagicLinkStatus} message LoginMagicLinkStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginMagicLinkStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginMagicLinkStatus message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_cvv.LoginMagicLinkStatus} LoginMagicLinkStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLinkStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_cvv.LoginMagicLinkStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.status = reader.string();
                        break;
                    }
                case 2: {
                        message.error = reader.bool();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginMagicLinkStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_cvv.LoginMagicLinkStatus} LoginMagicLinkStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginMagicLinkStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginMagicLinkStatus message.
         * @function verify
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginMagicLinkStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                if (!$util.isString(message.status))
                    return "status: string expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (typeof message.error !== "boolean")
                    return "error: boolean expected";
            return null;
        };

        /**
         * Creates a LoginMagicLinkStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_cvv.LoginMagicLinkStatus} LoginMagicLinkStatus
         */
        LoginMagicLinkStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_cvv.LoginMagicLinkStatus)
                return object;
            let message = new $root.amzs_cvv.LoginMagicLinkStatus();
            if (object.status != null)
                message.status = String(object.status);
            if (object.error != null)
                message.error = Boolean(object.error);
            return message;
        };

        /**
         * Creates a plain object from a LoginMagicLinkStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {amzs_cvv.LoginMagicLinkStatus} message LoginMagicLinkStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginMagicLinkStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.status = "";
                object.error = false;
            }
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = message.status;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            return object;
        };

        /**
         * Converts this LoginMagicLinkStatus to JSON.
         * @function toJSON
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginMagicLinkStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for LoginMagicLinkStatus
         * @function getTypeUrl
         * @memberof amzs_cvv.LoginMagicLinkStatus
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        LoginMagicLinkStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amzs_cvv.LoginMagicLinkStatus";
        };

        return LoginMagicLinkStatus;
    })();

    amzs_cvv.UserProfileData = (function() {

        /**
         * Properties of a UserProfileData.
         * @memberof amzs_cvv
         * @interface IUserProfileData
         * @property {string|null} [name] UserProfileData name
         */

        /**
         * Constructs a new UserProfileData.
         * @memberof amzs_cvv
         * @classdesc Represents a UserProfileData.
         * @implements IUserProfileData
         * @constructor
         * @param {amzs_cvv.IUserProfileData=} [properties] Properties to set
         */
        function UserProfileData(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserProfileData name.
         * @member {string} name
         * @memberof amzs_cvv.UserProfileData
         * @instance
         */
        UserProfileData.prototype.name = "";

        /**
         * Creates a new UserProfileData instance using the specified properties.
         * @function create
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {amzs_cvv.IUserProfileData=} [properties] Properties to set
         * @returns {amzs_cvv.UserProfileData} UserProfileData instance
         */
        UserProfileData.create = function create(properties) {
            return new UserProfileData(properties);
        };

        /**
         * Encodes the specified UserProfileData message. Does not implicitly {@link amzs_cvv.UserProfileData.verify|verify} messages.
         * @function encode
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {amzs_cvv.IUserProfileData} message UserProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified UserProfileData message, length delimited. Does not implicitly {@link amzs_cvv.UserProfileData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {amzs_cvv.IUserProfileData} message UserProfileData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserProfileData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserProfileData message from the specified reader or buffer.
         * @function decode
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {amzs_cvv.UserProfileData} UserProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.amzs_cvv.UserProfileData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.name = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserProfileData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {amzs_cvv.UserProfileData} UserProfileData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserProfileData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserProfileData message.
         * @function verify
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserProfileData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a UserProfileData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {amzs_cvv.UserProfileData} UserProfileData
         */
        UserProfileData.fromObject = function fromObject(object) {
            if (object instanceof $root.amzs_cvv.UserProfileData)
                return object;
            let message = new $root.amzs_cvv.UserProfileData();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a UserProfileData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {amzs_cvv.UserProfileData} message UserProfileData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserProfileData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this UserProfileData to JSON.
         * @function toJSON
         * @memberof amzs_cvv.UserProfileData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserProfileData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Gets the default type url for UserProfileData
         * @function getTypeUrl
         * @memberof amzs_cvv.UserProfileData
         * @static
         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
         * @returns {string} The default type url
         */
        UserProfileData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
            if (typeUrlPrefix === undefined) {
                typeUrlPrefix = "type.googleapis.com";
            }
            return typeUrlPrefix + "/amzs_cvv.UserProfileData";
        };

        return UserProfileData;
    })();

    return amzs_cvv;
})();

export { $root as default };
