import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {cvv_types, phone_login} from "../proto/compiled";
import {proto} from "../proto/messages";
import {FormStore} from "./form_store";
import {makeAutoObservable} from "mobx";


export enum MladiVoznikS1LoginStep {
    phone,
    pin,
    done
}

export class MladiVoznikStoreS1 {
    subscriptions: any[] = [];
    base: MladiVoznikStore;
    api: SocketApi;
    loginStep = MladiVoznikS1LoginStep.phone;
    error?: string;
    result?: phone_login.PhoneLoginResult;
    pin: string | undefined | null = null;

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.base = base;
        this.api = api;
        this.subscriptions.push(
            api.getMessageHandler(new proto.RxPhoneLoginResult()).subscribe((m) => this.onPhoneLoginResult(m))
        );
        makeAutoObservable(this);
    }

    dispose() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    init() {

    }

    get done(): boolean {
        return !!this.result;
    };

    private onPhoneLoginResult(message: proto.RxPhoneLoginResult) {
        this.result = message.proto;
        this.base.form.step4!.clanstvo = !this.result.jeClan;
        this.base.setStep(this.base.step + 1)
    }

    get info(): string | null {
        return null;
    }

    async requestPin(captcha: string) {
        if (this.base.api.authenticated) {
            await this.checkPin();
        } else {
            const response = await this.api.sendMessage(proto.TxPhoneLoginRequest.create({
                phone: this.base.form.step1?.phone,
                captcha: captcha,
            }), {ack: true, timeoutMs: 20000});
            if (response.status !== SocketApiAckStatus.success && response.error !== cvv_types.CvvErrorCode.sms_already_sent) {
                this.error = response.errorMessage ?? undefined;
                return;
            }
            this.error = undefined;
            this.loginStep = MladiVoznikS1LoginStep.pin;
        }
    }

    async checkPin() {
        const response = await this.api.sendMessage(proto.TxPhoneLoginWithPin.create({
            nameSurname: this.base.form.step1?.nameSurname,
            address: this.base.form.step1?.address,
            email: this.base.form.step1?.email,
            phone: this.base.form.step1?.phone,
            pin: this.pin,
            tecajId: FormStore.meta!.tecaj!.id,
            sessionId: this.base.formStore?.sessionId,
        }), {ack: true});

        if (response.status !== SocketApiAckStatus.success) {
            if (response.errorMessage) {
                this.error = response.errorMessage;
            }
            return;
        }
        this.error = undefined;
    }

    resetLogin() {
        this.loginStep = MladiVoznikS1LoginStep.phone;
        this.error = undefined;
        this.result = undefined;
        this.pin = undefined;
    }
}
