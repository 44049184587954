import {
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import styled, {useTheme} from "styled-components";
import {Theme} from "../../theme";
import {TailSpin} from "react-loader-spinner"
import React, {useState} from "react";
import {MdCheck} from "react-icons/md";

const Form = styled.form`
  display: flex;
`;

export const StripePayment = ({
                                  clientSecret,
                                  disabled,
                                  onSuccess,
                                  onError,
                                  inProgress,
                              }: { clientSecret?: string | null, disabled?: boolean, inProgress?: (p: boolean) => void, onSuccess: () => void, onError: () => void }) => {
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme() as Theme;
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);
    const [progress, setProgress] = useState<boolean>(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (elements == null || stripe == null) {
            return;
        }

        inProgress && inProgress(true);
        setProgress(true);
        const {paymentIntent, error} = await stripe.confirmCardPayment(clientSecret!, {
            payment_method: {
                card: elements.getElement(CardElement)!,
            }
        });
        setProgress(false);
        inProgress && inProgress(false);
        if (error) {
            setError(error.message);
            onError();
        } else if (paymentIntent?.status === 'succeeded') {
            setError(undefined);
            setSuccess(true);
            onSuccess();
        }
    };

    const btnDisabled = disabled || !stripe || !elements || !clientSecret || success || progress;
    if (error && btnDisabled) {
        setError(undefined);
    }

    return <Form onSubmit={handleSubmit}>
        <div style={{
            position: 'relative',
            backgroundColor: success ? 'rgba(0, 255, 0, .1)' : 'white',
            boxShadow: '0 0.25em 0.5em rgba(0, 45, 98, .3)',
            width: '100%',
            padding: '12px',
            borderRadius: '5px',
            marginRight: '8px',
            paddingBottom: error ? '36px' : '12px',
            transition: 'all 300ms',
            border: error ? 'solid 1px red' : success ? 'solid 1px green' : undefined,
        }}>
            <CardElement options={{hidePostalCode: true}}/>
            <div style={{
                backgroundColor: 'rgba(255, 0, 0, 0.05)',
                position: 'absolute',
                height: error ? '24px' : '0',
                overflow: 'hidden',
                bottom: '0',
                left: '0',
                right: '0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'all 300ms',
            }}>
                <theme.textTheme.error.span>
                    {error}
                </theme.textTheme.error.span>
            </div>
        </div>
        <button
            // type="submit"
            disabled={btnDisabled}
            style={{
                backgroundColor: success ? 'green' : theme.content,
                outline: 'none',
                border: 'none',
                borderRadius: '5px',
                overflow: 'hidden',
                boxShadow: '0 0.25em 0.5em rgba(0, 45, 98, .3)',
                width: btnDisabled ? '50px' : '100px',
                cursor: btnDisabled ? 'auto' : 'pointer',
                opacity: btnDisabled ? '0.8' : '1',
                transition: 'all 300ms'
            }}>
            <theme.textTheme.button.span style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
            }}>
                {!success && btnDisabled && <TailSpin
                                                    color={theme.primary}
                                                    height={'25px'}
                                                    width={'25px'}/>}
                {!success && !btnDisabled && 'PLAČAJ'}
                {success && <MdCheck style={{fontSize: '20px'}}/>}
            </theme.textTheme.button.span>
        </button>
    </Form>
}