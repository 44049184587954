import React, {createContext} from 'react';
import ReactDOM from 'react-dom';
import 'configurable-date-input-polyfill';
import './index.css';
import App from './app';
import {ThemeProvider} from "styled-components";
import {CvvTheme} from "./theme";
import {SocketApi} from "proto_socket_typescript";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MultiProvider from "./utils";
import {configure} from "mobx"
import {FormStore} from "./stores/form_store";
import {Login} from "./admin/login";
import {AuthStore} from "./admin/stores/auth_store";
import {AdminRazpisaniTecaji} from "./admin/admin_razpisani_tecaji";
import {ProtectedRoute} from "./admin/protected_route";
import {AdminParticipants} from "./admin/admin_participants";
import {AdminRazpisaniTecaj} from './admin/admin_razpisani_tecaj';
import {AppStore} from './admin/stores/app_store';

configure({
    enforceActions: "never",
})

const port = window.location.port === '3000' ? '8000' : window.location.port;
export const api = new SocketApi(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.hostname}:${port}/ws/`);
const formStore = new FormStore(api);
const authStore = new AuthStore(api);
export const appStore = new AppStore();
export const ApiContext = createContext<SocketApi>(api);
export const FormStoreContext = createContext<FormStore>(formStore);
export const AuthStoreContext = createContext<AuthStore>(authStore);
export const AppStoreContext = createContext<AppStore>(appStore);

ReactDOM.render(
    <MultiProvider providers={[
        <ApiContext.Provider value={api}/>,
        <AuthStoreContext.Provider value={authStore}/>,
        <AppStoreContext.Provider value={appStore}/>,
        <ThemeProvider theme={CvvTheme}/>
    ]}>
        <BrowserRouter>
            <Routes>
                <Route path={'/admin/*'}>
                    <Route path={'login/'} element={<Login/>}/>
                    <Route path={''}
                           element={<ProtectedRoute><AdminRazpisaniTecaji/></ProtectedRoute>}/>
                    <Route path={'prijavljene-osebe/'}
                           element={<ProtectedRoute><AdminParticipants/></ProtectedRoute>}/>
                    <Route path={'razpisani-tecaj/:id/'}
                           element={<ProtectedRoute><AdminRazpisaniTecaj/></ProtectedRoute>}/>
                    <Route path={'razpisani-tecaj/'}
                           element={<ProtectedRoute><AdminRazpisaniTecaj/></ProtectedRoute>}/>
                </Route>
                <Route path={'/resume/:sessionId/'} element={<App/>}/>
                <Route path={'/summary/:sessionId/'} element={<App summary={true}/>}/>
                <Route path={'/participation/:sessionId/'} element={<App summary={true}/>}/>
                <Route path={'/:tip/:tecaj/'} element={<App/>}/>
            </Routes>
        </BrowserRouter>
    </MultiProvider>,
    document.getElementById('root')
);