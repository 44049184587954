import {cvv_types, mladi_voznik, billing} from "../proto/compiled";
import {makeAutoObservable} from "mobx";
import {CvvValidators} from "../forms/validators";
import {SocketApi} from "proto_socket_typescript";
import {MladiVoznikStoreS1} from "./mladi_voznik_store_s1";
import {MladiVoznikStoreS2} from "./mladi_voznik_store_s2";
import {MladiVoznikStoreS3} from "./mladi_voznik_store_s3";
import {MladiVoznikStoreS4} from "./mladi_voznik_store_s4";
import {MladiVoznikStoreS5} from "./mladi_voznik_store_s5";
import {MladiVoznikStoreS6} from "./mladi_voznik_store_s6";
import {FormStore} from "./form_store";
import {PricelistItems, PricelistModifiers} from "../forms/pricelist";
import {FormMladiVoznik} from "../forms/mladi_voznik/form_mladi_voznik";

type StepValidators = { [k: string]: (ignore?: boolean) => string | null };

export class MladiVoznikStore {
    doneUpToStep;
    step = 0;
    step1: MladiVoznikStoreS1;
    step2: MladiVoznikStoreS2;
    step3: MladiVoznikStoreS3;
    step4: MladiVoznikStoreS4;
    step5: MladiVoznikStoreS5;
    step6: MladiVoznikStoreS6;

    form = mladi_voznik.MladiVoznikForm.create();

    ignoreEmptyValidations = true;

    validators = {
        step1: {
            nameSurname: (ignore = true) => CvvValidators.nameSurname(this.form!.step1!.nameSurname!, ignore && this.ignoreEmptyValidations),
            address: (ignore = true) => CvvValidators.notEmpty(
                this.form?.step1?.address?.address && this.form?.step1?.address?.lat && this.form?.step1?.address?.lng,
                ignore && this.ignoreEmptyValidations
            ),
            email: (ignore = true) => CvvValidators.email(this.form!.step1!.email!, ignore && this.ignoreEmptyValidations),
            phone: (ignore = true) => CvvValidators.phone(this.form!.step1!.phone!, ignore && this.ignoreEmptyValidations),
        },
        step2: {
            calendar: (ignore = true) => this.form.step2?.date || this.api.authenticated ? undefined : 'Izberite datum.',
        },
        step3: {
            emso: (ignore = true) => CvvValidators.emso(18)(this.form!.step3!.emso!, ignore && this.ignoreEmptyValidations),
            stVozniske: (ignore = true) => CvvValidators.stVozniske(this.form!.step3!.stVozniske!, ignore && this.ignoreEmptyValidations),
            datumIzdajeVozniske: (ignore = true) => this.step3.datumIzdajeValidator(ignore && this.ignoreEmptyValidations),
            datumOpravljenegaIntenzivnegaTecaja: (ignore = true) => this.step3.datumOpravljenegaIntenzivnegaTecajaValidator(ignore && this.ignoreEmptyValidations),
            krajRojstva: (ignore = true) => CvvValidators.notEmpty(
                this.form!.step3!.krajRojstva,
                ignore && this.ignoreEmptyValidations
            ),
            veljavnoVozniskoKazenskeTocke: (ignore = true) => {
                const s3 = this.validators.step3;
                return s3.pristojnoSodisce(ignore) || s3.stevilkaNapotila(ignore) || s3.datumNapotila(ignore) || s3.napotiloSoglasje(ignore);
            },
            napotiloSoglasje: (ignore = true) => {
                if (!this.config.s3?.veljavnoVozniskoKazenskeTocke) return undefined;
                if (this.form.step3?.napotilo !== mladi_voznik.KazenskeTockeNapotilo.prostovoljno) return undefined;
                return this.step3.kazenskeSoglasje ? undefined : 'Soglasje je obvezno!';
            },
            pristojnoSodisce: (ignore = true) => {
                if (!this.config.s3?.veljavnoVozniskoKazenskeTocke) return undefined;
                if (this.form.step3?.napotilo !== mladi_voznik.KazenskeTockeNapotilo.sodisce) return undefined;
                return CvvValidators.notEmpty(
                    this.form!.step3!.napotiloPristojnoSodisce,
                    ignore && this.ignoreEmptyValidations
                );
            },
            stevilkaNapotila: (ignore = true) => {
                if (!this.config.s3?.veljavnoVozniskoKazenskeTocke) return undefined;
                if (this.form.step3?.napotilo !== mladi_voznik.KazenskeTockeNapotilo.sodisce) return undefined;
                return CvvValidators.notEmpty(
                    this.form!.step3!.napotiloStevilka,
                    ignore && this.ignoreEmptyValidations
                );
            },
            datumNapotila: (ignore = true) => {
                if (!this.config.s3?.veljavnoVozniskoKazenskeTocke) return undefined;
                if (this.form.step3?.napotilo !== mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled &&
                    this.form.step3?.napotilo !== mladi_voznik.KazenskeTockeNapotilo.sodisce) return undefined;
                return this.step3.datumNapotilaValidator(ignore && this.ignoreEmptyValidations);
            },
        },
        step4: {
            voziloPrijateljEmail: (ignore = true) => this.form!.step4?.vozilo === cvv_types.Vozilo.najeto_prijatelj ? CvvValidators.email(this.form!.step4!.voziloPrijateljEmail!, ignore && this.ignoreEmptyValidations) : undefined,
            skupinaPrijateljIme: (ignore = true) => this.form!.step4!.vSkupiniSPrijateljem ? CvvValidators.nameSurname(this.form!.step4!.skupinaPrijateljIme!, ignore && this.ignoreEmptyValidations) : undefined,
        },
        step5: {
            agreements: (ignore = true) => this.step5.done ? undefined : 'Če želite nadaljevati, se morate strinjati s pogoji.'
        },
        step6: {
            stDarilnegaBona: (ignore = true) => this.formStore?.paymentType === billing.PaymentType.darilni_bon && CvvValidators.stDarilnegaBona(this.step6.stDarilnegaBona, ignore && this.ignoreEmptyValidations),
        },
    }

    private validatorsStepList = [
        this.validators.step1,
        this.validators.step2,
        this.validators.step3,
        this.validators.step4,
        this.validators.step5,
        this.validators.step6,
    ];
    private readonly stepList: MladiVoznikStoreStep[];

    api: SocketApi;
    formStore?: FormStore;
    done: boolean = false;

    constructor(api: SocketApi, formStore?: FormStore) {
        window.onbeforeunload = (e) => {
            const confirmationMessage = 'Ste prepričani?';
            (e || window.event).returnValue = confirmationMessage; // Gecko + IE
            return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
        };
        if (formStore?.resumeFormData?.json) {
            this.form = mladi_voznik.MladiVoznikForm.fromObject(JSON.parse(formStore.resumeFormData!.json!));
        }
        this.ensureFormFields();
        this.api = api;
        this.doneUpToStep = this.step;
        this.formStore = formStore;

        this.step1 = new MladiVoznikStoreS1(this, api);
        this.step2 = new MladiVoznikStoreS2(this, api);
        this.step3 = new MladiVoznikStoreS3(this, api);
        this.step4 = new MladiVoznikStoreS4(this, api);
        this.step5 = new MladiVoznikStoreS5(this, api);
        this.step6 = new MladiVoznikStoreS6(this, api);

        this.stepList = [
            this.step1,
            this.step2,
            this.step3,
            this.step4,
            this.step5,
            this.step6,
        ];

        makeAutoObservable(this);

        makeAutoObservable(this.form!.step1!);
        makeAutoObservable(this.form!.step1!.address!);
        makeAutoObservable(this.form!.step1!.mailingAddress!);
        makeAutoObservable(this.form!.step2!);
        makeAutoObservable(this.form!.step3!);
        makeAutoObservable(this.form!.step4!);
        makeAutoObservable(this.form!.step5!);
        makeAutoObservable(this.form!.step6!);

        this.stepList[this.step].init();
    }

    get config() {
        return this.formStore?.formConfig!;
    };

    get configStepList() {
        return [
            this.formStore?.formConfig?.s1!,
            this.formStore?.formConfig?.s2!,
            this.formStore?.formConfig?.s3!,
            this.formStore?.formConfig?.s4!,
            this.formStore?.formConfig?.s5!,
            this.formStore?.formConfig?.s6!,
        ];
    };

    private ensureFormFields() {
        this.form.step1 = this.form.step1 ?? mladi_voznik.MladiVoznikFormS1.create();
        this.form.step1.address = this.form.step1.address ?? cvv_types.Address.create();
        this.form.step1.mailingAddress = this.form.step1.mailingAddress ?? cvv_types.Address.create();
        this.form.step2 = this.form.step2 ?? mladi_voznik.MladiVoznikFormS2.create();
        this.form.step3 = this.form.step3 ?? mladi_voznik.MladiVoznikFormS3.create();
        this.form.step4 = this.form.step4 ?? mladi_voznik.MladiVoznikFormS4.create();
        this.form.step4.kosilo = this.form.step4.kosilo ?? true;
        this.form.step4.clanstvo = this.form.step4.clanstvo ?? true;
        this.form.step4.vozilo = this.form.step4.vozilo ?? cvv_types.Vozilo.svoje;
        this.form.step5 = this.form.step5 ?? mladi_voznik.MladiVoznikFormS5.create();
        this.form.step6 = this.form.step6 ?? mladi_voznik.MladiVoznikFormS6.create();
    }

    setAddress(place: google.maps.places.PlaceResult) {
        MladiVoznikStore.placeResultToAddress(this.form.step1!.address!, place);
    }

    static placeResultToAddress(address: cvv_types.IAddress, place: google.maps.places.PlaceResult) {
        address!.address = place.formatted_address;
        address!.lat = place.geometry?.location?.lat();
        address!.lng = place.geometry?.location?.lng();

        let street = '';
        let streetNumber = '';
        for (const component of place.address_components ?? []) {
            for (const type of component.types) {
                switch (type) {
                    case 'postal_town':
                        address!.city = component.long_name;
                        break;
                    case 'country':
                        address!.country = component.long_name;
                        break;
                    case 'street_number':
                        streetNumber = component.long_name;
                        break;
                    case 'route':
                        street = component.long_name;
                        break;
                    case 'postal_code':
                        address!.postalCode = component.long_name;
                        break;
                    case 'political':
                        address!.state = component.short_name;
                        break;
                }
            }
        }
        address!.line1 = street + ' ' + streetNumber;
    }

    onUserActionValidation = (): void => {
        this.ignoreEmptyValidations = false;
    }

    stepValid = (step: number, ignore?: boolean): boolean => {
        const config = (this.configStepList[step] ?? {}) as any;
        if (config.hide) return true;
        if (ignore === undefined) ignore = this.ignoreEmptyValidations;
        for (const [key, validate] of Object.entries(this.validatorsStepList[step] as StepValidators)) {
            if (config[key] === false) continue;
            if (validate(ignore)) return false;
        }
        return true;
    }

    setStep(step: number) {
        const nextStep = (s: number) => {
            while (this.configStepList[s]?.hide && s < this.stepList.length - 1) {
                s += 1;
            }
            return s;
        };
        step = nextStep(step);

        this.onUserActionValidation();
        const valid = this.stepValid(this.step);
        const currentInValidOrNotDone = !valid || !this.stepList[this.step].done || nextStep(this.doneUpToStep + 1) < step;
        console.log(step, this.step, valid, currentInValidOrNotDone, this.stepList[this.step].done, this.doneUpToStep);
        if (step >= this.step && currentInValidOrNotDone) {
            return;
        }
        this.ignoreEmptyValidations = true;
        console.log('step =', step);
        this.step = step;
        this.stepList[step].init();
        if (this.doneUpToStep < step) this.doneUpToStep = step;
    }

    get pricelistItems(): billing.IPricelistItem[] {
        if (!this.formStore?.pricelist) return [];
        const items: billing.IPricelistItem[] = [
            this.formStore.pricelist.items[PricelistItems.tecaj]
        ];
        if (this.form.step4?.kosilo) {
            items.push(this.formStore.pricelist.items[PricelistItems.kosilo]);
        }
        if (this.form.step4?.vozilo !== cvv_types.Vozilo.svoje) {
            items.push(this.formStore.pricelist.items[PricelistItems.vozilo])
        }
        return items;
    }

    get pricelistModifiers(): Set<string> {
        const modifiers = new Set<string>();
        if (this.step1.result?.jeClan || this.form.step4?.clanstvo) {
            modifiers.add(PricelistModifiers.amzs_clan);
        }

        if ([5, 7, 8, 9, 12, 13].includes(this.step1.result?.memberCategory ?? -1)) {
            modifiers.add(PricelistModifiers.amzs_clan_1);
        }

        if ([1, 2, 3, 4, 6, 10, 11, 14, 15].includes(this.step1.result?.memberCategory ?? -1)) {
            modifiers.add(PricelistModifiers.amzs_clan_2);
        }

        if (this.form.step1?.isStudent) {
            modifiers.add(PricelistModifiers.student_dijak);
        }

        if (this.form.prijatelj) {
            modifiers.add(PricelistModifiers.vozilo_s_prijateljem);
        } else if (this.form.step4?.vozilo === cvv_types.Vozilo.najeto_druga_oseba) {
            modifiers.add(PricelistModifiers.vozilo_z_drugim);
        }
        return modifiers;
    }

    dispose() {
        window.onbeforeunload = null;
        for (const step of this.stepList) {
            step.dispose();
        }
    }
}

export interface MladiVoznikStoreStep {
    done: boolean;
    error?: string;
    init: () => void;
    dispose: () => void;
}