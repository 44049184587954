import {SocketRxMessage, SocketRxMessageData, SocketTxMessage} from "proto_socket_typescript";
import {sfiles, calendar, phone_login, exports, admin_participants, form, billing, admin_tecaj, participation, socket_api, uploader, admin_tecaji, authentication, amzs_cvv, mladi_voznik} from "./compiled";

export namespace proto {

export class TxAdminArchiveParticipant extends SocketTxMessage<admin_participants.AdminArchiveParticipant> {
    static type: string = 'admin-archive-participant';
    proto: admin_participants.AdminArchiveParticipant;
    protoClass = admin_participants.AdminArchiveParticipant;
    

    constructor(proto: admin_participants.AdminArchiveParticipant) {
        super(TxAdminArchiveParticipant.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminArchiveParticipant = {}) {
        return new TxAdminArchiveParticipant(admin_participants.AdminArchiveParticipant.create(properties));
    }
}


export class RxAdminCommentTypes extends SocketRxMessage<admin_participants.AdminCommentTypes> {
    static type: string = 'admin-comment-types';
    proto = admin_participants.AdminCommentTypes.create({});
    protoClass = admin_participants.AdminCommentTypes;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminCommentTypes.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminCommentTypes(message);
    };
}


export class TxAdminCreateInvoice extends SocketTxMessage<admin_participants.AdminCreateInvoice> {
    static type: string = 'create-invoice';
    proto: admin_participants.AdminCreateInvoice;
    protoClass = admin_participants.AdminCreateInvoice;
    

    constructor(proto: admin_participants.AdminCreateInvoice) {
        super(TxAdminCreateInvoice.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminCreateInvoice = {}) {
        return new TxAdminCreateInvoice(admin_participants.AdminCreateInvoice.create(properties));
    }
}


export class TxAdminLoadCommentTypes extends SocketTxMessage<admin_participants.AdminLoadCommentTypes> {
    static type: string = 'admin-load-comment-types';
    proto: admin_participants.AdminLoadCommentTypes;
    protoClass = admin_participants.AdminLoadCommentTypes;
    

    constructor(proto: admin_participants.AdminLoadCommentTypes) {
        super(TxAdminLoadCommentTypes.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminLoadCommentTypes = {}) {
        return new TxAdminLoadCommentTypes(admin_participants.AdminLoadCommentTypes.create(properties));
    }
}


export class TxAdminLoadParticipant extends SocketTxMessage<admin_participants.AdminLoadParticipant> {
    static type: string = 'admin-load-participant';
    proto: admin_participants.AdminLoadParticipant;
    protoClass = admin_participants.AdminLoadParticipant;
    

    constructor(proto: admin_participants.AdminLoadParticipant) {
        super(TxAdminLoadParticipant.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminLoadParticipant = {}) {
        return new TxAdminLoadParticipant(admin_participants.AdminLoadParticipant.create(properties));
    }
}


export class TxAdminLoadParticipants extends SocketTxMessage<admin_participants.AdminLoadParticipants> {
    static type: string = 'admin-load-participants';
    proto: admin_participants.AdminLoadParticipants;
    protoClass = admin_participants.AdminLoadParticipants;
    

    constructor(proto: admin_participants.AdminLoadParticipants) {
        super(TxAdminLoadParticipants.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminLoadParticipants = {}) {
        return new TxAdminLoadParticipants(admin_participants.AdminLoadParticipants.create(properties));
    }
}


export class RxAdminParticipant extends SocketRxMessage<admin_participants.AdminParticipant> {
    static type: string = 'admin-participant';
    proto = admin_participants.AdminParticipant.create({});
    protoClass = admin_participants.AdminParticipant;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminParticipant.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminParticipant(message);
    };
}


export class RxAdminParticipantComments extends SocketRxMessage<admin_participants.AdminParticipantComments> {
    static type: string = 'admin-participant-comments';
    proto = admin_participants.AdminParticipantComments.create({});
    protoClass = admin_participants.AdminParticipantComments;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminParticipantComments.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminParticipantComments(message);
    };
}


export class TxAdminParticipantDeleteComment extends SocketTxMessage<admin_participants.AdminParticipantDeleteComment> {
    static type: string = 'admin-participant-delete-comment';
    proto: admin_participants.AdminParticipantDeleteComment;
    protoClass = admin_participants.AdminParticipantDeleteComment;
    

    constructor(proto: admin_participants.AdminParticipantDeleteComment) {
        super(TxAdminParticipantDeleteComment.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminParticipantDeleteComment = {}) {
        return new TxAdminParticipantDeleteComment(admin_participants.AdminParticipantDeleteComment.create(properties));
    }
}


export class TxAdminParticipantResendConfirmationMail extends SocketTxMessage<admin_participants.AdminParticipantResendConfirmationMail> {
    static type: string = 'resend-confirmation-mail';
    proto: admin_participants.AdminParticipantResendConfirmationMail;
    protoClass = admin_participants.AdminParticipantResendConfirmationMail;
    

    constructor(proto: admin_participants.AdminParticipantResendConfirmationMail) {
        super(TxAdminParticipantResendConfirmationMail.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminParticipantResendConfirmationMail = {}) {
        return new TxAdminParticipantResendConfirmationMail(admin_participants.AdminParticipantResendConfirmationMail.create(properties));
    }
}


export class TxAdminParticipantSaveComment extends SocketTxMessage<admin_participants.AdminParticipantSaveComment> {
    static type: string = 'admin-participant-save-comment';
    proto: admin_participants.AdminParticipantSaveComment;
    protoClass = admin_participants.AdminParticipantSaveComment;
    

    constructor(proto: admin_participants.AdminParticipantSaveComment) {
        super(TxAdminParticipantSaveComment.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminParticipantSaveComment = {}) {
        return new TxAdminParticipantSaveComment(admin_participants.AdminParticipantSaveComment.create(properties));
    }
}


export class TxAdminParticipantSetNewDate extends SocketTxMessage<admin_participants.AdminParticipantSetNewDate> {
    static type: string = 'admin-participant-set-new-date';
    proto: admin_participants.AdminParticipantSetNewDate;
    protoClass = admin_participants.AdminParticipantSetNewDate;
    

    constructor(proto: admin_participants.AdminParticipantSetNewDate) {
        super(TxAdminParticipantSetNewDate.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminParticipantSetNewDate = {}) {
        return new TxAdminParticipantSetNewDate(admin_participants.AdminParticipantSetNewDate.create(properties));
    }
}


export class TxAdminParticipantSetPresence extends SocketTxMessage<admin_participants.AdminParticipantSetPresence> {
    static type: string = 'admin-participant-set-presence';
    proto: admin_participants.AdminParticipantSetPresence;
    protoClass = admin_participants.AdminParticipantSetPresence;
    

    constructor(proto: admin_participants.AdminParticipantSetPresence) {
        super(TxAdminParticipantSetPresence.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminParticipantSetPresence = {}) {
        return new TxAdminParticipantSetPresence(admin_participants.AdminParticipantSetPresence.create(properties));
    }
}


export class RxAdminParticipants extends SocketRxMessage<admin_participants.AdminParticipants> {
    static type: string = 'admin-participants';
    proto = admin_participants.AdminParticipants.create({});
    protoClass = admin_participants.AdminParticipants;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminParticipants.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminParticipants(message);
    };
}


export class TxAdminPaymentAtCvv extends SocketTxMessage<admin_participants.AdminPaymentAtCvv> {
    static type: string = 'payment-at-cvv';
    proto: admin_participants.AdminPaymentAtCvv;
    protoClass = admin_participants.AdminPaymentAtCvv;
    

    constructor(proto: admin_participants.AdminPaymentAtCvv) {
        super(TxAdminPaymentAtCvv.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminPaymentAtCvv = {}) {
        return new TxAdminPaymentAtCvv(admin_participants.AdminPaymentAtCvv.create(properties));
    }
}


export class TxAdminSendPredracun extends SocketTxMessage<admin_participants.AdminSendPredracun> {
    static type: string = 'send-predracun';
    proto: admin_participants.AdminSendPredracun;
    protoClass = admin_participants.AdminSendPredracun;
    

    constructor(proto: admin_participants.AdminSendPredracun) {
        super(TxAdminSendPredracun.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminSendPredracun = {}) {
        return new TxAdminSendPredracun(admin_participants.AdminSendPredracun.create(properties));
    }
}


export class TxAdminUnovciDarilniBon extends SocketTxMessage<admin_participants.AdminUnovciDarilniBon> {
    static type: string = 'unovci-darilni-bon';
    proto: admin_participants.AdminUnovciDarilniBon;
    protoClass = admin_participants.AdminUnovciDarilniBon;
    

    constructor(proto: admin_participants.AdminUnovciDarilniBon) {
        super(TxAdminUnovciDarilniBon.type, true);
        this.proto = proto;
    }

    static create(properties: admin_participants.IAdminUnovciDarilniBon = {}) {
        return new TxAdminUnovciDarilniBon(admin_participants.AdminUnovciDarilniBon.create(properties));
    }
}


export class TxAdminDeleteRazpisaniTecaj extends SocketTxMessage<admin_tecaj.AdminDeleteRazpisaniTecaj> {
    static type: string = 'admin-delete-razpisani-tecaj';
    proto: admin_tecaj.AdminDeleteRazpisaniTecaj;
    protoClass = admin_tecaj.AdminDeleteRazpisaniTecaj;
    

    constructor(proto: admin_tecaj.AdminDeleteRazpisaniTecaj) {
        super(TxAdminDeleteRazpisaniTecaj.type, true);
        this.proto = proto;
    }

    static create(properties: admin_tecaj.IAdminDeleteRazpisaniTecaj = {}) {
        return new TxAdminDeleteRazpisaniTecaj(admin_tecaj.AdminDeleteRazpisaniTecaj.create(properties));
    }
}


export class TxAdminLoadRazpisaniTecaj extends SocketTxMessage<admin_tecaj.AdminLoadRazpisaniTecaj> {
    static type: string = 'admin-load-razpisani-tecaj';
    proto: admin_tecaj.AdminLoadRazpisaniTecaj;
    protoClass = admin_tecaj.AdminLoadRazpisaniTecaj;
    

    constructor(proto: admin_tecaj.AdminLoadRazpisaniTecaj) {
        super(TxAdminLoadRazpisaniTecaj.type, true);
        this.proto = proto;
    }

    static create(properties: admin_tecaj.IAdminLoadRazpisaniTecaj = {}) {
        return new TxAdminLoadRazpisaniTecaj(admin_tecaj.AdminLoadRazpisaniTecaj.create(properties));
    }
}


export class RxAdminRazpisaniTecaj extends SocketRxMessage<admin_tecaj.AdminRazpisaniTecaj> {
    static type: string = 'admin-razpisani-tecaj';
    proto = admin_tecaj.AdminRazpisaniTecaj.create({});
    protoClass = admin_tecaj.AdminRazpisaniTecaj;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminRazpisaniTecaj.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminRazpisaniTecaj(message);
    };
}


export class TxAdminSaveRazpisaniTecaj extends SocketTxMessage<admin_tecaj.AdminSaveRazpisaniTecaj> {
    static type: string = 'admin-save-razpisani-tecaj';
    proto: admin_tecaj.AdminSaveRazpisaniTecaj;
    protoClass = admin_tecaj.AdminSaveRazpisaniTecaj;
    

    constructor(proto: admin_tecaj.AdminSaveRazpisaniTecaj) {
        super(TxAdminSaveRazpisaniTecaj.type, true);
        this.proto = proto;
    }

    static create(properties: admin_tecaj.IAdminSaveRazpisaniTecaj = {}) {
        return new TxAdminSaveRazpisaniTecaj(admin_tecaj.AdminSaveRazpisaniTecaj.create(properties));
    }
}


export class RxAdminTecajInstructors extends SocketRxMessage<admin_tecaj.AdminTecajInstructors> {
    static type: string = 'admin-tecaj-instructors';
    proto = admin_tecaj.AdminTecajInstructors.create({});
    protoClass = admin_tecaj.AdminTecajInstructors;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminTecajInstructors.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminTecajInstructors(message);
    };
}


export class RxAdminCalendarData extends SocketRxMessage<admin_tecaji.AdminCalendarData> {
    static type: string = 'admin-calendar-data';
    proto = admin_tecaji.AdminCalendarData.create({});
    protoClass = admin_tecaji.AdminCalendarData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminCalendarData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminCalendarData(message);
    };
}


export class TxAdminLoadCalendar extends SocketTxMessage<admin_tecaji.AdminLoadCalendar> {
    static type: string = 'admin-load-calendar';
    proto: admin_tecaji.AdminLoadCalendar;
    protoClass = admin_tecaji.AdminLoadCalendar;
    

    constructor(proto: admin_tecaji.AdminLoadCalendar) {
        super(TxAdminLoadCalendar.type, true);
        this.proto = proto;
    }

    static create(properties: admin_tecaji.IAdminLoadCalendar = {}) {
        return new TxAdminLoadCalendar(admin_tecaji.AdminLoadCalendar.create(properties));
    }
}


export class RxAdminMeta extends SocketRxMessage<admin_tecaji.AdminMeta> {
    static type: string = 'admin-meta';
    proto = admin_tecaji.AdminMeta.create({});
    protoClass = admin_tecaji.AdminMeta;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAdminMeta.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAdminMeta(message);
    };
}


export class TxGetMagicLink extends SocketTxMessage<amzs_cvv.GetMagicLink> {
    static type: string = 'get-magic-link';
    proto: amzs_cvv.GetMagicLink;
    protoClass = amzs_cvv.GetMagicLink;
    

    constructor(proto: amzs_cvv.GetMagicLink) {
        super(TxGetMagicLink.type, true);
        this.proto = proto;
    }

    static create(properties: amzs_cvv.IGetMagicLink = {}) {
        return new TxGetMagicLink(amzs_cvv.GetMagicLink.create(properties));
    }
}


export class TxLoginMagicLink extends SocketTxMessage<amzs_cvv.LoginMagicLink> {
    static type: string = 'login-magic-link';
    proto: amzs_cvv.LoginMagicLink;
    protoClass = amzs_cvv.LoginMagicLink;
    

    constructor(proto: amzs_cvv.LoginMagicLink) {
        super(TxLoginMagicLink.type, true);
        this.proto = proto;
    }

    static create(properties: amzs_cvv.ILoginMagicLink = {}) {
        return new TxLoginMagicLink(amzs_cvv.LoginMagicLink.create(properties));
    }
}


export class RxLoginMagicLinkStatus extends SocketRxMessage<amzs_cvv.LoginMagicLinkStatus> {
    static type: string = 'login-magic-link-status';
    proto = amzs_cvv.LoginMagicLinkStatus.create({});
    protoClass = amzs_cvv.LoginMagicLinkStatus;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginMagicLinkStatus.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginMagicLinkStatus(message);
    };
}


export class RxUserProfileData extends SocketRxMessage<amzs_cvv.UserProfileData> {
    static type: string = 'user-profile-data';
    proto = amzs_cvv.UserProfileData.create({});
    protoClass = amzs_cvv.UserProfileData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUserProfileData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUserProfileData(message);
    };
}


export class TxLogin extends SocketTxMessage<authentication.Login> {
    static type: string = 'login';
    proto: authentication.Login;
    protoClass = authentication.Login;
    

    constructor(proto: authentication.Login) {
        super(TxLogin.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.ILogin = {}) {
        return new TxLogin(authentication.Login.create(properties));
    }
}


export class RxLoginError extends SocketRxMessage<authentication.LoginError> {
    static type: string = 'login-error';
    proto = authentication.LoginError.create({});
    protoClass = authentication.LoginError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginError(message);
    };
}


export class RxLoginToken extends SocketRxMessage<authentication.LoginToken> {
    static type: string = 'login-token';
    proto = authentication.LoginToken.create({});
    protoClass = authentication.LoginToken;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxLoginToken.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxLoginToken(message);
    };
}


export class TxRefreshToken extends SocketTxMessage<authentication.RefreshToken> {
    static type: string = 'refresh-token';
    proto: authentication.RefreshToken;
    protoClass = authentication.RefreshToken;
    

    constructor(proto: authentication.RefreshToken) {
        super(TxRefreshToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IRefreshToken = {}) {
        return new TxRefreshToken(authentication.RefreshToken.create(properties));
    }
}


export class RxRefreshTokenInvalid extends SocketRxMessage<authentication.RefreshTokenInvalid> {
    static type: string = 'refresh-token-invalid';
    proto = authentication.RefreshTokenInvalid.create({});
    protoClass = authentication.RefreshTokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxRefreshTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxRefreshTokenInvalid(message);
    };
}


export class RxTokenInvalid extends SocketRxMessage<authentication.TokenInvalid> {
    static type: string = 'token-invalid';
    proto = authentication.TokenInvalid.create({});
    protoClass = authentication.TokenInvalid;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxTokenInvalid.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxTokenInvalid(message);
    };
}


export class TxVerifyToken extends SocketTxMessage<authentication.VerifyToken> {
    static type: string = 'verify-token';
    proto: authentication.VerifyToken;
    protoClass = authentication.VerifyToken;
    

    constructor(proto: authentication.VerifyToken) {
        super(TxVerifyToken.type, true);
        this.proto = proto;
    }

    static create(properties: authentication.IVerifyToken = {}) {
        return new TxVerifyToken(authentication.VerifyToken.create(properties));
    }
}


export class RxStripePaymentIntent extends SocketRxMessage<billing.StripePaymentIntent> {
    static type: string = 'stripe-payment-intent';
    proto = billing.StripePaymentIntent.create({});
    protoClass = billing.StripePaymentIntent;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxStripePaymentIntent.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxStripePaymentIntent(message);
    };
}


export class RxCalendarData extends SocketRxMessage<calendar.CalendarData> {
    static type: string = 'calendar-data';
    proto = calendar.CalendarData.create({});
    protoClass = calendar.CalendarData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxCalendarData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxCalendarData(message);
    };
}


export class RxCalendarDayData extends SocketRxMessage<calendar.CalendarDayData> {
    static type: string = 'calendar-day-data';
    proto = calendar.CalendarDayData.create({});
    protoClass = calendar.CalendarDayData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxCalendarDayData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxCalendarDayData(message);
    };
}


export class TxLoadCalendar extends SocketTxMessage<calendar.LoadCalendar> {
    static type: string = 'load-calendar';
    proto: calendar.LoadCalendar;
    protoClass = calendar.LoadCalendar;
    

    constructor(proto: calendar.LoadCalendar) {
        super(TxLoadCalendar.type, true);
        this.proto = proto;
    }

    static create(properties: calendar.ILoadCalendar = {}) {
        return new TxLoadCalendar(calendar.LoadCalendar.create(properties));
    }
}


export class TxLoadCalendarDay extends SocketTxMessage<calendar.LoadCalendarDay> {
    static type: string = 'load-calendar-day';
    proto: calendar.LoadCalendarDay;
    protoClass = calendar.LoadCalendarDay;
    

    constructor(proto: calendar.LoadCalendarDay) {
        super(TxLoadCalendarDay.type, true);
        this.proto = proto;
    }

    static create(properties: calendar.ILoadCalendarDay = {}) {
        return new TxLoadCalendarDay(calendar.LoadCalendarDay.create(properties));
    }
}


export class TxExportSession extends SocketTxMessage<exports.ExportSession> {
    static type: string = 'export-session';
    proto: exports.ExportSession;
    protoClass = exports.ExportSession;
    

    constructor(proto: exports.ExportSession) {
        super(TxExportSession.type, true);
        this.proto = proto;
    }

    static create(properties: exports.IExportSession = {}) {
        return new TxExportSession(exports.ExportSession.create(properties));
    }
}


export class RxExportSlot extends SocketRxMessage<exports.ExportSlot> {
    static type: string = 'export-slot';
    proto = exports.ExportSlot.create({});
    protoClass = exports.ExportSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxExportSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxExportSlot(message);
    };
}


export class RxFormMeta extends SocketRxMessage<form.FormMeta> {
    static type: string = 'form-meta';
    proto = form.FormMeta.create({});
    protoClass = form.FormMeta;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxFormMeta.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxFormMeta(message);
    };
}


export class RxFormSummary extends SocketRxMessage<form.FormSummary> {
    static type: string = 'form-summary';
    proto = form.FormSummary.create({});
    protoClass = form.FormSummary;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxFormSummary.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxFormSummary(message);
    };
}


export class TxLoadForm extends SocketTxMessage<form.LoadForm> {
    static type: string = 'load-form';
    proto: form.LoadForm;
    protoClass = form.LoadForm;
    

    constructor(proto: form.LoadForm) {
        super(TxLoadForm.type, true);
        this.proto = proto;
    }

    static create(properties: form.ILoadForm = {}) {
        return new TxLoadForm(form.LoadForm.create(properties));
    }
}


export class TxLoadFormSummary extends SocketTxMessage<form.LoadFormSummary> {
    static type: string = 'load-form-summary';
    proto: form.LoadFormSummary;
    protoClass = form.LoadFormSummary;
    

    constructor(proto: form.LoadFormSummary) {
        super(TxLoadFormSummary.type, true);
        this.proto = proto;
    }

    static create(properties: form.ILoadFormSummary = {}) {
        return new TxLoadFormSummary(form.LoadFormSummary.create(properties));
    }
}


export class RxOrderSummary extends SocketRxMessage<form.OrderSummary> {
    static type: string = 'order-summary';
    proto = form.OrderSummary.create({});
    protoClass = form.OrderSummary;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxOrderSummary.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxOrderSummary(message);
    };
}


export class TxResumeForm extends SocketTxMessage<form.ResumeForm> {
    static type: string = 'resume-form';
    proto: form.ResumeForm;
    protoClass = form.ResumeForm;
    

    constructor(proto: form.ResumeForm) {
        super(TxResumeForm.type, true);
        this.proto = proto;
    }

    static create(properties: form.IResumeForm = {}) {
        return new TxResumeForm(form.ResumeForm.create(properties));
    }
}


export class RxResumeFormData extends SocketRxMessage<form.ResumeFormData> {
    static type: string = 'resume-form-data';
    proto = form.ResumeFormData.create({});
    protoClass = form.ResumeFormData;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxResumeFormData.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxResumeFormData(message);
    };
}


export class TxSubmitForm extends SocketTxMessage<form.SubmitForm> {
    static type: string = 'submit-form';
    proto: form.SubmitForm;
    protoClass = form.SubmitForm;
    

    constructor(proto: form.SubmitForm) {
        super(TxSubmitForm.type, true);
        this.proto = proto;
    }

    static create(properties: form.ISubmitForm = {}) {
        return new TxSubmitForm(form.SubmitForm.create(properties));
    }
}


export class RxMladiVoznikFormConfig extends SocketRxMessage<mladi_voznik.MladiVoznikFormConfig> {
    static type: string = 'mladi-voznik-form-config';
    proto = mladi_voznik.MladiVoznikFormConfig.create({});
    protoClass = mladi_voznik.MladiVoznikFormConfig;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxMladiVoznikFormConfig.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxMladiVoznikFormConfig(message);
    };
}


export class TxSaveMladiVoznikForm extends SocketTxMessage<mladi_voznik.SaveMladiVoznikForm> {
    static type: string = 'save-mladi-voznik-form';
    proto: mladi_voznik.SaveMladiVoznikForm;
    protoClass = mladi_voznik.SaveMladiVoznikForm;
    

    constructor(proto: mladi_voznik.SaveMladiVoznikForm) {
        super(TxSaveMladiVoznikForm.type, true);
        this.proto = proto;
    }

    static create(properties: mladi_voznik.ISaveMladiVoznikForm = {}) {
        return new TxSaveMladiVoznikForm(mladi_voznik.SaveMladiVoznikForm.create(properties));
    }
}


export class TxSaveMladiVoznikFormAdmin extends SocketTxMessage<mladi_voznik.SaveMladiVoznikFormAdmin> {
    static type: string = 'save-mladi-voznik-form-admin';
    proto: mladi_voznik.SaveMladiVoznikFormAdmin;
    protoClass = mladi_voznik.SaveMladiVoznikFormAdmin;
    

    constructor(proto: mladi_voznik.SaveMladiVoznikFormAdmin) {
        super(TxSaveMladiVoznikFormAdmin.type, true);
        this.proto = proto;
    }

    static create(properties: mladi_voznik.ISaveMladiVoznikFormAdmin = {}) {
        return new TxSaveMladiVoznikFormAdmin(mladi_voznik.SaveMladiVoznikFormAdmin.create(properties));
    }
}


export class TxSetSMSParticipation extends SocketTxMessage<participation.SetSMSParticipation> {
    static type: string = 'set-sms-participation';
    proto: participation.SetSMSParticipation;
    protoClass = participation.SetSMSParticipation;
    

    constructor(proto: participation.SetSMSParticipation) {
        super(TxSetSMSParticipation.type, true);
        this.proto = proto;
    }

    static create(properties: participation.ISetSMSParticipation = {}) {
        return new TxSetSMSParticipation(participation.SetSMSParticipation.create(properties));
    }
}


export class TxPhoneLoginRequest extends SocketTxMessage<phone_login.PhoneLoginRequest> {
    static type: string = 'phone-login-request';
    proto: phone_login.PhoneLoginRequest;
    protoClass = phone_login.PhoneLoginRequest;
    

    constructor(proto: phone_login.PhoneLoginRequest) {
        super(TxPhoneLoginRequest.type, true);
        this.proto = proto;
    }

    static create(properties: phone_login.IPhoneLoginRequest = {}) {
        return new TxPhoneLoginRequest(phone_login.PhoneLoginRequest.create(properties));
    }
}


export class RxPhoneLoginResult extends SocketRxMessage<phone_login.PhoneLoginResult> {
    static type: string = 'phone-login-result';
    proto = phone_login.PhoneLoginResult.create({});
    protoClass = phone_login.PhoneLoginResult;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxPhoneLoginResult.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxPhoneLoginResult(message);
    };
}


export class TxPhoneLoginWithPin extends SocketTxMessage<phone_login.PhoneLoginWithPin> {
    static type: string = 'phone-login-with-pin';
    proto: phone_login.PhoneLoginWithPin;
    protoClass = phone_login.PhoneLoginWithPin;
    

    constructor(proto: phone_login.PhoneLoginWithPin) {
        super(TxPhoneLoginWithPin.type, true);
        this.proto = proto;
    }

    static create(properties: phone_login.IPhoneLoginWithPin = {}) {
        return new TxPhoneLoginWithPin(phone_login.PhoneLoginWithPin.create(properties));
    }
}


export class TxDeleteFile extends SocketTxMessage<sfiles.DeleteFile> {
    static type: string = 'delete-file';
    proto: sfiles.DeleteFile;
    protoClass = sfiles.DeleteFile;
    

    constructor(proto: sfiles.DeleteFile) {
        super(TxDeleteFile.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IDeleteFile = {}) {
        return new TxDeleteFile(sfiles.DeleteFile.create(properties));
    }
}


export class RxUploadDone extends SocketRxMessage<sfiles.UploadDone> {
    static type: string = 'upload-done';
    proto = sfiles.UploadDone.create({});
    protoClass = sfiles.UploadDone;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadDone.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadDone(message);
    };
}


export class TxUploadEnd extends SocketTxMessage<sfiles.UploadEnd> {
    static type: string = 'upload-end';
    proto: sfiles.UploadEnd;
    protoClass = sfiles.UploadEnd;
    

    constructor(proto: sfiles.UploadEnd) {
        super(TxUploadEnd.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadEnd = {}) {
        return new TxUploadEnd(sfiles.UploadEnd.create(properties));
    }
}


export class RxUploadProgress extends SocketRxMessage<sfiles.UploadProgress> {
    static type: string = 'upload-progress';
    proto = sfiles.UploadProgress.create({});
    protoClass = sfiles.UploadProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadProgress(message);
    };
}


export class TxUploadStart extends SocketTxMessage<sfiles.UploadStart> {
    static type: string = 'upload-start';
    proto: sfiles.UploadStart;
    protoClass = sfiles.UploadStart;
    

    constructor(proto: sfiles.UploadStart) {
        super(TxUploadStart.type, true);
        this.proto = proto;
    }

    static create(properties: sfiles.IUploadStart = {}) {
        return new TxUploadStart(sfiles.UploadStart.create(properties));
    }
}


export class RxUploadStartSlot extends SocketRxMessage<sfiles.UploadStartSlot> {
    static type: string = 'upload-start';
    proto = sfiles.UploadStartSlot.create({});
    protoClass = sfiles.UploadStartSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadStartSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadStartSlot(message);
    };
}


export class RxAck extends SocketRxMessage<socket_api.Ack> {
    static type: string = 'ack';
    proto = socket_api.Ack.create({});
    protoClass = socket_api.Ack;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAck(message);
    };
}


export class RxAsyncProgress extends SocketRxMessage<socket_api.AsyncProgress> {
    static type: string = 'async-progress';
    proto = socket_api.AsyncProgress.create({});
    protoClass = socket_api.AsyncProgress;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxAsyncProgress.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxAsyncProgress(message);
    };
}


export class RxIlolAck extends SocketRxMessage<socket_api.IlolAck> {
    static type: string = 'ilol|ack';
    proto = socket_api.IlolAck.create({});
    protoClass = socket_api.IlolAck;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxIlolAck.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxIlolAck(message);
    };
}


export class RxIlolError extends SocketRxMessage<socket_api.IlolError> {
    static type: string = 'ilol|error';
    proto = socket_api.IlolError.create({});
    protoClass = socket_api.IlolError;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxIlolError.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxIlolError(message);
    };
}


export class RxUpgradeApiVersion extends SocketRxMessage<socket_api.UpgradeApiVersion> {
    static type: string = 'upgrade-api-version';
    proto = socket_api.UpgradeApiVersion.create({});
    protoClass = socket_api.UpgradeApiVersion;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUpgradeApiVersion.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUpgradeApiVersion(message);
    };
}


export class TxDeleteUFile extends SocketTxMessage<uploader.DeleteUFile> {
    static type: string = 'delete-ufile';
    proto: uploader.DeleteUFile;
    protoClass = uploader.DeleteUFile;
    

    constructor(proto: uploader.DeleteUFile) {
        super(TxDeleteUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IDeleteUFile = {}) {
        return new TxDeleteUFile(uploader.DeleteUFile.create(properties));
    }
}


export class RxUploadSlot extends SocketRxMessage<uploader.UploadSlot> {
    static type: string = 'upload-slot';
    proto = uploader.UploadSlot.create({});
    protoClass = uploader.UploadSlot;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadSlot.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadSlot(message);
    };
}


export class RxUploadTask extends SocketRxMessage<uploader.UploadTask> {
    static type: string = 'upload-task';
    proto = uploader.UploadTask.create({});
    protoClass = uploader.UploadTask;
    

    constructor(message: SocketRxMessageData | null = null) {
        super(RxUploadTask.type, message);
        if (message !== null) {
            this.proto = this.protoClass.fromObject(message.body);
        }
    }

    fromMessage(message: SocketRxMessageData) {
        return new RxUploadTask(message);
    };
}


export class TxUploadUFile extends SocketTxMessage<uploader.UploadUFile> {
    static type: string = 'upload-ufile';
    proto: uploader.UploadUFile;
    protoClass = uploader.UploadUFile;
    

    constructor(proto: uploader.UploadUFile) {
        super(TxUploadUFile.type, true);
        this.proto = proto;
    }

    static create(properties: uploader.IUploadUFile = {}) {
        return new TxUploadUFile(uploader.UploadUFile.create(properties));
    }
}


        export const rxMessages: SocketRxMessage<any>[] = [
            new RxAdminCommentTypes(),
    new RxAdminParticipant(),
    new RxAdminParticipantComments(),
    new RxAdminParticipants(),
    new RxAdminRazpisaniTecaj(),
    new RxAdminTecajInstructors(),
    new RxAdminCalendarData(),
    new RxAdminMeta(),
    new RxLoginMagicLinkStatus(),
    new RxUserProfileData(),
    new RxLoginError(),
    new RxLoginToken(),
    new RxRefreshTokenInvalid(),
    new RxTokenInvalid(),
    new RxStripePaymentIntent(),
    new RxCalendarData(),
    new RxCalendarDayData(),
    new RxExportSlot(),
    new RxFormMeta(),
    new RxFormSummary(),
    new RxOrderSummary(),
    new RxResumeFormData(),
    new RxMladiVoznikFormConfig(),
    new RxPhoneLoginResult(),
    new RxUploadDone(),
    new RxUploadProgress(),
    new RxUploadStartSlot(),
    new RxAck(),
    new RxAsyncProgress(),
    new RxIlolAck(),
    new RxIlolError(),
    new RxUpgradeApiVersion(),
    new RxUploadSlot(),
    new RxUploadTask()
        ];
}