import {observer} from "mobx-react-lite";
import {
    FormContainer, FormContentContainer, FormStep,
    FormStepsContainer,
    HeaderContainer,
    HeaderSubtitle,
    HeaderTitle
} from "../components/decorations";
import {createContext, useContext, useEffect, useMemo} from "react";
import {FormMladiVoznikS1} from "./form_mladi_voznik_s1";
import {FormMladiVoznikS2} from "./form_mladi_voznik_s2";
import {FormMladiVoznikS3} from "./form_mladi_voznik_s3";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {ApiContext, AuthStoreContext, FormStoreContext} from "../../index";
import {FormMladiVoznikS4} from "./form_mladi_voznik_s4";
import {FormMladiVoznikS5} from "./form_mladi_voznik_s5";
import {FormMladiVoznikS6} from "./form_mladi_voznik_s6";
import {FormStore} from "../../stores/form_store";
import {SocketApi} from "proto_socket_typescript";
import {AdminStore} from "../../admin/stores/admin_store";
import {AuthStore} from "../../admin/stores/auth_store";

export const MladiVoznikStoreContext = createContext<MladiVoznikStore | null>(null);

export const FormMladiVoznik = () => {
    const api = useContext(ApiContext)
    const formStore = useContext(FormStoreContext) as FormStore;
    const store = useMemo(() => new MladiVoznikStore(api, formStore), [api, formStore])
    useEffect(() => () => store.dispose(), [store]);

    return <MladiVoznikStoreContext.Provider value={store}>
        <FormMladiVoznikContent/>
    </MladiVoznikStoreContext.Provider>
}

const FormMladiVoznikContent = observer(() => {
    const formStore = useContext(FormStoreContext) as FormStore;
    const auth = useContext(AuthStoreContext);
    return <FormContainer style={{margin: auth.authenticated ? '0' : '0 auto 0 auto'}}>
        <HeaderContainer>
            <HeaderTitle>Prijava na program</HeaderTitle>
            <HeaderSubtitle>{formStore.meta?.tecaj?.tipName} - {formStore.meta?.tecaj?.tecajName}</HeaderSubtitle>
        </HeaderContainer>
        <Content/>
    </FormContainer>;
});

const Content = observer((props) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const steps = [0, 1, 2, 3, 4, 5].filter((i) => !store.configStepList[i]?.hide);
    return <>
        <FormStepsContainer style={{
            pointerEvents: store.formStore?.paymentInitiated ? 'none' : 'auto',
            opacity: store.formStore?.paymentInitiated ? '0' : '1',
            transition: 'all 300ms'
        }}>
            {
                steps.map((v, i) =>
                    <FormStep key={`form-step-${i}`} selected={store.step === v} onClick={() => store.setStep(v)}>
                        {i + 1}
                    </FormStep>
                )
            }
        </FormStepsContainer>
        <FormContentContainer step={store.step}>
            <FormMladiVoznikS1/>
            <FormMladiVoznikS2/>
            <FormMladiVoznikS3/>
            <FormMladiVoznikS4/>
            <FormMladiVoznikS5/>
            <FormMladiVoznikS6/>
        </FormContentContainer>
    </>;
});

