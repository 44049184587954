import {observer} from "mobx-react-lite";
import {CvvCategoryHeader} from "../components/headers";
import {CvvInputField} from "../components/input";
import {CvvTextInput} from "../components/text_input";
import {FormStepContainer} from "../components/decorations";
import {RefObject, useContext, useRef} from "react";
import {useTheme} from "styled-components";
import {Theme} from "../../theme";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {CvvButton} from "../../components/buttons";
import {StringFormatters} from "../../utils";
import DatePicker from 'react-date-picker'
import {DatepickerStyles} from "../../components/datepicker_styles";
import {CvvRadio} from "../../components/radio";
import {cvv_types, mladi_voznik} from "../../proto/compiled";
import {CvvCollapsible} from "../../components/collapsible";
import {CvvCheckbox} from "../../components/checkbox";

export const FormMladiVoznikS3 = observer((props: any) => {
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const theme = useTheme() as Theme;
    let nowMinus6M = new Date();
    nowMinus6M.setMonth(nowMinus6M.getMonth() - 6);
    let nowMinus2Y = new Date();
    nowMinus2Y.setFullYear(nowMinus2Y.getFullYear() - 2);
    const dateRef = useRef<HTMLInputElement>();

    return <FormStepContainer>
        <CvvCategoryHeader style={{marginTop: '52px'}}>
            DODATNI PODATKI
        </CvvCategoryHeader>
        {store.config.s3?.emso && <CvvInputField error={store.validators.step3.emso()} label={'EMŠO'} input={
            <CvvTextInput type={'number'} min={0} maxLength={13} invalid={!!store.validators.step3.emso()}
                          value={store.form.step3!.emso!}
                          pattern={'[0-9]*'}
                          onChange={(e) => store.form.step3!.emso = StringFormatters.numeric(e.target.value)}/>
        }/>}
        {store.config.s3?.stVozniske &&
            <CvvInputField error={store.validators.step3.stVozniske()} label={'ŠTEVILKA VOZNIŠKEGA DOVOLJENJA'} input={
                <CvvTextInput type={'number'} min={0} maxLength={9} invalid={!!store.validators.step3.stVozniske()}
                              value={store.form.step3!.stVozniske!}
                              pattern={'[0-9]*'}
                              onChange={(e) => store.form.step3!.stVozniske = StringFormatters.numeric(e.target.value)}/>
            }/>}
        {store.config.s3?.datumIzdajeVozniske && <CvvInputField error={store.validators.step3.datumIzdajeVozniske()}
                                                                label={'DATUM IZDAJE VOZNIŠKEGA DOVOLJENJA'}
                                                                input={
                                                                    <DatepickerStyles
                                                                        invalid={!!store.validators.step3.datumIzdajeVozniske()}>
                                                                        <DatePicker
                                                                            inputRef={dateRef as RefObject<HTMLInputElement>}
                                                                            value={store.step3!.datumIzdajeVozniske}
                                                                            format={'dd. MM. y'}
                                                                            onChange={(date?: Date) => store.step3.setDatumIzdajeVozniske(date)}
                                                                            defaultActiveStartDate={nowMinus6M}
                                                                        />
                                                                    </DatepickerStyles>
                                                                }/>}
        {store.config.s3?.datumOpravljenegaIntenzivnegaTecaja &&
            <CvvInputField error={store.validators.step3.datumOpravljenegaIntenzivnegaTecaja()}
                           label={'DATUM OPRAVLJENEGA INTENZIVNEGA TEČAJA'}
                           input={
                               <DatepickerStyles
                                   invalid={!!store.validators.step3.datumOpravljenegaIntenzivnegaTecaja()}>
                                   <DatePicker
                                       inputRef={dateRef as RefObject<HTMLInputElement>}
                                       value={store.step3!.datumOpravljenegaIntenzivnegaTecaja}
                                       format={'dd. MM. y'}
                                       onChange={(date?: Date) => store.step3.setDatumOpravljenegaIntenzivnegaTecaja(date)}
                                       defaultActiveStartDate={nowMinus6M}
                                   />
                               </DatepickerStyles>
                           }/>}
        {store.config.s3?.krajRojstva &&
            <CvvInputField error={store.validators.step3.krajRojstva()} label={'KRAJ ROJSTVA'} input={
                <CvvTextInput
                    invalid={!!store.validators.step3.krajRojstva()} value={store.form.step3!.krajRojstva!}
                    onChange={(e) => store.form.step3!.krajRojstva = e.target.value}/>
            }/>}
        {(store.config.s3?.veljavnoVoznisko || store.config.s3?.veljavnoVozniskoKazenskeTocke) && <>
            <CvvCategoryHeader style={{marginTop: '52px'}}>
                IMAM VELJAVNO VOZNIŠKO DOVOLJENJE
            </CvvCategoryHeader>
            <CvvRadio
                name={'veljavno-voznisko'} id={'da'} checked={store.form.step3!.veljavnoVoznisko!} onChange={() => {
                store.form.step3!.veljavnoVoznisko = true;
            }} label={'DA'}/>
            <CvvRadio
                name={'veljavno-voznisko'} id={'ne'} checked={!store.form.step3!.veljavnoVoznisko} onChange={() => {
                store.form.step3!.veljavnoVoznisko = false;
                if (store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.prostovoljno) {
                    store.form.step3!.napotilo = mladi_voznik.KazenskeTockeNapotilo.sodisce;
                }
            }} label={'NE'}/>
        </>}
        {store.config.s3?.veljavnoVozniskoKazenskeTocke && <>
            <CvvCategoryHeader style={{marginTop: '52px'}}>
                KAKO STE BILI NAPOTENI NA PROGRAM?
            </CvvCategoryHeader>
            <CvvRadio
                name={'kt-napotilo'} id={'sodisce'}
                checked={store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce} onChange={() => {
                store.form.step3!.napotilo = mladi_voznik.KazenskeTockeNapotilo.sodisce;
            }} label={'SODIŠČE'}/>
            <CvvRadio
                name={'kt-napotilo'} id={'zdravniski'}
                checked={store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled}
                onChange={() => {
                    store.form.step3!.napotilo = mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled;
                }} label={'KONTROLNI ZDRAVSTVENI PREGLED'}/>
            <CvvCollapsible expanded={!!store.form.step3!.veljavnoVoznisko}>
                <CvvRadio
                    name={'kt-napotilo'} id={'prostovoljno'}
                    checked={store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.prostovoljno}
                    onChange={() => {
                        if (!store.form.step3!.veljavnoVoznisko) return;
                        store.form.step3!.napotilo = mladi_voznik.KazenskeTockeNapotilo.prostovoljno;
                    }} label={'PROSTOVOLJNO'}/>
            </CvvCollapsible>
            <CvvCollapsible expanded={store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.prostovoljno}>
                <CvvCheckbox
                    label={'Izjavljam, da se programa za izbris kazenskih točk udeležujem na lastno željo in nisem nobene od kazenskih točk pridobil /-a z vožnjo pod vplivom alkohola, prepovedanih drog in drugih psihoaktivnih snovi.'}
                    style={{fontWeight: 'bold', fontSize: '12px'}}
                    checked={store.step3.kazenskeSoglasje}
                    onChange={() => store.step3.kazenskeSoglasje = !store.step3.kazenskeSoglasje}/>
            </CvvCollapsible>
            <CvvCollapsible expanded={store.form.step3!.napotilo !== mladi_voznik.KazenskeTockeNapotilo.prostovoljno}>
                <CvvCategoryHeader style={{marginTop: '52px'}}>
                    NAPOTILO
                </CvvCategoryHeader>
            </CvvCollapsible>
            <CvvCollapsible expanded={store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce}>
                <CvvInputField error={store.validators.step3.pristojnoSodisce()}
                               label={'PRISTOJNO SODIŠČE'}
                               input={
                                   <CvvTextInput
                                       invalid={!!store.validators.step3.pristojnoSodisce()}
                                       value={store.form.step3!.napotiloPristojnoSodisce!}
                                       onChange={(e) => store.form.step3!.napotiloPristojnoSodisce = e.target.value}
                                       {...store.form.step3!.napotilo !== mladi_voznik.KazenskeTockeNapotilo.sodisce && {tabIndex: -1}}
                                   />
                               }/>
                <CvvInputField error={store.validators.step3.pristojnoSodisce()}
                               label={'ŠTEVILKA NAPOTILA'}
                               input={
                                   <CvvTextInput
                                       invalid={!!store.validators.step3.stevilkaNapotila()}
                                       value={store.form.step3!.napotiloStevilka!}
                                       onChange={(e) => store.form.step3!.napotiloStevilka = e.target.value}
                                       {...store.form.step3!.napotilo !== mladi_voznik.KazenskeTockeNapotilo.sodisce && {tabIndex: -1}}
                                   />
                               }/>

            </CvvCollapsible>
            <CvvCollapsible expanded={
                store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce ||
                store.form.step3!.napotilo === mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled
            }>
                <CvvInputField error={store.validators.step3.datumNapotila()}
                               label={'DATUM NAPOTILA'}
                               input={
                                   <DatepickerStyles
                                       invalid={!!store.validators.step3.datumNapotila()}>
                                       <DatePicker
                                           inputRef={dateRef as RefObject<HTMLInputElement>}
                                           value={store.step3!.datumNapotila}
                                           format={'dd. MM. y'}
                                           onChange={(date?: Date) => store.step3.setDatumNapotila(date)}
                                           defaultActiveStartDate={nowMinus6M}
                                       />
                                   </DatepickerStyles>
                               }/>
            </CvvCollapsible>
        </>}
        <CvvButton
            onClick={() => store.setStep(store.step + 1)}
            style={{marginTop: '64px'}}
            disabled={!store.stepValid(store.step, false)}>
            <theme.textTheme.headline5.span>NADALJUJ</theme.textTheme.headline5.span>
            <NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
        </CvvButton>
    </FormStepContainer>
})