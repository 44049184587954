import {SocketApi, SocketApiAckStatus} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {mladi_voznik} from "../proto/compiled";
import {proto} from "../proto/messages";
import {toast} from "react-toastify";
import {makeAutoObservable} from "mobx";

export interface CheckboxData {
    content: any;
    checked?: boolean;
    expanded?: boolean;
    children?: CheckboxData[];
    owner?: CheckboxData;
}

export class MladiVoznikStoreS6 {
    api: SocketApi;
    base: MladiVoznikStore;
    stDarilnegaBona: string = 'CVV';

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;
        makeAutoObservable(this);
    }

    init() {
        this.base.formStore!.onSave();
        this.api.sendMessage(proto.TxSaveMladiVoznikForm.create({
            form: this.base.form as mladi_voznik.IMladiVoznikForm,
            validationCode: this.base.step1.result?.validationCode,
            paymentType: this.base.formStore?.paymentType
        }), {ack: true}).then((status) => {
            if (status.status !== SocketApiAckStatus.success) {
                toast.error(status.errorMessage);
            }
        });
    }

    dispose() {
    }

    get done(): boolean {
        return false;
    };

    async submit() {
        return await this.api.sendMessage(proto.TxSubmitForm.create({
            validationCode: this.base.step1.result?.validationCode,
            paymentType: this.base.formStore?.paymentType,
            stDarilnegaBona: this.stDarilnegaBona
        }), {ack: true});
    }
}
