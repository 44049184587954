import {SocketApi} from "proto_socket_typescript";
import {MladiVoznikStore} from "./mladi_voznik_store";
import {CalendarController} from "../forms/components/calendar";
import {autorun, makeAutoObservable} from "mobx";
import {proto} from "../proto/messages";
import {cvv_types, calendar} from "../proto/compiled";
import {FormStore} from "./form_store";

export class MladiVoznikStoreS2 {
    subscriptions: any[] = [];
    initialized = false;
    api: SocketApi;
    base: MladiVoznikStore;
    error?: string;

    calendarController: CalendarController;
    selectedDay?: Date;
    calendarData?: calendar.CalendarData;
    selectedDayData?: calendar.CalendarDayData;

    constructor(base: MladiVoznikStore, api: SocketApi) {
        this.api = api;
        this.base = base;

        if (this.base.form.step2?.date?.dateTime?.ts) {
            const date = new Date(this.base.form.step2!.date!.dateTime!.ts as number);
            this.selectedDay = date;
            this.calendarController = new CalendarController(date.getFullYear(), date.getMonth())
        } else {
            const now = new Date();
            this.calendarController = new CalendarController(now.getFullYear(), now.getMonth())
        }

        this.subscriptions.push(api.getMessageHandler(new proto.RxCalendarData()).subscribe(
            (m) => this.onCalendarData(m)
        ));
        this.subscriptions.push(api.getMessageHandler(new proto.RxCalendarDayData()).subscribe(
            (m) => this.selectedDayData = m.proto
        ));
        makeAutoObservable(this);
    }

    init() {
        if (this.initialized) return;
        this.initialized = true;
        autorun(() => {
            this.calendarData = undefined;
            return [this.calendarController.month, this.calendarController.year]; // deps
        });
        autorun(() => this.updateCalendarMonth());
        autorun(() => this.updateCalendarDay());
    }

    dispose() {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    get done(): boolean {
        return this.base.api.authenticated || !!this.base.form.step2?.date;
    };

    updateCalendarDay() {
        if (!this.selectedDay) {
            this.selectedDayData = undefined;
            return;
        }
        this.api.sendMessage(proto.TxLoadCalendarDay.create({
            day: new cvv_types.Date({
                year: this.selectedDay.getFullYear(),
                month: this.selectedDay.getMonth() + 1,  // WTF javascript?!
                day: this.selectedDay.getDate(),
            }),
            tecajId: FormStore.meta?.tecaj?.id
        }));
    }

    updateCalendarMonth() {
        this.api.sendMessage(proto.TxLoadCalendar.create({
            month: new cvv_types.Date({
                year: this.calendarController.year,
                month: this.calendarController.month + 1,
            }),
            tecajId: FormStore.meta?.tecaj?.id
        }));
    }

    setSelectedDay(date?: Date) {
        if (this.selectedDay?.getDate() === date?.getDate()) {
            this.selectedDay = undefined;
        } else {
            this.selectedDay = date;
        }
        this.selectedDayData = undefined;
    }

    setDate(date: calendar.IAvailableLecture) {
        if (this.base.form.step2!.date?.id === date.id) {
            this.base.form.step2!.date = undefined;
        } else {
            this.base.form.step2!.date = date;
            this.base.formStore!.meta!.paymentTypes = date.paymentTypes ?? this.base.formStore!.meta!.paymentTypes;
            setTimeout(() => this.base.setStep(this.base.step + 1), 300);
        }
    }

    private onCalendarData(message: proto.RxCalendarData) {
        this.calendarData = message.proto;
        if (this.calendarData.daysWithLectures.length && !this.selectedDay) {
            this.setSelectedDay(
                new Date(
                    this.calendarController.year,
                    this.calendarController.month,
                    this.calendarData.daysWithLectures[0]
                )
            );
        }
    }
}
